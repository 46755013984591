import { configStore, enquiriesValidationStore, loginStore, modalStore } from "../state/CommonState"
import { useSnapshot } from "valtio"
import { Cargo, Container, enquiryStore, routeFlag } from "../enquiry/enquiry-data"
import { Box } from "@mui/material"


export default function Agproducttab(props) {
    useSnapshot(configStore)
    let productList = props.productList
    let activeProduct = props.activeProduct
    let setActiveProduct = props.setActiveProduct
    let onClick = props.onClick 
    useSnapshot(enquiryStore)
    useSnapshot(modalStore)
    return loginStore.isClient() ?
        <Box sx={{ display: "flex", height : props?.height || "max-content", flexDirection: "row", marginY:"5px",  ...(props.width && { width: props.width }), backgroundColor: props?.isGreyBackground ? "#F5F5F5" : "white", borderRadius: "20px", padding: "4px", fontSize: "14px", fontFamily: "Figtree" }}>
            {productList.map((e, i) => <Box onClick={() => {
                setActiveProduct(e)
                configStore.homescreenCurrentShipmentType = e;
                //changes
                enquiryStore.initialize();
                routeFlag.originRouteFlag = 'No'
                routeFlag.destinationFlag = 'No'
                enquiryStore.current.gst = configStore.currentSelectedEntity.gst;
                enquiryStore.current.createdBy = configStore.currentSelectedEntity.admin;
                enquiriesValidationStore.origin = false;
                enquiriesValidationStore.destination = false;
                enquiriesValidationStore.cargoReadinessDate = false;
                if(onClick){
                    onClick();
                }
            }} sx={{
                borderRadius: "20px", padding : "5px 10px",whiteSpace:"nowrap",backgroundColor: e === activeProduct ? "#2C358A" : "transparent", color:e === activeProduct ? "white" : "#555555", flex: 1, display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Figtree",
                "&:hover": {
                    cursor: "pointer"
                }
            }} >{e}</Box>)}
        </Box>
        :
        <div id="quotelistclient" className="tabs" style={{ marginTop: '10px', backgroundColor: '#2c358a' }}>
            <ul>
                {
                    productList.map(e => {
                        let className = ''
                        let styles = { color: 'lightgrey' }
                        if (e === activeProduct) {
                            className = 'is-active'
                            styles = { backgroundColor: '#eea724', color: 'black' }
                        }
                        styles.paddingTop = props.padding?props.padding:'2px'
                        styles.paddingBottom = props.padding?props.padding:'2px'
                        styles.paddingRight = '15px'
                        return <li className={className} onClick={() => {

                            setActiveProduct(e)
                            configStore.homescreenCurrentShipmentType = e;
                            //changes
                            enquiryStore.initialize();
                            routeFlag.originRouteFlag = 'No'
                            routeFlag.destinationFlag = 'No'
                            enquiryStore.current.gst = configStore.currentSelectedEntity.gst;
                            // enquiryStore.current.entityV2Id = configStore.currentSelectedEntity.entityId;
                            enquiryStore.current.createdBy = configStore.currentSelectedEntity.admin;
                            enquiriesValidationStore.origin = false;
                            enquiriesValidationStore.destination = false;
                            enquiriesValidationStore.cargoReadinessDate = false;
                            modalStore.checkingOrigin = false;
                            modalStore.checkingDestination = false
                            enquiryStore.current.countryFromEnquiry = 'India'
                        }}>
                            <a style={styles}><span>{e}</span></a>
                        </li>
                    })
                }
            </ul>
        </div>
}



