import { useSnapshot } from "valtio"
import { breadCrumbStore, filtersState } from "../state/CommonState"
import { CreateBooking } from "./CreateBooking"
import { ReviewDetailsPage } from "./PricingDetailsPage"
import { BreadCrumb2 } from "../mui-components/BreadCrumb2"
import { SettingsPage } from "./SettingPage"
import { HelpCenter } from "./HelpCenter"
import TransactionDetails from "./TransactionData";
import { ShippingDetailsV2 } from "./ShippingDetailsV2"
import { useEffect } from "react"
import { enquiryStore } from "../enquiry/enquiry-data"
import { Constants } from "../util/Utils"


const newdashboard = {
    "Create Booking" : <CreateBooking/>,
    "Booking Summary" : <ReviewDetailsPage/>,
    "Additional Details" : <ShippingDetailsV2/>,
    "Settings" : <SettingsPage/>,
    "Help Center" : <HelpCenter/>,
    "Transaction Data" : <TransactionDetails/>
}


export function Home(){
   const {currentComponent} =  useSnapshot(breadCrumbStore);
   useEffect(()=>{
  
    enquiryStore.initialize();
       return ()=>{
        filtersState.filteredList = [];
       }
       
   },[])

    return <div className="w-full flex flex-col gap-[8px] h-full">
         {/* <p className="text-blue-dark font-bold text-xl font-roboto">{currentComponent}</p> */}
         <BreadCrumb2/>
         { currentComponent ? <>{newdashboard[currentComponent]}</> : <>{newdashboard["Create Booking"]}</>}
    </div>
}