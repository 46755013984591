import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useSnapshot } from "valtio";
import { viewBookingStore } from "../state/CommonState";
import { AttributeDetails } from "../mui-components/AttributeValueBox";
import Utils from "../util/Utils";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { enquiryStore } from "../enquiry/enquiry-data";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#2B6ED408",
      color: "black",
      fontSize: 14,
      fontWeight: "bold"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
      borderColor: "#D4D4D4",
    },
  }));


function GetProgress({percentage,actual,total}){
    if(actual && actual > 100){
        actual = 100;
    }
    return  <Box sx={{display:"flex",flexDirection:"row",gap:2,alignItems:"center"}}>
        <Typography sx={{fontFamily:"Figtree"}}>Cargo Delivered : <span style={{fontWeight:"bold"}}>{actual} / {total}</span></Typography>
                        <Box sx={{ height: "8px", width: "146px", backgroundColor: "#F5F5F5", borderRadius: "66px" }}>
                        <Box sx={{ height: "100%", width: `${percentage}%`, borderRadius: "66px", backgroundColor: "#2C8A57" }}></Box>
                    </Box>
</Box>
}  


function getPercentage(actual,total){
    if(!actual || !total){
        return 0;
    }

    if(actual>=total){
        return 100;
    }

    let percentage = (actual/total);
    
    return percentage*100;
}

function getActualBundlesCount(vehicleDetails,keyFrom){
    const booking = viewBookingStore.current;
    let actualBundles = 0;
    let key = "actual_delivery_date"
    vehicleDetails.forEach((vehicle)=>{
        if(vehicle[key]?.length>0){
            if(vehicle["number_of_package"]?.length>0){
                actualBundles += parseInt(vehicle["number_of_package"])
            }
        }
    })

    for(let i=1;i<=5;i++){
        let searchFrom = `${keyFrom}${i+1}`
         if(!booking[searchFrom]){
             break;
         }
         booking[searchFrom].forEach((vehicle)=>{
            if(vehicle[key]?.length>0){
                if(vehicle["number_of_package"]?.length>0){
                    actualBundles += parseInt(vehicle["number_of_package"])
                }
            }
        })
    }

    return actualBundles;
}

function OriginPickUp({vehicles,type}){
    const isFcl = type.includes("FCL");
    const isLcl = type.includes("LCL");
    const isAir = type.includes("Air");
    const originVehicles = [
        {label :"origin",key:"origin",type:"string"},
        {label :"destination",key:"destination",type:"string"},
        {label :"Driver Name",key:"driver_name",type:"string"},
        {label :"Driver Number",key:"driver_number",type:"string"},
        {label :"Vehicle Number",key:"vehicle",type:"string"},
        {label :"Arrival @ Factory",key:"arrival_factory",type:"date"},
        {label :"Cargo Pickup",key:"cargo_pickup",type:"date"},
       
    ]

    if(isLcl||isFcl){
        originVehicles.push({label :"Cargo Arrival at CFS",key:"cargo_arrival_cfs",type:"date"})
    }
    
    if(isAir){
        originVehicles.push({label :"Arrival @ Dispatch Airport",key:"arrival_dispatch_airport",type:"date"})
    }

      return <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
      <Typography sx={{fontFamily:'Figtree',fontSize:"16px",color:"#555555",fontWeight:"bold"}}>Origin Pick-up</Typography>
      {vehicles.map((vehicle)=><AttributeDetails details={originVehicles.map((originVehicle)=>({title:originVehicle.label,value:originVehicle.type==="date"?Utils.formatDateBooking(vehicle[originVehicle.key],false):vehicle[originVehicle.key]}))} />)}
  </Box>
}

function DestinationPickUp({vehicles,type}){
    const isFcl = type.includes("FCL");
    const isLcl = type.includes("LCL");
    const isAir = type.includes("Air");
    let destinationVehicles = [
        {label :"Vehicle Number",key:"vehicle",type:"string"},
        
        {label :"Estimated Delivery Date to consignee",key:"estimate_delivery_date_to_consignee",type:"date"},
        {label :"Actual Delivery Date",key:"actual_delivery_date",type:"date"},
    ]

    if(isFcl||isLcl){
        destinationVehicles = [
            ...destinationVehicles.slice(0,1),
        {label :"Loaded at CFS",key:"loaded_cfs",type:"date"},
        {label :isFcl? "Dispatched from CFS": "Cargo Picked-up for Delivery",key:"atdfrompod",type:"date"},
        ...destinationVehicles.slice(1)
        ]
    }

    if(isAir){
        destinationVehicles = [
            ...destinationVehicles.slice(0,1),
        {label :"Dispatched from Arrival Airport",key:"dispatched_from_arrival_airport",type:"date"},
        ...destinationVehicles.slice(1)
        ]
    }

    

    return <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
    <Typography sx={{fontFamily:'Figtree',fontSize:"16px",color:"#555555",fontWeight:"bold"}}>Destination Pick-up</Typography>
    {vehicles.map((vehicle)=><AttributeDetails details={destinationVehicles.map((destinationVehicle)=>({title:destinationVehicle.label,value:destinationVehicle.type==="date"?Utils.formatDateBooking(vehicle[destinationVehicle.key],false):vehicle[destinationVehicle.key]}))} />)}
</Box>
}

// export function VehicleDetailWithoutProgress(){
//     return <Box sx={{display:"flex",flexDirection:"column",gap:1,paddingX:"10px"}}>
//     {booking.vorgin && booking.vorgin.length > 0 && <OriginPickUp vehicles={booking.vorgin} type={booking.shipmentType}/>}
//     {booking.vdes && booking.vdes.length > 0 && <DestinationPickUp vehicles={booking.vdes} type={booking.shipmentType}/>}
// </Box>
// }

export function VehicleDetail(){
    const booking = viewBookingStore.current;
    const final = [];
    if(booking.vorgin && booking.vorgin.length > 0){
        if( booking.vorgin[0]["number_of_package"]?.length>0){
         final.push(<ProgressOriginPickup vehicles={booking.vorgin} type={booking.shipmentType} count={booking?.booking?.contract?.cargoTotals?.totNumPackages}/>)
        }else{
        final.push(<OriginPickUp vehicles={booking.vorgin} type={booking.shipmentType}/>)
        }
    }

    if(booking.vdes && booking.vdes.length > 0){
        // if( booking.vdes[0]["number_of_package"]){
         final.push(<ProgressDestination index={0} vehicles={booking.vdes} showProgress={true} type={booking.shipmentType} count={booking?.booking?.contract?.cargoTotals?.totNumPackages}/>)
        // }else{
        // final.push(<DestinationPickUp vehicles={booking.vdes} type={booking.shipmentType}/>)
        // }
    }

    for(let i = 1;i<=5;i++){
        let vdes = `vdes${i+1}`;
        if(!booking[vdes]){
            break;
        }
    final.push(<ProgressDestination index={i} vehicles={booking[vdes]} type={booking.shipmentType} count={booking?.booking?.contract?.cargoTotals?.totNumPackages}/>)
    }

    return  <Box sx={{display:"flex",flexDirection:"column",gap:3,paddingX:"10px"}}>
                  {final.map((comp)=>comp)}
    </Box>  
}

export function ProgressOriginPickup({vehicles,type}){
    const isFcl = type.includes("FCL");
    const isLcl = type.includes("LCL");
    const isAir = type.includes("Air");
    const count = vehicles[0]["total_package"];
    const bundlesCount = getActualBundlesCount(vehicles,"vorgin");
    const percentage = getPercentage(bundlesCount,parseInt(count))
    const originVehicles = [
        {label :"origin",key:"origin",type:"string"},
        {label :"destination",key:"destination",type:"string"},
        {label :"Driver Name",key:"driver_name",type:"string"},
        {label :"Driver Number",key:"driver_number",type:"string"},
        {label :"Vehicle Number",key:"vehicle",type:"string"},
        {label :"Bundles",key:"number_of_package",type:"string"},
        {label :"Arrival @ Factory",key:"arrival_factory",type:"date"},
        {label :"Cargo Pickup",key:"cargo_pickup",type:"date"},
       
    ]

    if(isLcl||isFcl){
        originVehicles.push({label :"Cargo Arrival at CFS",key:"cargo_arrival_cfs",type:"date"})
    }
    
    if(isAir){
        originVehicles.push({label :"Arrival @ Dispatch Airport",key:"arrival_dispatch_airport",type:"date"})
    }

    return <Box sx={{width:"100%",display:"flex",flexDirection:"column",gap:1}}>
        <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between"}}>
        <Typography sx={{fontFamily:'Figtree',fontSize:"16px",color:"#555555",fontWeight:"bold"}}>Origin Pick-up Details</Typography>
        <GetProgress percentage={percentage} actual={bundlesCount} total={count} />
        </Box>
        <TableContainer
    sx={{ border: 1, borderColor: "#D4D4D4", width: "100%", borderRadius: "10px"}}
  >
      <Table sx={{ width: "100%" }} aria-label="customized table">
      <TableHead>
          <TableRow>
            {originVehicles.map((vehicleDetail)=> <StyledTableCell>{vehicleDetail.label}</StyledTableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
         {vehicles.map((vehicle)=> <StyledTableRow >
{originVehicles.map((originDetail)=><StyledTableCell align="right"><Typography sx={{ display: "flex", fontFamily: "Figtree" }}>{originDetail.type==="date"?Utils.formatDateBooking(vehicle[originDetail.key],false):vehicle[originDetail.key]}</Typography></StyledTableCell>)}
         </StyledTableRow>)}
        </TableBody>
      </Table>
  </TableContainer>
    </Box>
}

export function ProgressDestination({vehicles,type,index,showProgress=false}){
    const isFcl = type.includes("FCL");
    const isLcl = type.includes("LCL");
    const isAir = type.includes("Air");
    const booking = viewBookingStore.current;
    let destinationVehicles = [
        {label :"origin",key:"origin",type:"string"},
        {label :"destination",key:"destination",type:"string"},
        {label :"Driver Name",key:"driver_name",type:"string"},
        {label :"Driver Number",key:"driver_number",type:"string"},
        {label :"Vehicle Number",key:"vehicle",type:"string"},
        {label :"Bundles",key:"number_of_package",type:"string"},
        {label :"Estimated Delivery Date to consignee",key:"estimate_delivery_date_to_consignee",type:"date"},
        {label :"Actual Delivery Date",key:"actual_delivery_date",type:"date"},
    ]

    if(isFcl){
        destinationVehicles = [
            ...destinationVehicles.slice(0,1),
            {label :"Container Number",key:"container_number_vehicle",type:"string"},
            ...destinationVehicles.slice(1),
        ]
    }

    if(isFcl||isLcl){
        destinationVehicles = [
            ...destinationVehicles.slice(0,5),
        {label :"Loaded at CFS",key:"loaded_cfs",type:"date"},
        {label :isFcl? "Dispatched from CFS": "Cargo Picked-up for Delivery",key:"atdfrompod",type:"date"},
        ...destinationVehicles.slice(5)
        ]
    }

    if(isAir){
        destinationVehicles = [
            ...destinationVehicles.slice(0,5),
        {label :"Dispatched from Arrival Airport",key:"dispatched_from_arrival_airport",type:"date"},
        ...destinationVehicles.slice(5)
        ]
    }
    return <Box sx={{width:"100%",display:"flex",flexDirection:"column",gap:1}}>
   { showProgress && (()=>{
     const count = vehicles[0]["total_package"];
     const bundlesCount = getActualBundlesCount(vehicles,"vdes");
     const percentage = getPercentage(bundlesCount,parseInt(count))
    return <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between"}}>
    <Typography sx={{fontFamily:'Figtree',fontSize:"16px",color:"#555555",fontWeight:"bold"}}>Destination Delivery Details</Typography>
    <GetProgress percentage={percentage} actual={bundlesCount} total={count} />
    </Box>})()}
   {booking.vdes2 && <p>Destination {index+1}</p>}
    <TableContainer
    sx={{ border: 1, borderColor: "#D4D4D4", width: "100%", borderRadius: "10px"}}
  >
      <Table sx={{ width: "100%" }} aria-label="customized table">
      <TableHead>
          <TableRow>
            {destinationVehicles.map((vehicleDetail)=> <StyledTableCell>{vehicleDetail.label}</StyledTableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
         {vehicles.map((vehicle)=> <StyledTableRow >
{destinationVehicles.map((originDetail)=><StyledTableCell align="right"><Typography sx={{ display: "flex", fontFamily: "Figtree" }}>{originDetail.type==="date"?Utils.formatDateBooking(vehicle[originDetail.key],false):vehicle[originDetail.key]}</Typography></StyledTableCell>)}
         </StyledTableRow>)}
        </TableBody>
      </Table>
  </TableContainer> 
  </Box>
}



export function ProgressVehicleDetail(){
    const booking = viewBookingStore.current;
    return <Box sx={{display:"flex",flexDirection:"column",gap:1,paddingX:"10px"}}>
           {booking.vorgin && booking.vorgin.length > 0 && <ProgressOriginPickup vehicles={booking.vorgin} type={booking.shipmentType}/>}
           {booking.vdes && booking.vdes.length > 0 && <ProgressDestination vehicles={booking.vdes} type={booking.shipmentType}/>}
    </Box>
}


export function VehicleDetailsViewForQuoation(){
    const vehicles = enquiryStore.current.vehicles;
    return <Box sx={{display:"flex",flexDirection:"row",flexWrap:"wrap",justifyContent:"start",width:"100%",paddingX:"10px"}}>
    {vehicles.map((vehicle)=> {
        const vehicleCount = parseInt(vehicle.numVehicles);
        return vehicleCount && vehicleCount>0 ? <Typography sx={{fontFamily:"Figtree",fontSize:"16px",color:"#555555",minWidth:"250px",maxWidth:"250px"}}>{vehicle.numVehicles} x {enquiryStore.current.vehicleDetails.fields[vehicle.vType]}</Typography> : <></>})}
    </Box> 
}