import { useSnapshot } from "valtio"
import { configStore, contractsStore, entityStore, loginStore, bookingsStore } from "../state/CommonState"
import { $c, $cg, $cr, AgInput, AgSelect } from '../components/AgComponents'
import EntityApi from "../state/entity-api"
import AguserApi from "../state/AguserApi"
import { useEffect, useState } from "react"
import Api from "../state/Api"
import { MSDS_AND_DG_FILE_MISSING_WARN, Roles, ServerLists } from "../StaticData"
import stateList from "../static-data/states"
import { AgCustomFile } from "../components/AgFile"
import LCLScheduleComp from "./LCLScheduleComp"
import BookingsApi from "../state/bookings-api"
import { incoTermsList, commodityList, hazardousList } from "../StaticData"
import BookingConfirmation from "../schedules/booking-confirmation"
import Agswitch from "../components/ag-switch"
import { Agyesno } from "../components/Agyesno"
import FCLScheduleComp from "./FCLScheduleComp"
import AirScheduleComp from "./AirScheduleComp"
import { AgWarnPopup } from "../components/AgCustomComponents"
import { DummySchedule } from "./dummy-schedule"

export default function CarrierWebForm(props) {
    let url = window.location.href
    const [space, setSpace] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [incoEditable, setIncoEditable] = useState(true)
    const [ctypeEditable, setCtypeEditable] = useState(true)
    const [isWarnAccepted, setIsWarnAccepted] = useState(false);
    useSnapshot(loginStore)
    useSnapshot(bookingsStore)
    useSnapshot(contractsStore)
    useSnapshot(configStore)
    useSnapshot(entityStore)
    let brec = bookingsStore.current
    const aguser = loginStore.userRec.aguserRec
    const [hasToAutoPopulateEntityDetail, setHasToAutoPopulateEntityDetail] = useState(false);

    useEffect(() => {
        Api.loadCountryList()
        EntityApi.getList();
        if (contractsStore.current.incoTerms && 
            contractsStore.current.incoTerms.length > 0) 
                setIncoEditable(false)
        if (contractsStore.current.commodityType &&
            contractsStore.current.commodityType.length > 0)
                setCtypeEditable(false)
        brec.firstName = aguser.firstName
        brec.lastName = aguser.lastName
        brec.phone = aguser.phone
        brec.emailid = loginStore.email
        brec.incoTerms = contractsStore.current.incoTerms
        brec.commodityType = contractsStore.current.commodityType
        brec.carrierMBL = 'No'
        // if(contractsStore.current.carrierMBL){
        //     brec.carrierMBL = contractsStore.current.carrierMBL
        // }else{
        //     brec.carrierMBL = 'No'
        // }

        const entity = entityStore.list.find(e1 => {
            return e1.gst === brec.contract.gst
        })
        if(!entity) {
            // setCompanyName(brec.contract.gst);
            return ;
        }
        let fromLoc =  brec.contract.shipmentType.toLowerCase().includes('air') ? 
            brec.contract.originAirport
            : brec.contract.portOfReceipt
        // let fpodCountry = brec.contract.finalPlaceOfDelivery.substring(brec.contract.portOfReceipt.indexOf(',')+1,brec.contract.portOfReceipt.indexOf("(")).trim();
        
        if(fromLoc.toLowerCase().includes(entity.country.toLowerCase())){
            setHasToAutoPopulateEntityDetail(true);
            brec.company = brec.contract.gst
            setCompanyName(entity.entityName)
            brec.address = entity.address
            brec.country = entity.country
            brec.pin = entity.pinCode
        }
        // if(hasToAutoPopulateEntityDetail){
        //     brec.company = brec.contract.gst
        //     console.log('GOT COMPANY AS ========', brec.company, entityStore.list.length)
        //     const ent = entityStore.list.filter(e1 => e1.gst === brec.company)
        //     if (ent.length > 0) {
        //         let entity = ent[0]
        //         console.log('entity found =', entity)
        //         setCompanyName(entity.entityName)
        //         brec.address = entity.address
        //         brec.country = entity.country
        //         brec.pin = entity.pinCode
        //     } else {
        //         setCompanyName(brec.contract.gst)
        //     }
        // }
        console.log('******urgentbooking', JSON.stringify(brec))
    }, [ bookingsStore.current])
    const domain = loginStore.email.split('@')[1]

    let title = 'Carrier Web Form'
    useSnapshot(contractsStore)

    const entity1 = entityStore.entityRec
    const renderLink = (fileName) => {
        if (fileName && fileName.length > 3) {
            return (
                <a href={process.env.REACT_APP_FILE_SERVER + '/clientfiles/' + fileName} target="_blank">{fileName}</a>
            )
        } else return <></>
    }
    const filecat = '' + Date.now()
    const enableMBL = () => (contractsStore.current.shipmentType.indexOf('FCL') >= 0
        && contractsStore.current.shipmentScope.endsWith('ort')) ? {} : { disabled: 1 }
    let commList = commodityList
    if (brec.contract.hazardous === 'Yes') commList = hazardousList
    const stype = () => brec.contract.shipmentType.toUpperCase().substr(0, 3)

    const onSubmit = () => {
        if (!brec.incoTerms || brec.incoTerms.length < 1) {
            configStore.setModalMessage('IncoTerms Mandatory')
            return
        }
        if (!brec.commodityType || brec.commodityType.length < 1) {
            configStore.setModalMessage('Commodity Type Mandatory')
            return
        }
        if (!brec.firstName || brec.firstName.length < 1) {
            configStore.setModalMessage('First Name is Mandatory')
            return
        }
        if (!brec.lastName || brec.lastName.length < 1) {
            // configStore.setModalMessage('Last Name is Mandatory')
            return
        }
        if (!brec.emailid || brec.emailid.length < 1) {
            configStore.setModalMessage('Email is Mandatory')
            return
        }
        if (!brec.phone || brec.phone.length < 10) {
            configStore.setModalMessage('Phone number is Mandatory')
            return
        }
        if(!brec.company 
            // || !brec.address || !brec.pin || !brec.country
            ){
            // configStore.setModalMessage('Please enter all company details')
            configStore.setModalMessage('Please enter shipper name.')
            return
        }
        if (brec.contract.hazardous === 'Yes') {
            // if (!brec.msds_filename || brec.msds_filename.length < 1) {
            //     configStore.setModalMessage('MSDS is Mandatory')
            //     return
            // }
            // if (!brec.dgdecl_filename || brec.dgdecl_filename.length < 1) {
            //     configStore.setModalMessage('DG Declaration is Mandatory')
            //     return
            // }
            if (!brec.iipcert_filename || brec.iipcert_filename.length < 1) {
                configStore.setModalMessage('IIP Certificate is Mandatory')
                return
            }
        }
        if (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals')) {
            if((!brec.msds_filename || brec.msds_filename.length < 1) || (!brec.nondgdecl_filename || brec.nondgdecl_filename.length < 1)){
                if (!configStore.hasToShowAgWarn && !isWarnAccepted) {
                    configStore.hasToShowAgWarn = true;
                    return ;
                }
            }
            // if (!brec.msds_filename || brec.msds_filename.length < 1) {
            //     configStore.setModalMessage('MSDS is Mandatory')
            //     return
            // }
            // if (!brec.nondgdecl_filename || brec.nondgdecl_filename.length < 1) {
            //     configStore.setModalMessage('Non-Haz Declaration is Mandatory')
            //     return
            // }
        }
        
    BookingsApi.saveBooking()
    let rec = contractsStore.current
    let S_Email = entityStore.getCustomerSEmail(rec.gst)
    let C_Email = entityStore.getCustomerCEmail(rec.gst)
    let CustomerName = entityStore.getCustomer4gstOnly(rec.gst)
    let shipType = rec.shipmentType
    let shipScope = rec.shipmentScope
    let origin = rec.origin
    let destination = rec.destination
        setTimeout(()=>{
            let from = `Agraga <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
            let to = `${S_Email},${C_Email}`
            if(url.indexOf('booking.agraga.com')>=0){
               to = to+',sanjeev.gupta@agraga.com' 
            }
            // let to = `${S_Email},${C_Email},sanjeev.gupta@agraga.com`;
            let subject = 'Booking - '+configStore.bookingModalNum
            let message = `Hi Team,<br />
            <br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; We have received a booking from ${CustomerName}.<br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;     Booking Number: ${configStore.bookingModalNum}.<br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    Shipment Type: ${shipType}.<br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    Scope :${shipScope}.<br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    Origin: ${origin}.<br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;     Destination: ${destination}.</p>
            <br />
            Team Agraga</p>`
    
           Api.sendClientMailwithoutMessage(from, to, subject, message)
        },2000)
        
        
    };
    if(contractsStore.current.fba!=undefined && (brec.fba==undefined || contractsStore.current.fba!=brec.fba)){
        brec.fba=contractsStore.current.fba
    }
    return (
        <div style={{ fontSize: '0.8rem' }}>
            <BookingConfirmation />
        <AgWarnPopup callback = {() => {
            setIsWarnAccepted(true);
            onSubmit();
        }}>
            <p className="is-size-7">&emsp;&emsp;{MSDS_AND_DG_FILE_MISSING_WARN}</p>
        </AgWarnPopup>
            {
                bookingsStore.current?.hasToShowDummySchedule ? <DummySchedule stype={stype()} viewonly={true} />
                : stype() === 'LCL' ?
                    <LCLScheduleComp data={bookingsStore.current.schedule} viewonly={true} /> :
                    stype() === 'FCL' ?
                        <FCLScheduleComp data={bookingsStore.current.schedule} viewonly={true} /> :
                        <AirScheduleComp />
            }

            <div className="columns">
                <$cr n='2'>First Name{space}</$cr>
                <$c n='3'>
                    <input className="input is-small" value={brec.firstName}
                        onChange={(e) => brec.firstName = e.target.value} />
                </$c>
                <$cr n='2'>Last Name</$cr>
                <$c n='3'>
                    <input className="input is-small" value={brec.lastName}
                        onChange={(e) => brec.lastName = e.target.value} />
                </$c>
            </div>
            <div className="columns">
                <$cr n='2'>Email ID</$cr>
                <$c n='3'>
                    <input className="input is-small" value={brec.emailid}
                        onChange={(e) => brec.emailid = e.target.value} />
                </$c>
                <$cr n='2'>Contact Number</$cr>
                <$c n='3'>
                    <input className="input is-small" value={brec.phone} min="10"
                        onChange={(e) => brec.phone = e.target.value}  onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                          }
                           onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10);
                          }} />
                </$c>
            </div>
            <div className="columns">
                <$cr n='2'>Shipper Name</$cr>
                <$c n='3'>
                    {/* <div class="control">
                        <div class="select">
                            <select className="select is-fullwidth is-small" value={brec.company}
                                onChange={(e) => {
                                    brec.company = e.target.value
                                    const ent = entityStore.list.filter(e1 => e1.gst === e.target.value)[0]
                                    brec.address = ent.address
                                    brec.country = ent.country
                                    //setSpace(space===''?' ':'')
                                    console.log('Address', ent.address, brec.address)
                                }} >
                                <option value=''></option>
                                {entityStore.list.map(e => <option value={e.gst}>{e.entityName} - {e.state}</option>)}
                            </select>
                        </div>
                    </div> */}
                    {   
                        hasToAutoPopulateEntityDetail 
                            ? companyName
                            : (<textarea className="textarea is-small" value={brec.company}
                                onChange={(e) => brec.company = e.target.value} />)
                    }   
                </$c>
                <$cr n='2'>Address</$cr>
                <$c n='3'>
                    {
                        hasToAutoPopulateEntityDetail ?  brec.address
                       : <textarea className="textarea" value={brec.address}
                        onChange={(e) => brec.address = e.target.value} />
                    }
                </$c>
            </div>
            <div className="columns is-narrow">
                <$cr n='2'>PIN/ZIP Code</$cr>
                <$c n='3'>
                    {
                        hasToAutoPopulateEntityDetail ?  brec.pin
                       : <input className="input is-small" value={brec.pin}
                        onChange={(e) => brec.pin = e.target.value} />
                    }
                </$c>
                <$cr n='2'>Country</$cr>
                <$c n='3'>
                   {
                        hasToAutoPopulateEntityDetail 
                        ?  brec.country
                        : <div class="control">
                                <div class="select">
                                    <select className="select is-fullwidth is-small" value={ brec.country}
                                        onChange={(e) => {
                                            brec.country = e.target.value
                                        }} >
                                        {ServerLists.countryNameList.map(e => <option value={e}>{e}</option>)}
                                    </select>
                                </div>
                            </div>
                   }
                </$c>
            </div>

            <div className="columns">
                <$cr n='2'>Incoterms</$cr>
                <$c n='3'>
                    {
                        (incoEditable) ?
                        <div class="control">
                        <div class="select">
                            <select className="select is-fullwidth is-small" value={brec.incoTerms}
                                onChange={(e) => {
                                    brec.incoTerms = e.target.value
                                }} >
                                {incoTermsList.map(e => <option value={e}>{e}</option>)}
                            </select></div></div> : brec.incoTerms
                    }
                </$c>
                <$cr n='2'>Commodity Type</$cr>
                <$c n='3'>
                    {
                        (ctypeEditable) ?
                            <>
                                <input defaultValue={brec.commodityType} list='commodities'
                                    onBlur={(e) => brec.commodityType = e.target.value}
                                    className="input is-small" />
                                <datalist id='commodities'>
                                    {
                                        commList.map(e => <option value={e} />)
                                    }
                                </ datalist>
                            </> : brec.commodityType
                    }
                </$c>
            </div>
            <div className="columns is-narrow">      
                <$cr n='2'>FBA</$cr>       
                <$c n='2'>
                    {
                        (contractsStore.current.fba!=undefined)?<Agyesno disabled={true} label='' record={brec} name='fba' />:
                        <Agyesno label='' record={brec} name='fba' />
                    }
                </$c>
                <$c n='8'>
                </$c>
            </div>
            <div className="columns is-narrow">
                <$cr n='2'>Special Instructions</$cr>
                <$c n='6'>
                    <textarea rows='3' style={{ fontSize: '0.8rem' }} className="textarea" value={brec.specialInstructions}
                        onChange={(e) => brec.specialInstructions = e.target.value} />
                </$c>
                <$c n='2'>
                    {
                        // (enableMBL().disabled )
                        (contractsStore.current.shipmentType.toLowerCase().includes('fcl') 
                        && contractsStore.current.shipmentScope.endsWith('ort')) ?
                            <Agyesno label='Do you require a Direct Carrier MBL?'
                                record={brec} name='carrierMBL' callback = {val => brec.carrierMBL = val} /> : null
                    }
                </$c>
            </div>

            <div className="columns">
                <$c n='1'></$c>
                <$c n='9'>
                    <div className="table-container ">
                        <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable ">
                            <thead>
                                <tr>
                                    <th style={{ width: '16rem' }}>Required Documents</th>
                                    <th>Document Uploaded</th>
                                    <th style={{ width: '12rem' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                         <tr id="cwf5">
                                            <td>Commercial Invoice (Non-Mandatory)</td>
                                            <td>
                                                    { brec.commercialInvoice_filename_list.map(renderLink).map(link => <>{link}<br/></>)}
                                            </td>
                                            <td>
                                                <AgCustomFile source='carrierwebform' cat={filecat} filetype='commercialInvoice' parent="cwf5" multi={true}
                                                    callback={(filename) => brec.commercialInvoice_filename_list.push(filename)} withBtn = {brec.commercialInvoice_filename_list.length > 0} />
                                            </td>
                                        </tr>
                                        <tr id="cwf6">
                                            <td>Packing List (Non-Mandatory)</td>
                                            <td>{brec.packingList_filename_list.map(renderLink).map(link => <>{link}<br/></>)}</td>
                                            <td>
                                                <AgCustomFile source='carrierwebform' cat={filecat} filetype='packingList' parent="cwf6" multi={true}
                                                    callback={(filename) => brec.packingList_filename_list.push(filename)} withBtn = {brec.packingList_filename_list.length > 0} />
                                            </td>
                                        </tr>
                                {
                                    (
                                        (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals'))
                                        || (brec.contract.hazardous === 'Yes')
                                    ) ?
                                        <tr id="cwf1">
                                            <td>MSDS</td>
                                            <td>{renderLink(brec.msds_filename)}</td>
                                            <td>
                                                <AgCustomFile source='carrierwebform' cat={filecat} filetype='msds' multi={false}
                                                    callback={(filename) => brec.msds_filename = filename} parent="cwf1"/>
                                            </td>
                                        </tr>
                                        : <></>
                                }
                                {
                                    (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals')) ?
                                        <tr id="cwf2">
                                            <td>Non-DG Declaration <a className="is-link" href="./Non_Hazardous_Declaration.doc" download>(Download Template)</a></td>
                                            <td>{renderLink(brec.nondgdecl_filename)}</td>
                                            <td>
                                                <AgCustomFile cat={filecat} filetype='nondgdecl' multi={false}
                                                    callback={(filename) => brec.nondgdecl_filename = filename} parent="cwf2" />
                                            </td>
                                        </tr> : <></>
                                }
                                {
                                    (brec.contract.hazardous === 'Yes') ?
                                        <>
                                            <tr id="cwf3">
                                                <td>DG Declaration <a className="is-link" href="./DG_Declaration_updated.doc" download>(Download Template)</a></td>
                                                <td>{renderLink(brec.dgdecl_filename)}</td>
                                                <td>
                                                    <AgCustomFile cat={filecat} filetype='dgdecl' multi={false}
                                                        callback={(filename) => brec.dgdecl_filename = filename} parent="cwf3" />
                                                </td>
                                            </tr>
                                            <tr id="cwf4">
                                                <td>IIP Certificate</td>
                                                <td>{renderLink(brec.iipcert_filename)}</td>
                                                <td>
                                                    <AgCustomFile cat={filecat} filetype='iipcert' multi={false}
                                                        callback={(filename) => brec.iipcert_filename = filename} parent="cwf4" />
                                                </td>
                                            </tr>
                                        </> : <></>
                                }
                            </tbody>
                        </table>
                    </div>
                </$c>
            </div>
            <div className="columns">
                <$cr n='1'></$cr>
                <$c n='9'>
                    <button className="button is-warning" onClick={(e) => {
                        configStore.scheduleOrCarrierform = 'schedule'
                    }}>
                        <span className="icon" style={{ marginRight: '5px' }}>
                            <i class="fa-solid fa-circle-left"></i>
                        </span>
                        Schedules
                    </button>
                    <div class="buttons" style={{ float: 'right' }}>
                    <button className="button is-danger is-right"  onClick={(e) => {
                            bookingsStore.current = {};
                            configStore.scheduleOrCarrierform = 'schedule'
                    }} > Cancel </button>
                    <button className="button is-link"  onClick={onSubmit}>
                        Confirm and Save Booking
                    </button>
                    </div>
                </$c>
            </div>
        </div>
    )
}
