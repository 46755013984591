import { Box, CircularProgress, Collapse, IconButton, InputAdornment, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { VisibilityTracker, VisibilityTrackerV2 } from "../mui-components/VisibilityTracker";
import { CiSearch } from "react-icons/ci";
import { Button } from "@mui/material";
import downloadicon from "../assets/images/downloadbutton.svg";
import Agproducttab from "../components/Agproducttab";
import {  useEffect, useReducer, useRef, useState } from "react";
import documentCenter from "../assets/images/document.svg";
import details from "../assets/images/details.svg";
import shipmenticon from "../assets/images/shipmenticon.svg";
import { useSnapshot } from "valtio";
import { bookingsStore, breadCrumbStore, configStore, viewBookingStore } from "../state/CommonState.js";
import { enquiryStore } from "../enquiry/enquiry-data.js";
import Utils from "../util/Utils.js";
import airicon from "../assets/images/airicon.svg";
import { GetProgressBar } from "../mui-components/GetProgress.js";
import ApiAgTable from "../components/ag-table/api_ag-table.js";
import { Nodata } from "../mui-components/nodatafound.js";
import { CenteredCircularProgress } from "../mui-components/CircularProgress.js";
import { SelectedBooking } from "./SelectedBooking.js";
import { Points } from "../mui-components/Points.js";
import { ShareBooking } from "../mui-components/ShareBooking.js";
import { MultiDestToolTip } from "../mui-components/MultiDestToolTip.js";
import logo from "../assets/images/logo.svg";
import { BookingCard } from "../mui-components/BookingCard.js";
import { PAGE_CHANGED, PAGE_SIZE_CHANGED, PAGE_SORT_CHANGED, PAYLOAD_CHANGED, reducer, TOTAL_COUNT_CHANGED } from "../components/ag-table/ag-reducer.js";
import { QueryClient, QueryClientProvider } from "react-query";
import { usePagination, useSortBy, useTable } from "react-table";
import { DRS_COLUMNS } from "../components/ag-table/columns.js";
import { myactionsstore } from "../my-actions/store-myactions.js";
import ReactTablePagination from "../components/ag-table/ReactTablePagination.js";
import { BreadCrumb2 } from "../mui-components/BreadCrumb2.js";









function getStatusColor(status) {
    const colorFinder = {
        inprogress: {
            backgroundColor: "#EBB02D33",
            color: "#D59A17"
        },
        submitted: {
            backgroundColor: "#717AD233",
            color: "#717AD2"
        },
        archived: {
            backgroundColor: "#2C8A5733",
            color: "#2C8A57"
        },
        cancelled: {
            backgroundColor: "#F25B5B33",
            color: "#F25B5B"
        },
        "cancellation requested" : {
            backgroundColor : "#FF751633",
            color : "#FF7516"
        }
    }
    return colorFinder[status.toLowerCase()];
}

const statusTag = {
    submitted: "Yet to commence",
    inprogress: "In-Transit",
    archived: "Completed",
    cancelled: "Cancelled",
    "cancellation requested" : "Cancellation Requested"
}

// function BookingCard({ booking, showButtons = true }) {
//     const currentEnquiry = booking.booking.contract;
//     console.log("the contract is ", currentEnquiry);
//     const shipmentType = currentEnquiry.shipmentType.split(" ")[0];
//     const isOriginDoor = currentEnquiry.shipmentScope.split("-")[0].toLowerCase() === "door";
//     const isDestinationDoor = currentEnquiry.shipmentScope.split("-")[2].toLowerCase() === "door";
//     let legendColor = getStatusColor(booking.booking.status);
//     const {isImport,isExport} = Utils.getBookingsImportAndExportStatus(booking);
//     if (!legendColor) {
//         legendColor = {
//             backgroundColor: "#555555",
//             color: "black"
//         }
//     }


//     const getDate = (isDestination=false)=>{
//         if(isDestination){
//             if(shipmentType.toLowerCase() === "air" ){
//                  return booking.ata_at_arrival_airport?.length >0 ? 
//                 { date : Utils.formatDateBooking(booking.ata_at_arrival_airport),arrived:true}
//                  :
//                 {date : Utils.formatDateBooking(booking.eta_at_arrival_airport),arrived:false}
//             }else{
//                 if(shipmentType.toLowerCase()==="lcl"){
//                      return booking.gatein_fpod?.length > 0 ? 
//                     { date : Utils.formatDateBooking(booking.gatein_fpod),arrived:true}
//                      : { date : Utils.formatDateBooking(booking.eta_fpod), arrived : false}
//                 }else{
//                     let gateinFpod = Utils.getFCLAtaatFpod(booking);
//                     return gateinFpod?.length > 0 ? { date : Utils.formatDateBooking(gateinFpod),arrived:true} :{ date : Utils.formatDateBooking(booking.eta_fpod),arrived:false}
//                 }
//             }
//         }else{
//            if(shipmentType.toLowerCase() === "air" ){
//                 return  booking.loaded_on_board?.length > 0 ? 
                             
//                { date: Utils.formatDateBooking(booking.loaded_on_board),arrived:true}
//                 :
//               { date : Utils.formatDateBooking(booking.etd_airport_of_departure), arrived : false}
//            }else{
//                 return  booking.sob_pol?.length > 0 ?
//                { date : Utils.formatDateBooking(booking.sob_pol),arrived:true}
//                 :
//                { date : Utils.formatDateBooking(booking.etd_at_pol), arrived : false}
//            }
//         }
//     }
    
// const originDate = getDate();
// const destinationDate = getDate(true);
// const isAir = booking?.booking?.contract?.shipmentType === "Air"

//     return <Box sx={{ position: "relative", display: "flex", flexDirection: "column", minHeight: "240px" ,  border: "1px solid #D4D4D4", borderRadius: "12px", padding:"10px 10px 0px 10px",gap:2 }}>
//         <Box sx={{ position: "absolute", right: "14px", top: "14px" }}>
//             <ShareBooking bookingId={booking._id} branchId={booking.booking.branchId} entityId={booking.booking.entityId} />
//         </Box>
//          <Box sx={{display:"flex",flexDirection:'row',justifyContent:"space-between",width:"100%"}}>
//             <Box sx={{display:"flex",flexDirection:"column",gap:0.5}}>
//                 <p className="text-sm font-bold text-black">Status</p>
//                 <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Figtree", borderRadius: "46px", paddingY: "2px", paddingX: "8px", color: legendColor.color, backgroundColor: legendColor.backgroundColor, height: "20px", fontSize: "14px" }}>{statusTag[booking.booking.status.toLowerCase()]}</Box>
//             </Box>
//          <Box sx={{ display: "flex",  flexDirection: "column", justifyContent: "space-between", gap: 0.5,height:"100%",width:"78%"}}>
// <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2 }}>
//     {/* <Points propertyKey="Shipper" value={isExport ? booking.booking.shipper : booking?.Consignee?.companyName || "Not Assigned"} /> */}
//     <Points propertyKey="Shipment Type" value={shipmentType} />
//     <Points propertyKey="Booking Ref." value={booking["agraga_booking_#"]} />
//     <Points propertyKey="Branch" value={booking.branch_name && booking.branch_name.length > 0 ? booking.branch_name.split("-")[0] : "-"} />
//     <Points propertyKey="Shipper" value={booking.booking.shipper} />
    

// </Box>
// <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2 }}>
//     <Points propertyKey="Customer Ref." value={booking.cust_ref_number} />
//     {/* <Points propertyKey="Consignee" value={isImport? booking.booking.shipper:  booking?.Consignee?.companyName || "Not Assigned" }  /> */}
//     <Points propertyKey={isAir? "HAWB No. " : "HBL No."} value={isAir? booking.hawb_number : booking.hbl_number} />
   
//     <Points propertyKey={ isAir ? "MAWB No. " : "MBL No."} value={ isAir? booking.mawb_number : booking.mbl_number} />
//     <Points propertyKey="Consignee" value={ booking.booking.consignee && booking.booking.consignee.length>0 ?  booking.booking.consignee : "Not Assigned"  }  />
// </Box>
// </Box>
// <Box sx={{width:"30px",height:"30px",backgroundColor:"white"}}></Box>
//          </Box>
//          <Box sx={{display:"flex",flexDirection:"row",gap:2,flex:1,width:"100%"}}>
//              <Box sx={{display:"flex",flexDirection:'column',width:"110px",height:"100%",justifyContent:"space-around",alignItems:"center"}}>
//                 {/* <Box sx={{width:"100%",height:"73px",display:"flex",justifyContent:"center",alignItems:"center"}}> */}
//                     <img style={{height:"40px",width:"80px"}} src={logo} />
//                 {/* </Box> */}
//                <div className="flex flex-col gap-[5px] items-center">
//                 <p className="text-xs">Arriving In</p>
//                <p className="text-[18px] font-bold text-black">3 Days</p>
//                </div>
//              </Box>
//              <Box sx={{height:"100%",display:"flex",width:"92%",justifyContent:"center",alignItems:"center"}}>
// <VisibilityTrackerV2 isViewFull={true} mode={booking.shipmentType} booking={Utils.getTrackerPoints(booking)} />
//              </Box>
//          </Box>
//        <Box sx={{ padding: "0px 0px 8px 0px", display: "flex", flexDirection: "row", gap: 2, alignItems: "center" }}>
//             <Button disabled={!showButtons} onClick={() => {
//                 breadCrumbStore.setBreadCrumbs(["My Bookings", `Booking Id - ${booking._id}`]);
//                 viewBookingStore.accordionToBeOpended = 0;
//                 viewBookingStore.current = booking;
//                 bookingsStore.current = booking;
//                 enquiryStore.initializeWithConract(booking.booking.contract);
//             }} size="small" fullWidth variant="contained" startIcon={<img src={documentCenter} />} sx={{ textTransform: "capitalize", fontFamily: "Figtree", height: "30px" }}>Document Center</Button>
//             <Button onClick={() => {
//                 breadCrumbStore.setBreadCrumbs(["My Bookings", `Booking Id - ${booking._id}`])
//                 viewBookingStore.accordionToBeOpended = 1;
//                 viewBookingStore.current = booking;
//                 bookingsStore.current = booking;
//                 enquiryStore.initializeWithConract(booking.booking.contract);
                
//             }} size="small" fullWidth variant="contained" startIcon={<img src={details} />} sx={{ textTransform: "capitalize", fontFamily: "Figtree", height: "30px" }}>Booking Details</Button>
//             {/* <Button disabled={!showButtons} onClick={() => {
//                 viewBookingStore.current = booking;
//                 breadCrumbStore.setBreadCrumbs(["My Bookings", `Booking Id - ${booking._id}`])
//                 enquiryStore.initializeWithConract(booking.booking.contract);
//             }} size="small" fullWidth variant="contained" startIcon={<img src={actions} />} sx={{ textTransform: "capitalize", fontFamily: "Figtree", height: "30px" }}>Pending Actions</Button> */}
//         </Box>
//     </Box>
// }

// function OldWidget({booking}){
//     return <Box sx={{ display: "flex", flex: 1, flexDirection: "row" }}>
//     <Box sx={{ width: "39%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
//         <Box sx={{ display: "flex", flexDirection: "row", gap:1,alignItems:"center" }}>
//             <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", fontSize: "14px", color: "black" }}>{shipmentType}</Typography>
//             <Typography>-</Typography>
//             <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Figtree", borderRadius: "46px", paddingY: "2px", paddingX: "8px", color: legendColor.color, backgroundColor: legendColor.backgroundColor, height: "20px", fontSize: "14px" }}>{statusTag[booking.booking.status.toLowerCase()]}</Box>
//             {booking.branch_name && booking.branch_name.length > 0 && <>
//                 <Typography>-</Typography>
//                 <Typography sx={{fontSize:"14px",fontFamily:"Figtree"}}><span style={{color:"black",fontWeight:"bold"}}>(</span> <span style={{color:"black",fontWeight:"bold"}}>Br:</span> {booking.branch_name.split("-")[0]} <span style={{color:"black",fontWeight:"bold"}}>)</span></Typography>
//             </>}
//         </Box>
//         <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 4.4 }}>
//             <Box sx={{ display: "flex", flexDirection: "column", gap: "5px",justifyContent:"space-between" }}>
//                 <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", fontSize: "14px", color: "black" }}>{Utils.getCity(currentEnquiry.origin, isOriginDoor)}</Typography>
//                 <Typography sx={{ fontFamily: "Figtree", fontSize: "12px",color:originDate.arrived?"#2C358A":"#555555" }}>
//                     {originDate.date
//                     }
//                 </Typography>
//             </Box>
//             <img src={shipmentType.toLowerCase() === "air" ? airicon : shipmenticon} />
//             <Box sx={{ display: "flex", flexDirection: "column", gap: "5px",justifyContent:"space-between" }}>
//                 {currentEnquiry?.multidest?.length>1  ? <MultiDestToolTip multidest={currentEnquiry.multidest} /> : <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", fontSize: "14px", color: "black" }}>{Utils.getCity(currentEnquiry.destination, isDestinationDoor)}</Typography>}
//                 <Typography sx={{ fontFamily: "Figtree", fontSize: "12px",fontSize: "12px",color:destinationDate.arrived?"#2C358A":"#555555" }}>
//                     {
//                    destinationDate.date
//                     }
//                 </Typography>
//             </Box>
//         </Box>
//          {  shipmentType.toLowerCase() === "air" ?  <GetProgressBar isCancelled={!showButtons} status={{departureStatus:booking.departureStatus,arrivalStatus:booking.arrivalStatus}} statusCompleted={booking.status_closed?.toLowerCase()==="y"} ataFpod={booking.ata_at_arrival_airport} etaFpod={booking.eta_at_arrival_airport} ataPol={booking.loaded_on_board} /> : <GetProgressBar isCancelled={!showButtons} status={{departureStatus:booking.departureStatus,arrivalStatus:booking.arrivalStatus}} statusCompleted={booking.status_closed?.toLowerCase()==="y"} ataFpod={  shipmentType.toLowerCase() === "lcl" ? booking.gatein_fpod : Utils.getFCLAtaatFpod(booking)} etaFpod={booking.eta_fpod} ataPol={booking.sob_pol} />} 
       
//         <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", gap: 0.5, width: "100%" }}>
//             <Points propertyKey="Booking Ref." value={booking["agraga_booking_#"]} />
//             {/* <Points propertyKey="Shipper" value={isExport ? booking.booking.shipper : booking?.Consignee?.companyName || "Not Assigned"} /> */}
//             <Points propertyKey="Shipper" value={booking.booking.shipper && booking.booking.shipper.length >0 ? booking.booking.shipper : "Not Assigned"} />
//             <Points propertyKey={isAir? "HAWB No. " : "HBL No."} value={isAir? booking.hawb_number : booking.hbl_number} />
//             <Points propertyKey="Customer Ref." value={booking.cust_ref_number} />
//             {/* <Points propertyKey="Consignee" value={isImport? booking.booking.shipper:  booking?.Consignee?.companyName || "Not Assigned" } /> */}
//             <Points propertyKey="Consignee" value={ booking.booking.consignee && booking.booking.consignee.length>0 ?  booking.booking.consignee : "Not Assigned" } />
//             <Points propertyKey={ isAir ? "MAWB No. " : "MBL No."} value={ isAir? booking.mawb_number : booking.mbl_number} />
//         </Box>
//     </Box>
//     <Box sx={{ width: "60%" }}>
//         <Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
//             <VisibilityTracker mode={booking.shipmentType} booking={Utils.getTrackerPoints(booking)} />
//         </Box>
//     </Box>
// </Box>

// }








function BookingList(){
    const productList = ["In-Progress", "Completed", "Cancelled"];
    const Bookings = ["Created Bookings", "Assigned Bookings"];
    const [status, setStatus] = useState("In-Progress");
    const [bookingStatus, setBoookingStatus] = useState("Created Bookings");
    const [value, setValue] = useState(0);
    const [searchText, setSearchText] = useState("")
    const [apiState, setApiState] = useState({ data: [], isLoading: true, count: 0 });
    const [pageNumber,setPageNumber] = useState(0);
    const [lazyLoader,setLazyLoad] = useState(false);
    const previousSearchText = useRef("");
    const reachedEnd = useRef(false);
    const containerRef = useRef(null);
    const config = useSnapshot(configStore);
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/downloadxl/${config.currentSelectedEntity._id}`;
    useEffect(() => {
        return () => {
            setSearchText("");
            setBoookingStatus("Created Bookings");
            setStatus("In-Progress");
        }
    }, [])


    useEffect(()=>{
        const container = containerRef.current;
        console.log("the values of the booleans ",lazyLoader, " ",reachedEnd.current);
        const handleScroll = ()=>{
           
            if ( container && !lazyLoader &&
                container.scrollTop + container.clientHeight >= container.scrollHeight - 80 &&
                !reachedEnd.current
              ) {
                setPageNumber((prevPageNumber)=>prevPageNumber+1);
              }
        }
const debounced = Utils.debouncing(handleScroll,300);
        if(container){
            container.addEventListener('scroll', debounced);
        }
        if( breadCrumbStore.id?.length>0){
            setTimeout(()=>{
             setSearchText(breadCrumbStore.id);
            },2000)
         }
        return ()=>{
         if(container){
         container.removeEventListener('scroll', debounced);
         }
         breadCrumbStore.id = undefined;
        }
    },[])
    const abortControllerRef = useRef(null);
    const loadData  = ()=>{
       
        try {
            const abortController = new AbortController();
            abortControllerRef.current = abortController;
            if(previousSearchText.current!==searchText){
                setApiState((prev)=>({...prev,isLoading:true}))
            }
           
            if(!reachedEnd.current){
            ApiAgTable.getAllBooking(pageNumber, 10, "", "", { filter: "ALL", booking: bookingStatus,  filter1: status === "In-Progress" ? "in-transit" : status.toLowerCase(), search: searchText },abortController.signal).then(async (data) => {
                let lres = await data.json();
                console.log("the previous search ",previousSearchText.current," ",searchText);
                if(previousSearchText.current!==searchText){
                    setApiState({ isLoading: false, data:lres.results1, count: lres.count })
                    previousSearchText.current = searchText;
                }else{
                    setApiState((prevData)=>({ isLoading: false, data:[ ...prevData.data,...lres.results1], count: lres.count }))
                }
                setLazyLoad(false);
                if((pageNumber+1)*10>lres.count){
                    reachedEnd.current = true;
                }else{
                    reachedEnd.current = false;
                }
            }).catch((e)=>{
                setApiState(() => ({ data: [], isLoading: true }));
            })
        }
        } catch (error) {
            console.log("the error  ",error)
            setApiState(() => ({ data: [], isLoading: true }));
        }
    }

    useEffect(()=>{
        setInitialState();
    },[status, bookingStatus, config.currentSelectedEntity, config.currentSelectedBranch])


    function setInitialState(){
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }
        setApiState({isLoading:true,data:[]});
            reachedEnd.current = false
            setPageNumber(0);
    }


    useEffect(() => {
        setLazyLoad(true);
       loadData();
    }, [ status, bookingStatus, searchText, pageNumber,config.currentSelectedEntity, config.currentSelectedBranch])

    const handleChange = (val, newValue) => {
        setValue(newValue);
        setBoookingStatus(val)
      };
    return  <>
    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "7%" }}>
        {/* <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black" }}>Bookings</Typography> */}
      
    </Box>
    <Box sx={{height:"44px",gap:"4px",display:"flex",flexDirection:"row"}}>
        {Bookings.map((ele,i)=><Box onClick={()=>{
                handleChange(ele,i)
            }
        } sx={{height:"100%",width:"204px",display:"flex",justifyContent:"center",alignItems:"center",borderTopLeftRadius:bookingStatus===ele?"3px":"0px",borderTopRightRadius:bookingStatus===ele?"3px":"0px",backgroundColor:bookingStatus===ele?"white":"transparent",color:bookingStatus===ele?"#2C358A":"#585858",fontWeight:bookingStatus===ele?"bold":"normal",fontSize:bookingStatus===ele?"16px":"13px","&:hover":{
            cursor:"pointer"
        }}}>
           {ele}
        </Box>)}
    </Box>
    <Box sx={{ display: "flex", flexDirection: "column", borderRadius: "10px", borderTopLeftRadius:"0px",backgroundColor: "white", minHeight: "80%", height: "100%", gap: 1, padding: "10px" }}>
    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>

{/* <Tabs sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}} value={value} onChange={handleChange} aria-label="My tabs">
    {Bookings.map((ele, i) => <Tab disableRipple value={i} sx={{ color: "#555555", textTransform: "capitalize", fontFamily: "Figtree", fontWeight: "bold" , fontSize:"14px"}} 
    label={<><p>{ele}</p></>} /> )}
</Tabs> */}
{/* <Agproducttab onClick={()=>{
    setInitialState();
}}  isGreyBackground="#F5F5F5" productList={productList} activeProduct={status} setActiveProduct={setStatus} />  */}

<div className="flex flex-row h-[46px] gap-3 rounded-[32px] border-1 border-solid border-[#B5B8CE] bg-[#F9F9F9] p-1">
  {productList.map((product,i)=><div onClick={()=>{
    setStatus(product)
  }} className={`h-full w-[140px] rounded-[34px] flex justify-center items-center ${status===product?"bg-blue-dark text-white":"transparent text-blue-dark hover:cursor-pointer"}`}>{product}</div>)}
</div>

<div className="w-full bg-white h-full flex flex-row items-center justify-end gap-4 ">
<TextField
            sx={{ width: "25%" }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={searchText}
            onChange={(e) => {
                previousSearchText.current = searchText;
                setSearchText(e.target.value) 
                setApiState({isLoading:true,data:[]}); 
                reachedEnd.current = false
                setPageNumber(0);
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <CiSearch style={{ fontSize: 20 }} />
                    </InputAdornment>
                ),
                style: { borderRadius: '57px', height: '35px', backgroundColor: "white" },
            }}
            placeholder="Search"
        />
{/* <Button href={url} variant="contained" startIcon={<img src={downloadicon} />} sx={{ textTransform: "none", height: "35px", backgroundColor:"#2C358A" , "&:hover":{
     backgroundColor:"#2C358A",
     color:"white" 
}}}>Download DSR</Button> */}
</div>

</Box>
        <Box ref={containerRef} sx={{
            display: "flex", flexDirection: "column", flex: 1, gap: 1, overflowX: "auto", paddingRight: "8px", "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#2C358A !important"
            }
        }}>
            {
                apiState.isLoading ?
                    <CenteredCircularProgress />
                    : apiState.data && apiState.data.length > 0 ? 
                    <>
                    {apiState.data.map((bookingData) => <BookingCard showButtons={status!=="Cancelled"} booking={bookingData} />)} 
                    {lazyLoader&&
                    <Box sx={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center"}}>
                    <CircularProgress />
                    </Box>
                    }
                    </>:
                        <Nodata />
            }
            
        </Box>
    </Box>
</>
}

const queryClient = new QueryClient()

const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    queryPayload: {}
};


function BookingList2(){
    const productList = ["In-Progress", "Completed", "Cancelled"];
    const Bookings = ["Created Bookings", "Assigned Bookings"];
    const [status, setStatus] = useState("In-Progress");
    const [bookingStatus, setBoookingStatus] = useState("Created Bookings");
    const [value, setValue] = useState(0);
    const [searchText, setSearchText] = useState("")
    const [apiState, setApiState] = useState({ data: [], isLoading: true, count: 0 });
    const [pageNumber,setPageNumber] = useState(0);
    const [lazyLoader,setLazyLoad] = useState(false);
    const previousSearchText = useRef("");
    const reachedEnd = useRef(false);
    const containerRef = useRef(null);
    const config = useSnapshot(configStore);
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/downloadxl/${config.currentSelectedEntity._id}`;
    useEffect(() => {
        if( breadCrumbStore.id?.length>0){
                        setTimeout(()=>{
                         setSearchText(breadCrumbStore.id);
                        },2000)
                     }
        return () => {
            setSearchText("");
            setBoookingStatus("Created Bookings");
            setStatus("In-Progress");
        }
    }, [])
    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
    useReducer(reducer, initialState);
    let columns = DRS_COLUMNS
    const totalPageCount = Math.ceil(totalCount / queryPageSize)
    const manualPageSize = []
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable({
        columns,
        data: apiState.data,
        initialState: {
            pageIndex: queryPageIndex,
            pageSize: queryPageSize,
            sortBy: queryPageSortBy,
        },
        manualPagination: true,
        pageCount: apiState.data ? totalPageCount : null,
        autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: false
    },
        useSortBy,
        usePagination,
    );

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex,gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
        gotoPage(0);
    }, [pageSize, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
        gotoPage(0);
    }, [sortBy, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAYLOAD_CHANGED, payload: { search:searchText,filter1:status === "In-Progress" ? "in-transit" : status.toLowerCase(),filter:"ALL",booking:bookingStatus } });
        gotoPage(0);
    }, [searchText,status, gotoPage,bookingStatus]);

    useEffect(() => {
        if (apiState.count) {
            dispatch({
                type: TOTAL_COUNT_CHANGED,
                payload: apiState.count,
            });
        }
    }, [apiState.count])


//     useEffect(()=>{
//         const container = containerRef.current;
//         console.log("the values of the booleans ",lazyLoader, " ",reachedEnd.current);
//         const handleScroll = ()=>{
           
//             if ( container && !lazyLoader &&
//                 container.scrollTop + container.clientHeight >= container.scrollHeight - 80 &&
//                 !reachedEnd.current
//               ) {
//                 setPageNumber((prevPageNumber)=>prevPageNumber+1);
//               }
//         }
// const debounced = Utils.debouncing(handleScroll,300);
//         if(container){
//             container.addEventListener('scroll', debounced);
//         }
//         if( breadCrumbStore.id?.length>0){
//             setTimeout(()=>{
//              setSearchText(breadCrumbStore.id);
//             },2000)
//          }
//         return ()=>{
//          if(container){
//          container.removeEventListener('scroll', debounced);
//          }
//          breadCrumbStore.id = undefined;
//         }
//     },[])

    const abortControllerRef = useRef(null);
    const loadData  = ()=>{
       
        try {
            const abortController = new AbortController();
            abortControllerRef.current = abortController;
            // if(previousSearchText.current!==searchText){
                setApiState((prev)=>({data:[],isLoading:true,count:0}))
            // }
           
            
            ApiAgTable.getAllBooking(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload,abortController.signal).then(async (data) => {
                let lres = await data.json();
if(lres.error){
    setApiState(() => ({ data: [], isLoading: false , count:0}));
    return;
}
                    setApiState({ isLoading: false, data:lres.results1, count: lres.count })
                
                
                // setLazyLoad(false);
                // if((pageNumber+1)*10>lres.count){
                //     reachedEnd.current = true;
                // }else{
                //     reachedEnd.current = false;
                // }
            }).catch((e)=>{
                setApiState(() => ({ data: [], isLoading: true , count:0}));
            })
    
        } catch (error) {
            console.log("the error  ",error)
            setApiState(() => ({ data: [], isLoading: true }));
        }
    }

  
    useEffect(() => {
        
        //Newapi.getAllDsr4Display();
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        if(queryPayload.filter1){
           loadData();
        }
        
},[queryPayload,queryPageIndex,queryPageSize,queryPageFilter,queryPageSortBy,configStore.currentSelectedEntity, configStore.currentSelectedBranch,myactionsstore.recheck])


    function setInitialState(){
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }
        setApiState({isLoading:true,data:[]});
            reachedEnd.current = false
            setPageNumber(0);
    }


   

    const handleChange = (val, newValue) => {
        setValue(newValue);
        setBoookingStatus(val)
      };
    return  <>
    {/* <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "7%" }}>
        <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black" }}>Bookings</Typography> 
    </Box> */}
    <Box sx={{height:"44px",gap:"4px",display:"flex",flexDirection:"row"}}>
        {Bookings.map((ele,i)=><Box onClick={()=>{
                handleChange(ele,i)
            }
        } sx={{height:"100%",width:"204px",display:"flex",justifyContent:"center",alignItems:"center",borderTopLeftRadius:bookingStatus===ele?"3px":"0px",borderTopRightRadius:bookingStatus===ele?"3px":"0px",backgroundColor:bookingStatus===ele?"white":"transparent",color:bookingStatus===ele?"#2C358A":"#585858",fontWeight:bookingStatus===ele?"bold":"normal",fontSize:bookingStatus===ele?"16px":"13px","&:hover":{
            cursor:"pointer"
        }}}>
           {ele}
        </Box>)}
    </Box>
    <Box sx={{ display: "flex", flexDirection: "column", borderRadius: "10px", borderTopLeftRadius:"0px",backgroundColor: "white", minHeight: "80%", height: "100%", gap: 1, padding: "10px" }}>
    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>

{/* <Tabs sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}} value={value} onChange={handleChange} aria-label="My tabs">
    {Bookings.map((ele, i) => <Tab disableRipple value={i} sx={{ color: "#555555", textTransform: "capitalize", fontFamily: "Figtree", fontWeight: "bold" , fontSize:"14px"}} 
    label={<><p>{ele}</p></>} /> )}
</Tabs> */}
{/* <Agproducttab onClick={()=>{
    setInitialState();
}}  isGreyBackground="#F5F5F5" productList={productList} activeProduct={status} setActiveProduct={setStatus} />  */}

<div className="flex flex-row h-[46px] gap-3 rounded-[32px] border-1 border-solid border-[#B5B8CE] bg-[#F9F9F9] p-1">
  {productList.map((product,i)=><div onClick={()=>{
    setStatus(product)
  }} className={`h-full w-[140px] rounded-[34px] flex justify-center items-center ${status===product?"bg-blue-dark text-white":"transparent text-blue-dark hover:cursor-pointer"}`}>{product}</div>)}
</div>

<div className="w-full bg-white h-full flex flex-row items-center justify-end gap-4 ">
<TextField
            sx={{ width: "25%" }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={searchText}
            onChange={(e) => {
                previousSearchText.current = searchText;
                setSearchText(e.target.value) 
                setApiState({isLoading:true,data:[]}); 
                reachedEnd.current = false
                setPageNumber(0);
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <CiSearch style={{ fontSize: 20 }} />
                    </InputAdornment>
                ),
                style: { borderRadius: '57px', height: '35px', backgroundColor: "white" },
            }}
            placeholder="Search"
        />
{/* <Button href={url} variant="contained" startIcon={<img src={downloadicon} />} sx={{ textTransform: "none", height: "35px", backgroundColor:"#2C358A" , "&:hover":{
     backgroundColor:"#2C358A",
     color:"white" 
}}}>Download DSR</Button> */}
</div>

</Box>
        <Box ref={containerRef} sx={{
            display: "flex", flexDirection: "column", flex: 1, gap: 1, overflowX: "auto", paddingRight: "8px", "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#2C358A !important"
            }
        }}>
            {
                apiState.isLoading ?
                    <CenteredCircularProgress />
                    : apiState.data && apiState.data.length > 0 ? 
                    <>
                    {apiState.data.map((bookingData) => <BookingCard showButtons={status!=="Cancelled"} booking={bookingData} />)} 
                  
                    </>:
                        <Nodata />
            }
            
        </Box>
        { <ReactTablePagination
                page={page}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageOptions={pageOptions}
                pageSize={pageSize}
                pageIndex={pageIndex}
                pageCount={pageCount}
                setPageSize={setPageSize}
                manualPageSize={manualPageSize}
                dataLength={totalCount}
                rows={rows}
            /> }  
    </Box> 
</>
}

const breadCrumbComponents = {
    "My Bookings" :  <QueryClientProvider client={queryClient}>
    <BookingList2/>
    </QueryClientProvider>,
    "Booking" : <SelectedBooking />
}

export function BookingWithVisibility() {
    
    const { breadCrumbs,currentComponent } = useSnapshot(breadCrumbStore);
    const booking = useSnapshot(viewBookingStore).current
   
    useEffect(()=>{
        // breadCrumbStore.reset();
     return ()=>{
        // breadCrumbStore.reset();
     }
    },[])

    return <Box sx={{ display: "flex", height: "100%", flexDirection: "column"}}>
        <div className="pb-1"><BreadCrumb2/></div>
        {breadCrumbComponents[currentComponent]}
    </Box>
}