import React, { useState, useEffect, useRef } from "react";
import { useTable, usePagination, useSortBy } from "react-table"
import { QueryClient, QueryClientProvider } from "react-query";


import { AgragaBooking, ClientBooking } from "../components/ag-table/columns";

import ReactTablePagination from "../components/ag-table/ReactTablePagination";
import TableFilter from "../components/ag-table/TableFilter";


import { reducer, PAGE_CHANGED, PAGE_SIZE_CHANGED, PAGE_SORT_CHANGED, PAGE_FILTER_CHANGED, TOTAL_COUNT_CHANGED, PAYLOAD_CHANGED } from "../components/ag-table/ag-reducer"
import ApiAgTable from "../components/ag-table/api_ag-table";
import { AgmodalStore, EntityV2Store, bookingsStore, configStore, contractsStore, creditformStore, entityStore, loginStore } from "../state/CommonState";
import { useSnapshot } from "valtio";

import { useGlobalState } from "../GlobalState";
import { AgWarnPopup1 } from "../components/AgCustomComponents";
import BookingsApi from "../state/bookings-api";
import QuoteCalcV2 from "../util/quote-calc-v2";
import Utils from "../util/Utils";
import TableHead from "../util/dataTable";
import { enquiryStore } from "../enquiry/enquiry-data";
import { Box, Typography } from "@mui/material";
import Api from "../state/Api";
import EntityApi from "../state/entity-api";
import AgBookingRequestCancellationv3 from "./AgBookingRequestCancellation-v3";
import { loader } from "../util/loader";

const queryClient = new QueryClient()


const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    queryPayload: {}
};


const DataTable = (props) => {
    useSnapshot(loginStore)
    useSnapshot(bookingsStore)
    useSnapshot(configStore)
    useSnapshot(entityStore)
    useSnapshot(contractsStore)
    useSnapshot(enquiryStore)
    useSnapshot(AgmodalStore)
    const filterStatus = {
        'SPOT': 'SPOT'
    }
    const filterStatus1 = {
        'Yes': 'Yes',
        'No' : 'No'
    }
    const filterStatus2 = {
        'ERP Number Updated': 'ERP Number Updated',
        'ERP Number not updated' : 'ERP Number not updated'
    }
    const filterStatus3 = {
        'Not Verified': 'Not Verified',
        'Verified' : 'Verified'
    }
    const filterStatus4 = {
        'LCL': 'LCL',
        'FCL' : 'FCL',
        'AIR' : 'AIR'
    }
    const [selectedFilter, SetSelectedFilter] = useState('ALL');
    const [selectedFilter1, SetSelectedFilter1] = useState('ALL');
    const [selectedFilter2, SetSelectedFilter2] = useState('ALL');
    const [selectedFilter3, SetSelectedFilter3] = useState('ALL');
    const [selectedFilter4, SetSelectedFilter4] = useState('ALL');
    let title = 'All Bookings'

    const { changeCurrentMenu } = useGlobalState()
    if (loginStore.isClient()) title = 'My Bookings'
    const tablist = ['Submitted Bookings', 'Shipments In-Progress', 'Archives', 'Cancelled']

    let status = 'SUBMITTED'
    switch (bookingsStore.listActiveTab) {
        case 'Shipments In-Progress': status = 'INPROGRESS'; break;
        case 'Archives': status = 'ARCHIVED'; break;
        case 'Cancelled': status = 'CANCELLED'; break;
    }

    let column = {}

    if (!loginStore.isClient()) {
        column = AgragaBooking
    } else {
        column = ClientBooking
    }

    const [keyword, setKeyword] = useState('');
    const [data1, setData1] = useState({ results: [] })
    const onClickFilterCallback = (filter) => {
        setKeyword(filter)
    }

    let columns = column
    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
        React.useReducer(reducer, initialState);
    useEffect(() => {
        if(queryPayload.status && AgmodalStore.apiCall){
            ApiAgTable.bookingsData(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload).then(res => {
                res.clone().json().then((res) => {
                    let a = []
                    for (let i = 0; i < res.results.length; i++) {
                        let d = res.results[i]
                        let stype = d.Bookings.contract.shipmentType.substr(0, 3).toLowerCase()
                        if(stype=='air'){
                            if(d.Bookings.contract.airline != undefined && d.Bookings.contract.airline.trim().length==0 && d.Bookings.airline != undefined && d.Bookings.airline.trim().length!=0){
                                d.Bookings.contract.airline = d.Bookings.airline
                            }
                        }
                        a.push(d)
                    }
                    res.results = a
                    if (JSON.stringify(data1.results) != JSON.stringify(res.results)) {
                        if (JSON.stringify(data1) != JSON.stringify(res)  && res.results && res.results.length > 0) {
                            setData1(res)
                        }else{
                            setData1({count: 0, results: []})
                        }
                    }
                }, err => {
                    console.log(err)
                })
            })
        }
        
    }, [AgmodalStore.apiCall, queryPayload, queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, configStore.currentSelectedEntity , configStore.currentSelectedBranch])



    const totalPageCount = Math.ceil(totalCount / queryPageSize)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable({
        columns,
        data: data1?.results,
        initialState: {
            pageIndex: queryPageIndex,
            pageSize: queryPageSize,
            sortBy: queryPageSortBy,
        },
        manualPagination: true,
        pageCount: data1 ? totalPageCount : null,
        autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: false
    },
        useSortBy,
        usePagination,
    );

    const manualPageSize = []

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
        gotoPage(0);
    }, [pageSize, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
        gotoPage(0);
    }, [sortBy, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword });
        gotoPage(0);
    }, [keyword, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAYLOAD_CHANGED, payload: { status : status, quotationref:selectedFilter, fba:selectedFilter1, erpnumber:selectedFilter2, verifiedentity:selectedFilter3, product:selectedFilter4 } });
        gotoPage(0);
    }, [columns, bookingsStore.listActiveTab, bookingsStore.requestCancellationReason, selectedFilter,selectedFilter1,selectedFilter2,selectedFilter3,selectedFilter4]);

    React.useEffect(() => {
        if (data1?.count) {
            dispatch({
                type: TOTAL_COUNT_CHANGED,
                payload: data1.count,
            });
        }
    }, [data1?.count]);





    const myRole = loginStore.userRec.aguserRec.role
    const [focusedBookingId, setFocusedBookingId] = useState(null);
    return (
        <>
            <AgWarnPopup1 callback={async () => {
                if (bookingsStore.requestCancellationReason === null || bookingsStore.requestCancellationReason == '') {
                    configStore.setModalMessage(`Invalid Reason.`);
                }
                // configStore.setText = false
                await BookingsApi.cancelBooking(focusedBookingId, bookingsStore.requestCancellationReason);
                bookingsStore.requestCancellationReason = null;
            }}>
                <p className="is-size-7 notification is-warning">{`Are you sure you wish to cancel the booking ${focusedBookingId}`}</p>
                <br></br>
                <p>Reason:{bookingsStore.requestCancellationReason}</p>
            </AgWarnPopup1>
            <AgBookingRequestCancellationv3 />
            <nav className="level">
                <div class="level-left">
                    <div class="level-item">
                        <p class="title is-4" style={{ color: '#313131', fontFamily: 'product_sans_bold' }}>
                            {title}
                        </p>
                    </div>
                    <div class="level-item">
                        <TableFilter onClickFilterCallback={onClickFilterCallback} defaultKeyword={keyword} />
                    </div>
                </div>
                {
                    (!loginStore.isClient())?<><div class="level-item level-left ml-6">
                    <div class="field m-0">
                        <span className="is-size-5">Spot</span>
                        <p class="control has-icons-left has-icons-right">
                            <span class="select">
                                
                                <select name="status" id="status"
                                    value={selectedFilter} onChange={(e) => SetSelectedFilter(e.target.value)}
                                >
                                    <option value="ALL">ALL</option>
                                    {
                                        Object.entries(filterStatus).map(([key, value]) => (
                                            <option value={value}>{key}</option>
                                        ))
                                    }
                                </select>
                                <span class="icon">
                                    <i class="fa-solid fa-filter"></i>
                                </span>
                            </span>
                        </p>
                    </div>
                    <div class="field">
                        <p class="control has-icons-left has-icons-right">
                        &nbsp;&nbsp;
                        </p>
                    </div>
                    <div class="field m-0">
                        <span className="is-size-5">FBA</span>
                        <p class="control has-icons-left has-icons-right">
                            <span class="select">
                                <select name="status" id="status"
                                    value={selectedFilter1} onChange={(e) => SetSelectedFilter1(e.target.value)}
                                >
                                    <option value="ALL">ALL</option>
                                    {
                                        Object.entries(filterStatus1).map(([key, value]) => (
                                            <option value={value}>{key}</option>
                                        ))
                                    }
                                </select>
                                <span class="icon">
                                    <i class="fa-solid fa-filter"></i>
                                </span>
                            </span>
                        </p>
                    </div>
                    <div class="field">
                        <p class="control has-icons-left has-icons-right">
                        &nbsp;&nbsp;
                        </p>
                    </div>
                    <div class="field m-0">
                        <span className="is-size-5">ERP Number</span>
                        <p class="control has-icons-left has-icons-right">
                            <span class="select">
                                <select name="status" id="status"
                                    value={selectedFilter2} onChange={(e) => SetSelectedFilter2(e.target.value)}
                                >
                                    <option value="ALL">ALL</option>
                                    {
                                        Object.entries(filterStatus2).map(([key, value]) => (
                                            <option value={value}>{key}</option>
                                        ))
                                    }
                                </select>
                                <span class="icon">
                                    <i class="fa-solid fa-filter"></i>
                                </span>
                            </span>
                        </p>
                    </div>
                    <div class="field">
                        <p class="control has-icons-left has-icons-right">
                        &nbsp;&nbsp;
                        </p>
                    </div>
                    <div class="field m-0">
                        <span className="is-size-5">Verified Entity</span>
                        <p class="control has-icons-left has-icons-right">
                            <span class="select">
                                <select name="status" id="status"
                                    value={selectedFilter3} onChange={(e) => SetSelectedFilter3(e.target.value)}
                                >
                                    <option value="ALL">ALL</option>
                                    {
                                        Object.entries(filterStatus3).map(([key, value]) => (
                                            <option value={value}>{key}</option>
                                        ))
                                    }
                                </select>
                                <span class="icon">
                                    <i class="fa-solid fa-filter"></i>
                                </span>
                            </span>
                        </p>
                    </div>
                    <div class="field">
                        <p class="control has-icons-left has-icons-right">
                        &nbsp;&nbsp;
                        </p>
                    </div>
                    <div class="field m-0">
                        <span className="is-size-5">Product</span>
                        <p class="control has-icons-left has-icons-right">
                            <span class="select">
                                <select name="status" id="status"
                                    value={selectedFilter4} onChange={(e) => SetSelectedFilter4(e.target.value)}
                                >
                                    <option value="ALL">ALL</option>
                                    {
                                        Object.entries(filterStatus4).map(([key, value]) => (
                                            <option value={value}>{key}</option>
                                        ))
                                    }
                                </select>
                                <span class="icon">
                                    <i class="fa-solid fa-filter"></i>
                                </span>
                            </span>
                        </p>
                    </div>
        </div>
        </>:""
                }
                
            </nav>
          { loginStore.isClient() ? 
             <Box sx={{width:"100%",display: "flex", flexDirection: 'row', gap: 1,backgroundColor:"white",borderRadius:"5px", marginY : "5px",padding:"5px"}}>
               {tablist.map((e)=>  <Box onClick={()=>{
                     bookingsStore.listActiveTab = e
                }} sx={{
      height: "100%", borderRadius: "5px", display: "flex", flexDirection: 'row', gap: 1, justifyContent: "center", alignItems: "center", padding: "5px 14px", backgroundColor: e === bookingsStore.listActiveTab ? "#2C358A" : "transparent", color: e === bookingsStore.listActiveTab ? "white" : "#555555", '&:hover': {
          cursor: "pointer"
      }
  }}>
      {/* <img style={{ filter: selected ? "brightness(0) invert(1)" : "brightness(0) saturate(100%) invert(31%) sepia(4%) saturate(22%) hue-rotate(49deg) brightness(98%) contrast(85%)" }} height="13%" width="13%" src={icon} /> */}
      <Typography fontSize="14px" fontFamily="Figtree" fontWeight={600}>{e==="Archives"?"Completed":e}</Typography>
  </Box>)}
             </Box>

          :  <div id="quotelistclient" className="tabs is-boxed" style={{ marginBottom: '0px', backgroundColor: '#2c358a' }}>
                <ul>
                    {
                        tablist.map(e => {
                            let className = ''
                            let styles = { color: 'lightgrey' }
                            if (e === bookingsStore.listActiveTab) {
                                className = 'is-active'
                                styles = { backgroundColor: '#eea724', color: 'black' }
                            }
                            styles.paddingTop = '5px'
                            styles.paddingBottom = '5px'
                            styles.paddingRight = '15px'
                            return <li className={className} onClick={() => {
                                bookingsStore.listActiveTab = e
                            }}>
                                <a style={styles}><span>{(e=='Archives'?'Completed':e)}</span></a>
                            </li>
                        })
                    }
                </ul>
            </div>}
            <div className="box" style={{flex:1}}>
                <div className="table-container" >
                    <table className="table is-fullwidth is-small ">
                        <TableHead headerGroups={headerGroups} sort={true}/>
                        <tbody className="contractlist" style={{ fontSize: '0.9rem' }} {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}
                                    onClick={() => {
                                        BookingsApi.getBookingWithEntitie(row.original.Bookings._id, (data) => {
                                            console.log("check-------->", data)
                                            bookingsStore.current = data
                                            let en = data.Entities
                                            en['branch']= [data.Branches]
                                            entityStore.entityRec.set(en)
                                            EntityV2Store.EntityRec.set(en)
                                            if(en?.isCreditCustomer==='Yes'){
                                                creditformStore.CreditformRec.set(data?.Credit)
                                            }
                                            contractsStore.current = data.contract
                                            contractsStore.current = new QuoteCalcV2(contractsStore.current).recalc()
                                            enquiryStore.initializeWithConract(data.contract)
                                            // bookingsStore.currentBookingsListComponent = 'view'
                                            AgmodalStore.modalPage = "ViewEnquiryBooking-v3"
                                            AgmodalStore.pageVisible = true
                                        })
                                    }}
                                >
                                                            {
                                                                row.cells.map(cell => {
                                                                    {
                                                                        let e = cell['row']['original']
                                                                        switch (cell['column']['Header']) {
                                                                            case "Booking Date":
                                                                                return <td>{Utils.ddmmyyyystring(e.Bookings.bookingDate)}</td>
                                                                            case "Destination":
                                                                                return <td>{e.Bookings?.contract?.destination2!=undefined?"Multiple":e.Bookings?.contract?.destination}</td>
                                                                            case "BranchName":
                                                                                return <td>{`${e.Branches?.state?.split("/")[0]}-${(e.Branches?.gst.length>0 ?e.Branches?.gst : e.Entities?.tinNum )}`}</td>
                                                                            case "Quotation Ref #":
                                                                                if(loginStore.isClient()){
                                                                                    return <td>{e.contract._id}</td>
                                                                                }else{
                                                                                    return <>
                                                                                    {(e.Bookings?.contract?.quotationNum=="SPOT")?<td>SPOT</td>:<td><a onClick={(event) => {
                                                                                        event.stopPropagation();
                                                                                        changeCurrentMenu("Quotationv3")
                                                                                        AgmodalStore.modalPage = 'QuoteViewv3';
                                                                                        AgmodalStore.pageVisible = true;
                                                                                        Api.getQuote(e.Bookings?.contract?.quotationNum);
                                                                                        EntityApi.getList()
                                                                                    }}>{e.Bookings?.contract?.quotationNum}</a></td>}
                                                                                    </>
                                                                                    
                                                                                    
                                                                                }
                                                                            case "Status":
                                                                                return <>
                                                                                <td>{(e.Bookings.status=="ARCHIVED")?"COMPLETED":e.Bookings.status}</td>
                                                                                </>
                                                                            case "Actions": 
                                                                                if(e.Bookings.status === `SUBMITTED`){
                                                                                    return <td>
                                                                                    <div
                                                                                        class='dropdown post-options is-right is-hoverable '
                                                                                        onClick={null}
                                                                                    >
                                                                                        <div class='dropdown-trigger'>
                                                                                            <button
                                                                                                class='button'
                                                                                                aria-haspopup='true'
                                                                                                aria-controls='dropdown-menu-post'
                                                                                            >
                                                                                                <span class='icon is-small'>
                                                                                                    <i
                                                                                                        class='fas fa-ellipsis-h'
                                                                                                        aria-hidden='true'
                                                                                                    ></i>
                                                                                                </span>
                                                                                            </button>
                                                                                        </div>
                                                                                         <div
                                                                                            class='dropdown-menu'
                                                                                            id='dropdown-menu-post'
                                                                                            role='menu'
                                                                                        >
                                                                                           <div class='dropdown-content'>
                                                                                                
                                                                                                   
                                                                                                        <a class='dropdown-item'
                                                                                                            onClick={(event) => {
                                                                                                                event.preventDefault();
                                                                                                                event.stopPropagation();
                                                                                                                setFocusedBookingId(e._id);
                                                                                                                loader.show()
                                                                                                                BookingsApi.getBooking(e._id, (data) => {
                                                                                                                    loader.hide()
                                                                                                                    Object.assign(bookingsStore.current,data)
                                                                                                                    bookingsStore.hasToShowRequestCancellation = true;
                                                                                                                })
                                                                                                            }}
                                                                                                        >
                                                                                                            Request Cancellation
                                                                                                        </a>
                                                                                                 
                                                                                            </div>
                                                                                             
                                                                                        </div>
                                                                                         
                                                                                    </div>
                                                                                            </td>
                                                                                }else{
                                                                                    return <></>
                                                                                }
                                                                            default:
                                                                                return <td {...cell.getCellProps()}><span>{cell.render('Cell')}</span></td>
                                                                        }
                                                                    }
                                        })
                                    }
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <ReactTablePagination
                        page={page}
                        gotoPage={gotoPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        pageCount={pageCount}
                        setPageSize={setPageSize}
                        manualPageSize={manualPageSize}
                        dataLength={totalCount}
                        rows={rows}
                    />
                </div>
            </div>
        </>
    )

}


const BookingListv3 = (props) => {
    return (
        <QueryClientProvider client={queryClient}>
            <DataTable />
        </QueryClientProvider>
    )
}

export default BookingListv3;