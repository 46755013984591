import React, { useState } from 'react'
import { taskStore } from "./task-store";
import { useSnapshot } from "valtio";
import Api from '../state/Api';
import { configStore,bookingsStore,EntityV2Store } from '../state/CommonState';
import Taskapi from './tasks-api';
function Billchecklist({callback,type}) {
    const [SbNo, setSbNo] = useState("")
    const [SbDate, setSbDate] = useState("")
    const [loadder, setLoader] = useState(false)
    const [exsitNo, setExist] = useState(false)
    useSnapshot(taskStore)
    let task = taskStore.current
    let docs = task.files
    console.log(type);
    const VerifySBList =async () => {
        // alert("true")
        setLoader(true)
        if (exsitNo) {
            setLoader(false)
            return 
        }
        if ((SbNo === "" || SbNo === null || SbNo === undefined) || (SbDate === "" || SbDate === null || SbDate === undefined)) {
            setLoader(false)
           return configStore.setModalMessage("Please fill relavent Data")
        }
        if (SbNo?.length != 7) {
            setLoader(false)
           return configStore.setModalMessage(`Please enter a valid ${type=="sbc"?'Shipping Bill Number':type=="boe"?'Bill of Entry Number':"Number"}`)
        }
        let wtPerContainer = 0
        bookingsStore.current.contract.containerList.map((d)=> wtPerContainer+=parseInt(d?.wtPerContainer))
        // console.log();
        let body = {
            finalPlaceOfDelivery:bookingsStore.current.contract.finalPlaceOfDelivery || "",
            bookingData:bookingsStore.current._id || "",
            entityId:EntityV2Store.EntityRec.entityId || "",
            entityName:EntityV2Store.EntityRec.entityName || "",
            entityEmails:[EntityV2Store.EntityRec.customer.crossBorder.relationshipManager,EntityV2Store.EntityRec.customer.crossBorder.customerService] || [""],
            type,
            origin:bookingsStore.current.contract.origin || "",
            wtPerContainer:wtPerContainer || 0,
        }
        if (type=="sbc") {
            body = {...body,
                SbNo,
                SbDate:SbDate.split('-').reverse().join(''),
            }
        }
        if (type=="boe") {
            body = {...body,
                SbNo,
                SbDate:SbDate.split('-').reverse().join(''),
            }
        }
        console.log(body);
       await Api.verifySbNummber(body,async(data)=>{
        console.log(data);
        if (data && data?.success) {
            if (data.mismatch) {
                taskStore.current.icegateReqData = {
                    mismatch : true,
                    no:SbNo,
                    date:SbDate.split('-').reverse().join(''),
                    type
                }
                taskStore.current.icegateResData = null
                await Taskapi.updateTask(taskStore.current)
                // return configStore.setModalMessage(`Please enter a valid ${type.toUpperCase()} No. and ${type.toUpperCase()} Date //mismatch`)
                if (type == 'sbc') {                    
                    setLoader(false)
                    taskStore.current.icegateReqData = {
                        mismatch : false,
                        no:SbNo,
                        date:SbDate.split('-').reverse().join(''),
                        type
                    }
                    taskStore.current.icegateResData = data?.data
                    taskStore.current.stage = 4
                    taskStore.current.pendingWith = 'AGRAGA'
                    taskStore.setComplete()
                    await Taskapi.updateTask(taskStore.current)
                    taskStore.triggerActionVisible = false
                    taskStore.myActionVisible = false        
                return configStore.setModalMessage(<><p>The Shipping Bill Number or Date is not matching with ICEGATE records. Please re-check to ensure there is no mistake and verify the email for any discrepancies.</p>
                <br/>
                    <p>If correct, proceed to continue with other actions. The system will check at regular intervals to verify the details with ICEGATE.</p></>) 
                }
                if (type == 'boe') {
                    setLoader(false)
                    taskStore.current.icegateReqData = {
                        mismatch : false,
                        no:SbNo,
                        date:SbDate.split('-').reverse().join(''),
                        type
                    }
                    taskStore.current.icegateResData = data?.data
                    taskStore.current.stage = 4
                    taskStore.current.pendingWith = 'AGRAGA'
                    taskStore.setComplete()
                    await Taskapi.updateTask(taskStore.current)
                    taskStore.triggerActionVisible = false
                    taskStore.myActionVisible = false
                return configStore.setModalMessage(<><p>The Bill of Entry Number or Date is not matching ICEGATE records. Please re-check to ensure there is no mistake and verify the email for any discrepancies.</p>
                <br/>
                    <p>If correct, proceed to continue with other actions. The system will check at regular intervals to verify the details with ICEGATE.</p></>) 
                }

                const msg = `Please enter a valid Data, Mismatch Found`
                setLoader(false)
                return configStore.setModalMessage(msg)
             }
             taskStore.current.icegateReqData = {
                mismatch : false,
                no:SbNo,
                date:SbDate.split('-').reverse().join(''),
                type
            }
            taskStore.current.icegateResData = data?.data
            taskStore.current.stage = 4
            taskStore.current.pendingWith = 'AGRAGA'
            taskStore.setComplete()
            await Taskapi.updateTask(taskStore.current)
            taskStore.triggerActionVisible = false
            taskStore.myActionVisible = false
            if (type == 'sbc') { 
                setLoader(false)
                return  configStore.setModalMessage("The Shipping Bill Number and  Shipping Date has been verified")
             }
            if (type == 'boe') { 
                setLoader(false)
                return  configStore.setModalMessage("The Bill of Entry Number and Bill of Entry Date has been verified")
             }
             setLoader(false)
             return  configStore.setModalMessage("verified")
        }
        else {
            if (data.errMsg) {
                setLoader(false)
                taskStore.current.icegateReqData = {
                    mismatch : false,
                    no:SbNo,
                    date:SbDate.split('-').reverse().join(''),
                    type
                }
                taskStore.current.icegateResData = data?.data
                taskStore.current.stage = 4
                taskStore.current.pendingWith = 'AGRAGA'
                taskStore.setComplete()
                await Taskapi.updateTask(taskStore.current)
                taskStore.triggerActionVisible = false
                taskStore.myActionVisible = false
                return configStore.setModalMessage(data.errMsg.error)
            }
            callback(true)
            setLoader(false)
            configStore.setModalMessage("failed")
        }
       })
    }
    const handleChange = (e)=>{
        if (e.target.value?.length > 7) {
            return  setSbNo(e.target.value?.slice(0,7))
        }
        setSbNo(e.target.value)
       
    }
    const loseFocus =async(e)=>{
        if (e.target.value?.length >= 7) {   
               //call 
               console.log(e.target.value);
               let obj = {
                id:e.target.value,
                type
               }
               await Api.getSbAndBeNo(obj,(data)=>{
                console.log("return",data?.data[0]);
                if (data?.data[0]) {
                setExist(data?.data[0]?.bookingNum)
                }else{
                    setExist(false)
                }
               })
               
        }
      }
    return (
        <>
            <div className="table-container" onClick={(e) => e.stopPropagation()}>
                <div style={{ paddingBottom: '20px', fontWeight: 'bold' }}></div>
                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                    <thead>
                        <tr>
                            {type==="sbc"?<th>Shipping Bill Number</th>:<th>Bill of Entry Number</th>}
                            {type==="sbc"?<th>Shipping Bill Date</th>:<th>Bill of Entry Date</th>}
                            <th>Document URL</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>

                            <td >
                                <input
                                    className="input is-normal"
                                    type="number"
                                    onChange={(e)=>handleChange(e)}
                                    value={SbNo}
                                    maxLength={7}
                                    onBlur={(e)=>loseFocus(e)}
                                    onKeyDown={(e) =>
                                        ["e", "E", "+", "-", "."].includes(e.key) &&
                                        e.preventDefault()
                                      }
                                />
                            </td>
                            <td>
                                <input
                                    className="input is-normal"
                                    type="date"
                                    placeholder="Enter a Number"
                                    max={new Date().toISOString().split('T')[0]}
                                    onChange={(e)=>setSbDate(e.target.value)}
                                />
                            </td>
                            <td style={{ verticalAlign: "middle" }}>{docs.map((e, i) =>



                                (e.filename) ?
                                    <a target='_blank' href={process.env.REACT_APP_FILE_SERVER + '/api/v1/filestore/getfile/' + task.bookingNum + '/' + e.filename}>
                                        {e.filename}
                                    </a> : <></>


                            )}</td>
                            <td style={{ verticalAlign: "middle", textAlign: "center" }}><button className='button ' disabled={loadder} style={{ backgroundColor: "#4e95d9", color: "#fff" }} onClick={() => VerifySBList()}>Verify</button></td>
                        </tr>
                    </tbody>
                </table>
                {exsitNo && <p style={{color:"#f03a5f"}}>This {type==="sbc"?"SB":"BOE"} No already exists for #{exsitNo}.</p>}
            </div>
        </>
    )
}

export default Billchecklist