/* eslint-disable no-lone-blocks */
import { packageTypeList } from "./StaticData"
import {  TableSelect, AgInput, AgSelect } from "./components/AgComponents"
import {TableEditWithValue} from "./components/AgCustomComponents.js"
import { useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import {
    quoteDataRec, cargoStore,
    containerStore
} from './state/CommonState'
import Utils from "./util/Utils"
import { CargoValidationStore } from "./state/validation-stores"
import { useRef } from "react"
import { enquiryStore } from "./enquiry/enquiry-data.js"

export const calcCargoDetails = (e,displayCargo4fcl) => {
    if (quoteDataRec.shipmentScope !== "Port-to-Port" || quoteDataRec.shipmentType.toLowerCase().includes('air') || quoteDataRec.shipmentType.toLowerCase().includes('fcl') || displayCargo4fcl) {
        let l = parseFloat(e.length);
        let w = parseFloat(e.width);
        let h = parseFloat(e.height);
        e.volPerPackage = (l * w * h) / 1000000.0;
        e.volPerPackage = parseFloat(e.volPerPackage.toFixed(5));
        e.totalWeight = parseFloat(parseFloat(e.numPackages) * parseFloat(e.wtPerPackage));
        console.log(" teh evnt rytgyfv hevf efvuhbhefvf efv ",e);
    if(quoteDataRec.shipmentType.toLowerCase().includes('air') || enquiryStore.current.shipmentType.toLowerCase().includes('air'))
        e.totalVolume = ((parseFloat(e.length)*parseFloat(e.width)*parseFloat(e.height)) / 1000000) * parseFloat(e.numPackages) * 166.67;
        else
            e.totalVolume = parseFloat(e.volPerPackage * e.numPackages);
    }
    //   let val1 = parseFloat((e.totalVolume * 1000000) / 6000);
    //   let val2 = e.totalWeight;
    //   e.chargeableWeight = val1 > val2 ? val1 : val2;
    //   e.chargeableWeight =parseFloat(e.chargeableWeight)
    try {
        e.totalVolume = parseFloat(e.totalVolume.toFixed(3))
    } catch(error) {
        if(e.totalVolume!=undefined){
            e.totalVolume = parseFloat(parseFloat(e.totalVolume).toFixed(3))
        }
    }
    return e;
};

export default function CargoComp() {
    let [space, setSpace] = useState('')
    const imCargoStore = useSnapshot(cargoStore)
    const imContainerStore = useSnapshot(containerStore)
    const a = imCargoStore.newCargo
    const b = imCargoStore.editCargo
    const imQuoteDataRec = useSnapshot(quoteDataRec)
    const displayCargo4fcl = Utils.outGaugeContainerCheck(imContainerStore.list)
    const isP2P = () => imQuoteDataRec.shipmentScope === 'Port-to-Port' &&  !displayCargo4fcl 
    const hide = () => (isP2P() ? { className: 'is-hidden' } : {})
    const dispForair = () => (quoteDataRec.shipmentType.indexOf('Air') >= 0) ? {} : { className: 'is-hidden' }
    let totNumPackages = 0
    let totTotalWeight = 0
    let totTotalVolume = 0
    let totChargeableWeight = 0

    const deleteCargoRec = (id) => {
        const index = cargoStore.cargoList.findIndex(e => e.id === id)
        let records = cargoStore.cargoList
        records = [...records]
        records.splice(index, 1)
        for (let i = 0; i < records.length; i++) {
            records[i].id = i + 1
        }
        cargoStore.cargoList = records
        console.log('AFTER DELETE=', JSON.stringify(cargoStore.cargoList))
    }
    const Th2 = (props) => <th rowSpan="2" {...props} style={{ paddingTop: '1.5rem' }}>{props.children}</th>
    const Ts = ({ i, name, dataList, ...otherProps }) => <TableSelect
        record={cargoStore.cargoList[i]} name={name} dataList={dataList}  {...otherProps}/>
    const Te = ({ i, name }) => <TableEditWithValue record={cargoStore.cargoList[i]} name={name} />
    const Tn = ({ i, name, tabIndex ,isreadonly, ...otherProps }) => <TableEditWithValue tabIndex={tabIndex} record={cargoStore.cargoList[i]} name={name} type='number' isreadonly={isreadonly}  {...otherProps}/>
    const Tsn = ({ name, dataList, tabIndex, ...otherProps }) => <TableSelect
        tabIndex={tabIndex} record={cargoStore.newCargo} name={name} dataList={dataList}  {...otherProps}/>
    const Ten = ({ name }) => <TableEditWithValue record={cargoStore.newCargo} name={name} />
    const _Tnn = (name) =>  {
        return {
            record: cargoStore.newCargo,
            name,
            type: 'number'
        }
    }
    const Tnn = ({ name, tabIndex , ...otherProps }) => <TableEditWithValue 
        tabIndex={tabIndex} record={cargoStore.newCargo}
        name={name} type='number'  {...otherProps}/>
    for (let i = 0; i < cargoStore.cargoList.length; i++) {
        const e = cargoStore.cargoList[i]
        totNumPackages += parseInt(e.numPackages)
        totTotalWeight += parseFloat(e.totalWeight)
        totTotalVolume += parseFloat(e.totalVolume)
        // totChargeableWeight += parseFloat(e.chargeableWeight)
        totTotalWeight = Utils.R(totTotalWeight)
        totTotalVolume = Utils.R(totTotalVolume)
        totChargeableWeight = Math.ceil(Math.max(totTotalVolume, totTotalWeight));

        // totChargeableWeight = parseFloat(totChargeableWeight)
    }
    if(cargoStore.cargoList.length>1){
        Object.assign(cargoStore.totals, {
            totNumPackages,
            totTotalWeight,
            totTotalVolume,
            totChargeableWeight
        })
    }
    console.log(cargoStore.totals)
    const isAutoCalculated = ['Port-to-Door', 'Door-to-Port', 'Door-to-Door'].includes(quoteDataRec.shipmentScope) || !quoteDataRec.shipmentType.toLowerCase().includes('lcl');
    const isHidden = (key) =>{
        switch (key) {
            case 'dimensions':
                return isP2P() && quoteDataRec.shipmentType.toLowerCase().includes('lcl') ? {className: 'is-hidden'} : {};
            // case 'totalWeight':
            //     return quoteDataRec.shipmentType.toLowerCase().includes('air') ? {className: 'is-hidden'} : {};
            case 'wtPerPackage':
                return quoteDataRec.shipmentType.toLowerCase().includes('air') ? {} : isP2P() ? {className: 'is-hidden'} : {} ;
            default:
                return {};
        }
    }
    return (
        <>
            <hr />

            <div className="table-container" onClick={(e)=> e.stopPropagation()}>
                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                    <thead>
                        <tr>
                            <th colSpan='11'>
                                <h3 className="is-size-5 has-text-centered">{Utils.labelForOutGauge1(imQuoteDataRec,imContainerStore.list) ?'Cargo Details for Out-Gauge Containers':`Cargo Details ${space}`}</h3>
                            </th>
                        </tr>
                        <tr>
                            <Th2>#</Th2>
                            <Th2>Package Type</Th2>
                            <Th2># of Packages</Th2>
                            <Th2 {...isHidden('wtPerPackage')}>Weight per Package (kgs)</Th2>
                            <th {...isHidden('dimensions')} colSpan="3" style = {{textAlign: 'center'}}>Dimensions per Package (CM)</th>
                            <Th2 {...(quoteDataRec.shipmentType.toLowerCase().includes('air') ? {className: 'is-hidden'} : hide())} >Volume per Package (CBM)</Th2>
                            <Th2 {...isHidden('totalWeight')}>{quoteDataRec.shipmentType.toLowerCase().includes('air') ? 'Total Gross Weight (kgs)' : 'Total Weight (kgs)'}</Th2>
                            {/* <Th2 {...dispForair()}>Chargeable weight (kgs)</Th2> */}
                            <Th2>{quoteDataRec.shipmentType.toLowerCase().includes('air') ? 'Total Volume Weight (kgs)' : 'Total Volume (CBM)'} </Th2>
                            <Th2>Action</Th2>
                        </tr>
                        <tr {...isHidden('dimensions')}>
                            <th>L</th>
                            <th>W</th>
                            <th>H</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            cargoStore.cargoList.map((e, i) => {
                                /*if (e.id !== imCargoStore.editCargoId)*/ {
                                    return <tr key={e.id}>
                                        <td >{i +1}</td>
                                        <td><Ts i={i} name='packageType' dataList={packageTypeList} isValid = {CargoValidationStore.isValidCargoValue('packageType', i)} /></td>
                                        <td><Tn i={i} tabIndex={1} name='numPackages' isValid = {CargoValidationStore.isValidCargoValue('numPackages', i)} /></td>
                                        <td {...isHidden('wtPerPackage')}><Tn i={i} tabIndex={1} name='wtPerPackage' isValid = {CargoValidationStore.isValidCargoValue('wtPerPackage', i)} /></td>
                                        <td {...isHidden('dimensions')}><Tn i={i} tabIndex={1} name='length' isValid = {CargoValidationStore.isValidCargoValue('length', i)} /></td>
                                        <td {...isHidden('dimensions')}><Tn i={i} tabIndex={1} name='width' isValid = {CargoValidationStore.isValidCargoValue('width', i)} /></td>
                                        <td {...isHidden('dimensions')}><Tn i={i} tabIndex={1} name='height' isValid = {CargoValidationStore.isValidCargoValue('height', i)} /></td>
                                        <td {...(quoteDataRec.shipmentType.toLowerCase().includes('air') ? {className: 'is-hidden'} : hide())}><Tn i={i} tabIndex={1} name='volPerPackage' isreadonly={ isAutoCalculated} /></td>
                                        <td {...isHidden('totalWeight')}><Tn i={i} name='totalWeight' isreadonly={isAutoCalculated} isValid = {CargoValidationStore.isValidCargoValue('totalWeight', i)} /></td>
                                        {/* <td {...dispForair()}><Tn i={i} name='chargeableWeight' isreadonly={isAutoCalculated} /></td> */}
                                        <td><Tn i={i} name='totalVolume' isreadonly={isAutoCalculated} isValid = {CargoValidationStore.isValidCargoValue('totalVolume', i)} /></td>
                                        <td>
                                            <div className="field has-addons">
                                                <p className="control">
                                                    <button className="button is-info is-inverted"

                                                        onClick={() => {
                                                            let obj = cargoStore.cargoList[i]
                                                            obj = { ...obj }
                                                            obj = calcCargoDetails(obj,displayCargo4fcl)
                                                            cargoStore.cargoList[i] = obj
                                                            setSpace(space === '' ? ' ' : '')
                                                        }}>
                                                        <span className="icon is-small">
                                                            <i className="fas fa-calculator"></i>
                                                        </span>
                                                    </button>
                                                </p>
                                                <p className="control">
                                                    <button className="button is-danger is-inverted"

                                                        onClick={() => {
                                                            deleteCargoRec(e.id)
                                                            setSpace(space === '' ? ' ' : '')
                                                        }}>
                                                        <span className="icon">
                                                            <i className="fas fa-trash"></i>
                                                        </span>
                                                    </button>
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            }
                            )
                        }
                        <tr style={{ border: '2px solid grey' }}>
                            <td ></td>
                            {/* <td><Tsn tabIndex='0' name='packageType' dataList={packageTypeList} /></td> */}
                            <td><TableSelect  record={cargoStore.newCargo} name='packageType' dataList={packageTypeList} /></td>
                            <td>
                            <TableEditWithValue  record={cargoStore.newCargo} name={'numPackages'} type='number' />
                            </td>
                            <td {...isHidden('wtPerPackage')}>
                            <TableEditWithValue  record={cargoStore.newCargo} name={'wtPerPackage'} type='number' />
                            </td>
                            <td {...isHidden('dimensions')}>
                            <TableEditWithValue  record={cargoStore.newCargo} name={'length'} type='number' />
                            </td>
                            <td {...isHidden('dimensions')}>
                            <TableEditWithValue  record={cargoStore.newCargo} name={'width'} type='number' />
                            </td>
                            <td {...isHidden('dimensions')}>
                            <TableEditWithValue  record={cargoStore.newCargo} name={'height'} type='number' />
                            </td>
                            <td {...(quoteDataRec.shipmentType.toLowerCase().includes('air') ? {className: 'is-hidden'} : hide())}><TableEditWithValue {..._Tnn('volPerPackage')} isreadonly={isAutoCalculated} /></td>
                            <td {...isHidden('totalWeight')}><TableEditWithValue {..._Tnn('totalWeight')} isreadonly={isAutoCalculated} /></td>
                            {/* <td {...dispForair()}><TableEditWithValue {..._Tnn('chargeableWeight')} isreadonly={isAutoCalculated} /></td> */}
                            <td><TableEditWithValue {..._Tnn('totalVolume')} isreadonly={isAutoCalculated} /></td>
                            <td>
                                <div className="field has-addons">
                                    <p className="control">
                                        <button tabIndex={10} className="button is-success is-inverted"
                                            onClick={() => {
                                                // let obj = cargoStore.newCargo
                                                // obj = { ...obj }
                                                // if(isAutoCalculated) obj = calcCargoDetails(obj)
                                                // cargoStore.cargoList.push({ ...obj, id: cargoStore.cargoList.length + 1 })
                                                cargoStore.cargoList.push({ ...(isAutoCalculated ? calcCargoDetails(cargoStore.newCargo):cargoStore.newCargo), id: cargoStore.cargoList.length + 1 })
                                                cargoStore.resetNewCargo()
                                                setSpace(space === '' ? ' ' : '')
                                            }}>
                                            <span className="icon is-small">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                        </button>
                                    </p>
                                </div>
                            </td>
                        </tr>
                        <tr className="has-text-weight-bold">
                            <td ></td>
                            <td></td>
                            <td>{totNumPackages}</td>
                            <td {...isHidden('wtPerPackage')}></td>
                            <td {...isHidden('dimensions')}></td>
                            <td {...isHidden('dimensions')}></td>
                            <td {...isHidden('dimensions')}></td>
                            <td {...(quoteDataRec.shipmentType.toLowerCase().includes('air') ? {className: 'is-hidden'} : hide())}></td>
                            <td {...isHidden('totalWeight')}>{totTotalWeight}</td>
                            {/* {quoteDataRec.shipmentType.toLowerCase().includes('air') && <td>{totChargeableWeight}</td>} */}
                            <td>{totTotalVolume}</td>
                            <td></td>
                        </tr>
                        {
                            quoteDataRec.shipmentType.toLowerCase().includes('air') &&
                            <tr className="has-text-weight-bold">
                                <td colSpan={8}><p className="is-size-7 has-text-right has-text-danger">* Chargeable weight (kgs) &emsp;</p></td>
                                <td><p className="has-text-left">{totChargeableWeight}</p></td>
                                <td></td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

const CargoSelect = ({ record, name }) => {
    const imRecord = useSnapshot(record)
    const packageTypeList = ['', 'Pallet', 'Drum', 'Crate', 'Skid', 'Loose Cartons', 'Box', 'Bales', 'Rolls']
    return (
        <div className="select is-small">
            <select name={name} value={imRecord[name]} onChange={(e) => record[name] = e.target.value} >
                {packageTypeList.map(val => <option key={val}>{val}</option>)}
            </select>
        </div>
    )
}   