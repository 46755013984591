import React from 'react'
import { Box, Button, Dialog, Typography } from "@mui/material";
import deleteIcon from "../assets/images/DeleteIcon.svg"


function DeletePopup({ open, onCancel, onSubmit, booking, popupMessage }) {
    return (
        <Dialog
            slotProps={{ backdrop: { sx: { backgroundColor: 'transparent', backdropFilter: 'blur(3px)' } } }}
            PaperProps={{
                sx: {
                    backgroundColor: "transparent",
                    minWidth: '600px',
                    border: "none",
                    boxShadow: "none"
                }
            }} open={open} sx={{
                backgroundColor: "transparent !important",

                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#2C358A !important"
                }

            }} onClose={onCancel}>

            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", backgroundColor: "transparent" }}>
                <Box sx={{ width: "575px", height: "250px", position: "relative", backgroundColor: "transparent", display: "flex", justifyContent: "end" }}>
                    {popupMessage === '' ? <Box sx={{ display: "flex", flexDirection: "column", height: "222px", backgroundColor: "#F5F5F5", width: "100%", alignItems: "center", borderRadius: "10px", border: '2px solid #E8E8E8', position: "absolute", bottom: 0, padding: "15px", justifyContent: "space-between" }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Figtree', fontSize: '24px', lineHeight: '30px', fontWeight: 'bold', flex: 1 }}>Are you sure you want to delete this Booking ?</Box>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end", gap: 1, width: "100%" }}>
                            <Button onClick={onCancel} sx={{
                                width: '110px', height: '40px',
                                textTransform: "capitalize", backgroundColor: "#FFFFFF", color: "#2B6ED4", borderRadius: "5px", border: '2px solid #E8E8E8', "&:hover": {
                                    backgroundColor: "#FFFFFF", color: "#2B6ED4"
                                }
                            }}>Cancel</Button>
                            <Button onClick={() => { onSubmit() }} sx={{
                                width: '110px', height: '40px',
                                textTransform: "capitalize", backgroundColor: "#F25B5B", color: "#F5F5F5", borderRadius: "5px", "&:hover": {
                                    backgroundColor: "#F25B5B", color: "#F5F5F5"
                                }
                            }}>Delete</Button>
                        </Box>
                    </Box> :
                     <Box sx={{ display: "flex", flexDirection: "column", height: "222px", backgroundColor: "#F5F5F5", width: "100%", alignItems: "center", borderRadius: "10px", border: '2px solid #E8E8E8', position: "absolute", bottom: 0, padding: "15px", justifyContent: "space-between" }}>
                        <Box sx={{ display: 'flex',justifyContent:'center',alignItems:'center',paddingY:'70px' }}>
                            <Typography sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Figtree', fontSize: '26px', lineHeight: '40px', fontWeight: 'bold' }}>{popupMessage}</Typography>
                        </Box>
                    </Box>}
                    <Box sx={{ width: "100%", height: "60px", position: "absolute", top: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60px", width: "60px", borderRadius: "50%", backgroundColor: "#FEEFEF" }}>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px", width: "40px", borderRadius: "50%", backgroundColor: "#F25B5B" }}>
                                <img src={deleteIcon} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}

export default DeletePopup
