import { Box } from "@mui/material";
import { AttributeDetails } from "../mui-components/AttributeValueBox";
import { PricingDetailsCard } from "../mui-components/PricingDetailsCard";
import { useSnapshot } from "valtio";
import { viewBookingStore } from "../state/CommonState";
import Utils from "../util/Utils";
import { useEffect } from "react";
import airlineCodes from "../static-data/airlines";
import { SHIPMENT_SCOPE, SHIPMENT_TYPE } from "../util/constants";



const lclBookingDetails = ({booking})=>{
//  let isCarrierFromPricing =  booking?.contractNum === "SPOT" ;
 
    return [
        { label: "Carrier", key: "carrier", type: "string", targetKey : null ,value:"Agraga"  },
    { label: "Stuffing Confirmation", key: "stuffing_confirmation", type: "date" },
    { label: "POL Container", key: "pol_container_number", type: "string" },
    { label: "HBL Number", key: "hbl_number", type: "string" },
    { label: "MBL Number", key: "mbl_number", type: "string" },
    { label: "New Carrier Name", key: "new_carrier_name", type: "string" },
    { label: "New Carrier MBL Number", key: "new_carrier_mbl_number", type: "string" },
    { label: "De-stuffing Status at FPOD", key: "destuffing_fpod", type: "date" },
    { label: "Last Free Date at FPOD", key: "last_free_date_at_fpod", type: "date" },
    { label: "Arrival Notice to Consignee", key: "arrival_notice", type: "date" },
    { label: "Delivery Order Release", key: "delivery_order_release", type: "date" },
    { label: "Special Remarks", key: "remarks", type: "string" }
]}

const fclBookingDetails = ({booking})=>{
    let isCarrierIsAgraga =  booking?.contractRefNum === "SPOT";
    return [
    { label: "Carrier", key: isCarrierIsAgraga ? "scac" : "carrier", type: "string", targetObject :  "booking.contract"  },
    { label: "Carrier Booking Reference #", key: "carrier_booking_ref_number", type: "string" },
    { label: "Carrier Release Order", key: "carrier_booking_release", type: "string" },
    { label: "VGM Cut off", key: "vgm_cut_off", type: "date" },
    { label: "SI Cut-off", key: "shipping_instructions_cut_off", type: "date" },
    { label: "HBL #", key: "hbl_number", type: "string" },
    { label: "Last Free Date at FPOD", key: "last_free_date_at_fpod", type: "date" },
    { label: "Arrival Notice to Consignee", key: "arrival_notice", type: "date" },
    { label: "Delivery Order Release", key: "delivery_order_release", type: "date" },
    { label: "Special Remarks", key: "remarks", type: "string" }
]
}
const airBookingDetails =({booking})=>{
    let isCarrierFromPricing =  booking?.contractRefNum === "SPOT"  ;
    return [
    {label:"Airline",key: isCarrierFromPricing ? "iata":"airline",type:"string",targetObject:"booking.contract",formatValue:(value)=>{
        return value?.length===2 ? airlineCodes.find(({code})=>code===value).name : value;
    }},
    { label: "Airline Release Order", key: "airline_booking_release", type: "date" },
    { label: "Airline Booking Reference #", key: "airline_booking_ref_number", type: "string" },
    { label: "Flight number from departure airport", key: "flight_number_departure_airport", type: "string" },
    { label: "HAWB Number", key: "hawb_number", type: "string" },
    { label: "MAWB Number", key: "mawb_number", type: "string" },
    { label: "Arrival notice to consignee", key: "arrival_notice", type: "date" },
    { label: "Last Free Date @ FPOD", key: "last_free_date_at_fpod", type: "date" },
    { label: "Delivery order released to consignee", key: "delivery_order_release", type: "date" },
    { label: "Special Remarks", key: "remarks", type: "string" },

]
}


function getTargetObject(objectFrom,keysConcatinatedWithDots){
    if(!keysConcatinatedWithDots){
        return objectFrom;
    }
    let keys = keysConcatinatedWithDots?.split(".");
    let targetedObject = objectFrom;
    if(keys && keys.length>0){
        keys?.forEach((targetKey)=>{
            targetedObject = targetedObject[targetKey];
        }) 
    }
    return targetedObject
}

export function ShippingDetailsView() {
    const booking = viewBookingStore.current
    const isAir = booking.booking.contract.shipmentType === "Air";
    const isFcl = booking.booking.contract.shipmentType.includes("FCL");
    const isLcl = booking.booking.contract.shipmentType.includes("LCL");
    const shipmentScope = booking.booking.contract.shipmentScope
    const shipmentType = booking.booking.contract.shipmentType
    let gateInCFS = false
    if((shipmentScope === SHIPMENT_SCOPE.p2d || shipmentScope === SHIPMENT_SCOPE.p2p)&&!(shipmentType === SHIPMENT_TYPE.fcl_f_f || shipmentType === SHIPMENT_TYPE.fcl_f_cfs)){
        gateInCFS = true
    }

    let showDetailsMasterArray = [];
   
    if (isAir) {
        showDetailsMasterArray = [...airBookingDetails(booking)];
        if(gateInCFS){
            showDetailsMasterArray.push({ label: "Gate-in at Cargo Terminal", key: "arrival_dispatch_airport", type: "date"})
        }
        for (let i = 1; i < 5; i++) {
            const key = `flight_number_ts${i}`
            if (booking[key] && booking[key].length > 0) {
                showDetailsMasterArray.push({ label: `Flight number from transhipment ${i} airport`, key, type: "string" })
            }
        }
    } else if (isLcl) {
        showDetailsMasterArray = [...lclBookingDetails(booking)]
        if(gateInCFS){
            showDetailsMasterArray.push({ label: "Cargo Gate-in at CFS", key: "cargo_gatein_CFS", type: "date"})
        }
        if (booking["reworking_at_pol"]?.toLowerCase() === "yes") {
            showDetailsMasterArray.push({ label: "De-stuffing Status", key: "destuffing_at_pol", type: "date" });
            showDetailsMasterArray.push({ label: "Re-stuffing Status", key: "restuffing_at_pol", type: "date" });
        }
    } else if (isFcl) {
        showDetailsMasterArray = [...fclBookingDetails(booking)]
        if(gateInCFS){
            showDetailsMasterArray.push({ label: "Cargo Gate-in at CFS", key: "cargo_gatein_CFS", type: "date"})
        }
    }

    return <Box sx={{ paddingX: "10px" }}><AttributeDetails details={showDetailsMasterArray.map((bookingDetail) => {
        const objectToBeSearched = getTargetObject(booking,bookingDetail.targetObject);
       
        return { title: bookingDetail.label, value: bookingDetail.value ? bookingDetail.value : bookingDetail.type === "date" ? Utils.formatDateBooking(objectToBeSearched[bookingDetail.key], false) : bookingDetail.formatValue ? bookingDetail.formatValue(objectToBeSearched[bookingDetail.key] ) : objectToBeSearched[bookingDetail.key] }
    }
    )} /></Box>

}