import PdfViewer from "../mui-components/PDFViewer";
import { Box, Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import closeIcon from "../assets/images/close.svg";
import downloadbutton from "../assets/images/downloadicon.svg"
import axios from "axios";
import { configStore } from "../state/CommonState";
import { ApproveRejectComponent } from "../mui-components/ApproveReject";
import MonApi from "../state/mongo-api";
import { taskStore } from "../mytasks/task-store";
import ExcelTable from "../mui-components/ExcelTable";
import { useEffect, useState } from "react";
let server = process.env.REACT_APP_API_SERVER

export function DocumentViewer({documentTitle,open,onClose,documentUrl,serverUrl,needsApproval=false,onRejection,onApprove,onReject,isExcel,download}){
  useEffect(()=>{
     const extension = documentUrl?.split(".");
     if( extension && extension[extension?.length-1]==="pdf"){
      setPdf(true);
     }
  },[documentUrl])
  const [isPdf,setPdf] = useState(false);
    const handleDownload = async(event)=>{
        try {
            const response = await axios(serverUrl, {
              responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a'); // const buffer = await response.arrayBuffer();
            link.href = url;
            link.setAttribute('download', documentTitle);
            document.body.appendChild(link);
            link.click();
          } catch (error) {
            configStore.setModalMessage(error.message)
          }
    }

  
    return <Dialog sx={{paddingX:5,paddingY:2, zIndex: 2000000}} open={open} fullScreen fullWidth >
            <Box sx={{display:"flex",flexDirection:"column",height:"100%",width:"100%"}}>
                <Box sx={{display:"flex",flexDirection:"row",height:"40px",width:"100%",backgroundColor:"#2C358A",alignItems:"center",paddingX:2}}>
                    <Typography sx={{fontFamily:"Figtree",color:"white",fontSize:"14px",marginRight:"auto"}}>{documentTitle}</Typography>
                    <Box sx={{display:"flex",flexDirection:"row"}} >
                        {download?<Box onClick={handleDownload} sx={{display:"flex",flexDirection:"row",gap:1,alignItems:"center",marginRight:"30px",cursor:"pointer"}}>
                            <img src={downloadbutton} />
                            <Typography sx={{fontFamily:"Figtree",color:"white",fontSize:"14px"}}>Download</Typography>
                        </Box>:''}
                        <IconButton onClick={onClose}>
                            <img src={closeIcon}/>
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{display:"flex",flexDirection:"row",width:"100%",height:"100%",justifyContent:"center",backgroundColor:"#F5F5F5",gap:2,textAlign:"center",paddingY:"5px",overflow:"auto","&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#2C358A !important"
                }}}>
                   {isExcel ? <Box sx={{height:"98%",maxWidth:"80%",display:"flex"}}>
                  { documentUrl &&  <ExcelTable url={documentUrl}/>}
                   </Box> : <iframe style={{height:"98%",width:isPdf?"700px" : "80%"}} 
                    // src={`${documentUrl}#toolbar=0`} 
                    src={`${documentUrl}`} 
                    />}
                   { needsApproval && <ApproveRejectComponent onApprove={onApprove} onReject={onReject} closeDialog={()=>{
                      if(onClose){
                        onClose();
                      }
                   }} onRejectedReason={onRejection} fileName={documentTitle}/>}
                </Box>
            </Box>
    </Dialog>
}