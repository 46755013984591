import React,{useState,useEffect, useRef,} from "react";
import {useTable,usePagination,useSortBy} from "react-table";
import { QueryClient,QueryClientProvider } from "react-query";



import {ClientUploadQuote,
    ClientUploadContract,
    ClientUploadArchives,
    AgragaUploadQuote,
    AgragaUploadContract,
    AgragaUploadArchives} from "../components/ag-table/columns";

import TableFilter from "../components/ag-table/TableFilter";

import {reducer,PAGE_CHANGED,PAGE_SIZE_CHANGED,PAGE_SORT_CHANGED,PAGE_FILTER_CHANGED,TOTAL_COUNT_CHANGED,PAYLOAD_CHANGED} from "../components/ag-table/ag-reducer"
import ApiAgTable from "../components/ag-table/api_ag-table";
import { configStore,loginStore,contractsStore, AgmodalStore } from "../state/CommonState";
import { useSnapshot } from "valtio";
import { enquiryStore } from "../enquiry/enquiry-data";
import Utils from "../util/Utils";
import TableHead from "../util/dataTable";
import Agproducttab from "../components/Agproducttab";
import Newapi from "../state/new-api";
import { Box, Typography } from "@mui/material";
import { Nodata } from "../mui-components/nodatafound";
import Api from "../state/Api";
import { AgLoader } from "../components/ag-loader/ag-loader";
import { loader } from "../util/loader";
import ReactTablePagination from "../components/ag-table/ReactTablePagination";


const queryClient = new QueryClient()


const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    queryPayload: {}
};

const scheduleUploadStatus = {
    'UPLOAD_SCHEDULES': 'PENDING',
    'SCHEDULES_UPLOADED': 'UPLOADED'
}









const DataTable = (props) => {
    useSnapshot(loginStore)
    useSnapshot(configStore)
    useSnapshot(contractsStore)
  useSnapshot(loader)

    let title = 'Load Schedules'
    if (loginStore.isClient()) title = 'Create Booking'
    const [activeTab, setActiveTab] = useState('Quotations')
    useEffect(() => {
        if (contractsStore.navigateFromCharts) setActiveTab('Contracts')
    }, [contractsStore.navigateFromCharts])
    let tablist = ['Quotations', 'Contracts', 'Archives']
    // if(loginStore.isClient()) tablist.push('New Enquiries')
    const [activeProduct, setActiveProduct] = useState('LCL')
    let productList = ['LCL', 'FCL', 'Air']
    const [selectedFilter, SetSelectedFilter] = useState(`ALL`);
    const abortControllerRef = useRef(null);
    let column = {}

    if(!loginStore.isClient()){
        if(activeTab === 'Quotations' ){
            column = AgragaUploadQuote
        }else if (activeTab === 'Contracts'){
            column = AgragaUploadContract
        }else{
            column = AgragaUploadArchives
        }
    }else{
        if(activeTab === 'Quotations'){
            column = ClientUploadQuote
        }else if(activeTab === 'Contracts'){
            column = ClientUploadContract
        }else{
            column = ClientUploadArchives
        }
    }

    const [keyword, setKeyword] = useState('');
    const [data1, setData1] = useState({ results: [] });
    const [isLoading,setLoading] = useState(true);
    const onClickFilterCallback = (filter) => {
        setKeyword(filter)
    }

    let columns = column
    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
    React.useReducer(reducer, initialState);


    useEffect(()=>{
        if(queryPayload.status){
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
              }
            const abortController = new AbortController();
            abortControllerRef.current = abortController;
            setLoading(true)
        ApiAgTable.uploadCheduleData(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload,abortController.signal).then(res=>{
            res.clone().json().then((res) => {
                if (JSON.stringify(data1?.results) != JSON.stringify(res?.results)) {
                    if(JSON.stringify(data1)!=JSON.stringify(res) && res.results && res.results.length > 0){
                        setData1(res);
                    }else{
                        setData1({ count: 0, results: [] });
                    }
                }
                setLoading(false);
            }, err => {
                setLoading(false);
                console.log(err)
            })
        }).catch((e)=>{
            if(e.name==="AbortError"){
                setLoading(true)
            } else{
                 setLoading(false);
            }
            
            setData1({ results: [] });
        })
        }
},[queryPayload,queryPageIndex,queryPageSize,queryPageFilter,queryPageSortBy , configStore.currentSelectedBranch , configStore.currentSelectedEntity])

    const totalPageCount = Math.ceil(totalCount / queryPageSize)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable({
        columns,
        data: data1?.results,
        initialState: { 
            pageIndex: queryPageIndex,
            pageSize: queryPageSize,
            sortBy: queryPageSortBy,
        },
        manualPagination: true,
        pageCount: data1 ? totalPageCount : null,
        autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: false
    },
        useSortBy,
        usePagination,
    );

    const manualPageSize = [];
    useEffect(()=>{
       if(isLoading){
          loader.show();
       }else{
        loader.hide();
       }
    },[isLoading])

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex,gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
        gotoPage(0);
    }, [pageSize, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
        gotoPage(0);
    }, [sortBy, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword });
        gotoPage(0);
    }, [keyword, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAYLOAD_CHANGED, payload: { status : selectedFilter, date:Utils.getCurrentDateString(), type:activeProduct, mode:activeTab  } });
        gotoPage(0);
    }, [columns,activeProduct,activeTab,selectedFilter]);

    React.useEffect(() => {
        if (data1?.count) {
            dispatch({
                type: TOTAL_COUNT_CHANGED,
                payload: data1?.count,
            });
        }
    }, [data1?.count]);


    return (
        <>
        <AgLoader/>
        <nav className="level">
                <div class="level-left">
                    <div class="level-item">
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h5 className="title is-4" style={{ color: '#313131', fontFamily: 'product_sans_bold' }}>
                                {title}
                            </h5>
                            <br />
                        </span>
                    </div>
                    <div class="level-item">
                    <TableFilter onClickFilterCallback={onClickFilterCallback} defaultKeyword={keyword} />
                    </div>
                </div>
                {
                    (!loginStore.isClient()) ?
                        <div class="level-item level-left ml-6">
                            <div class="field">
                                <p class="control has-icons-left has-icons-right">
                                    <span class="select">
                                        <select name="status" id="status" value={selectedFilter} onChange={(e) => SetSelectedFilter(e.target.value)}>
                                            <option value="ALL">ALL</option>
                                            {
                                                Object.entries(scheduleUploadStatus).map(([key, value]) => (
                                                    <option value={key}>{value}</option>
                                                ))
                                            }
                                        </select>
                                        <span class="icon">
                                            <i class="fa-solid fa-filter"></i>
                                        </span>
                                    </span>
                                </p>
                            </div>
                        </div>
                        : <></>
                }
            </nav>
            {
                (contractsStore.navigateFromCharts) ?
                    <h5 className="title is-5"
                        style={{ color: '#ffcccc', fontFamily: 'product_sans_bold' }}>
                        Showing Contracts expiring within the next 7 days
                    </h5>
                    : <></>
            }
            {
                (!contractsStore.navigateFromCharts) ?
                loginStore.isClient() ? 
                <Box sx={{width:"100%",display: "flex", flexDirection: 'row', gap: 1,backgroundColor:"white",borderRadius:"5px", marginY : "5px",padding:"5px"}}>
                  {tablist.map((e)=>  <Box onClick={()=>{
                       if (e === 'New Enquiries') {
                        enquiryStore.initialize()
                        enquiryStore.currentCargoTableKey = '' + new Date().getMilliseconds()
                        enquiryStore.current.createdBy = loginStore.email
                        enquiryStore.current.gst = configStore.currentSelectedEntity.gst
                        enquiryStore.current.shipmentType = configStore.homescreenCurrentShipmentType
                        configStore.homescreenCurrentView = 'new'
                        configStore.scheduleOrCarrierform = 'schedule'
                    } else {
                        setActiveTab(e)
                        contractsStore.navigateFromCharts = false;
                    }
                   }} sx={{
                    
         height: "100%", borderRadius: "5px", display: "flex", flexDirection: 'row', gap: 1, justifyContent: "center", alignItems: "center", padding: "5px 14px", backgroundColor: e === activeTab ? "#2C358A" : "transparent", color: e === activeTab ? "white" : "#555555", '&:hover': {
             cursor: "pointer"
         }
     }}>
         {/* <img style={{ filter: selected ? "brightness(0) invert(1)" : "brightness(0) saturate(100%) invert(31%) sepia(4%) saturate(22%) hue-rotate(49deg) brightness(98%) contrast(85%)" }} height="13%" width="13%" src={icon} /> */}
         <Typography fontSize="14px" fontFamily="Figtree" fontWeight={600}>{e=='Archives'?'Completed':e}</Typography>
     </Box>)}
                </Box>    
                 
                : 
                    <div id="quotelistclient" className="tabs is-boxed"
                        style={{ marginBottom: '0px', backgroundColor: '#2c358a' }}>
                        <ul>
                            {
                                tablist.map(e => {
                                    let className = ''
                                    let styles = { color: 'lightgrey' }
                                    if (e === activeTab) {
                                        className = 'is-active'
                                        styles = { backgroundColor: '#eea724', color: 'black' }
                                    }
                                    styles.paddingTop = '5px'
                                    styles.paddingBottom = '5px'
                                    styles.paddingRight = '15px'
                                    return <li className={className} onClick={() => {
                                        if (e === 'New Enquiries') {
                                            enquiryStore.initialize()
                                            enquiryStore.currentCargoTableKey = '' + new Date().getMilliseconds()
                                            enquiryStore.current.createdBy = loginStore.email
                                            enquiryStore.current.gst = configStore.currentSelectedEntity.gst
                                            enquiryStore.current.shipmentType = configStore.homescreenCurrentShipmentType
                                            configStore.homescreenCurrentView = 'new'
                                            configStore.scheduleOrCarrierform = 'schedule'
                                        } else {
                                            setActiveTab(e)
                                        }
                                    }}>
                                        <a style={styles}><span>{(e=='Archives'?'Completed':e)}</span></a>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    : <></>
            }
             <div style={{  width: loginStore.isClient() ? "20%" : "100%"}}>
            <Agproducttab productList={productList}
                activeProduct={activeProduct} setActiveProduct={setActiveProduct} />
</div>

<div className="box" style={{flex:1}}>
{/* { isLoading ? <CenteredCircularProgress/> :  */}
                <div className="table-container" style={{ overflow: 'initial' }}>
                    <table className="table is-fullwidth is-small ">
                    <TableHead headerGroups={headerGroups} sort={true}/>
                    <tbody className="contractlist" style={{ fontSize: '0.9rem' }} {...getTableBodyProps()}>
                                                {page.map((row, i) => {
                                                    prepareRow(row);
                                                    return (
                                                        <tr {...row.getRowProps()} 
                                                        onClick={async ()=>{
                                                            loader.show()
                                                            if (!loginStore.isClient()) {
                                                                let lock = await Newapi.acquireContractLock(row.original._id)
                                                                if (!lock.acquired) {
                                                                    loader.hide()
                                                                    configStore.setModalMessage(`Quote/Contract already locked by ${lock.email}`)
                                                                    return
                                                                }
                                                                contractsStore.lockedContract = row.original._id
                                                            } 
                                                            contractsStore.current = {}
                                                            // if(loginStore.isClient()){
                                                                Api.getContract(row.original._id, (data) => {
                                                                    // if (loginStore.isClient()) {
                                                                        // let l = 1
                                                                        // loginStore.userRec.companyList.filter((e) => {
                                                                        //     if (e?.entityId == data?.entityId) {
                                                                        //         configStore.currentSelectedEntity = e
                                                                        //         l = 0
                                                                        //     }
                                                                        // })
                                                                        // if (l == 0) {
                                                                        loader.hide()
                                                                            enquiryStore.initializeWithConract(data)
                                                                            //if (loginStore.isClient()) Api.createContractFromQuote(contractId)
                                                                            configStore.homescreenCurrentView = 'view'
                                                                            AgmodalStore.pageVisible = true;
                                                                            AgmodalStore.modalPage = "HomeScreenv3";
                                                                            configStore.scheduleOrCarrierform = 'schedule'
                                                                            let stype = enquiryStore.current.shipmentType.substr(0, 3).toLowerCase()
                                                                            if (stype === 'lcl') configStore.homescreenCurrentShipmentType = 'LCL'
                                                                            else if (stype === 'fcl') configStore.homescreenCurrentShipmentType = 'FCL'
                                                                            else configStore.homescreenCurrentShipmentType = 'Air'
                                                                            configStore.homescreenCurrentShipmentScope = enquiryStore.current.shipmentScope
                                                                        // } 
                                                                        // else {
                                                                        //     window.location.href = window.location.href
                                                                        // }
                                                                    // }
                                                                })
                                                            // }
                                                            // else{
                                                            //     window.open(`${window.location.href}contractsv2/view/${row.original._id}`, '_blank').focus();
                                                            // }

                                                        

                                                        }}
                                                        >
                                                            {
                                                                row.cells.map(cell => {
                                                                    {
                                                                        let e = cell['row']['original']
                                                                        switch (cell['column']['Header']) {
                                                                            case "Quotation Date":
                                                                                return <td>{Utils.ddmmyyyystring(e.mailedOn)}</td>
                                                                            case "Destination":
                                                                                return <td>{e.destination2!=undefined?"Multiple":e.destination}</td>
                                                                            case "Date":
                                                                                return <td>{Utils.ddmmyyyystring(e.contractCreatedOn)}</td>
                                                                            case "Valid Till":
                                                                                return <td>{Utils.ddmmyyyystring(e.validUpto)}</td>
                                                                            case 'Status':
                                                                                return <td {...cell.getCellProps()}><span>{scheduleUploadStatus[e.status]}</span></td>
                                                                            default:
                                                                                return <td {...cell.getCellProps()}><span>{cell.render('Cell')}</span></td>
                                                                        }
                                                                    }
                                                                    
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })}
                    </tbody>
                    </table>
                    {rows.length===0 && loginStore.isClient() && <Nodata width="170px" height="170px" />}
                    {(rows.length > 0) && (
                                        <>
                                            <div className="columns" style={{width:"100%"}}>
                                                <div className="column is-12">
                                                <ReactTablePagination
                        page={page}
                        gotoPage={gotoPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        pageCount={pageCount}
                        setPageSize={setPageSize}
                        manualPageSize={manualPageSize}
                        dataLength={totalCount}
                        rows={rows}
                    />
                                                </div>
                                                

                                            </div>


                                        </>
                                    )}
                </div>
{/* } */}
            </div>
        </>
    )
}




const HomeListv3 = (props) => {
    return (
        <QueryClientProvider client={queryClient}>
            <DataTable />
        </QueryClientProvider>
    )
}

export default HomeListv3;