import { CustomIconButton } from "./CustomIconButton";
import shareicon from "../assets/images/share.svg";
import { Box, Button, Chip, IconButton, Menu, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import closeicon from "../assets/images/closeicon.svg";
import { isError } from "lodash";
import { Add, AddCircle, AddCircleRounded, AddOutlined } from "@mui/icons-material";
import { AddCircleOutline } from "@mui/icons-material"



function CustomChip({label,onDelete}){
   return <Box sx={{display:"flex",flexDirection:"row",gap:1,alignItems:"center",backgroundColor:"#E5F1FF",borderRadius:"3px",paddingY:"5px",paddingX:"8px",height:"max-content"}}>
        <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px",fontWeight:"bold"}}>{label}</Typography>
        <IconButton onClick={onDelete}><img src={closeicon}/></IconButton>
   </Box>
}

const defaultErrorState = {isError:false,message:"Email Id is required"};
export function ShareButton({onSubmit,title="Share Live Tracking",height="35px",width="35px"}){
    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const emailField = useRef();
    const [error,setError] = useState({...defaultErrorState});
    const handleClick = (event) => setAnchorEl(event.currentTarget);
   
    const [inputValue, setInputValue] = useState("");
    const [selectedValues, setSelectedValues] = useState([]);
    const handleClose = (e) => {
      e.stopPropagation();
      setAnchorEl(null);
      setSelectedValues([])
      setError({...defaultErrorState})
      setInputValue("");
  };
    useEffect(()=>{
      return ()=>{
        setSelectedValues([])
        setError({...defaultErrorState})
      }
    },[])

    useEffect(()=>{
      if(selectedValues.length>0){
        setError({...defaultErrorState})
      }
    },[selectedValues.length])
  
    const handleInputChange = (event) => {
        event.stopPropagation();
      setInputValue(event.target.value);
    };
  
    const handleInputKeyDown = (event) => {
        event.stopPropagation();
        if (
          // event.key === "Enter" && 
        inputValue.trim() !== "") {
          let pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          const isMatch = pattern.test(inputValue.trim());
          if(!isMatch){
             setError(({isError:true,message:`Invalid email address ${inputValue.trim()}`}))
             return;
          }else{
             setError({...defaultErrorState})
          }

          setSelectedValues([...selectedValues, inputValue.trim()]);
          setInputValue("");
        }
      };


    
      const handleDeleteValue = (index) => {
        const updatedValues = [...selectedValues];
        updatedValues.splice(index, 1);
        setSelectedValues(updatedValues);
      };

      const handleSubmit = ()=>{
        setError({...defaultErrorState})
        if(selectedValues.length===0){
          setError((prev)=>({...prev,isError:true}))
          return
        }
        if(onSubmit){
          onSubmit(selectedValues);
        }
        setSelectedValues((__)=>[]);
        setError({...defaultErrorState})
        setAnchorEl(null);
      }

    return <>
         <CustomIconButton  onClick={handleClick} height={height} width={width} src={shareicon} />
         <Menu
         onClick={(event)=>{
            event.stopPropagation();
         }}
         onKeyUp={(event)=>{
          event.stopPropagation();
         }}
         onKeyDown={(event)=>{
          event.stopPropagation();
         }}
         sx={{minWidth:"400px",padding:8}}
         anchorEl={anchorEl}
         open={isOpen}
         onClose={handleClose}
         anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
         >
         <Box 
         onClick={(event)=>{
          event.stopPropagation();
       }}
       onKeyUp={(event)=>{
        event.stopPropagation();
       }}
       onKeyDown={(event)=>{
        event.stopPropagation();
       }}
         sx={{display:"flex",flexDirection:"column",width:"400px",justifyContent:"space-between",paddingX:"10px",gap:1.8}}>
            <Typography sx={{fontFamily:"Figtree",fontSize:"14px",color:"#555555"}}>{title}</Typography>
            <Box onClick={()=>{
              if(emailField.current){
                setTimeout(() => {
                  emailField.current.focus();
                }, 0);
              }
            }} sx={{display:"flex",flexWrap:"wrap",height:"109px",width:"100%",border:"1px solid #D4D4D4",borderRadius:"5px",gap:1,padding:"8px",overflowY:"auto", "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#2C358A !important"
                        }}}>
                {selectedValues.map((value, index) => (
              <CustomChip
                key={index}
                label={value} 
                onDelete={() => handleDeleteValue(index)}
              />
            ))}
     <TextField
    
  inputRef={emailField}
  size="small"
  
  variant="standard"
          placeholder="Enter email address"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={(event)=>{
            event.stopPropagation()
          }}
          // onKeyDown={handleInputKeyDown}
          InputProps={{ 
            endAdornment : inputValue?.length>4 && <IconButton onClick={handleInputKeyDown} sx={{padding:0}}><AddCircleRounded color="primary" fontSize="medium" /></IconButton>  ,
            disableUnderline: true,style:{
            alignSelf:"center"
          } }}
        />
            </Box>
            { error.isError && <Typography sx={{fontFamily:"Figtree",fontSize:"14px",color:"red"}}>{error.message}</Typography>}
        <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent: selectedValues.length>0  ?"space-between" : "end",alignItems:"start"}}>
            {selectedValues.length>0 && 
            <Box sx={{display:"flex",flexDirection:"row",gap:1,alignItems:"center"}}>
                <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"3px",paddingX:"5px",color:"#555555",fontFamily:"Figtree",fontSize:"12px",backgroundColor:"#E5F1FF"}}>
                    {selectedValues.length} Email Id
                </Box>
                <Typography sx={{fontFamily:"Figtree",fontSize:"12px",color:"#555555"}}>have been added</Typography>
            </Box>
            }
            
        <Button onClick={handleSubmit} variant="contained" size="small" sx={{display:'flex',alignSelf:"flex-end",textTransform:"capitalize"}}>Send Email</Button>
        </Box>
         </Box>
         </Menu>
    </>
}