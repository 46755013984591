import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import SelectedBooking from "./TrackBooking.js";
import { configStore, newDashboardState } from "../state/CommonState.js";
import { useSnapshot } from "valtio";


export default function TrackMyBookingV2() {
    useSnapshot(configStore);
   
 
    return <Box sx={{ display: "flex", flexDirection: "column", gap: 1, height: "100%" }}>
        {
            configStore?.currentSelectedEntity?.isTrackMyBooking?.toLowerCase() === "yes" ?
                <SelectedBooking />
                : <></>
        }
    </Box>
}