import { useEffect, useRef, useState } from "react"
import { proxy,  useSnapshot } from "valtio"
import { configStore, contractsStore, entityStore, loginStore, modalStore,  bookingsStore, EntityV2Store } from "../state/CommonState"
import { Cargo, Container, enquiryStore } from "../enquiry/enquiry-data"
import EnquiriesApi from "../state/enq-api"
import Agproducttab from "../components/Agproducttab"
import Utils from "../util/Utils"
import { rtpStore } from "../pricing/pricing-store"
import PricingUtils from "../pricing/pricing.utils"
import MonApi from "../state/mongo-api"
import AgCustlist from "../components/AgCustlist"
import BookingConfirmation from "../schedules/booking-confirmation"
import SpotLCLDisplayv3 from "./spot.lcl.display-v3"
import Pricingcwf from "../pricing/pricing.cwf"
import { Button } from "@mui/material"
import EnquiryConfirmationv3 from "../enquiryv3/enquiry-confirmation-v3"
import ActrejectModalV3 from "../enquiryv3/enquirysuggestion-v3"
import SpotFCLDisplayv3 from "./spot.fcl.display-v3"
import SpotAirDisplayv3 from "./spot.air.display-v3"
import { ActiveTab } from "../v3-componets/AgActiveTab"
import { useForm } from "react-hook-form"
import { SHIPMENT_SCOPE } from "../util/quotationUtil"
import { Route } from "../v3-componets/AgRoute"
import { Details } from "../v3-componets/AgDetails"
import { loader } from "../util/loader"

let pricingStore = proxy({
    gst: '',
    origin: '',
    destination: ''
})
function EnquiryOption(props) {
    useSnapshot(enquiryStore)
    useSnapshot(loginStore)
    useSnapshot(rtpStore)
    let message = `We do not have these routes real-time but, would love to provide a customized solution.
    We will get in touch with your shortly over Phone or E-mail.`
    let oklabel = 'OK'
    let cancellabel = 'No'
    if (!loginStore.isClient()) {
        message = 'Real-time rates unavailable'
        oklabel = 'Submit Enquiry'
        cancellabel = 'Cancel'
    }
    return (
        <div style={{
            width: '50%', margin: 'auto', padding: '3rem', border: '1px solid black',
            backgroundColor: '#D4F2B5'
        }}>
            <div>{message}</div>
            {
                (rtpStore.customerTBA != 'TBA' && (!rtpStore.records || !rtpStore.records?.length)) &&
                <button className="button islink" style={{ float: 'right', marginLeft: '2rem' }}
                    onClick={(e) => {
                        //enquiryStore.current.entityId = 'TBA'
                        /*EnquiriesApi.saveEnquiry()
                        configStore.enquiryModalVisible = true*/
                        props.setMessage('')
                        if(!loginStore.isClient()){
                            configStore.quotationModalVisible4 = true
                        }else{
                            EnquiriesApi.saveEnquiry()
                        }
                    }}
                >{oklabel}</button>
            }
            <button className="button is-danger" style={{ float: 'right' }}
                onClick={(e) => {
                    props.setMessage('')
                }}
            >{cancellabel}</button>
        </div>
    )
}
function RoutesEmpty({ message, setMessage }) {
    useSnapshot(rtpStore)
    let marr = ['Enumerating Routes...', 'Calculating Cost...',
        'Loading Schedules...', 'Fetching Transport Rates...', 'Optimizing Results...',
        'Sorting Results...', 'Serializing ...']
    if (message == 'No Routes') return <EnquiryOption setMessage={setMessage} />

    message = '' + message
    if (message.startsWith('Fetching')) {
        let m = marr[rtpStore.loadingCounter % 5]
        if (!m || typeof m == 'undefined') m = 'Enumerating Routes...'
        return <div className="title is-3" style={{ color: 'blueviolet', marginBottom: "100px" }}>{'' + m}</div>
    }
    return (
        <div className="title is-5">{'' + message}</div>
    )

}
export default function SpotBookingv3(props) {
    let viewonly = '0'
    useSnapshot(configStore)
    useSnapshot(loginStore)
    useSnapshot(pricingStore)
    useSnapshot(enquiryStore)
    useSnapshot(rtpStore)
    useSnapshot(bookingsStore)
    useSnapshot(modalStore)
    useSnapshot(EntityV2Store)
    const [routeFilter, setRouterFilter] = useState('Cheapest')
    let cheapestColor = routeFilter == 'Cheapest' ? 'islink' : ''
    let fastestColor = routeFilter == 'Fastest' ? 'islink' : ''
    const [cwfVisible, setcwfVisible] = useState(false)
    const [selectedType, setType] = useState(enquiryStore.current.shipmentType);
    const cargoForm = useForm({
        defaultValues: {
            mode: "LCL",
            origin: enquiryStore.current.enqRoute.origin,
            orginScope: false,
            destinationScope: false,
            cargoreadinessdate: Utils.yyyymmdd(new Date()),
            scope: SHIPMENT_SCOPE.P2P,
            shipmentType: enquiryStore.current.shipmentType,
            originType: false,
            destinationType: false,
            customerreference : enquiryStore.current.enqRoute.customerReferenceNum,
            hazardous: enquiryStore.current.hazardous === "No" ? false : true,
            quoteClass: enquiryStore.current.quoteClass,
            packingGroup: enquiryStore.current.packingGroup,
            temperature: enquiryStore.current.temperature,
            cargoValue: enquiryStore.current.cargoValue,
            unnumber: enquiryStore.current.unnumber,
            nonStackable:  enquiryStore.current.nonStackable === "No" ? false : true,
            temperatureControlled:  enquiryStore.current.temperatureControlled === "No" ? false : true,
            minTemperature: enquiryStore.current.minTemperature,
            maxTemperature: enquiryStore.current.maxTemperature,
            stuffingType: enquiryStore.current.stuffingType,
            destuffingType: enquiryStore.current.destuffingType,
            status: "ENQUIRY",
            cargoDimensionUnit: "Cms",
            enqRoute: enquiryStore.current.enqRoute,
            vehicledetails : enquiryStore.current.vehicles,
            isFba:false,
            showPalletLabel:false,
            ior:false,
            occ : false,
            dcc:false,
            survey:false,
            storageAtDestination : false,
            days : 0,
            palletizationandlabelling : true,
            showCargoDetailsOutGuage: false,
            pick_up_by_amazon:false,
            multidest:enquiryStore.current.multidest,
            containerDetails:[new Container()],
            fbaalways:"No"
        }
    })
    useEffect(()=>{
        if(enquiryStore.current.shipmentType.length>3 && enquiryStore.current.shipmentType.toLowerCase().indexOf('fcl')!=-1){
            enquiryStore.current.shipmentType = 'FCL'
            setValue("mode",'FCL');
        }
        if(enquiryStore.current.multidest==undefined){
            if(enquiryStore.current.containerDetails==undefined) enquiryStore.current.containerDetails = [new Container()]
            if(enquiryStore.current.cargoDetails==undefined) enquiryStore.current.cargoDetails = [new Cargo()]
            setValue("multidest", [
                {
                    destination:enquiryStore.current.enqRoute.destination,
                    cargoDetails:enquiryStore.current.cargoDetails,
                }
            ]);
            enquiryStore.current.multidest = [
                {
                    destination:enquiryStore.current.enqRoute.destination,
                    cargoDetails:enquiryStore.current.cargoDetails,
                }
            ]
        }
    },[enquiryStore.current])
    useEffect(()=>{
        setValue("mode",selectedType);
    },[selectedType])
    const { control, watch, register, setValue, getValues,handleSubmit, reset, clearErrors, formState, trigger, getFieldState } = cargoForm;
    const changeShipmentTypes = (type) =>{
        setActiveProduct(type)
        setType(type)
        const entityId = enquiryStore.current.entityId
        enquiryStore.initialize();
        enquiryStore.current.entityId = entityId
        enquiryStore.current.shipmentType = type
        setValue("origin", "");
        setValue("multidest", [
            {
                destination:"",
                cargoDetails:[new Cargo()],
            }
        ]);
        setValue("containerDetails",[new Container()])
        setValue("mode",selectedType);
        clearErrors();
        
    }
    const checkcontainer = (containerDetails)=>{
        let k = {}
        let l = true
        for (let j = 0; j < containerDetails.length; j++) {
            if(containerDetails[j]['containerType']!=undefined && containerDetails[j]['containerType'].length>0){
                let c = containerDetails[j]['containerType']
                if(k[c]==undefined){
                k[c] = Number(containerDetails[j]['wtPerContainer'])
                }
                if(k[c]!=Number(containerDetails[j]['wtPerContainer'])){
                configStore.setModalMessage(
                    `Different Container Weight Not Allowed (${containerDetails[j]['containerType']})`
                )
                l=false
                }
            }
        }
        return l
      }
    const buttonRef = useRef();
    

    const [serverTime, setServerTime] = useState(0)
    const [routesEmptyMessage, setRoutesEmptyMessage] = useState('')
    const [routes, setRoutes] = useState([])
    const _params = (label, name, type = 'text', dataList = []) => {
        return {
            type, name, label, record: pricingStore,
            dataList
        }
    }
    useEffect(() => {
        enquiryStore.current.countryFromEnquiry = 'India'
    }, [])
    useEffect(() => {
        rtpStore.countryName = enquiryStore.current.countryFromEnquiry
    }, [enquiryStore.current.countryFromEnquiry])
    useEffect(() => {
        let r = [...routes]
        if (routeFilter == 'Cheapest')
            r.sort((a, b) => a.dummyContract.quoteValue < b.dummyContract.quoteValue ? -1 : 1)
        else
            r.sort((a, b) => a.minTransitTime < b.minTransitTime && a.minTransitTime > 0 ? -1 : 1)
        setRoutes(r)
    }, [routeFilter])
    useEffect(() => {
        rtpStore.records = routes;
        if (routes.length > 0) {
            setTimeout(() => {
                if (buttonRef.current) {
                    buttonRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                }
            }, 200)
        }
    }, [routes])
    useEffect(() => {
        rtpStore.pricingSourceScreen = 'pricing'
        return () => {
            configStore.homescreenCurrentView = "list"
        }
    }, [])
    const origin = watch("origin")
    const multidest = getValues("multidest")?getValues("multidest"):watch("multidest")
    useEffect(() => {
        setRoutes([])
        setRoutesEmptyMessage('')
    }, [origin,JSON.stringify(multidest)])
    useEffect(() => {
        rtpStore.records = routes
    }, [routes])
    console.log('INSIDE HOMESCREEN spot V3')
    const rec = contractsStore.current

    const [activeProduct, setActiveProduct] = useState(configStore.homescreenCurrentShipmentType)

    const displayVehicleTable = () => {
        return false
    }
    const getRoutes = async (data, e) => {
        contractsStore.current = {}
        if(checkcontainer(data.containerDetails)){
            let cargo = []
            let container = []
            let obj = {}
            let multidest_new = []
            for (let i = 0; i < data.multidest.length; i++) {
                let z1 = {}
                z1['destination'] = data.multidest[i]['destination']
                z1['cargoDetails'] = []
                if(i==0){
                    enquiryStore.current.enqRoute.destination = data.multidest[i]['destination']
                }else{
                    let k = i+1
                    enquiryStore.current.enqRoute['destination'+k] = data.multidest[i]['destination']
                }
                for (let j = 0; j < data.multidest[i]['cargoDetails'].length; j++) {
                    let k = data.multidest[i]['cargoDetails'][j]
                    k = Utils.setCargo_unit(k,data.multidest[0]['cargoDetails'][0],['weight','chargeableWeight','volume','totalWeight','totalVolume','dimensions'],data.cargoDimensionUnit)
                    z1['cargoDetails'].push(k)
                    cargo.push(k)
                }
                multidest_new.push(z1)
            }
            for (let j = 0; j < data.containerDetails.length; j++) {
                let z = data.containerDetails[j]['containerType']
                if(obj[z]==undefined){
                    obj[z] = {
                        containerType : "",
                        numContainers: 0,
                        unit: "",
                        wtPerContainer: 0
                    }
                }
                obj[z]['containerType'] = z
                obj[z]['unit'] = data.containerDetails[0]['unit']
                obj[z]['numContainers'] = obj[z]['numContainers']+Number(data.containerDetails[j]['numContainers'])
                obj[z]['wtPerContainer'] = Number(data.containerDetails[j]['wtPerContainer'])
            }
            let o = Object.keys(obj)
            for (let i = 0; i < o.length; i++) {
                obj[o[i]]['wtPerContainer'] = obj[o[i]]['wtPerContainer']
                container.push(obj[o[i]])
            }
            enquiryStore.current.nonStackable = data.nonStackable ? 'Yes' : "No"
            enquiryStore.current.enqRoute.cargoReadinessDate = data.cargoreadinessdate
            enquiryStore.current.enqRoute.origin = data.origin
            enquiryStore.current.shipmentScope = data.scope
            if(data.mode == 'FCL'){
                enquiryStore.current.shipmentType = `FCL (${data.stuffingType}/${data.destuffingType})`;
            }else{
                enquiryStore.current.shipmentType = data.mode
            }
            enquiryStore.current.enqRoute.customerReferenceNum = data.customerreference
            enquiryStore.current.hazardous = data.hazardous ? 'Yes' : "No"
            enquiryStore.current.temperatureControlled = data.temperatureControlled ? 'Yes' : "No"
            enquiryStore.current.containerDetails = JSON.parse(JSON.stringify(container))
            enquiryStore.current.cargoDetails = JSON.parse(JSON.stringify(cargo))
            enquiryStore.current.cargoDimensionUnit = data.cargoDimensionUnit;
            enquiryStore.current.unnumber = data.unnumber;
            enquiryStore.current.packingGroup = data.packingGroup
            enquiryStore.current.quoteClass = data.quoteClass
            enquiryStore.current.stuffingType = data.stuffingType
            enquiryStore.current.destuffingType = data.destuffingType
            enquiryStore.current.minTemperature = data.minTemperature
            enquiryStore.current.maxTemperature = data.maxTemperature
            enquiryStore.current.temperature = data.temperature
            enquiryStore.current.cargoValue = data.cargoValue;
            enquiryStore.current.fba = data.isFba ? "Yes" : "No";
            enquiryStore.current.fbaIorRequired = data.ior ? "Yes" : "No";
            enquiryStore.current.fbaOCC = data.occ ? "Yes" : "No";
            enquiryStore.current.fbaDCC = data.dcc ? "Yes" : "No";
            enquiryStore.current.fbaPAL = data.palletizationandlabelling ? "Yes" : "No";
            enquiryStore.current.fbaSAD = data.storageAtDestination ? "Yes" : "No";
            enquiryStore.current.fbaSurvey = data.survey ? "Yes" : "No";
            enquiryStore.current.pick_up_by_amazon = data.pick_up_by_amazon ? "Yes" : "No";
            enquiryStore.current.fbaDays = data.days!=undefined? data.days: 0 ;
            enquiryStore.current.isfromAgragaEnquiry = 'Yes'
            let rec = enquiryStore.current
            if(rec.entityId != undefined && rec.entityId.length==0){
                configStore.setModalMessage('Select Customer')
                return
            }
            let l = Utils.enquiry_validation_check(rec,JSON.parse(JSON.stringify(multidest_new)))
        if(l.length>0){
            configStore.setModalMessage(l[0])
            return
        }
        enquiryStore.current.multidest = JSON.parse(JSON.stringify(multidest_new));
        bookingsStore.initialize()
        console.log('\n\n\n\n\n==============================')
        console.log('/', enquiryStore.current.enqRoute.origin, '/', enquiryStore.current.enqRoute.destination, rtpStore ,'/\n===============\n\n\n')
        setRoutes([])
        setRoutesEmptyMessage('Fetching Routes ...')
        rtpStore.loadingMessage = 'Fetching Routes '
        rtpStore.loadingTimer = setInterval(() => {
            rtpStore.loadingCounter++
        }, 10000);
        setRouterFilter('Cheapest')
        let curDate = data.cargoreadinessdate
        let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/pricing/getroutes`
        if (activeProduct == 'FCL') url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/fclpricing/getroutesv2`
        else if (activeProduct == 'Air') url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/airpricing/getroutes`
        let start = Date.now()
        if (loginStore.isClient()) pricingStore.entityId = configStore.currentSelectedEntity.entityId
        else configStore.currentSelectedEntity = { entityId: pricingStore.entityId }
        if (loginStore.isClient()) enquiryStore.current.entityId = configStore.currentSelectedEntity.entityId
        // enquiryStore.current.shipmentType = 'LCL'
        // enquiryStore.current.entity = pricingStore.gst
        enquiryStore.current.createdBy = loginStore.email
        let otype = enquiryStore.current.shipmentScope.split("-")[0];
        let dtype = enquiryStore.current.shipmentScope.split("-")[2];
        let crd = '' + enquiryStore.current.enqRoute.cargoReadinessDate
        crd = crd.trim()
        if (rtpStore.customerTBA == 'TBA') {
            enquiryStore.current.checkAgCustomer = 'No'
            enquiryStore.current.entityName = "YET TO BE ASSIGNED"
            enquiryStore.current.entityId = "TBA"
            enquiryStore.current.gst = 'GSTTBA000000000'
            enquiryStore.current.state = ''
            enquiryStore.current.createdBy = loginStore.email
            enquiryStore.current.isfromAgragaEnquiry = 'Yes'
        }
        let dimunit = enquiryStore.current.cargoDimensionUnit
        for (let e of enquiryStore.current.cargoDetails) {
            e.dimensions.unit = dimunit
        }
        let displayCargo4fcl = false
        let stype = enquiryStore.current.shipmentType.substr(0, 3).toLowerCase()
        displayCargo4fcl = false
        if (stype === 'fcl') {
            displayCargo4fcl = Utils.outGaugeContainerCheck(enquiryStore.current.containerDetails)
            if (enquiryStore.current.originType != 'Port' && enquiryStore.current.stuffingType != 'Factory') displayCargo4fcl = true
            if (enquiryStore.current.destinationType != 'Port' && enquiryStore.current.destuffingType != 'Factory') displayCargo4fcl = true
        }
        let payload = {
            entity: enquiryStore.current.entityId,
            // entity: "CEN0665",
            origin: enquiryStore.current.enqRoute.origin,
            destination: enquiryStore.current.enqRoute.destination,
            sscope: `${otype}-to-${dtype}`,
            crd: enquiryStore.current.enqRoute.cargoReadinessDate,
            curDate,
            cargoDetails: enquiryStore.current.cargoDetails,
            vehicleDetails: enquiryStore.current.vehicleDetails,
            fclCargoApplicable: displayCargo4fcl,
            enquiry: enquiryStore.current
        }
        for (let i = 1; i < multidest_new.length; i++) {
            if(i!=0){
                let k = i+1
                payload['destination'+k] = multidest_new[i]['destination']
            }
        }
        if (rtpStore.customerTBA == 'TBA') {
            payload.entity = 'COUNTRY:' + rtpStore.countryName
        }
        if (!PricingUtils.getTotalVehicleCount(payload.vehicleDetails) && displayVehicleTable()) {
            configStore.setModalMessage('At least one vehicle needs to be selected for India transport=')
            return
        }
        let ocountry = 'origin'
        let portre = /.*\((.....)\)$/
        let doorre = /.*\[(..,.*)\]$/
        let origin1 = payload.origin
        let destination1 = payload.destination
        if (origin1.includes('(') || origin1.includes('[')) {
            if (otype == 'Port') ocountry = origin1.match(portre)[1].slice(0, 2)
            else ocountry = origin1.match(doorre)[1].slice(0, 2)
        }
        let dcountry = 'destination'
        if (destination1.includes('(') || destination1.includes('[')) {
            if (dtype == 'Port') dcountry = destination1.match(portre)[1].slice(0, 2)
            else dcountry = destination1.match(doorre)[1].slice(0, 2)
        }
        //console.log('$$$$', payload.origin, payload.destination, ocountry, dcountry)
        if (ocountry == dcountry) {
            configStore.setModalMessage('We do not have Domestic Transport Capabilities at present' + ocountry + '/' + dcountry)
            return
        }
        if (ocountry != 'IN' && ocountry != 'origin' && dcountry != 'IN' && dcountry != 'destination') {
            configStore.setModalMessage('We do not serve these routes at present and will keep you posted when we launch in these markets')
            enquiryStore.current.enqRoute.origin = ''
            enquiryStore.current.enqRoute.destination = ''
            return
        }
        // ##################################################################
        let ret
        ret = await MonApi.apost(url, payload)
        if (ret.additionalsets) rtpStore.additionalsets = ret.additionalsets
        if (ret.finalset) ret = ret.finalset
        rtpStore.dpd = 'Direct Port Delivery'
        // ##################################################################
        console.log('payload==', JSON.stringify(payload))
        //console.log('new data=', enquiryStore.current.originType, enquiryStore.current.destinationType)
        setServerTime(Date.now() - start)
        clearInterval(rtpStore.loadingTimer)
        rtpStore.loadingCounter = 0
        console.log("The ret is ", ret);
        if (ret.error) {
            setRoutesEmptyMessage('')
            configStore.setModalMessage('' + ret.error, [], true, [], '', 5000)
        } else {
            for (let i = 0; i < ret.length; i++) {
                ret[i].dummyContract['entityId'] = enquiryStore.current.entityId
                ret[i].quote.quoteData['entityId'] = enquiryStore.current.entityId
                ret[i].quote.quoteSummary['entityId'] = enquiryStore.current.entityId
                if (i == ret.length - 1) {
                    setRoutes(ret)
                }
            }
            if (!ret || !ret.length) setRoutesEmptyMessage('No Routes')
        }
    }
    }
    useSnapshot(loader)
    if(EntityV2Store.Cuslist.length==0){
        loader.show()
    }else{
        loader.hide()
    }

    console.log('*** ### INSIDE SPOT.BOOKING.V3.JS', rec, props.viewonly)
    let title = 'Quick Quote'

    return (
        <>
            {
                EntityV2Store.Cuslist.length>0 && <>
            <p><hr /></p>
            <EnquiryConfirmationv3 />
            {
                configStore.quotationModalVisible4 && <ActrejectModalV3 fromPricing={true} />
            }
            <h5 className="title is-4" style={{ color: '#2c358a', fontFamily: 'product_sans_bold' }}>
                {title}
            </h5>
            
                <div className="columns">
                    <div className="column is-4" >
                        <label><strong>Customer</strong></label>
                        <AgCustlist {..._params('', '', 'entityId', EntityV2Store.Cuslist)} name='entityId' record={enquiryStore.current} />
                    </div>
                </div>
          
            {
                (!props.viewonly && !cwfVisible) ?
                    <div style={{ width: "100%" }}>
                        <ActiveTab callback={changeShipmentTypes} viewonly={viewonly=='1'} selectedType={selectedType}/>
                    </div>
                    : <></>
            }
            <form noValidate onSubmit={handleSubmit(getRoutes)} style={{width:"100%",display:"flex",flexDirection:"column"}}>
       <div className="flex flex-row gap-[10px]">
          
       <Route form={cargoForm} viewonly={viewonly=='1'}/>
       <Details form={cargoForm} viewonly={viewonly=='1'}/> 
    
       </div>
       
      


            <div className="columns">
                <div className="column is-12">
                    {
                        (props.viewonly && loginStore.isClient()) ?
                            <button style={{ float: 'left' }}
                                className="button is-rounded is-small islink" onClick={() => {
                                    let customTC = []
                                    if (rec['customTC']) {
                                        customTC = rec['customTC'].split(/\r?\n/)
                                    }
                                    rec['tc'].map(e => customTC.push(e))
                                    configStore.setModalMessage("", [], false, customTC)
                                }}>Terms and Conditions</button> :
                            <></>


                    }


                    <BookingConfirmation />
                    {
                        (!cwfVisible) ?
                            <div className="columns" style={{display:'flex',justifyContent:"flex-end",paddingTop:'5px'}}>
                                <div className="column is-7">
                                    {
                                        (!routes.length) ?
                                            <RoutesEmpty message={routesEmptyMessage} setMessage={setRoutesEmptyMessage} />
                                            : <></>
                                    }
                                </div>
                                {routes && routes?.length > 0?
                                <div className="column is-2">
                                 <button className="button" style={{ marginRight: '3px'}}
                                        onClick={(e) => {
                                            configStore.quotationModalVisible4 = true
                                        }}
                                    >Raise Enquiry</button>
                                </div>:<></>

                                }
                                
                                <div className="column is-1">
                                    <button className="button" style={{ marginLeft: '3px' }}
                                        onClick={(e) => {
                                            enquiryStore.initialize()
                                            enquiryStore.current.originType = 'Port'
                                            enquiryStore.current.destinationType = 'Port'
                                        }}
                                    >Clear</button>
                                </div>
                                <div className="column is-2">
                                    <button className="button islink " type="submit" style={{ marginLeft: '3px' }}>Get Rates</button>
                                </div>
                            </div>
                            : <></>
                    }
                </div>
            </div >
            </form>
            <hr />
            {
                (cwfVisible) ?
                    <Pricingcwf hideme={setcwfVisible} activeProduct={activeProduct} />
                    :
                    <div className="columns">
                        <div className="column is-12">
                            {
                                (!routes.length) ?
                                    <></>
                                    :
                                    <> {
                                        activeProduct == 'LCL' &&
                                        <div className="columns">
                                            <div className="column is-4">
                                                {loginStore.isClient() ? <Button ref={buttonRef} onClick={() => {
                                                    setRouterFilter('Cheapest')
                                                }} variant={routeFilter === "Cheapest" ? "contained" : ""} startIcon={<i class="fa-solid fa-money-check-dollar"></i>} sx={{
                                                    textTransform: "capitalize", marginRight: "10px", backgroundColor: routeFilter === "Cheapest" ? "#2A6ED4" : "white", "&:hover": {
                                                        backgroundColor: routeFilter === "Cheapest" ? "#2A6ED4" : "white"
                                                    }
                                                }}>Cheapest</Button> : <button className={'button ' + cheapestColor} onClick={(e) => {
                                                    setRouterFilter('Cheapest')
                                                }}>
                                                    <span className="icon" style={{ marginRight: '5px' }}>
                                                        <i class="fa-solid fa-money-check-dollar"></i>
                                                    </span>
                                                    Cheapest
                                                </button>}
                                                {loginStore.isClient() ? <Button onClick={() => {
                                                    setRouterFilter('Fastest')
                                                }} startIcon={<i class="fa-solid fa-clock"></i>} variant={routeFilter === "Cheapest" ? "" : "contained"} sx={{
                                                    textTransform: "capitalize"
                                                    , backgroundColor: routeFilter === "Cheapest" ? "white" : "", "&:hover": {
                                                        backgroundColor: routeFilter === "Cheapest" ? "white" : "#2A6ED4"
                                                    }
                                                }}>Fastest</Button> : <button className={'button ' + fastestColor}
                                                    style={{ marginLeft: '1rem' }} onClick={(e) => {
                                                        setRouterFilter('Fastest')
                                                    }}>
                                                    <span className="icon" style={{ marginRight: '5px' }}>
                                                        <i class="fa-solid fa-clock"></i>
                                                    </span>
                                                    Fastest
                                                </button>}
                                            </div>
                                            <div className="column is-4"></div>
                                        </div>
                                    }
                                        {
                                            activeProduct == 'LCL' &&
                                            <SpotLCLDisplayv3 routes={routes}
                                                cwfVisible={cwfVisible} setcwfVisible={setcwfVisible} />
                                        }
                                        {
                                            activeProduct == 'FCL' &&
                                            <SpotFCLDisplayv3 routes={routes}
                                                cwfVisible={cwfVisible} setcwfVisible={setcwfVisible} />
                                        }
                                        {
                                            activeProduct == 'Air' &&
                                            <SpotAirDisplayv3 routes={routes}
                                                cwfVisible={cwfVisible} setcwfVisible={setcwfVisible} />
                                        }
                                    </>
                            }
                        </div>
                    </div>
            }
            </>
            }
        </>

    )
    
}