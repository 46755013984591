import Api from "../state/Api";
import { configStore, contractsStore, loginStore } from "../state/CommonState";
import MonApi from "../state/mongo-api";

export default class ChartApi {
    static async get(url) {
        let h = Api.H()
        if(!loginStore.isClient()){
           h['Current-Entity'] = configStore.chartSelectedEntity
        }
        const requestOptions = {
            headers: h
        }

        return await fetch(url, requestOptions).then(res => res.json())
    }

    static async getEnquirySummary(callback = f=>f) {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/chart/getenquiriesdata`
        let ret = await ChartApi.get(url)
        callback(ret)
        return ret
    }
    static async getEnquiryCount() {
        let filter = {}
        if (loginStore.isClient()) filter = {'gst': configStore.currentSelectedEntity.gst }
        let ret = await MonApi.aggregate('Enquiries', [
            {
                '$match': filter
            },
            {
                '$count':'count'
            }
        ])
        console.log('RETURN FROM ENQUIRIES QUERY=', filter, ret)
        if (ret.length > 0) return ret[0].count
        return 0
    }
    static async getEnquiryQuote() {
        let filter = {}
        if (loginStore.isClient()) filter = {'gst': configStore.currentSelectedEntity.gst }
        let ret = await MonApi.aggregate('Enquiries', [
            {
                '$match': filter
            },
            {
                '$lookup':{
                    from:'Quotes',
                    localField:'_id',
                    foreignField:'_id',
                    as:'quotes'
                }
            },
            {
                '$unwind':'$quotes'
            },
            {
                '$project':{
                    _id:1,
                    'gst':1,
                    'quotes.mailedOn':1,
                    'enquiryDate':1
                }
            }
        ])
        return ret
    }
    static async getProductData(callback = f=>f) {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/chart/getproductdata`
        let ret = await ChartApi.get(url)
        callback(ret)
        return ret
    }
    static async getFinanceData(callback = f=>f) {
        // let url = `${process.env.REACT_APP_API_SERVER}/api/v1/common/financialsnew`
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/common/financialserp`
        let ret = await ChartApi.get(url)
        callback(ret)
        return ret
    }

    static async getAllBookings(callback = f => f) {
        
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/bookings`
        let ret = await ChartApi.get(url)
        callback(ret)
        return ret
    }

    static async getContracts(callback = f => f) {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/contracts`
        let data = await ChartApi.get(url)
        if (!contractsStore.navigateFromCharts) contractsStore.list = data
        console.log('*** LOADED NEW CONTRACTS ***')
        callback(data)
        return data
    }
}