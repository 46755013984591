import { useEffect, useState, useRef } from "react"
import { Buffer } from 'buffer'
import { Col, Col1, Col2, Col3, Col4, Col5, Col6, Columns } from "../../Controls"
import { ReactComponent as Loader } from "../../assets/loader.svg"
import { BiPlus } from "react-icons/bi"
import { MdDelete } from "react-icons/md"
import { commodityList, currencyList } from "../../StaticData"
import PhoneField from '../../mui-components/PhoneField'
import { useSnapshot } from "valtio"
import CreditsApis from "../../state/credit-api"
import { AiFillEdit } from "react-icons/ai"
import { MdDownload } from "react-icons/md"
import { TbEditOff } from "react-icons/tb";
import { ImCancelCircle } from "react-icons/im"
import InputField from "../../drivers/components/input/InputField"
import { EntityV2Store, configStore, customerStore, cususerStore, routerStore } from "../../state/CommonState"
import SelectField from "../../drivers/components/select/SelectField"
import BranchApi from "../../state/branch-api"
import UsersApis from "../../state/users-api"
import { USERS_STATUS } from "../../util/constants"
import { CustomeruserType } from "../../state/Types"
import EntityUtils from "../../util/entityUtils"



export default function EditUsers(props) {
  useSnapshot(cususerStore)
 
   let user = cususerStore.cususerRec
 const Entity = EntityV2Store.EntityRec;
 const [userAvailable , setuserAvailable] = useState(false)
 const [phoneNumber , setPhoneNumber] = useState("")
 const [phonePrefix , setPhonePrefix] = useState("")
const [checkedAdminuser , setcheckedAdminuser] = useState(false)
const [BranchList , setBranchList] = useState([])
const [SelectedBranchList , setSelectedBranchList] = useState([])
const [userdata , setUserdata] = useState({})
const [errors, setErrors] = useState({})
const [userType , setUserType] = useState("")
const [Usersstatus , setUsersstatus] = useState({})
const [roleList , setRoleList] = useState({})
let  [loading , setLoading] = useState(false)


// useSnapshot(roleList)
 let usertypes =["Entity" , "Branch"]
  const handleChange = (e , r )=> {
    
    if(e.target.value.length > 15 && r === "phone") {
    return null 
     }
    user[r]=e.target.value
    clearFields()
    errors[r]=""
    setErrors(errors)
    switch (r) {
      case "email":
        const value = e.target.value.trim().toLowerCase();

        user[r]=value
        if(value.includes("agraga.com")){
        configStore.setModalMessage("Agraga mail id not allowed for creating client users")
        cususerStore.cususerRec.set(CustomeruserType)
        }else if(!value.includes("agraga.com") && (value.includes(".") && value.includes("@"))  ){
          UsersApis.checkUser(value , Entity.entityId, setUserdata)
          if(props.mode === 'new' && Object.keys(userdata)?.length>0 && userdata.status=== 200){
            user.phone= ' '
          }
       }
       
    break  
    default :
      break
      }
  }

  const handleChange1 = (e , r )=> {
    let Error = errors
    Error[r]=""
    setErrors(Error)
    switch (r) {
      case "designation":
        roleList.designation = e.target.value
       setRoleList(roleList)
       let name = user.firstName.trim()
       user.firstName = "n"
       user.firstName = name
       break
      case "userType":
        if( e.target.value.toLowerCase() === "entity") {
          setUserType("Entity")
        }else if( e.target.value.toLowerCase() === "branch") {
          setUserType("Branch")
          BranchApi.getAllBranches(Entity.entityId , setBranchList)
        } 
        break 
      default:
      break
  }
}
  let title = ""
  switch (props.mode) {
    case "edit": //(${Branch?._id}) ${Branch?.status}
      title = `User Details`
      break
    case "view":
      title = `User Details`
      break
    case "new":
      title = "Create New User"
      break
    default:
      title = "User Details"
      break
  }
  function clearFields() {
   if(isEntityUser()){
    user.entityList = user.entityList.filter(
      (a) => a.entityId !== Entity.entityId
    );
   }else if (isBranchUser()){
    user.mainEntityList = user.mainEntityList.filter(
      (a) => a.entityId  !== Entity.entityId
    );
   }

  }
  useEffect(()=> {
    if(!user?.notification){
      user.notification = {email:false,whatsapp:false}
    }
    if(props.mode === "new" ||(user?.notifyMyAction === null ||user?.notifyMyAction === undefined)){
      user.notifyMyAction = true
    }
    if(props.mode === "edit" || props.mode === "view"){
      setUsersDetailsfn()
    }
    setUserStatusfn()
  },[])


  function setUserStatusfn  ()  {
    let status = JSON.stringify(USERS_STATUS)
    let userStatus = JSON.parse(status)
    delete userStatus['DEACTIVATE'];
    if(user.status !== USERS_STATUS.NEW_REG){
      delete userStatus['NEW_REG'];
    }else if (user.status === USERS_STATUS.NEW_REG && user?.inviteSend === false){
      delete userStatus['ACTIVE']
      delete userStatus['INACTIVE']
    }
    // Usersstatus.
    setUsersstatus(userStatus)
  }


 useEffect(()=> {
   if(userdata.status === 200){
    const listEntities= userdata.data.entityList?.filter((r)=>r.entityId === Entity.entityId )
    const listMainEntities= userdata.data.mainEntityList?.filter((r)=>r.entityId === Entity.entityId )
    if(listEntities?.length > 0 || listMainEntities?.length > 0) {
      cususerStore.cususerRec.set(CustomeruserType)
      routerStore.entityUserManageView = "new"
      configStore.setModalMessage("User  already registered in this entity update it if required but can't create a new one")
      setRoleList({})
      setUserType("")
      if(props.mode === 'new'){
        user.phone= ' '
      }
    }else {
      cususerStore.cususerRec.set(userdata.data)
      setUsersDetailsfn()
      setuserAvailable(true)
    }
   }else if (userdata.status === 404){
    if(userAvailable){
    let customerUserData =  new CustomeruserType()
    customerUserData.email =userdata?.email 
    // let customerUser = JSON.stringify(customerUserData)
    cususerStore.cususerRec.set(customerUserData)
    setuserAvailable(false)
   }
  }
 }, [setUserdata , userdata])

  // useEffect(setUserdata()=> {
  //    BranchApi.getAllBranches(Entity.entityId , setBranchList)
  // },[])

  function setUsersDetailsfn (){
    const i = user?.mainEntityList?.findIndex(e => e.entityId === Entity.entityId)
      const r = user?.entityList?.findIndex(e => e.entityId === Entity.entityId)
      if (i >= 0){
        setRoleList(user.mainEntityList[i])
       if(user.mainEntityList[i].role === "Entity Admin"){
        setcheckedAdminuser(true)
        setUserType("Entity")
       }else if (user.mainEntityList[i].role === "Entity User") {
        setcheckedAdminuser(false)
        setUserType("Entity")
       }else {
        setcheckedAdminuser(false)
       }
      }else if (r >= 0){
        setRoleList(user.entityList[r])
        if(EntityUtils.isIndia(Entity)){
          setSelectedBranchList(user.entityList[r].branchList)
        }else {
          setSelectedBranchList(user.entityList[r].branchList)
        }
        setUserType("Branch")
        BranchApi.getAllBranches(Entity.entityId , setBranchList)
      }
  }

  const ckAdminuserckbox = (e) => {
    if (e.target.checked){
      setcheckedAdminuser(!checkedAdminuser)
    }
  }
  function isEntityUser() {
    if(userType === "Entity"){
      return true
    }
    return false
  }

  function isBranchUser() {
    if(userType === "Branch"){
      return true
    }
    return false
  }

  const addBranchList = (e, r) => {
    errors.branchList=""
    setErrors(errors)
    if (e.target.checked === true) {
      SelectedBranchList.push(r.branchId)
      setSelectedBranchList(SelectedBranchList)
    } else if (e.target.checked === false) {
      const index = SelectedBranchList.indexOf(r.branchId);
      if(index > -1){
        SelectedBranchList.splice(index, 1)
      }
      setSelectedBranchList(SelectedBranchList)
    }

  };
  const addBranchListNI = (e, r) => {
    errors.branchList=""
    setErrors(errors)
    if (e.target.checked === true) {
      SelectedBranchList.push(r.branchId)
      // SelectedBranchList.push(r.state)
      setSelectedBranchList(SelectedBranchList)
    } else if (e.target.checked === false) {
      const index = SelectedBranchList.indexOf(r.branchId);
      if(index > -1){
        SelectedBranchList.splice(index, 1)
      }
      setSelectedBranchList(SelectedBranchList)
    }

  };

  function isNewMode () {
    return props.mode === "new"
  }
  function isViewMode () {
    return props.mode === "view"
  }
  function isEditMode () {
    return props.mode === "edit"
  }

  function checkedBranchesfn (r) {
   let entityList =  SelectedBranchList.filter(
      (a) => a === r.gst
    );
    if(entityList.length > 0){
     return true 
    }
    return false
  }
  const onFormSubmit = async() => {
    if((phonePrefix === "+91" && (phoneNumber.length > 10 || (phoneNumber.length !== 0 && phoneNumber.length !== 10)))){
      return
    } else if (phonePrefix !== "+91" && ((phoneNumber?.length > 15 || phoneNumber?.length < 7) && phoneNumber?.length !== 0)) {
        return
    }
    setLoading(true)

    if(user.phone != phoneNumber){
      user.mobileVerified = false
      user.phone = phoneNumber;
    }
    if(user.phonePrefix != phonePrefix){
      user.mobileVerified = false
      user.phonePrefix = phonePrefix
    }
    if(isNewMode()){
      user.mobileVerified = false
    }
    const fieldsCheck = await dovalidationcheck()
    if (fieldsCheck.isError === false) {
    if(isEntityUser() && !isViewMode()){
      let designation = document.getElementById("designationId").value
    let obj ={}
    if(EntityUtils.isIndia(Entity)){
     obj={entityIdentifier : Entity.panNum , entityId : Entity.entityId, isAdmin : checkedAdminuser , role : checkedAdminuser? "Entity Admin" : "Entity User" , designation : designation , status : "ACTIVE" , remarks : ""}
    }else {
     obj = {entityIdentifier : Entity.tinNum , entityId : Entity.entityId, isAdmin : checkedAdminuser , role : checkedAdminuser? "Entity Admin" : "Entity User" , designation : designation , status : "ACTIVE" , remarks : ""}
    }
    if(isEditMode()){
      user.entityList = user.entityList.filter((r)=>r.entityId !== Entity.entityId)
      user.mainEntityList = user.mainEntityList.filter((r)=>r.entityId !== Entity.entityId)
    }
    user.mainEntityList.push(obj) 
    }else if (isBranchUser() && !isViewMode()){
      let designation = document.getElementById("designationId").value
      let obj = {}
      if(EntityUtils.isIndia(Entity)){
        obj = {entityIdentifier : Entity.panNum , entityId : Entity.entityId,  role : "Branch User" , designation : designation ,gstList: [], branchList :SelectedBranchList , status : "ACTIVE" , remarks : ""}
      }else {
        obj = {entityIdentifier : Entity.tinNum, entityId : Entity.entityId,  role : "Branch User" , designation : designation , gstList :[] , status : "ACTIVE" , remarks : "" , branchList : SelectedBranchList}
      }
   
    if(isEditMode()){
      user.entityList = user.entityList.filter((r)=>r.entityId !== Entity.entityId)
      user.mainEntityList = user.mainEntityList.filter((r)=>r.entityId !== Entity.entityId)
    }
    user.entityList.push(obj)
    }
    if(user.mainEntityList.length>0){
      user.role =["Client Admin"]
    }else if (user.entityList.length>0) {
      user.role =["Client User"]
    }
   
    let data = {
     from : user.firstName + ' ' + user.lastName + ` <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
    }
    if(user.email.includes("agraga")){
      user.isAgragaUser = "Yes"
    }else 
    user.country = Entity.entityCountry
    if (user.status === "TEMP USER") {
      user.status = "ACTIVE"
    }
    let userData = JSON.stringify(user)
   let userDataNew = JSON.parse(userData)
    await UsersApis.createUser(data, (datafin)=> { 
      if(datafin){
        setLoading(false)
      }
    })
  }else{
    setLoading(false)
  }
}

  const dovalidationcheck = async () => {
    function isValid(value) {
      return (
        (!Array.isArray(value) &&
          value &&
          value.trim().length > 0 &&
          value !== "default") ||
        (Array.isArray(value) &&
          value.some((item) => item.trim() !== "default"))
      )
    }
    let errors = {}
    let fieldsCheck = { isError: false }
    const emailRegexPattern =
   /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const namePattern = /^[A-Za-z0-9 ]{1,100}$/;
    const designationPattern = /^[A-Za-z0-9./ ]{1,100}$/;
    // let ErrorsSubmit = {}
    let fieldsToSubmit = [
      { title: "FirstName", field: "firstName", required: true , maxLenth : 100 ,pattern:namePattern , example : "No Special Characters allowed , only Alphanumeric"},
      {
        title: "LastName",
        field: "lastName",
        // required: true,
        maxLenth : 100,
        pattern:namePattern ,
        example : "No Special Characters allowed , only Alphanumeric"
      },
      {
        title: "Contact Number",
        field: "phone",
        required: false,
        minLenth : 7,
        maxLenth: 15,
      },
      {
        title: "Email",
        field: "email",
        required: true,
        pattern : emailRegexPattern,
        example : "abc@gmail.com",
      },
      {
        title: "UserType",
        field: "userType",
        required: true,
      },
      {
        title: "Status",
        field: "status",
        required: true,
      },
      {
        title: "Designation",
        field: "designation",
        maxLenth : 100,
        pattern:designationPattern ,
        example : "No Special Characters allowed , only Alphanumeric"
      },

    ]
    fieldsToSubmit.forEach(({ title, field, required, dropdown, maxValue ,minLenth , pattern ,example , maxLenth}) => {
      let value = user[field] 
      if(title === "Designation"){
        value = roleList.designation
      }
      if( title === "UserType"){
        if(!isEntityUser() && !isBranchUser()){
          errors[field] = `Please select a valid ${title}.`
          fieldsCheck.isError = true
        }
      }else {
        if (required && !isValid(value)) {
          errors[field] = `${title} is required.`
          fieldsCheck.isError = true
        }
        if (required && value > maxValue) {
          errors[field] = `${title} value should be between 0 and ${maxValue}`
          fieldsCheck.isError = true
        }
        if (isValid(value) && value.length > maxLenth) {
          errors[field] = `${title} should contain only ${maxLenth} characters`
          fieldsCheck.isError = true
        }
        if (isValid(value) && value.length < minLenth) {
          errors[field] = `${title} should contain atleast ${minLenth} characters`
          fieldsCheck.isError = true
        }

        if (dropdown && value === "default") {
          errors[field] = `Please select a valid ${title}.`
          fieldsCheck.isError = true
        }
        if ((title === "Email" || title === 'FirstName' || title === 'LastName' ||  title === 'Designation')  && isValid(value)  && !pattern.test(value.trim())) {
          errors[field] = `Invalid ${title} pattern eg: ${example}.`
          fieldsCheck.isError = true
        }
      }
      }
    )
    let obj = []
    if(isBranchUser()){
      obj.push(
        {
          title: "BranchList",
          field: "branchList",
          value: SelectedBranchList,
        },
   
      )
    }
    
    obj?.forEach(({ field, value }) => {
      if (value.length < 1 ) {
        errors[field] = `Please select atleast any one branch`
        fieldsCheck.isError = true
      }
    })



    setErrors(errors)
    return fieldsCheck
  }

  console.log(user , "props" , props);


  const sendmailInvite = async() => {
   if(!loading){
    setLoading(true)
    let data = {
      from : user.firstName + ' ' + user.lastName + ` <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
     }
     data.email = user.email
     await UsersApis.sendInviteMail(data ,user, setUserStatusfn , (data)=> {
      if(data){
        setLoading(false)
      }
     })
   }
  }
  // console.log(SelectedBranchList);

  function isEmailValid(email) {
    const pattern = /^[\w\.\-+]+@agraga\.com$/i
    return pattern.test(email)
  }

  
  return (
    <>
        <div
         className="adduserpg p-5"
        >
          <div className="is-flex is-justify-content-space-between pb-4 conwd_80">
            <span className="is-size-5 has-text-weight-bold">{title}</span>
            {(user.status?.toLowerCase() === "deactivated" && roleList.status?.toLowerCase() !== USERS_STATUS.DEACTIVATE )  && 
              <button className="is-size-5 has-text-weight-bold statusbtn" id ="userdacbtn">{user.status}</button>
            } {(roleList.status === USERS_STATUS.DEACTIVATE )  && 
              <button className="is-size-5 has-text-weight-bold statusbtn" id ="userdacbtn">{roleList.status}</button>
            }
            {isViewMode() ? <> <span className="exit_icon">
              <ImCancelCircle
                // onClick={() => closeaddUser()}
                onClick={async (e) => {
                  e.preventDefault()
                  // props.fn.fn(false)
                  routerStore.entityUserManageView = "list"
                }}
                className="is-clickable exit_icon"
              />
            </span></> : null

            }
           
          </div>
          <Columns className="is-flex">
            <Col5>
            <InputField
              type={"text"} 
              label_class={"label is-small"}
              label_style={{marginBottom: "0px" }}
              placeholder={"First Name"} 
              classname={"input is-small"} 
              value={user.firstName} 
              pattern={"[A-Z0-9]"} 
              errorsmsg={errors.firstName}
              callback={(e) => handleChange(e, "firstName")}
                    children={{...(isViewMode() 
                      ? { disabled: true }
                      : null)}}

              ></InputField>
            </Col5>
            <Col1></Col1>
            <Col5>
            <InputField
              type={"text"} 
              label_class={"label is-small"}
              label_style={{marginBottom: "0px" }}
              placeholder={"Last Name"} 
              classname={"input is-small"} 
              value={user.lastName} 
              pattern={"[A-Z0-9]"} 
              callback={(e) => handleChange(e, "lastName")}
              errorsmsg={errors.lastName}
              mandatory={false}
              children={{...(isViewMode() 
                ? { disabled: true }
                : null)}}

              ></InputField>
            </Col5>
          </Columns>
          <Columns className="is-flex">
          <Col5>
          <PhoneField callback={(e)=> {
            if(isNewMode()){
              user.phone=e?.number;
              user.phonePrefix=e?.code;
            }
            setPhonePrefix(e?.code)
            setPhoneNumber(e?.number)
        }} countryCode={user.phonePrefix} phone={user.phone} manageUsersFlag={true} disabled={(props.mode == 'view'|| (props.mode=='edit'&& user.mobileVerified==true)) && true}></PhoneField>
            </Col5>
            <Col1></Col1>
            <Col5>
            <InputField
              type={"text"} 
              label_class={"label is-small"}
              label_style={{marginBottom: "0px" }}
              placeholder={"Email"} 
              classname={"input is-small"} 
              value={user.email} 
              pattern={"[A-Z0-9]"} 
              callback={(e) => handleChange(e, "email")}
              errorsmsg={errors.email}
              children={{...((isViewMode() || isEditMode()) 
                ? { disabled: true }
                : null)}}

              ></InputField>
            </Col5>
          </Columns>
          <Columns className="is-flex">
            <Col5>
              {/* <p>Role*</p>
              <p>
                <input placeholder="" className="p-1 wd_80"></input>
              </p> */}
                <InputField
              type={"text"} 
              label_class={"label is-small"}
              label_style={{marginBottom: "0px" }}
              placeholder={"Designation"} 
              classname={"input is-small"} 
              value={roleList.designation} 
              pattern={"[A-Z0-9]"} 
              callback={(e) => handleChange1(e, "designation")}
              id = "designationId"
              errorsmsg={errors.designation}
              mandatory={false}
              children={{...(isViewMode() 
                ? { disabled: true }
                : null)}}

              ></InputField>
            </Col5>
            <Col1></Col1>
            <Col5>
              <SelectField
                            placeholder={"Role"}
                            classname="select is-small "
                            data={usertypes?.map((r,i)=> {
                              return <option>{r}</option>
                            })}
                            label_class={"label is-small"}
                            label_style={{marginBottom: "0px" }}
                            // ref={ref}
                            input_style={{width: "100%", border: "1px solid #d1d1e0" }}
                            value={userType}
                            callback={(e) => handleChange1(e , "userType")}
                            // children={ {...(isViewMode() ? { disabled: true } : {})}}
                            first_value={""}
                            default_label={"select user type"}
                            errorsmsg={errors.userType}
                            children={{...(isViewMode() 
                              ? { disabled: true }
                              : null)}}
                          
              >
              </SelectField>
            </Col5>
          </Columns>
          <Columns>
        
          <Col5>

{isBranchUser() && EntityUtils.isIndia(Entity) && (<>
    <p
      className="label is-small"
      style={{ marginBottom: "0px" }}
    >
      Select branches
    </p>
{SelectedBranchList?.length > -1  && BranchList?.map((r, i) => {
        return (
          <>
            <p className="is-flex is-flex-direction-row">
              <input
                id="scopeOfActivityRT"
                type="checkbox"
                onChange={(e)=>addBranchList(e,r)}
                {...(((isEditMode()|| isViewMode() ) && SelectedBranchList.includes(r.branchId) )  ? {
                  checked:true
                 } : null)}
                // checked={checkedBranchesfn(r)}
                {...(isViewMode() 
                  ? { disabled: true }
                  : null)}
              ></input>
              <span className="is-size-7 ml-2">{r.state?.split("/")[0]}--{r.gst}</span>
            </p>
          </>
        );
      })}
      {errors.branchList && (
                  <p className="help is-input-danger-message">
                    {errors.branchList}
                  </p>
                )}
</>
)
}
{isBranchUser() && !EntityUtils.isIndia(Entity) && (<>
    <p
      className="label is-small"
      style={{ marginBottom: "0px" }}
    >
      Select branches
    </p>
{SelectedBranchList?.length > -1  && BranchList?.map((r, i) => {
        return (
          <>
            <p className="is-flex is-flex-direction-row">
              <input
                id="scopeOfActivityRT"
                type="checkbox"
                onChange={(e)=>addBranchListNI(e,r)}
                {...(((isEditMode()|| isViewMode() ) && SelectedBranchList.includes(r.branchId) )  ? {
                  checked:true
                 } : null)}
                // checked={checkedBranchesfn(r)}
                {...(isViewMode() 
                  ? { disabled: true }
                  : null)}
              ></input>
              <span className="is-size-7 ml-2">{r.state}--{r.uid}</span>
            </p>
          </>
        );
      })}
      {errors.branchList && (
                  <p className="help is-input-danger-message">
                    {errors.branchList}
                  </p>
                )}
</>
)
}
{isEntityUser() && (<>
            <p>Is Admin User*</p>
            <p>
              <input type="radio" checked={checkedAdminuser} id="inpAdminCkbox" onClick={(e)=>ckAdminuserckbox(e) }   {...(isViewMode() 
                  ? { disabled: true }
                  : null)}></input><span>Yes</span>
              <input type="radio" checked={!checkedAdminuser} className="ml-3"  onClick={(e)=>ckAdminuserckbox(e)}   {...(isViewMode() 
                  ? { disabled: true }
                  : null)}></input><span>No</span>
            </p>
            </>
            )

            }
</Col5>
        <Col1>
        </Col1>
        <Col5>
        {(isEditMode()) && (<>
          <SelectField
                            placeholder={"Status"}
                            classname="select is-small "
                            data={Object.values(Usersstatus)?.map((r,i)=> {
                              return <option>{r}</option>
                            })}
                            label_class={"label is-small"}
                            label_style={{marginBottom: "0px" }}
                            // ref={ref}
                            input_style={{width: "100%", border: "1px solid #d1d1e0" }}
                            value={user.status}
                            callback={(e) => handleChange(e , "status")}
                            // children={ {...(isViewMode() ? { disabled: true } : {})}}
                            first_value={""}
                            errorsmsg={errors.status}
                            default_label={"Select status"}
                            children={{...(isViewMode() 
                              ? { disabled: true }
                              : null)}}
                          
              >
              </SelectField>
        </>)}

        </Col5>
        
          </Columns>
          
          <Columns>
          {roleList?.remarks?.length > 0 && 
            <div className="mt-4">
              <p>
                <span className="has-text-weight-bold pl-4">Remarks :</span>
                <span className="ml-4">{roleList?.remarks}</span>
              </p>
            </div>
          }
            
          </Columns>
          <Columns>
            <Col5>
            <p
      className="label is-small"
      style={{ marginBottom: "0px" }}
    >
      Notify my actions through E-mail
    </p> <input
                id="scopeOfActivityRT"
                type="checkbox"
                checked={user?.notifyMyAction}
                disabled={isViewMode()}
                onChange={() => {
                  if (user) {
                      user.notifyMyAction = !user.notifyMyAction; 
                  }
              }}
                />
            </Col5>
          </Columns>
          { (isEditMode() || isViewMode()) &&
          <Columns>
          <Col5>
            <p
              className="label is-small"
              style={{ marginBottom: "0px", color: "black" }}
            >
              Notification Method
            </p>
            <label style={{ marginRight: "10px", color: "black" }}>
            <input
              id="notifyWhatsApp"
              type="checkbox"
              checked={user?.notification?.whatsapp}
              disabled={user?.mobileVerified !== true || isViewMode()}
              onChange={() => {
                if (user) {                
                  user.notification.whatsapp = !user.notification.whatsapp;
                }
              }}
            />WhatsApp</label>

          <label style={{ marginRight: "10px", color: "black" }}>
            <input
              id="notifyEmail"
              type="checkbox"
              checked={user?.notification?.email}
              disabled={isViewMode()}
              onChange={() => {
                if (user) {
                  user.notification.email = !user.notification.email;
                }
              }}
            />Email</label>

          </Col5>
          </Columns>}

          {(isEditMode()|| isNewMode()) &&
            <div className="">
            {(isEditMode() && user.status === USERS_STATUS.NEW_REG ) && <>
                <button
                className="is-clickable"
                style={{
                  backgroundColor: "rgb(50, 168, 147)",
                  border: "none",
                  padding: "1%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "3%",
                  marginLeft: "70%",
                }}
                onClick={sendmailInvite}
              >
              {
                !user?.inviteSend ? 'Invite' : 'Re-Invite'
              }
                {/* Invite */}
              </button>
              </>}

              <button
                className="is-clickable"
                style={{...((isEditMode() && user.status === USERS_STATUS.NEW_REG )) ? 
                 {
                    marginLeft : "5%",
                    backgroundColor: "#00ccff",
                  border: "none",
                  padding: "1%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "3%",
                  }  : {
                  backgroundColor: "#00ccff",
                  border: "none",
                  padding: "1%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "3%",
                  marginLeft: "75%",
                }}}
                onClick={async (e) => {
                  e.preventDefault()
                  routerStore.entityUserManageView = "list"
                }}
              >
                CANCEL
              </button>
             
              <button
                className="is-clickable"
                style={{
                  backgroundColor: "#00ccff",
                  border: "none",
                  padding: "1%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "3%",
                  marginLeft: "3%",
                }}
                onClick={onFormSubmit}
                disabled={loading}
               
              >
                SUBMIT
              </button>
        
          
          </div>
            
          }
       
        </div>
    </>
  )
}
