import { Box, Dialog, IconButton, Typography } from "@mui/material";
import approve from "../assets/images/approve.svg";
import reject from "../assets/images/reject.svg";
import MonApi from "../state/mongo-api";
import { CancelBookingRequest } from "./CancelBookingRequest";
import { useState } from "react";
import { taskStore } from "../mytasks/task-store";
import approvedpopup from "../assets/images/aprrovepopup.svg";


let server = process.env.REACT_APP_FILE_SERVER


function ApproveDialog({open,onClose}){
  
    return <Dialog
    slotProps={{
        backdrop :  {sx: { 
            backgroundColor: 'transparent',
            backdropFilter: 'blur(3px)' } }
    }}
    PaperProps={{
        sx:{
            backgroundColor:"transparent",
            minWidth:"637px",
            border:"none",
            boxShadow:"none"
        }
       }} open={open} sx={{
        backgroundColor:"transparent !important",
        
        "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#2C358A !important"
            }
        }}>
 <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100%",backgroundColor:"transparent"}}>
 <Box sx={{width:"500px",height:"230px",position:"relative",backgroundColor:"transparent",display:"flex",justifyContent:"end"}}>
    <Box sx={{position:"absolute",bottom:0,height:"200px",width:"100%",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"10px",backgroundColor:"white"}}>
        <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"24px"}}>Airway Bill has been approved</Typography>
    </Box>
 <Box sx={{width:"100%",height:"60px",position:"absolute",top:0,display:"flex",justifyContent:"center",alignItems:"center"}}>
                <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",height:"60px",width:"60px",borderRadius:"50%",backgroundColor:"#EAF6FF"}}>
                    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",height:"40px",width:"40px",borderRadius:"50%",backgroundColor:"#2B6ED4"}}>
                    <img src={approvedpopup}/>
                    </Box>
                </Box>
            </Box>
 </Box>
 </Box>
    </Dialog>
}



export function ApproveRejectComponent({fileName,onRejectedReason,closeDialog,onApprove,onReject}){
    const [open,setOpen] = useState(false);
    const [openApprove,setApproveOpen] = useState(false);

    

    const updateApproval = async (val) => {
        let path1 = 'clearapproval'
        if (val === 'Approve') path1 = 'approvefile'
        let url = server + '/api/v1/filestore/' + path1 + '/' + taskStore.current.bookingNum + '/' + fileName
        await MonApi.get(url)
    }
    
    return <Box sx={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"space-between",height:"160px",width:"50px",borderRadius:"54px",boxShadow: "2px 2px 6px 0px #0000001A"
    }}>
<Box sx={{display:"flex",justifyContent:"center",alignItems:"center",height:"79px",width:"100%"}}><IconButton  onClick={()=>{
    setApproveOpen(true)
    setTimeout(() => {
        setApproveOpen(false);
        if(closeDialog){
            closeDialog();
        }
    }, (3000));
    if(onApprove){
        onApprove()
    }
    updateApproval("Approve")
}}><img src={approve} /></IconButton></Box>
<Box sx={{height:"1px",backgroundColor:"#AAAAAA",width:"40px",borderRadius:"1px"}}></Box>
<Box sx={{display:"flex",justifyContent:"center",alignItems:"center",height:"79px",width:"100%"}}><IconButton onClick={()=>{
    setOpen(true)
    updateApproval("Reject")
}}><img src={reject} /></IconButton></Box>
<CancelBookingRequest open={open} onCancel={()=>{
    setOpen(false);
}} onSubmit={(reason)=>{
      if(onRejectedReason){
        onRejectedReason(reason);
      }
      if(closeDialog){
        closeDialog();
    }
    if(onReject){
        onReject();
    }
}}/>
<ApproveDialog open={openApprove} />
    </Box>
}