import { useEffect, useRef, useState } from "react";
import { AgMultiSelectWithCheckBoxes } from "../components/AgCustomComponents";
import { BiPlus } from "react-icons/bi";
import { Select, MenuItem, Checkbox, ListItemText, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { MdDelete } from "react-icons/md";
import { Agmongofile } from "../components/AgFile";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import Api from "../state/Api";
import { subscribe, useSnapshot } from "valtio";
import { RiFolderDownloadFill } from "react-icons/ri";
import axios from "axios";
import { AgmodalStore, configStore, loginStore } from "../state/CommonState";
import { DocumentViewer } from "../pages/DocumentViewer";
import { Autocomplete, TextField } from '@mui/material';




function TableViewComponent({ vendorType, Details }) {
    return (
        <><div>
            <p style={{ fontWeight: 'bold', color: 'darkblue', fontSize: '18px' }}>{vendorType}</p>
            <div style={{display:'flex',flexDirection:'row',gap:40}}>
            <p><b>Vendor Selection :</b>{Details?.entityName}</p>
            {Details?.refNumber?<p><b>Vendor Ref Number :</b>{Details?.refNumber}</p>:<></>}
            </div>
        </div>
            <div>
                <div className="is-flex pt-4 ">
                    <table className="table_caf_fin wd_80">
                        <thead>
                            <td>Contact Person</td>
                            <td>Description/Department</td>
                            <td>Contact Number</td>
                            <td>Email</td>
                        </thead>
                        <tbody>
                            {Details?.information?.map((r, i) => {
                                return (
                                    <tr>
                                        <td>
                                            <input
                                                className={`inp_table_caf is-size-6`}
                                                value={Details.information[i].customercontact}
                                                disabled={true}
                                            ></input>
                                        </td>
                                        <td>
                                            <input
                                                className={`inp_table_caf input is-size-6`}
                                                value={Details.information[i].department}
                                                disabled={true}
                                            ></input>
                                        </td>
                                        <td
                                            style={{ borderRight: "none" }}
                                            className={`is-flex wd_101`}>
                                            <span className="contNumcodespn mt-1"></span>
                                            <input
                                                type="number"
                                                className={`inp_table_caf input is-small out_none_inp`}
                                                value={Details.information[i].contactnum}
                                                disabled={true}
                                            ></input>
                                        </td>
                                        <td style={{ border: "1px solid black" }}>
                                            <input
                                                className={`inp_table_caf is-size-6 `}
                                                value={Details.information[i].email}
                                                disabled={true}
                                            ></input>
                                        </td>

                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div></>)
}

export default function DocumentRequest() {
    useSnapshot(taskStore)
    let task = taskStore.current
    let stage = task.stage
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [today] = useState(new Date().toISOString().split("T")[0]);
    const [fileUrl, setFileUrl] = useState('');
    const [fileName, setFileName] = useState('')
    const [vendorDetails, setVendorDetails] = useState([])
    const [bool, setBool] = useState(true)
    const options = ['All', 'Customs Broker', 'Freight Forwarder', 'Road Transport', 'Warehouse Operator'];
    const openModal = (url, title) => {
        setFileUrl(url);
        setFileName(title)
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setFileUrl('');
    };
    useEffect(() => {
        async function fetchdata() {
            const response = await Api.getVerifiedVendors()
            setVendorDetails(response)
        }
        fetchdata()
    }, [])
    let server = process.env.REACT_APP_API_SERVER
    const InvisibleIcon = () => <span style={{ visibility: 'hidden' }}>&#9660;</span>;

    if (stage === 1) {
        return (<div className="box">
            {modalIsOpen && <DocumentViewer
                documentTitle={fileName}
                open={modalIsOpen}
                onClose={closeModal}
                documentUrl={fileUrl}
            />}
            <p>Please Download Document Request</p>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <tr>
                    <th style={{ textAlign: 'left', padding: '10px' }}>Document Type</th>
                    <th style={{ textAlign: 'left', padding: '10px' }}>Template</th>
                    <th style={{ textAlign: 'left', padding: '10px' }}>Special Remarks</th>
                    <th style={{ textAlign: 'left', padding: '10px' }}>Upload Document</th>
                    {task?.documentRequest?.some(e => e?.rejectReason) && (
                        <th style={{ textAlign: 'left', padding: '10px' }}>
                            Remarks
                        </th>
                    )}
                </tr>
                {
                    task.documentRequest.map((row, index) => (
                        row?.status === 'Reject' || row?.status === ''?(<tr key={index}>
                            <td style={{ padding: '10px',width:'25%' }}>
                                <p>{row.documentType}</p>
                            </td>
                            <td style={{ padding: '10px',width:'25%' }}>
                                {row.template ? <div style={{ display: 'flex', flexDirection: 'row' }}> <a title={row.template} style={{maxWidth: '150px',whiteSpace: 'normal',overflow:'hidden',display:'inline-block',textOverflow:'ellipsis'}} onClick={() => openModal(`${server}/api/v1/filestore/getfile/${taskStore.current.bookingNum}/${row.template}`,row?.template)} className="is-flex is-align-items-center">
                                    {row.template}</a>
                                    <a onClick={async () => {
                                        try {
                                            const response = await axios(`${server}/api/v1/filestore/getfile/${taskStore.current.bookingNum}/${row.template}`, {
                                                responseType: 'blob'
                                            });
                                            const url = window.URL.createObjectURL(new Blob([response.data]));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', row.template);
                                            document.body.appendChild(link);
                                            link.click();
                                        } catch (error) {
                                            if (error.message.includes(500)) {
                                                configStore.setModalMessage('File Not Found')
                                            } else {
                                                configStore.setModalMessage(error.message)
                                            }
                                        }
                                    }}
                                    >
                                        <span className="icon mt-1 ml-3 is-size-4 ">
                                            <RiFolderDownloadFill />
                                        </span>
                                    </a>
                                </div> : <></>}
                            </td>
                            <td style={{ padding: '10px',width:'25%' }}>
                                <p>{row.specialRemarks}</p>
                            </td>
                                <td style={{ padding: '10px',width:'25%' }}>{row?.uploadedDocument ? <><a title={row.uploadedDocument} style={{maxWidth: '150px',whiteSpace: 'normal',overflow:'hidden',display:'inline-block',textOverflow:'ellipsis'}} onClick={() => { openModal(`${server}/api/v1/filestore/getfile/${taskStore.current.bookingNum}/${row.uploadedDocument}`,row.uploadedDocument) }}
                            >{row.uploadedDocument}</a>&nbsp;<button style={{}} class="button is-danger size-6" onClick={() => row.uploadedDocument = ""}><i class="fa fa-trash" aria-hidden="true"></i></button></> :
                                <Agmongofile label={row.documentType} filekey={taskStore.current.bookingNum} parent={"template"} callback={(data) => {
                                    row.uploadedDocument = data.key.filename
                                    row.errors.uploadedDocument = false
                                }} />}
                                {row.errors.uploadedDocument ? <p style={{ color: 'red',fontSize:'.75rem' }}>This field is required</p> : <></>}
                            </td>
                            <td>
                                {row?.rejectReason ? <p>{row.rejectReason}</p> : ""}
                            </td>

                            </tr>):<></>

                    ))
                }

            </table>
            <div>
                <p>
                    <span>
                        <button style={{ margin: '10px', float: 'right' }} className="button islink" onClick={async () => {
                            // let tasks = taskStore.current
                            let isValid = true
                            task.documentRequest = task.documentRequest.map((e) => {
                                let errors = { documentType: false, requestedBy: false, uploadedDocument: false }
                                if (e?.uploadedDocument === '') {
                                    errors.uploadedDocument = true
                                    isValid = false
                                } else {
                                    errors = { documentType: false, requestedBy: false, uploadedDocument: false }
                                    if(e.status==='Reject')e.status = ''
                                }
                                return { ...e, errors }
                            })
                            if (isValid) {
                                task.yetToSubmitted = false
                                task.stage = 3
                                task.pendingWith = 'AGRAGA'
                                taskStore.setCustomerComplete()
                                Api.sendVendorMail(taskStore.current)
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                                if (AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()) {
                                    AgmodalStore.pageVisible = false
                                    AgmodalStore.apiCall = true
                                }
                            }
                        }}>Submit</button>
                    </span>
                </p>
                <p>
                    <span>
                        <button style={{ margin: '10px', float: 'right' }} className="button islink"
                            onClick={async () => {
                                taskStore.current.documentRequest.forEach((e)=>e.errors.uploadedDocument = false)
                                task.yetToSubmitted = true
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                                if (AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()) {
                                    AgmodalStore.pageVisible = false
                                    AgmodalStore.apiCall = true
                                }
                            }}
                        >Save</button>
                    </span>
                </p>
            </div>
        </div>)
    }

    if (stage === 3) {
        return (
            <div>
                {modalIsOpen && <DocumentViewer
                    documentTitle={fileName}
                    open={modalIsOpen}
                    onClose={closeModal}
                    documentUrl={fileUrl}
                />}
                <table style={{ width: '100%', borderCollapse: 'collapse' }} >
                    <tr>
                        <th style={{ textAlign: 'left', padding: '10px' }}>Document Name</th>
                        <th style={{ textAlign: 'left', padding: '10px' }}>Template</th>
                        <th style={{ textAlign: 'left', padding: '10px' }}>Special Remarks</th>
                        <th style={{ textAlign: 'left', padding: '10px' }}>Document</th>
                        <th style={{ textAlign: 'left', padding: '10px' }}>Requested by</th>
                        <th style={{ textAlign: 'left', padding: '10px' }}>Document Type</th>
                        <th style={{ textAlign: 'left', padding: '10px' }}>Status</th>
                        <th style={{ textAlign: 'left', padding: '10px' }}>Valid Upto</th>
                        <th style={{ textAlign: 'left', padding: '10px' }}>Remarks<span>(if Rejected)</span></th>
                    </tr>
                    {
                        task?.documentRequest.map((row, index) => (
                            task.reUpload ? (
                                row.rejectReason !==''?
                                    (<tr key={index}>
                                        <td style={{ padding: '10px' }}>
                                            <p>{row?.documentType}</p>
                                        </td>
                                        <td style={{ padding: '10px' }}>
                                            {row?.template !== '' ? <a title={row.template} style={{ maxWidth: '150px', whiteSpace: 'normal', overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis' }} onClick={() => { openModal(`${server}/api/v1/filestore/getfile/${taskStore.current.bookingNum}/${row?.template}`, row?.template) }} >{row?.template}</a> : ''}
                                        </td>
                                        <td style={{ padding: '10px' }}>
                                            <p>{row?.specialRemarks}</p>
                                        </td>
                                        <td style={{ padding: '10px' }}>
                                            <a title={row.uploadedDocument} style={{ maxWidth: '150px', whiteSpace: 'normal', overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis' }} onClick={() => { openModal(`${server}/api/v1/filestore/getfile/${taskStore.current.bookingNum}/${row?.uploadedDocument}`, row?.uploadedDocument) }} >{row?.uploadedDocument}</a>
                                        </td>
                                        <td style={{ padding: '10px' }}>
                                            <FormControl fullWidth>
                                                <Select
                                                    multiple
                                                    value={row?.requestedBy}
                                                    // onChange={(event) => handleChange(event, index)}
                                                    SelectDisplayProps={{
                                                        style: {
                                                            padding: '5px'
                                                        }
                                                    }}
                                                    IconComponent={InvisibleIcon}
                                                    renderValue={(selected) => selected.join(', ')}
                                                >
                                                    {options.map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            <Checkbox checked={
                                                                // option === 'All'
                                                                //     ? isAllSelected(row.requestedBy)
                                                                row?.requestedBy.indexOf(option) > -1
                                                            } />
                                                            <ListItemText primary={option} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </td>
                                        <td style={{ padding: '10px' }}>
                                        {row.documentCategory}
                                        </td>
                                        <td style={{ padding: '10px' }}>
                                            <select defaultValue="" class="select is-normal" value={row.status} onChange={(e1) => {
                                                row.status = e1.target.value
                                                row.errors.status = false
                                            }}>
                                                <option value=''>Select</option>
                                                <option value='Approve'>Approve</option>
                                                <option value='Reject'>Reject</option>
                                            </select>
                                            {row?.errors?.status && <p style={{ color: 'red', fontSize: '.75rem' }}>This field is required</p>}
                                        </td >
                                        <td style={{ padding: '10px' }}>
                                        {row.documentCategory === 'Entity'?<input type="date" min={today} onChange={(e)=>{
                                           row.validUpto = e.target.value;
                                        }}/>:<></>}
                                    </td>
                                        <td style={{ padding: '10px' }}>
                                            {row?.status === 'Reject' ?
                                                <input className="input is-normal" value={row.rejectReason} placeholder="Rejection Reason" onChange={(e) => {
                                                    row.rejectReason = e.target.value;
                                                    row.errors.rejectReason = false
                                                }}></input> : <p>{row.rejectReason}</p>}
                                            {row?.errors?.rejectReason && <p style={{ color: 'red', fontSize: '.75rem' }}>This field is required</p>}
                                        </td>
                                </tr>):<></>) :
                                <tr key={index}>
                                    <td style={{ padding: '10px' }}>
                                        <p>{row?.documentType}</p>
                                    </td>
                                    <td style={{ padding: '10px' }}>
                                    {row?.template !== '' ? <a title={row.template} style={{maxWidth: '150px',whiteSpace: 'normal',overflow:'hidden',display:'inline-block',textOverflow:'ellipsis'}} onClick={() => { openModal(`${server}/api/v1/filestore/getfile/${taskStore.current.bookingNum}/${row?.template}`,row?.template) }} >{row?.template}</a> : ''}
                                    </td>
                                    <td style={{ padding: '10px' }}>
                                        <p>{row?.specialRemarks}</p>
                                    </td>
                                    <td style={{ padding: '10px' }}>
                                    <a title={row.uploadedDocument} style={{maxWidth: '150px',whiteSpace: 'normal',overflow:'hidden',display:'inline-block',textOverflow:'ellipsis'}} onClick={() => { openModal(`${server}/api/v1/filestore/getfile/${taskStore.current.bookingNum}/${row?.uploadedDocument}`,row?.uploadedDocument)}} >{row?.uploadedDocument}</a>
                                    </td>
                                    <td style={{ padding: '10px' }}>
                                        <FormControl fullWidth>
                                            <Select
                                                multiple
                                                value={row?.requestedBy}
                                                // onChange={(event) => handleChange(event, index)}
                                                SelectDisplayProps={{
                                                    style: {
                                                        padding: '5px'
                                                    }
                                                }}
                                                IconComponent={InvisibleIcon}
                                                renderValue={(selected) => {
                                                    return (
                                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        {selected.map((value) => (
                                                          <div key={value} style={{ padding: '4px 0' }}>
                                                            {value}
                                                          </div>
                                                        ))}
                                                      </div>
                                                    );
                                                  }}                                            >
                                                {options.map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        <Checkbox checked={
                                                            // option === 'All'
                                                            //     ? isAllSelected(row.requestedBy)
                                                            row?.requestedBy.indexOf(option) > -1
                                                        } />
                                                        <ListItemText primary={option} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </td>
                                    <td style={{ padding: '10px' }}>
                                        {row.documentCategory}
                                    </td>
                                    <td style={{ padding: '10px' }}>
                                        <select class="select is-normal" value={row.status} onChange={(e1) => {
                                            row.status = e1.target.value
                                            row.errors.status = false
                                            if(row.status === 'Approve') row.rejectReason = ''
                                        }}>
                                            <option value=''>Select</option>
                                            <option value='Approve'>Approve</option>
                                            <option value='Reject'>Reject</option>
                                        </select>
                                    {row?.errors?.status && <p style={{ color: 'red',fontSize:'.75rem' }}>This field is required</p>}
                                    </td >
                                    <td style={{ padding: '10px' }}>
                                        {row.documentCategory === 'Entity'?<input type="date" min={today} onChange={(e)=>{
                                           row.validUpto = e.target.value;
                                        }}/>:<></>}
                                    </td>
                                    <td style={{ padding: '10px' }}>
                                        {row?.status === 'Reject' ?
                                            <input className="input is-normal" value={row.rejectReason} placeholder="Rejection Reason" onChange={(e) => {
                                                row.rejectReason = e.target.value;
                                                row.errors.rejectReason = false
                                    }}></input>:<p>{row.rejectReason}</p>}
                                   {row?.errors?.rejectReason && <p style={{ color: 'red',fontSize:'.75rem' }}>This field is required</p>}
                                    </td>
                                </tr>
                        ))
                    }

                </table>
                <div>
                    <p style={{ fontWeight: 'bold', fontSize: '30px' }}>Vendor Details</p>
                    {task.reUpload ?
                        (task?.documentRequest.some(e => e.rejectReason !== ''&&e.requestedBy.includes('Customs Broker')) ?
                        task?.customBroker&&<TableViewComponent vendorType={'Customs Broker'} Details={task?.customBroker} /> :
                            <></>) : task?.customBroker&&<TableViewComponent vendorType={'Customs Broker'} Details={task?.customBroker} />}

                    {task.reUpload ?
                        (task?.documentRequest.some(e => e.rejectReason !== ''&&e.requestedBy.includes('Road Transport')) ?
                        task?.roadTransporter&& <TableViewComponent vendorType={'Road Transporter'} Details={task?.roadTransporter} /> :
                            <></>) : task?.roadTransporter&&<TableViewComponent vendorType={'Road Transporter'} Details={task?.roadTransporter} />}

                    {task.reUpload ?
                        (task?.documentRequest.some(e => e.rejectReason !== ''&&e.requestedBy.includes('Warehouse Operator')) ?
                        task?.warehouseOperator&& <TableViewComponent vendorType={'Warehouse Operator'} Details={task?.warehouseOperator} />
                            : <></>) : task?.warehouseOperator&&<TableViewComponent vendorType={'Warehouse Operator'} Details={task?.warehouseOperator} />
                    }
                    {task.reUpload ?
                        (task?.documentRequest.some(e => e.rejectReason !== ''&&e.requestedBy.includes('Freight Forwarder')) ?
                        task?.freightForwarder&& <TableViewComponent vendorType={'Freight Forwarder'} Details={task?.freightForwarder} /> :
                            <></>) : task?.freightForwarder&&<TableViewComponent vendorType={'Freight Forwarder'} Details={task?.freightForwarder} />}
                </div>

                <p>
                    <span>
                        <button style={{ margin: '10px', float: 'inline-end' }} className="button islink" onClick={async () => {
                            let isValid = true
                            let emptyCheck = true
                            task.documentRequest = task.documentRequest.map((e) => {
                                let errors = { status: false, rejectReason: false }
                                if (e?.status === 'Reject') {
                                    isValid = false
                                }
                                if (e.status === 'Reject' && e.rejectReason === '') {
                                    errors.rejectReason = true
                                    emptyCheck = false
                                }
                                if (e?.status === '') {
                                    errors.status = true
                                    emptyCheck = false
                                }
                                return { ...e, errors }
                            })
                            if (emptyCheck) {
                                if (isValid) {
                                    taskStore.current.stage = 4
                                    taskStore.current.pendingWith = 'AGRAGA'
                                    taskStore.setComplete()
                                    await Taskapi.updateTask(taskStore.current)
                                    Api.uploadAdditionalDocumentFiles(taskStore.current)
                                    taskStore.triggerActionVisible = false
                                    taskStore.myActionVisible = false
                                    if (AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()) {
                                        AgmodalStore.pageVisible = false
                                        AgmodalStore.apiCall = true
                                    }
                                } else {
                                    // task.documentRequest = task.documentRequest.filter(e=>e.status ==='Reject')
                                    // Api.updateBookingDetails(taskStore.current)
                                    task.yetToSubmitted = true
                                    taskStore.current.stage = 1
                                    taskStore.current.reUpload = true
                                    taskStore.current.pendingWith = 'CUSTOMER'
                                    taskStore.current.customerStatus = 'PENDING'
                                    taskStore.current.customerCompletedBy = ''
                                    taskStore.current.customerCompletedOn = ''
                                    Api.sendRejectionMail(taskStore.current)
                                    await Taskapi.updateTask(taskStore.current)
                                    taskStore.triggerActionVisible = false
                                    taskStore.myActionVisible = false
                                    if (AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()) {
                                        AgmodalStore.pageVisible = false
                                        AgmodalStore.apiCall = true
                                    }
                                }
                            }
                        }}
                        >Submit</button>
                    </span>
                </p>
            </div>
        )
    }

    if (stage >= 4) {
        return (
            <div>
                {modalIsOpen && <DocumentViewer
                    documentTitle={fileName}
                    open={modalIsOpen}
                    onClose={closeModal}
                    documentUrl={fileUrl}
                />}
                <table style={{ width: '100%', borderCollapse: 'collapse' }} >
                    <tr>
                        <th style={{ textAlign: 'left', padding: '10px' }}>Document Name</th>
                        <th style={{ textAlign: 'left', padding: '10px' }}>Template</th>
                        <th style={{ textAlign: 'left', padding: '10px' }}>Special Remarks</th>
                        {task.actionType === 'AGRAGA' ? <th style={{ textAlign: 'left', padding: '10px' }}>Document Type</th>:<></>}
                        <th style={{ textAlign: 'left', padding: '10px' }}>Document</th>
                        {task.actionType === 'AGRAGA' ? <th style={{ textAlign: 'left', padding: '10px' }}>Requested by</th> : <></>}
                        {task.actionType === 'AGRAGA' ? <th style={{ textAlign: 'left', padding: '10px' }}>Status</th> : <></>}
                    </tr>
                    {
                        task?.documentRequest.map((row, index) => (
                            <tr key={index}>
                                <td style={{ padding: '10px' }}>
                                    <p>{row?.documentType}</p>
                                </td>
                                <td style={{ padding: '10px' }}>
                                    {row?.template !== '' ?
                                        <a title={row.template} style={{maxWidth: '150px',whiteSpace: 'normal',overflow:'hidden',display:'inline-block',textOverflow:'ellipsis'}} onClick={() => { openModal(`${server}/api/v1/filestore/getfile/${taskStore.current.bookingNum}/${row?.template}`,row?.template) }} >{row?.template}</a> : ''}
                                </td>
                                <td style={{ padding: '10px' }}>
                                    <p>{row?.specialRemarks}</p>
                                </td>
                                {task.actionType === 'AGRAGA' ?<td style={{ padding: '10px' }}>
                                    <p>{row?.documentCategory}</p>
                                </td>:<></>}
                                <td style={{ padding: '10px' }}>
                                    <a title={row.uploadedDocument} style={{maxWidth: '150px',whiteSpace: 'normal',overflow:'hidden',display:'inline-block',textOverflow:'ellipsis'}} onClick={() => { openModal(`${server}/api/v1/filestore/getfile/${taskStore.current.bookingNum}/${row?.uploadedDocument}`,row?.uploadedDocument) }} >{row?.uploadedDocument}</a>
                                </td>
                                {task.actionType === 'AGRAGA' ? <td style={{ paddingRight: '20px', paddingBottom: '10px' }} >
                                    <FormControl fullWidth>
                                        <Select
                                            multiple
                                            value={row?.requestedBy}
                                            // onChange={(event) => handleChange(event, index)}
                                            SelectDisplayProps={{
                                                style: {
                                                    padding: '5px'
                                                }
                                            }}
                                            IconComponent={InvisibleIcon}
                                            renderValue={(selected) => {
                                                return (
                                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {selected.map((value) => (
                                                      <div key={value} style={{ padding: '4px 0' }}>
                                                        {value}
                                                      </div>
                                                    ))}
                                                  </div>
                                                );
                                              }}                                        >
                                            {options.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    <Checkbox checked={
                                                        // option === 'All'
                                                        //     ? isAllSelected(row.requestedBy)
                                                        row?.requestedBy.indexOf(option) > -1
                                                    } />
                                                    <ListItemText primary={option} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </td> : <></>}
                                {task.actionType === 'AGRAGA' ?
                                    <td style={{ padding: '10px' }}>
                                        <p>{row.status==='Approve'&&'Approved'}</p>
                                    </td> : <></>}
                            </tr>
                        ))
                    }

                </table>
                {task.actionType === 'AGRAGA' ? <div><>
                    <p style={{ fontWeight: 'bold', fontSize: '30px' }}>Vendor Details</p>
                    {task?.customBroker && task?.customBroker.entityId !== '' ? <>
                        <TableViewComponent vendorType={'Customs Broker'} Details={task?.customBroker} />
                    </> : <></>}
                    {task?.roadTransporter && task?.roadTransporter?.entityId !== '' ? <>
                        <TableViewComponent vendorType={'Road Transporter'} Details={task?.roadTransporter} />
                    </> : <></>}
                    {task?.warehouseOperator && task?.warehouseOperator?.entityId !== '' ? <>
                        <TableViewComponent vendorType={'Warehouse Operator'} Details={task?.warehouseOperator} />
                    </> : <></>}
                    {task?.freightForwarder && task?.freightForwarder?.entityId !== '' ? <>
                        <TableViewComponent vendorType={'Freight Forwarder'} Details={task?.freightForwarder} />
                    </> : <></>}</>
                </div> : <></>}
            </div>
        )
    }
}


export function DocumentRequestTrigger() {
    let server = process.env.REACT_APP_API_SERVER
    const [rows, setRows] = useState([
        { documentType: '', template: '', specialRemarks: '', documentCategory:'',sendMail:true,requestedBy: [], uploadedDocument: '', status: '', rejectReason: '', errors: { documentType: false, requestedBy: false,documentName:false } }
    ]);
    const options = ['Customs Broker', 'Freight Forwarder', 'Road Transport', 'Warehouse Operator'];
    const [vendor, setVendor] = useState({ customBroker: 0, freightForwarder: 0, roadTransporter: 0, warehouse: 0 })
    const [customBrokerEntity, setCustomBrokerEntity] = useState('')
    const [freightForwarderEntity, setFreightForwarderEntity] = useState('')
    const [warehouseEntity, setWarehouseEntity] = useState('')
    const [roadTransporterEntity, setRoadTransporterEntity] = useState('')
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const [fileName, setFileName] = useState('')

    const [customBrokerDetails, setCustomBrokerDetails] = useState([{ customercontact: "", department: "", contactnum: "", email: "" }])
    const [freightForwarderDetails, setFreightForwarderDetails] = useState([{ customercontact: "", department: "", contactnum: "", email: "",function: "", product: [], type: "", location: "" }])
    const [warehouseDetails, setWarehouseDetails] = useState([{ customercontact: "", department: "", contactnum: "", email: "" }])
    const [roadTransporterDetails, setRoadTransporterDetails] = useState([{ customercontact: "", department: "", contactnum: "", email: "" }])
    const [vendorRefNum, setvendorRefNum] = useState({
        customBrokerRefNum: '',
        freightForwarderRefNum: '',
        warehouseRefNum: '',
        roadTransporterRefNum: '',
      });
    const optionToVendorKey = {
        'Customs Broker': 'customBroker',
        'Freight Forwarder': 'freightForwarder',
        'Road Transport': 'roadTransporter',
        'Warehouse Operator': 'warehouseOperator',
    };

    const openModal = (url, title) => {
        setFileUrl(url);
        setFileName(title)
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setFileUrl('');
    };

    const handleVendorRefNumChange = (key, value) => {
        setvendorRefNum((prevEntities) => ({
          ...prevEntities,
          [key]: value, // Update only the key that changed
        }));
      };

    const handleChange = (event, rowIndex) => {
        const { value } = event.target;
        let newSelectedValues;
        // if (value.includes('All')) {
        //     newSelectedValues = rows[rowIndex].requestedBy.includes('All')
        //         ? []
        //         : options.filter(option => option !== 'All');
        //         console.log(newSelectedValues,"newSelectedValues");
                
        // } else {
            newSelectedValues = typeof value === 'string' ? value.split(',') : value;
        // }
        const updatedRows = [...rows];
        updatedRows[rowIndex].requestedBy = newSelectedValues;
        updatedRows[rowIndex].errors.requestedBy = false;
        setRows(updatedRows);
        setVendor((prevVendor) => {
            const newVendorState = { ...prevVendor };
            let resetCustomBroker = true;
            let resetRoadTransporter = true;
            let resetFreightForwarder = true;
            let resetWarehouse = true;
            Object.keys(newVendorState).forEach(key => newVendorState[key] = 0);
            updatedRows.forEach(row => {
                row.requestedBy.forEach(option => {
                    const vendorKey = optionToVendorKey[option];
                    if (vendorKey) {
                        newVendorState[vendorKey] = 1;
                    }
                    if (vendorKey === 'customBroker') resetCustomBroker = false;
                    if (vendorKey === 'roadTransporter') resetRoadTransporter = false;
                    if (vendorKey === 'freightForwarder') resetFreightForwarder = false;
                    if (vendorKey === 'warehouseOperator') resetWarehouse = false;
                });
            });
            if (resetCustomBroker) {
                setCustomBrokerDetails([{ customercontact: "", department: "", contactnum: "", email: "" }])
                setCustomBrokerEntity('')
            }
            if (resetRoadTransporter) {
                setRoadTransporterDetails([{ customercontact: "", department: "", contactnum: "", email: "" }])
                setRoadTransporterEntity('')
            }
            if (resetFreightForwarder) {
                setFreightForwarderDetails([{ customercontact: "", department: "", contactnum: "", email: "",function: "", product: [], type: "", location: "" }])
                setFreightForwarderEntity('')
            }
            if (resetWarehouse) {
                setWarehouseDetails([{ customercontact: "", department: "", contactnum: "", email: "" }])
                setWarehouseEntity('')
            }
            return newVendorState;
        });
    };
    const [vendorDetails, setVendorDetails] = useState([])
    const [value, setValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [documentType, setDocumentType] = useState(['Importer on Record Agreement with Stelcore','Power of Attorney','Continuous Bond',"Copy of GST (Form-6) self-attested with active status on GST portal",'Copy of PAN self-attested','Copy of TAN self-attested','Copy of ID / Address proof self-attested','IEC copy self-attested with active status on IEC gate portal with AD code linked','KYC form Self-attested (to be made as per industry norms)','AD Code - Copy of AD Code Letter',"Certificate of Incorporation (If, 'Private Limited')",'Shipping Bill under LUT (Letter of Undertaking)','LUT Registration Copy & Tax Invoice','Drawback Bank A/C no, IFSC code no, Bank name & address','Forex Bank AD Code No, Bank A/C no, Bank name & address','Previous Shipping Bill Copy','Authorization Letter','Previous Bill of Entry Copy'])

    useEffect(() => {
        async function fetchdata() {
            const response = await Api.getVerifiedVendors()
            setVendorDetails(response)
        }
        async function fetchDocumentType() {
            const resp = await Api.getDocumentType()
            if (resp.length > 0) {
                setDocumentType((prev)=>{
                    const checkDuplicate = resp
                          .map((e) => e.documentName)
                          .filter((docName) => !prev.includes(docName));

                    const updated = [...prev, ...checkDuplicate];
                    return updated                
                })
            }
        }
        fetchDocumentType()
        fetchdata()
    }, [])

    const isAllSelected = (selectedValues) => selectedValues.length === options.length - 1;

    const addNewLogisticrow1 = (type) => {
        const newRow = { customercontact: "", department: "", contactnum: "", email: "",function: "", product: [], type: "", location: "" };
        const setStateMap = {
            warehouse: setWarehouseDetails,
            freightForwarder: setFreightForwarderDetails,
            customBroker: setCustomBrokerDetails,
            roadTransport: setRoadTransporterDetails,
        };
        const setState = setStateMap[type];
        if (setState) {
            setState((prev) => [...prev, newRow]);
        }
        // 
        // if (type === 'warehouse') {
        //     setWarehouseDetails((prev) => {
        //         const updatedState = [...prev, { customercontact: "", department: "", contactnum: "", email: "" }]
        //         return updatedState
        //     })
        // }
        // if (type === 'freightForwarder') {
        //     setFreightForwarderDetails((prev) => {
        //         const updatedState = [...prev, { customercontact: "", department: "", contactnum: "", email: "" }]
        //         return updatedState
        //     })
        // }
        // if (type === 'customBroker') {
        //     setCustomBrokerDetails((prev) => {
        //         const updatedState = [...prev, { customercontact: "", department: "", contactnum: "", email: "" }]
        //         return updatedState
        //     })
        // }
        // if (type === 'roadTransport') {
        //     setRoadTransporterDetails((prev) => {
        //         const updatedState = [...prev, { customercontact: "", department: "", contactnum: "", email: "" }]
        //         return updatedState
        //     })
        // }
    };

    const removeLogisticrow1 = (i, type) => {
        const detailsMap = {
            warehouse: [warehouseDetails, setWarehouseDetails],
            freightForwarder: [freightForwarderDetails, setFreightForwarderDetails],
            customBroker: [customBrokerDetails, setCustomBrokerDetails],
            roadTransport: [roadTransporterDetails, setRoadTransporterDetails]
        };
        const [details, setDetails] = detailsMap[type];
        if (details.length > 1) {
            const updatedDetails = details.filter((_, index) => index !== i);
            setDetails(updatedDetails);
        }
    };

    const handleAddRow = () => {
        let isValid = true;
        const updatedRows = rows.map(row => {
            const errors = { documentType: false, requestedBy: false,documentName:false };
            if (row.documentType === '') {
                errors.documentType = true;
                isValid = false;
            }
            if (row.documentCategory === '') {
                errors.documentName = true;
                isValid = false;
            }
            if (!row.requestedBy.length) {
                errors.requestedBy = true;
                isValid = false;
            }
            return { ...row, errors };
        });

        if (isValid) {
            setInputValue('')
            setValue('')
            setRows([...rows, { documentType: '', template: '', specialRemarks: '',documentCategory:'',sendMail:true, requestedBy: [], uploadedDocument: '', status: '', rejectReason: '', errors: { documentType: false, requestedBy: false,documentName:false } }]);
        } else {
            setRows(updatedRows);
        }
    };
    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newRows = [...rows];
        newRows[index][name] = value;
        newRows[index].errors[name] = false;
        setRows(newRows);
    };

const handleCheckBoxChange = (index, event) => {
    const newRows = [...rows];
    newRows[index].sendMail = event.target.checked;
    setRows(newRows);
}
    
    const [visibleDropdown, setVisibleDropdown] = useState(null); // Track which dropdown is open (by row index)

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleOutsideClick = (event) => {
            const dropdowns = document.querySelectorAll('.product-dropdown-menu');
            let clickedInside = false;

            dropdowns.forEach((dropdown) => {
                if (dropdown.contains(event.target)) {
                    clickedInside = true;
                }
            });

            if (!clickedInside) {
                setVisibleDropdown(null); // Close all dropdowns when clicked outside
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const toggleDropdown = (index) => {
        setVisibleDropdown((prevIndex) => (prevIndex === index ? null : index)); // Toggle visibility for the current row
    };

    const toggleProductSelection = (rowIndex, product) => {
        const currentProducts = freightForwarderDetails[rowIndex].product || [];
        const updatedProducts = currentProducts.includes(product)
            ? currentProducts.filter((p) => p !== product) // Remove product
            : [...currentProducts, product]; // Add product

        const updatedDetails = freightForwarderDetails.map((row, index) =>
            index === rowIndex ? { ...row, product: updatedProducts } : row
        );

        setFreightForwarderDetails(updatedDetails);  
    };  

    const handleChange2 = (e, i, r, type) => {
        let re = /^([A-Za-z0-9- ]{0, 100})$/;
        // if (r !== "email" && !re.test(e.target.value)) {
        //     return null;
        // }
        // if (r === "contactnum" && e.target.value.length > 15) {
        //     return null;
        // }
        // if (e.target.value.length > 100) {
        //     return null;
        // }
        if (type === 'freightForwarder') {
            setFreightForwarderDetails((prevDetails) => {
                const updatedDetails = [...prevDetails];
                updatedDetails[i] = {
                    ...updatedDetails[i],
                    [r]: e.target.value,
                };
                return updatedDetails;
            });
        }
        if (type === 'warehouseOperator') {
            setWarehouseDetails((prevDetails) => {
                const updatedDetails = [...prevDetails];
                updatedDetails[i] = {
                    ...updatedDetails[i],
                    [r]: e.target.value,
                };
                return updatedDetails;
            });
        }
        if (type === 'customsBroker') {
            setCustomBrokerDetails((prevDetails) => {
                const updatedDetails = [...prevDetails];
                updatedDetails[i] = {
                    ...updatedDetails[i],
                    [r]: e.target.value,
                };
                return updatedDetails;
            });
        }
        if (type === 'roadTransport') {
            setRoadTransporterDetails((prevDetails) => {
                const updatedDetails = [...prevDetails];
                updatedDetails[i] = {
                    ...updatedDetails[i],
                    [r]: e.target.value,
                };
                return updatedDetails;
            });
        }
    };

    const [errors1, setErrors1] = useState({});

    const dovalidationcheck1 = async () => {
        function isValid(value) {
            return (
                (!Array.isArray(value) &&
                    value &&
                    value.trim().length > 0 &&
                    value !== "default") ||
                (Array.isArray(value) &&
                    value.some((item) => item.trim() !== "default"))
            );
        }
      const emailRegexPattern = /^[A-Za-z0-9_!#$%&'*+/=?{|}~^.-]+@(?!agraga\.com$)[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        let errors1 = {};  
        let fieldsCheck1 = { isError: false };

        // Initialize errors for all tables
        errors1.customBrokerDetails = { entity:false,obj: [], error: false };
        errors1.freightForwarderDetails = { entity:false,obj: [], error: false };
        errors1.warehouseDetails = { entity:false,obj: [], error: false };
        errors1.roadTransporterDetails = { entity:false,obj: [], error: false };

        if(vendor['customBroker'] > 0&& (customBrokerEntity === ''||customBrokerEntity === undefined)){
            errors1.customBrokerDetails.entity = 'Please Select a vendor'
            fieldsCheck1.isError = true;
            errors1.customBrokerDetails.error = true;
        }
        if(vendor['freightForwarder'] > 0&& (freightForwarderEntity === ''||freightForwarderEntity === undefined)){
            errors1.freightForwarderDetails.entity = 'Please Select a vendor'
            fieldsCheck1.isError = true;
            errors1.freightForwarderDetails.error = true;
        }
        if(vendor['warehouseOperator'] > 0&& (warehouseEntity === ''||warehouseEntity === undefined)){
            errors1.warehouseDetails.entity = 'Please Select a vendor'
            fieldsCheck1.isError = true;
            errors1.warehouseDetails.error = true;
        }
        if(vendor['roadTransporter'] > 0&& (roadTransporterEntity === ''||roadTransporterEntity === undefined)){
            errors1.roadTransporterDetails.entity = 'Please Select a vendor'
            fieldsCheck1.isError = true;
            errors1.roadTransporterDetails.error = true;
        }

        // Validation for logisticInformation
        vendor['customBroker'] > 0 && customBrokerDetails.forEach((r, i) => {
            errors1.customBrokerDetails.obj.push({
                customercontact: "",
                department: "",
                contactnum: "",
                email: "",
            });
            if (!isValid(r.customercontact)) {
                errors1.customBrokerDetails.obj[i].customercontact = `Please enter all values`;
                fieldsCheck1.isError = true;
                errors1.customBrokerDetails.error = true;
            }
            if (!isValid(r.department)) {
                errors1.customBrokerDetails.obj[i].department = `Please enter all values`;
                fieldsCheck1.isError = true;
                errors1.customBrokerDetails.error = true;
            }
            if (!isValid(r.contactnum)) {
                errors1.customBrokerDetails.obj[i].contactnum = `Please enter all values`;
                fieldsCheck1.isError = true;
                errors1.customBrokerDetails.error = true;
            }
            if (!isValid(r.email)) {
                errors1.customBrokerDetails.obj[i].email = `Please enter all values`;
                fieldsCheck1.isError = true;
                errors1.customBrokerDetails.error = true;
            }
            if (isValid(r.contactnum) && (r.contactnum.length > 15 || r.contactnum.length < 7)) {
                errors1.customBrokerDetails.obj[i].contactnum = `Enter Valid Phone Number (7-15 Digits)`;
                fieldsCheck1.isError = true;
                errors1.customBrokerDetails.error = true;
                errors1.customBrokerDetails.contactNumber = `Enter Valid Phone Number (7-15 Digits)`;
            }
            if (isValid(r.email) && !emailRegexPattern.test(r.email.trim())) {
                errors1.customBrokerDetails.obj[i].email = `Invalid email pattern`;
                fieldsCheck1.isError = true;
                errors1.customBrokerDetails.error = true;
                errors1.customBrokerDetails.email = `Invalid email`;
            }
        });

        // Validation for freightForwarderDetails
        vendor['freightForwarder'] && freightForwarderDetails.forEach((r, i) => {
            errors1.freightForwarderDetails.obj.push({
                customercontact: "",
                department: "",
                contactnum: "",
                email: "",
                location: "",
                function: "",
                type: "",
                product: ""
            });
            if (!isValid(r.customercontact)) {
                errors1.freightForwarderDetails.obj[i].customercontact = `Please enter all values`;
                fieldsCheck1.isError = true;
                errors1.freightForwarderDetails.error = true;
            }
            if (!isValid(r.department)) {
                errors1.freightForwarderDetails.obj[i].department = `Please enter all values`;
                fieldsCheck1.isError = true;
                errors1.freightForwarderDetails.error = true;
            }
            if (!isValid(r.contactnum)) {
                errors1.freightForwarderDetails.obj[i].contactnum = `Please enter all values`;
                fieldsCheck1.isError = true;
                errors1.freightForwarderDetails.error = true;
            }
            if (!isValid(r.email)) {
                errors1.freightForwarderDetails.obj[i].email = `Please enter all values`;
                fieldsCheck1.isError = true;
                errors1.freightForwarderDetails.error = true;
            }
            if (!isValid(r.location)) {
                errors1.freightForwarderDetails.obj[i].location = `Please enter a value for location`;
                fieldsCheck1.isError = true;
                errors1.freightForwarderDetails.error = true;
            }
            if (!isValid(r.function)) {
                errors1.freightForwarderDetails.obj[i].function = `Please select a function`;
                fieldsCheck1.isError = true;
                errors1.freightForwarderDetails.error = true;
            }
            if (!isValid(r.type)) {
                errors1.freightForwarderDetails.obj[i].type = `Please select a type`;
                fieldsCheck1.isError = true;
                errors1.freightForwarderDetails.error = true;
            }
            if (!Array.isArray(r.product) || r.product.length === 0) {
                errors1.freightForwarderDetails.obj[i].product = `Please select at least one product`;
                fieldsCheck1.isError = true;
                errors1.freightForwarderDetails.error = true;
            }
        
            // Additional validation for phone number length and email pattern
            if (isValid(r.contactnum) && (r.contactnum.length > 15 || r.contactnum.length < 7)) {
                errors1.freightForwarderDetails.obj[i].contactnum = `Enter Valid Phone Number (7-15 Digits)`;
                fieldsCheck1.isError = true;
                errors1.freightForwarderDetails.error = true;
                errors1.freightForwarderDetails.contactNumber = `Enter Valid Phone Number (7-15 Digits)`;
            }
            if (isValid(r.email) && !emailRegexPattern.test(r.email.trim())) {
                errors1.freightForwarderDetails.obj[i].email = `Invalid email pattern`;
                fieldsCheck1.isError = true;
                errors1.freightForwarderDetails.error = true;
                errors1.freightForwarderDetails.email = `Invalid email`;
            }
        });

        // Validation for warehouseDetails
        vendor['warehouseOperator'] > 0 && warehouseDetails.forEach((r, i) => {
            errors1.warehouseDetails.obj.push({
                customercontact: "",
                department: "",
                contact: "",
                email: ""
            });
            if (!isValid(r.customercontact)) {
                errors1.warehouseDetails.obj[i].customercontact = `Please enter all values`;
                fieldsCheck1.isError = true;
                errors1.warehouseDetails.error = true;
            }
            if (!isValid(r.department)) {
                errors1.warehouseDetails.obj[i].department = `Please enter all values`;
                fieldsCheck1.isError = true;
                errors1.warehouseDetails.error = true;
            }
            if (!isValid(r.contactnum)) {
                errors1.warehouseDetails.obj[i].contactnum = `Please enter all values`;
                fieldsCheck1.isError = true;
                errors1.warehouseDetails.error = true;
            }
            if (!isValid(r.email)) {
                errors1.warehouseDetails.obj[i].email = `Please enter all values`;
                fieldsCheck1.isError = true;
                errors1.warehouseDetails.error = true;
            }
            if (isValid(r.contactnum) && (r.contactnum.length > 15 || r.contactnum.length < 7)) {
                errors1.warehouseDetails.obj[i].contactnum = `Enter Valid Phone Number (7-15 Digits)`;
                fieldsCheck1.isError = true;
                errors1.warehouseDetails.error = true;
                errors1.warehouseDetails.contactNumber = `Enter Valid Phone Number (7-15 Digits)`;

            }
            if (isValid(r.email) && !emailRegexPattern.test(r.email.trim())) {
                errors1.warehouseDetails.obj[i].email = `Invalid email pattern`;
                fieldsCheck1.isError = true;
                errors1.warehouseDetails.error = true;
                errors1.warehouseDetails.email = `Invalid email`;
            }
        });

        // Validation for shippingDetails
        vendor['roadTransporter'] > 0 && roadTransporterDetails.forEach((r, i) => {
            errors1.roadTransporterDetails.obj.push({
                customercontact: "",
                department: "",
                contact: "",
                email: ""
            });
            if (!isValid(r.customercontact)) {
                errors1.roadTransporterDetails.obj[i].customercontact = `Please enter all values`;
                fieldsCheck1.isError = true;
                errors1.roadTransporterDetails.error = true;
            }
            if (!isValid(r.department)) {
                errors1.roadTransporterDetails.obj[i].department = `Please enter all values`;
                fieldsCheck1.isError = true;
                errors1.roadTransporterDetails.error = true;
            }
            if (!isValid(r.contactnum)) {
                errors1.roadTransporterDetails.obj[i].contactnum = `Please enter all values`;
                fieldsCheck1.isError = true;
                errors1.roadTransporterDetails.error = true;
            }
            if (!isValid(r.email)) {
                errors1.roadTransporterDetails.obj[i].email = `Please enter all values`;
                fieldsCheck1.isError = true;
                errors1.roadTransporterDetails.error = true;
            }
            if (isValid(r.contactnum) && (r.contactnum.length > 15 || r.contactnum.length < 7)) {
                errors1.roadTransporterDetails.obj[i].contactnum = `Enter Valid Phone Number (7-15 Digits)`;
                fieldsCheck1.isError = true;
                errors1.roadTransporterDetails.error = true;
                errors1.roadTransporterDetails.contactNumber = `Enter Valid Phone Number (7-15 Digits)`;
            }
            if (isValid(r.email) && !emailRegexPattern.test(r.email.trim())) {
                errors1.roadTransporterDetails.obj[i].email = `Invalid email pattern`;
                fieldsCheck1.isError = true;
                errors1.roadTransporterDetails.error = true;
                errors1.roadTransporterDetails.email = `Invalid email`;
            }
        });

        setErrors1(errors1);
        return fieldsCheck1;
    };

    const handleRemoveRow = (index) => {
        const newRows = rows.filter((row, i) => i !== index);
        setRows(newRows);
        setVendor((prevVendor) => {
            const newVendorState = { ...prevVendor };
            let resetCustomBroker = true;
            let resetRoadTransporter = true;
            let resetFreightForwarder = true;
            let resetWarehouse = true;
            // Reset all values for customBroker, roadTransporter, freightForwarder, warehouse to 0
            Object.keys(newVendorState).forEach(key => {
                if (['customBroker', 'roadTransporter', 'freightForwarder', 'warehouseOperator'].includes(key)) {
                    newVendorState[key] = 0;
                    delete taskStore.current[key]
                }
            });
            newRows.forEach(row => {
                row.requestedBy.forEach(option => {
                    const vendorKey = optionToVendorKey[option];
                    if (vendorKey) {
                        newVendorState[vendorKey] = 1;
                    }
                    if (vendorKey === 'customBroker') resetCustomBroker = false;
                    if (vendorKey === 'roadTransporter') resetRoadTransporter = false;
                    if (vendorKey === 'freightForwarder') resetFreightForwarder = false;
                    if (vendorKey === 'warehouseOperator') resetWarehouse = false;
                });
            });
            if (resetCustomBroker) {
                setCustomBrokerDetails([{ customercontact: "", department: "", contactnum: "", email: "" }])
                setCustomBrokerEntity('')
            }
            if (resetRoadTransporter) {
                setRoadTransporterDetails([{ customercontact: "", department: "", contactnum: "", email: "" }])
                setRoadTransporterEntity('')
            }
            if (resetFreightForwarder) {
                setFreightForwarderDetails([{ customercontact: "", department: "", contactnum: "", email: "",function: "", product: [], type: "", location: "" }])
                setFreightForwarderEntity('')
            }
            if (resetWarehouse) {
                setWarehouseDetails([{ customercontact: "", department: "", contactnum: "", email: "" }])
                setWarehouseEntity('')
            }
            return newVendorState;
        });
    };
    const InvisibleIcon = () => <span style={{ visibility: 'hidden' }}>&#9660;</span>;
    const [template,setTemplate]= useState('')

    return (<><div className="box" >
        {modalIsOpen && <DocumentViewer
            documentTitle={fileName}
            open={modalIsOpen}
            onClose={closeModal}
            documentUrl={fileUrl}
        />}
        <table style={{ width: '100%', borderCollapse: 'collapse' }} >
            <thead>
                <tr>
                    <th style={{ textAlign: 'left', padding: '10px',width:'20%' }}>Document Name&nbsp;<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></th>
                    <th style={{ textAlign: 'left', padding: '10px',width:'20%' }}>Template</th>
                    <th style={{ textAlign: 'left', padding: '10px',width:'20%' }}>Special Remarks</th>
                    <th style={{ textAlign: 'left', padding: '10px',width:'20%' }}>Document Type</th>
                    <th style={{ textAlign: 'left', padding: '10px',width:'20%' }}>Mail to be Sent</th>
                    <th style={{ textAlign: 'left', padding: '10px',width:'20%' }}>Requested by&nbsp;<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></th>
                    <th style={{ padding: '10px',width:'5%' }}></th>
                </tr>
            </thead>
            {
                rows.map((row, index) => (
                    <tr key={index}>
                        <td style={{ padding: '10px',width:'20%' }}>
                            <Autocomplete
                                freeSolo
                                options={documentType}
                                value={row.documentType || ''}
                                inputValue={row.inputValue || ''}
                                onInputChange={(event, newInputValue) => {
                                    if (newInputValue.length <= 100) {
                                        setInputValue(newInputValue);
                                        setValue(newInputValue);
                                        const updatedRows = rows.map((r, i) => {
                                            if (i === index) {
                                                return {
                                                    ...r,
                                                    inputValue: newInputValue,
                                                    documentType: newInputValue,
                                                    errors: { ...r.errors, documentType: false }
                                                };
                                            }
                                            return r;
                                        });
                                        setRows(updatedRows);
                                    } else {
                                        row.errors.documentType = true
                                    }
                                }}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        setValue(newValue);
                                    }
                                    const updatedRows = rows.map((r, i) => {
                                        if (i === index) {
                                            return {
                                                ...r,
                                                documentType: newValue,
                                                errors: { ...r.errors, documentType: false }
                                            };
                                        }
                                        return r;
                                    });
                                    setRows(updatedRows);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{
                                            width: '200.36px',
                                            height: '10px',
                                            '& .MuiOutlinedInput-root': {
                                                padding: '2px'
                                            }
                                        }}
                                        placeholder="Enter Document Type"
                                        helperText={row.errors.documentType ? 'This field is required and less than 100 characters ' : ''}
                                        error={row.errors.documentType}
                                        {...params}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </td>
                        <td style={{ padding: '10px',width:'20%' }}>
                            {row?.template ? <> <a title={row.template} style={{maxWidth: '175px',whiteSpace: 'normal',overflow:'hidden',display:'inline-block',textOverflow:'ellipsis'}} onClick={() => openModal(server + '/api/v1/filestore/getfile/' + taskStore.current.bookingNum + '/' + row.template,row.template)} > {row.template}</a>
                                &nbsp;<button class="button is-danger size-8" onClick={() => {
                                    row.template = ""
                                    setTemplate('')
                                }}><i class="fa fa-trash" aria-hidden="true"></i></button></>
                                : <Agmongofile label={"template"} filekey={taskStore.current.bookingNum} parent={('template').replaceAll(" ", "") + "___" + index} callback={(data) => {
                                    row.template = data.key.filename
                                    setTemplate(data.key.filename)
                                }} />}
                        </td>
                        <td style={{ padding: '10px',width:'20%' }}>
                            <input
                                type="text"
                                className="input is-normal"
                                name="specialRemarks"
                                placeholder="Special Remarks"
                                value={row.specialRemarks}
                                onChange={(event) => handleInputChange(index, event)}
                                style={{ marginRight: '10px' }}
                            />
                        </td>
                        <td style={{ padding: '10px', width: '20%' }}>
                            <FormControl sx={{ minWidth: 120 }} size="small">
                                <Select
                                    displayEmpty
                                    error={row.errors.documentName}
                                    name={'documentCategory'}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    value={row.documentCategory}
                                    onChange={(event) => {
                                        handleInputChange(index, event) 
                                        row.errors.documentName = false
                                    }}                                    >
                                    <MenuItem value="">Select</MenuItem>
                                    <MenuItem value={'Booking'}>Booking</MenuItem>
                                    <MenuItem value={'Entity'}>Entity</MenuItem>
                                </Select>
                                <FormHelperText sx={{ color: 'red' }} >
                                    {row.errors.documentName ? 'This field is required' : ''}
                                </FormHelperText>
                            </FormControl>
                        </td>
                        <td style={{ padding: '10px', width: '20%' }}>
                            <Checkbox name={'sendMail'} defaultChecked={true} onChange={(e)=>{
                                handleCheckBoxChange(index,e)
                            }} />
                        </td>
                        <td style={{ padding: '10px',width:'20%' }}>
                            <FormControl fullWidth>
                                <Select
                                    multiple
                                    displayEmpty
                                    error={row.errors.requestedBy}
                                    value={row.requestedBy && row.requestedBy.length === 0 ? [] : row.requestedBy}
                                    onChange={(event) => handleChange(event, index)}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                          return 'Select an option';
                                        }
                                        return (
                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {selected.map((value) => (
                                              <div key={value} style={{ padding: '4px 0' }}>
                                                {value}
                                              </div>
                                            ))}
                                          </div>
                                        );
                                      }}                                    
                                      SelectDisplayProps={{
                                        style: {
                                            padding: '8px'
                                        }
                                    }}
                                    IconComponent={InvisibleIcon}>
                                    {options.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox checked={
                                                // option === 'All'
                                                //     ? isAllSelected(row.requestedBy)
                                                //     : 
                                                    row.requestedBy.indexOf(option) > -1
                                            } />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText sx={{ color: 'red' }} >
                                    {row.errors.requestedBy ? 'This field is required' : ''}
                                </FormHelperText>
                            </FormControl>
                        </td>
                        <td style={{width:'5%',padding:'10px'}}>
                            <span className=" icon is-normal">
                                <BiPlus
                                    className="is-clickable fas fa-lg"
                                    style={{ color: "red" }}
                                    onClick={handleAddRow}
                                />
                            </span>
                            {
                                rows.length > 1 && (<span className=" icon is-medium">
                                    <MdDelete
                                        className="is-clickable fas fa-lg"
                                        onClick={() => handleRemoveRow(index)}
                                    />
                                </span>)
                            }
                        </td>
                    </tr>
                ))
            }
        </table>
    </div>

        <div>
            {vendor['customBroker'] > 0 || vendor['roadTransporter'] > 0 || vendor['warehouseOperator'] > 0 || vendor['freightForwarder'] > 0 ? <p style={{ fontWeight: 'bold', fontSize: '30px' }}>Vendor Details</p> : <></>}
            {vendor['customBroker'] > 0 ? <> <div>
                <p style={{ fontWeight: 'bold', color: 'darkblue', fontSize: '18px' }}>Customs Broker</p>
                <label><b>Vendor Selection&nbsp;<span style={{ color: 'red', fontWeight: 'bold' }}>*</span> :</b></label>
                <div className={`select is-small ${errors1?.customBrokerDetails?.error?'is-danger':''} `}                >
                <select
                    value={customBrokerEntity}
                    style={{ marginLeft: '20px',maxWidth: '200px', whiteSpace: 'normal', wordWrap: 'break-word' }}
                    onChange={(event) => {
                        const selectedValue = event.target.value
                        setCustomBrokerEntity(selectedValue);
                        const selectedVendor = vendorDetails.find(
                            (e) => e.entityId === selectedValue
                        );
                        if (selectedVendor) {
                            const updated = selectedVendor.logisticInformation && selectedVendor.logisticInformation.map(item => ({
                                ...item,
                                disable: item.customercontact === '' ? false : true
                            }));
                            setCustomBrokerDetails(updated || [{ customercontact: "", department: "", contactnum: "", email: "" }]);
                        } else {
                            setCustomBrokerDetails([{ customercontact: "", department: "", contactnum: "", email: "" }]); // Clear details if no match is found
                        }
                    }}>                
                    <option value="">Select Vendor</option>
                    {vendorDetails.map((e) =>
                        e.vendor.vendorType === 'Customs Broker' ? (
                            <option key={e.entityId} value={e.entityId}>
                                {e.entityName}
                            </option>
                        ) : null
                    )}
                </select>
                {
                    errors1?.customBrokerDetails?.error&&(<p style={{color:'red',fontSize:'.75rem',paddingLeft:'24px'}}>{errors1?.customBrokerDetails?.entity}</p>)
                }</div>

                <label style={{marginLeft:'30px'}}><b>Vendor Ref Number :</b></label>
                <input type="text" maxLength={50} onChange={(e)=>handleVendorRefNumChange('customBrokerRefNum',e.target.value)} style={{marginLeft:'20px',height:'30px'}}/>
            </div>
                <div>
                    <div className="is-flex pt-4 ">
                        <table className="table_caf_fin wd_80">
                            <thead>
                                <td>Contact Person</td>
                                <td>Description/Department</td>
                                <td>Contact Number</td>
                                <td>Email</td>
                                <td></td>
                            </thead>
                            <tbody>
                                {customBrokerDetails?.map((r, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>
                                                <input
                                                    className={`inp_table_caf is-size-6`}
                                                    value={r.customercontact}
                                                    disabled={r.disable}
                                                    onChange={(e) => {
                                                        handleChange2(e, i, "customercontact", "customsBroker")
                                                    }
                                                    }
                                                ></input>
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className={`inp_table_caf  is-size-6`}
                                                    value={r.department}
                                                    disabled={r.disable}
                                                    onChange={(e) =>
                                                        handleChange2(e, i, "department", "customsBroker")
                                                    }
                                                ></input>
                                            </td>
                                            <td
                                                style={{ borderRight: "none" }}
                                                className={`is-flex wd_101`}>
                                                <span className="contNumcodespn mt-1"></span>
                                                <input
                                                    type="number"
                                                    className={`inp_table_caf input is-small out_none_inp`}
                                                    value={r.contactnum}
                                                    disabled={r.disable}
                                                    onChange={(e) =>
                                                        handleChange2(e, i, "contactnum", "customsBroker")
                                                    }
                                                    onKeyDown={(e) =>
                                                        ["e", "E", "+", "-", "."].includes(e.key) &&
                                                        e.preventDefault()
                                                    }
                                                    min={0}></input>
                                            </td>
                                            <td style={{ border: "1px solid black" }}>
                                                <input
                                                    className={`inp_table_caf is-size-6 `}
                                                    disabled={r.disable}
                                                    value={r.email}
                                                    onChange={(e) => handleChange2(e, i, "email", "customsBroker")}
                                                ></input>
                                            </td>
                                            <td>
                                                {true &&
                                                    customBrokerDetails.length ==
                                                    Math.abs(i + 1) ? (
                                                    <span className=" icon is-medium">
                                                        <BiPlus
                                                            className="is-clickable fas fa-lg"
                                                            style={{ color: "red" }}
                                                            onClick={() => addNewLogisticrow1('customBroker')}
                                                        />
                                                    </span>
                                                ) : (
                                                    <span className=" icon is-medium"></span>
                                                )}
                                                {r.disable ? <span className="icon is-medium"></span> :
                                                    <span className=" icon is-medium">
                                                        <MdDelete
                                                            className="is-clickable fas fa-lg"
                                                            onClick={() => removeLogisticrow1(i, 'customBroker')}
                                                        // disabled={!isNewMode()}
                                                        // disabled={!v}
                                                        />
                                                    </span>}
                                            </td>

                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    {errors1.customBrokerDetails?.error && (
                        <p className="help is-input-danger-message">
                            {errors1.customBrokerDetails.contactNumber ||
                                errors1.customBrokerDetails.email
                                ? errors1.customBrokerDetails.contactNumber ||
                                errors1.customBrokerDetails.email
                                : "Please enter all details"}
                        </p>
                    )}
                </div></> : <></>}
            {vendor['roadTransporter'] > 0 ? <> <div>
                <p style={{ fontWeight: 'bold', color: 'darkblue', fontSize: '18px' }}>Road Transporter</p>
                <label><b>Vendor Selection&nbsp;<span style={{ color: 'red', fontWeight: 'bold' }}>*</span> :</b></label>
                <div className={`select is-small ${errors1?.roadTransporterDetails?.error?'is-danger':''} `}                >
                <select
                    name="requestedBy"
                    onChange={(event) => {
                        const selectedValue = event.target.value
                        setRoadTransporterEntity(selectedValue);
                        const selectedVendor = vendorDetails.find(
                            (e) => e.entityId === selectedValue
                        );
                        if (selectedVendor) {
                            const updated = selectedVendor.logisticInformation && selectedVendor.logisticInformation.map(item => ({
                                ...item,
                                disable: item.customercontact === '' ? false : true
                            }));
                            setRoadTransporterDetails(updated || [{ customercontact: "", department: "", contactnum: "", email: "" }]);
                        } else {
                            setRoadTransporterDetails([{ customercontact: "", department: "", contactnum: "", email: "" }]); // Clear details if no match is found
                        }

                    }}
                    style={{ marginLeft: '20px',maxWidth: '200px', whiteSpace: 'normal', wordWrap: 'break-word' }}
                >
                    <option value="">Select Vendor</option>
                    {vendorDetails.map((e) =>
                        e.vendor.vendorType === 'Road Transport' ? (
                            <option key={e.entityId} value={e.entityId}>
                                {e.entityName}
                            </option>
                        ) : null
                    )}
                </select>
                {
                    errors1?.roadTransporterDetails?.error&&(<p style={{color:'red',fontSize:'.75rem',paddingLeft:'24px'}}>{errors1?.roadTransporterDetails?.entity}</p>)
                }
                </div>
                <label style={{marginLeft:'30px'}}><b>Vendor Ref Number :</b></label>
                <input type="text" maxLength={50} onChange={(e)=>handleVendorRefNumChange('roadTransporterRefNum',e.target.value)} style={{marginLeft:'20px',height:'30px'}}/>
            </div><div>
                    <div className="is-flex pt-4 ">
                        <table className="table_caf_fin wd_80">
                            <thead>
                                <td>Contact Person</td>
                                <td>Description/Department</td>
                                <td>Contact Number</td>
                                <td>Email</td>
                                <td></td>
                            </thead>
                            <tbody>
                                {roadTransporterDetails?.map((r, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>
                                                <input
                                                    className={`inp_table_caf is-size-6`}
                                                    value={r.customercontact}
                                                    disabled={r.disable}
                                                    onChange={(e) =>
                                                        handleChange2(e, i, "customercontact", "roadTransport")
                                                    }
                                                ></input>
                                            </td>
                                            <td>
                                                <input
                                                    className={`inp_table_caf input is-size-6`}
                                                    value={r.department}
                                                    disabled={r.disable}
                                                    onChange={(e) =>
                                                        handleChange2(e, i, "department", "roadTransport")
                                                    }
                                                ></input>
                                            </td>
                                            <td
                                                style={{ borderRight: "none" }}
                                                className={`is-flex wd_101`}>
                                                <span className="contNumcodespn mt-1"></span>
                                                <input
                                                    type="number"
                                                    className={`inp_table_caf input is-small out_none_inp`}
                                                    value={r.contactnum}
                                                    onChange={(e) =>
                                                        handleChange2(e, i, "contactnum", "roadTransport")
                                                    }
                                                    disabled={r.disable}
                                                    onKeyDown={(e) =>
                                                        ["e", "E", "+", "-", "."].includes(e.key) &&
                                                        e.preventDefault()
                                                    }
                                                    min={0}></input>
                                            </td>
                                            <td style={{ border: "1px solid black" }}>
                                                <input
                                                    className={`inp_table_caf is-size-6 `}
                                                    value={r.email}
                                                    disabled={r.disable}
                                                    onChange={(e) => handleChange2(e, i, "email", "roadTransport")}
                                                ></input>
                                            </td>
                                            <td>
                                                {true &&
                                                    roadTransporterDetails.length ===
                                                    Math.abs(i + 1) ? (
                                                    <span className=" icon is-medium">
                                                        <BiPlus
                                                            className="is-clickable fas fa-lg"
                                                            style={{ color: "red" }}
                                                            onClick={() => addNewLogisticrow1('roadTransport')}
                                                        />
                                                    </span>
                                                ) : (
                                                    <span className=" icon is-medium"></span>
                                                )}
                                                {r.disable ? <span className="icon is-medium"></span> :
                                                    <span className=" icon is-medium">
                                                        <MdDelete
                                                            className="is-clickable fas fa-lg"
                                                            onClick={() => removeLogisticrow1(i, 'roadTransport')}
                                                        />
                                                    </span>}
                                            </td>

                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    {errors1.roadTransporterDetails?.error && (
                        <p className="help is-input-danger-message">
                            {errors1.roadTransporterDetails.contactNumber ||
                                errors1.roadTransporterDetails.email
                                ? errors1.roadTransporterDetails.contactNumber ||
                                errors1.roadTransporterDetails.email
                                : "Please enter all details"}
                        </p>
                    )}
                </div></> : <></>}
            {vendor['warehouseOperator'] > 0 ? <> <div>
                <p style={{ fontWeight: 'bold', color: 'darkblue', fontSize: '18px' }}>Warehouse Operator</p>
                <label><b>Vendor Selection&nbsp;<span style={{ color: 'red', fontWeight: 'bold' }}>*</span> :</b></label>
                <div className={`select is-small ${errors1?.warehouseDetails?.error?'is-danger':''} `}                >
                <select
                    // value={row.requestedBy}
                    onChange={(event) => {
                        const selectedValue = event.target.value
                        setWarehouseEntity(selectedValue);
                        const selectedVendor = vendorDetails.find(
                            (e) => e.entityId === selectedValue
                        );
                        if (selectedVendor) {
                            const updated = selectedVendor.logisticInformation && selectedVendor.logisticInformation.map(item => ({
                                ...item,
                                disable: item.customercontact === '' ? false : true
                            }));
                            setWarehouseDetails(updated || [{ customercontact: "", department: "", contactnum: "", email: "" }]);
                        } else {
                            setWarehouseDetails([{ customercontact: "", department: "", contactnum: "", email: "" }]); // Clear details if no match is found
                        }
                    }}
                    style={{ marginLeft: '20px',maxWidth: '200px', whiteSpace: 'normal', wordWrap: 'break-word' }}
                >
                    <option value="">Select Vendor</option>
                    {vendorDetails.map((e) =>
                        e.vendor.vendorType === 'Warehouse Operator' ? (
                            <option key={e.entityId} value={e.entityId}>
                                {e.entityName}
                            </option>
                        ) : null
                    )}
                </select>
                {
                    errors1?.warehouseDetails?.error&&(<p style={{color:'red',fontSize:'.75rem',paddingLeft:'24px'}}>{errors1?.warehouseDetails?.entity}</p>)
                }
                </div>
                <label style={{marginLeft:'30px'}}><b>Vendor Ref Number :</b></label>
                <input type="text" maxLength={50} onChange={(e)=>handleVendorRefNumChange('warehouseRefNum',e.target.value)} style={{marginLeft:'20px',height:'30px'}}/>

            </div><div>
                    <div className="is-flex pt-4 ">
                        <table className="table_caf_fin wd_80">
                            <thead>
                                <td>Contact Person</td>
                                <td>Description/Department</td>
                                <td>Contact Number</td>
                                <td>Email</td>
                                <td></td>
                            </thead>
                            <tbody>
                                {warehouseDetails?.map((r, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>
                                                <input
                                                    className={`inp_table_caf is-size-6`}
                                                    value={
                                                        r.customercontact
                                                    }
                                                    disabled={r.disable}
                                                    onChange={(e) =>
                                                        handleChange2(e, i, "customercontact", 'warehouseOperator')
                                                    }
                                                ></input>
                                            </td>
                                            <td>
                                                <input
                                                    className={`inp_table_caf input is-size-6`}
                                                    value={r.department}
                                                    disabled={r.disable}
                                                    onChange={(e) =>
                                                        handleChange2(e, i, "department", 'warehouseOperator')
                                                    }
                                                ></input>
                                            </td>
                                            <td
                                                style={{ borderRight: "none" }}
                                                className={`is-flex wd_101`}>
                                                <span className="contNumcodespn mt-1"></span>
                                                <input
                                                    type="number"
                                                    className={`inp_table_caf input is-small out_none_inp`}
                                                    value={r.contactnum}
                                                    disabled={r.disable}
                                                    onChange={(e) =>
                                                        handleChange2(e, i, "contactnum", 'warehouseOperator')
                                                    }
                                                    onKeyDown={(e) =>
                                                        ["e", "E", "+", "-", "."].includes(e.key) &&
                                                        e.preventDefault()
                                                    }
                                                    min={0}></input>
                                            </td>
                                            <td style={{ border: "1px solid black" }}>
                                                <input
                                                    className={`inp_table_caf is-size-6 `}
                                                    value={r.email}
                                                    disabled={r.disable}
                                                    onChange={(e) => handleChange2(e, i, "email", 'warehouseOperator')}
                                                ></input>
                                            </td>
                                            <td>
                                                {true &&
                                                    warehouseDetails.length ==
                                                    Math.abs(i + 1) ? (
                                                    <span className=" icon is-medium">
                                                        <BiPlus
                                                            className="is-clickable fas fa-lg"
                                                            style={{ color: "red" }}
                                                            onClick={() => addNewLogisticrow1('warehouse')}
                                                        />
                                                    </span>
                                                ) : (
                                                    <span className=" icon is-medium"></span>
                                                )}
                                                {r.disable ? <span className="icon is-medium"></span> :
                                                    <span className=" icon is-medium">
                                                        <MdDelete
                                                            className="is-clickable fas fa-lg"
                                                            onClick={() => removeLogisticrow1(i, 'warehouse')}
                                                        />
                                                    </span>}
                                            </td>

                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    {errors1.warehouseDetails?.error && (
                        <p className="help is-input-danger-message">
                            {errors1.warehouseDetails.contactNumber ||
                                errors1.warehouseDetails.email
                                ? errors1.warehouseDetails.contactNumber ||
                                errors1.warehouseDetails.email
                                : "Please enter all details"}
                        </p>
                    )}
                </div></> : <></>}
            {vendor['freightForwarder'] > 0 ? <> <div>
                <p style={{ fontWeight: 'bold', color: 'darkblue', fontSize: '18px' }}>Freight Forwarder</p>
                <label><b>Vendor Selection&nbsp;<span style={{ color: 'red', fontWeight: 'bold' }}>*</span> :</b></label>
                <div className={`select is-small ${errors1?.freightForwarderDetails?.error?'is-danger':''} `}                >
                <select
                    name="requestedBy"
                    onChange={(event) => {
                        const selectedValue = event.target.value
                        setFreightForwarderEntity(selectedValue);
                        const selectedVendor = vendorDetails.find(
                            (e) => e.entityId === selectedValue
                        );
                        if (selectedVendor) {
                            const updated = selectedVendor.logisticInformation && selectedVendor.logisticInformation.map(item => ({
                                ...item,
                                disable: item.customercontact === '' ? false : true
                            }));
                            setFreightForwarderDetails(updated || [{ customercontact: "", department: "", contactnum: "", email: "",function: "", product: [], type: "", location: "" }]);
                        } else {
                            setFreightForwarderDetails([{ customercontact: "", department: "", contactnum: "", email: "",function: "", product: [], type: "", location: "" }]); // Clear details if no match is found
                        }
                        // } else {
                        //     setFreightForwarderDetails([{ customercontact: "", department: "", contactnum: "", email: "" }]); // Clear details if no vendor is selected
                        // }
                    }}
                    style={{ marginLeft: '20px',maxWidth: '200px', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    <option value="">Select Vendor</option>
                    {vendorDetails.map((e) =>
                        e.vendor.vendorType === 'Freight Forwarder' ? (
                            <option key={e.entityName} value={e.entityId}>
                                {e.entityName}
                            </option>
                        ) : null
                    )}
                </select>
                {/* {
                    errors1?.freightForwarderDetails?.error&&(<p style={{color:'red',fontSize:'.75rem',paddingLeft:'24px'}}>{errors1?.freightForwarderDetails?.entity}</p>)
                } */}
                </div>
                <label style={{marginLeft:'30px'}}><b>Vendor Ref Number :</b></label>
                <input type="text" maxLength={50} onChange={(e)=>handleVendorRefNumChange('freightForwarderRefNum',e.target.value)} style={{marginLeft:'20px',height:'30px'}}/>
            </div><div>
                    <div >
                        <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '16px' }}>
                            <thead>
                                <tr>
                                    <td class="table-head3">Contact Person</td>
                                    <td class="table-head3">Description/Department</td>
                                    <td class="table-head3">Function</td>
                                    <td class="table-head3 width-product">Product</td>
                                    <td class="table-head3">Type</td>
                                    <td class="table-head3">Location</td>
                                    <td class="table-head3">Contact Number</td>
                                    <td class="table-head3">Email</td>
                                    <td class="table-head3"></td>
                                </tr>
                            </thead>
                            <tbody>
                                {freightForwarderDetails.map((r, i) => {
                                    return (
                                        <tr key={i}>
                                            <td className="table-cell">
                                                <input
                                                    className="table-inputa"
                                                    value={r.customercontact}
                                                    disabled={r.disable}
                                                    onChange={(e) => handleChange2(e, i, "customercontact", "freightForwarder")}
                                                />
                                            </td>
                                            <td className="table-cell">
                                                <input
                                                    className="table-inputa"
                                                    value={r.department}
                                                    disabled={r.disable}
                                                    onChange={(e) => handleChange2(e, i, "department", "freightForwarder")}
                                                />
                                            </td>
                                            <td className="table-cell">
                                                <select
                                                    className="table-inputb"
                                                    value={r.function}
                                                    disabled={r.disable}
                                                    onChange={(e) => handleChange2(e, i, "function", "freightForwarder")}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Pricing">Pricing</option>
                                                    <option value="Documentation">Documentation</option>
                                                    <option value="Coordination">Coordination</option>
                                                    <option value="Finance">Finance</option>
                                                    <option value="Admin">Admin</option>
                                                </select>
                                            </td>
                                            <td className="custom-cell">
                                                <div
                                                    style={{
                                                        display: 'inline-block',
                                                        width: '100%',
                                                        height:'100%',
                                                        padding: '10px',
                                                        border: '1px solid #aaa',
                                                        borderRadius: '4px',
                                                        cursor: r.disable ? 'not-allowed' : 'pointer',
                                                        backgroundColor: '#f8f8f8',
                                                        position: 'relative',
                                                    }}
                                                    onClick={() => {
                                                        if (!r.disable) {
                                                            toggleDropdown(i); 
                                                        }
                                                    }}
                                                >
                                                    
                                                    {Array.isArray(r.product) && r.product.length > 0 ? r.product.join(', ') : 'Select Product'}

                                                    
                                                    {visibleDropdown === i && (
                                                        <div
                                                            className="product-dropdown-menu"
                                                            style={{
                                                                display: 'block',
                                                                position: 'absolute',
                                                                top: '100%',
                                                                left: '0',
                                                                width: '100%',
                                                                backgroundColor: '#f8f8f8',
                                                                border: '1px solid #aaa',
                                                                zIndex: '1',
                                                                maxHeight: '100px',
                                                                overflowY: 'auto',
                                                            }}
                                                        >
                                                            {['Air', 'LCL', 'FCL'].map((product) => (
                                                                <div key={product} >
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={Array.isArray(r.product) && r.product.includes(product)}
                                                                            onChange={() => toggleProductSelection(i, product)}
                                                                            disabled={r.disable}
                                                                        />
                                                                        {product}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </td>

                                            <td className="table-cell">
                                              <select
                                                className="table-inputb"
                                                value={r.type}
                                                    disabled={r.disable}
                                                onChange={(e) => handleChange2(e, i, "type", "freightForwarder")}
                                              >
                                                <option value="">Select</option>
                                                <option value="Export">Export</option>
                                                <option value="Import">Import</option>
                                                <option value="Both">Both</option>
                                              </select>
                                            </td>
                                            <td className="table-cell">
                                                <input
                                                    className="table-inputa"
                                                    value={r.location}
                                                    disabled={r.disable}
                                                    onChange={(e) => handleChange2(e, i, "location", "freightForwarder")}
                                                />
                                            </td>
                                            <td className="table-cell">
                                                <input
                                                    type="number"
                                                    className="table-inputa"
                                                    value={r.contactnum}
                                                    disabled={r.disable}
                                                    onChange={(e) => handleChange2(e, i, "contactnum", "freightForwarder")}
                                                    onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                                                    min={0}
                                                />
                                            </td>
                                            <td className="table-cell">
                                                <input
                                                    className="table-inputa"
                                                    value={r.email}
                                                    disabled={r.disable}
                                                    onChange={(e) => handleChange2(e, i, "email", "freightForwarder")}
                                                />
                                            </td>
                                            <td className="table-cell">
                                                {true && freightForwarderDetails.length === i + 1 ? (
                                                    <span className="icon is-medium">
                                                        <BiPlus
                                                            className="is-clickable fas fa-lg"
                                                            style={{ color: "red" }}
                                                            onClick={() => addNewLogisticrow1('freightForwarder')}
                                                        />
                                                    </span>
                                                ) : (
                                                    <span className="icon is-medium"></span>
                                                )}
                                                {r.disable ? <span className="icon is-medium"></span> :
                                                    <span className="icon is-medium">
                                                        <MdDelete
                                                            className="is-clickable fas fa-lg"
                                                            onClick={() => removeLogisticrow1(i, 'freightForwarder')}
                                                        />
                                                    </span>}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    {errors1.freightForwarderDetails?.error && (
                        <p className="help is-input-danger-message">
                            {errors1.freightForwarderDetails.contactNumber ||
                                errors1.freightForwarderDetails.email
                                ? errors1.freightForwarderDetails.contactNumber ||
                                errors1.freightForwarderDetails.email
                                : "Please enter all details"}
                        </p>
                    )}
                </div>
            </> : <></>}
        </div>
        <p>
            <span>
                <button style={{ margin: '10px', float: 'inline-end' }} className="button islink"
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'CUSTOMER'
                        let errorObj = { documentType: false, requestedBy: false,documentName:false }
                        rows.map((e) => {
                            e.errors.documentType = e.documentType === '' ? true : false
                            errorObj.documentType = e.documentType === '' ? true : false
                            e.errors.documentName = e.documentType === '' ? true : false
                            errorObj.documentName = e.documentType === '' ? true : false
                            errorObj.requestedBy = e.requestedBy.length === 0 ? true : false
                            e.errors.requestedBy = e.requestedBy.length === 0 ? true : false
                        })
                        taskStore.current.documentRequest = JSON.parse(JSON.stringify(rows));
                        console.log("Checking",JSON.stringify(taskStore.current.documentRequest),"1234567898765",JSON.stringify(rows));
                        const a = await dovalidationcheck1()
                        if (vendor['freightForwarder'] > 0) {
                            taskStore.current.freightForwarder = {
                                entityId: freightForwarderEntity,
                                refNumber:vendorRefNum.freightForwarderRefNum,
                                entityName: vendorDetails.find(e => e.entityId === freightForwarderEntity)?.entityName,
                                information: freightForwarderDetails
                            }
                        }
                        if (vendor['customBroker'] > 0) {
                            taskStore.current.customBroker = {
                                entityId: customBrokerEntity,
                                refNumber:vendorRefNum.customBrokerRefNum,
                                entityName: vendorDetails.find(e => e.entityId === customBrokerEntity)?.entityName,
                                information: customBrokerDetails
                            }
                        }
                        if (vendor['roadTransporter'] > 0) {
                            taskStore.current.roadTransporter = {
                                entityId: roadTransporterEntity,
                                refNumber:vendorRefNum.roadTransporterRefNum,
                                entityName: vendorDetails.find(e => e.entityId === roadTransporterEntity)?.entityName,
                                information: roadTransporterDetails
                            }
                        }
                        if (vendor['warehouseOperator'] > 0) {
                            taskStore.current.warehouseOperator = {
                                entityId: warehouseEntity,
                                refNumber:vendorRefNum.warehouseRefNum,
                                entityName: vendorDetails.find(e => e.entityId === warehouseEntity)?.entityName,
                                information: warehouseDetails
                            }
                        }
                        console.log(taskStore.current,"taskStore.current");
                        if (!a.isError && !errorObj.documentType && !errorObj.requestedBy&&!errorObj.documentName) {
                            Api.updateLogisticsInformation(taskStore.current)
                            Api.sendDocumentRequestCustomerMail(taskStore.current)
                            Api.addDocumentType(taskStore.current)
                            await Taskapi.saveTask(taskStore.current)
                            taskStore.triggerActionVisible = false
                            taskStore.myActionVisible = false
                        }
                    }}>Trigger Action</button>
            </span>
        </p>
    </>)
}


