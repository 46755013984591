
import { useSnapshot } from "valtio"
import { myactionsstore } from "./store-myactions"
import { TableLine } from "./tableline"
import { CnviewModal } from "./cnviewmodal"
import { configStore } from "../state/CommonState"
import { Vviewmodal } from "./vviewmodal"
import { dsrstatic_AirViewDetailsKeys, dsrstatic_DockDockViewDetailsKeys, dsrstatic_DockFactoryViewDetailsKeys, dsrstatic_FactoryDockViewDetailsKeys, dsrstatic_FactoryFactoryViewDetailsKeys, dsrstatic_LCLViewDetailsKeys } from "./dsr_static"
import { Typography } from "@mui/material"
import { useRef, useState } from "react"
import { CustomModal } from "./customModal"
import Utils from "../util/Utils"

export function VisibilityTable(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    let rec = props.dsrRec
    let st = rec.shipmentType
    let sc = rec.shipmentScope
    let obj = []
    let [isDetailsFound,setDetailsFound] = useState(false)
    useSnapshot(myactionsstore)
    useSnapshot(configStore)


    if("FCL (Factory/Factory)"==st){
        obj = dsrstatic_FactoryFactoryViewDetailsKeys
    }
    if("FCL (Dock/Factory)"==st){
        obj = dsrstatic_DockFactoryViewDetailsKeys
    }

    if("FCL (Factory/Dock)"==st){
        obj = dsrstatic_FactoryDockViewDetailsKeys
    }
    if("FCL (Dock/Dock)"==st){
        obj = dsrstatic_DockDockViewDetailsKeys
    }

    if(st && st.toLowerCase().indexOf("lcl") != -1){
        obj = dsrstatic_LCLViewDetailsKeys
    }

    if(st && st.toLowerCase().indexOf("air") != -1){
        obj = dsrstatic_AirViewDetailsKeys
    }
    let key = Object.keys(obj)
    const isNoDetailsFound = (key)=>{
        let isDetailsFound = false;
        key.forEach(e=>{
            if(rec[e]!=undefined && rec[e].length>0){
                isDetailsFound = true;
                return;
            }
        })
        return isDetailsFound ? <></> : <Typography sx={{textAlign:"center", flex:1,fontWeight:"bold", fontFamily:"Figtree",paddingY:"5px"}}>No Details Found </Typography>
    }
    return (
        <>
            <CnviewModal />
            {(myactionsstore.vviewModalVisible) && <Vviewmodal data={configStore.modalvisibledata}/>}
            <CustomModal data={rec} open={isModalOpen} close={closeModal} disable={true} />

            <div className="columns is-multiline" style={{ fontSize: '0.8rem', color: '#555555' }}>
                {
                (rec['containerlist'] != undefined && rec['containerlist'].length>0)?
                <div className="column is-4">
                    <button className="button is-small" onClick={()=>{
                                configStore.modalvisibledata = rec
                        myactionsstore.cnviewModalVisible=true
                        }}  style={{ borderRadius: '5px',background:"#2c358f",color:"white",width:"10rem"}}>Container Details</button>
                </div>:<></>
                }
                {
                (sc != "Port-to-Port" && (st.toLowerCase().indexOf("air") != -1 || st.toLowerCase().indexOf("lcl") != -1 || (st!='FCL (Factory/Factory)' &&  (st=='FCL (Dock/Dock)' || (st!='FCL (Dock/Dock)' && (sc == "Port-to-Door" && st!='FCL (Dock/Factory)') || (sc == "Door-to-Port" && st!='FCL (Factory/Dock)') || (sc == "Door-to-Door" && st=='FCL (Factory/Dock)') || (sc == "Door-to-Door" && st=='FCL (Dock/Factory)'))))))? 
                <div className="column is-4">
                <button className="button is-small" onClick={()=>{
                                configStore.modalvisibledata = rec
                    myactionsstore.vviewModalVisible=true
                }}  style={{ borderRadius: '5px',background:"#2c358f",color:"white",width:"10rem"}}>Vehicle Details</button></div>:<></>
                }
                {(rec?.exportClearance?.length>0 && rec?.importClearance?.length>0)  || (rec?.icegateReqData && rec?.icegateResData && (rec?.icegateReqData?.type=="sbc"|| rec?.icegateReqData?.type=="boe"))? <div className="column is-4">
                    <button className="button is-small" onClick={() => setIsModalOpen(true)} style={{ borderRadius: '5px', background: "#2c358f", color: "white", width: "10rem" }}>Custom Details</button>
                </div> : <></>
                }
                {
                key.map(e=>{
                    if(rec[e]!=undefined && rec[e].length>0){
                            // setDetailsFound(true);
                            return <div className="column is-6">
                                <TableLine label={`${obj[e]}: `} value={rec[e]} />
                            </div>
                        }
                    })
                }
                {/* {rec?.icegateReqData && rec?.icegateReqData?.type=="sbc" && <div>
                        <div>Shipping Bill Date : <strong>{Utils.convertICEGATEDate(rec?.icegateReqData?.date,1)}</strong></div>
                        <div>Shipping Bill Number : <strong>{rec?.icegateReqData?.no}</strong></div>
                        <div>Shipping Bill Status : <strong>{rec?.icegateReqData?.mismatch?"Pending":"Verified"}</strong></div>
                        </div>
                }
                {rec?.icegateReqData && rec?.icegateReqData?.type=="boe" && <div>
                        <div>Bill Of Entry Date : <strong>{Utils.convertICEGATEDate(rec?.icegateReqData?.date,1)}</strong></div>
                        <div>Bill Of Entry Number : <strong>{rec?.icegateReqData?.no}</strong></div>
                        <div>Bill Of Entry Status : <strong>{rec?.icegateReqData?.mismatch?"Pending":"Verified"}</strong></div>
                        </div>
                } */}
                {
                   !rec?.icegateReqData && isNoDetailsFound(key)
                }
                {/* {
                !isDetailsFound && <Typography sx={{textAlign:"center", flex:1,fontWeight:"bold", fontFamily:"Figtree",paddingY:"5px"}}>No Details Found </Typography>
            } */}
            </div>
        </>
    )
}