import { Avatar, Box, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, InputAdornment, MenuItem, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { EntityV2Store, aguserStore, configStore, loginStore, newDashboardState } from '../state/CommonState'
import LabeledSelectField from './LabeledSelectField'
import LabeledTextField from './LabeledTextField'
import { useForm } from 'react-hook-form'
import { EntityTypeV2 } from '../state/Types'
import { styled } from '@mui/system'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import Api from '../state/Api'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Row } from 'reactstrap'
import EntityApi from '../state/entity-api'
import EntityV2Api from '../state/entities-api'
import EntityUtils from '../util/entityUtils'
import TaskIcon from '@mui/icons-material/Task';
import Visibility from '@mui/icons-material/Visibility';
import { CUSTOMER_CATEGORY, ENTITY_STATUS } from '../util/constants'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { useSnapshot } from 'valtio'
import { red } from '@mui/material/colors'
import BranchApi from '../state/branch-api'
import { City, Country, State } from 'country-state-city'
import UsersApis from '../state/users-api'
import { debounce } from 'lodash'


const EntityList = ({ props }) => {


  const [defaultEntityValue, setdefaultEntitytValue] = useState({})
  const [EntityData, setEntityData] = useState({})
  const [stringifyEntitydata , setstringifyEntitydata] = useState({})
  const [scopeofbusiness, setscopeofbusiness] = useState(['Cross Border']);
  const [image, setImage] = useState(null);
  const [uplDocStatus, setuplDocStatus] = useState({
    iecDoc : true,
    panDoc : true,
    gstDoc : true,
    tinDoc : true ,
    taxResDoc : true,
    uidDoc:true
  })
  const [loading, setloading] = useState(false)
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [panAvailable, setPanAvailable] = useState(false)
  const [tinAvailable, setTinAvailable] = useState(false)
  const [gstAvailable, setGstAvailable] = useState(false)
  const [uplDocs , setUplDocs] = useState({})
  const [userRoleType , setuserRoleType] = useState({})
  const [agusersName , setAgusersName] = useState({
    relationShipManager : '',
    customerService : ''
  })

  useSnapshot(EntityV2Store);
  let Entity = EntityV2Store.EntityRec



  const logisticInformation = [
    {
      customercontact: "",
      department: "",
      contactnum: "",
      email: ""
  }
  ]
   useEffect( () => {
    let Entity =
      EntityV2Store.EntityRec
    if (Entity.logisticInformation.length === 0) {
      Entity.logisticInformation = logisticInformation
    }
    // Entity.scopeOfBusiness= ['Cross Border']
    // Entity.contactInformation = contactInformation
    setdefaultEntitytValue({ ...Entity })
    setEntityData(Entity)
    setscopeofbusiness(Entity.scopeOfBusiness)
    setImage(`${process.env.REACT_APP_FILE_SERVER}/api/v1/filestore/getfileNew/${Entity.entityLogo?.pkey}/${Entity.entityLogo?.fileLink}`)
    setStatesFn(Entity)
    getUserName(Entity)

    let userRec = loginStore.userRec.aguserRec
    UsersApis.viewUser(userRec.email , (data)=>  {
      let entityUserDataMainlist = data?.mainEntityList?.filter((x,i) => x.entityId === Entity.entityId) 
      let entityUserData = data?.entityList?.filter((x,i) => x.entityId === Entity.entityId) 
      if(entityUserDataMainlist?.length > 0){
        setuserRoleType(entityUserDataMainlist[0])
      }else if(entityUserData?.length > 0) {
        setuserRoleType(entityUserData[0])
      }
    })
   
    setstringifyEntitydata(JSON.parse(JSON.stringify(Entity)))

  }, [])

  function setStatesFn(EntityData) {
    let country = Country.getAllCountries().filter(
      (x) => x.name === EntityData.entityCountry
    );
    const Statess = State.getStatesOfCountry(country[0]?.isoCode);
    setStates(Statess)

    let stateCode = EntityData.branch[0].state.split('/')[1]
    let cities = City.getCitiesOfState(country[0].isoCode, stateCode)
    setCities(cities)

  }

  const defaultForm = useForm({
    defaultValues: { ...EntityV2Store.EntityRec }
  });

  // console.log(EntityV2Store.EntityRec);

  

  const { control, handleSubmit, reset, setValue, resetField, getValues, setError, clearErrors , formState: { isSubmitSuccessful} , unregister , register } = defaultForm


  const handlechangeSCB = (e) => {
    const value = e.target.value
    setscopeofbusiness(value)
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  function change(files, filetype, i, filekey) {
    const formData = new FormData()
    formData.append("myFile", files[0])
    formData.append("label", filetype)
    formData.append("key", i)
    fetch(`${process.env.REACT_APP_FILE_SERVER}/api/v1/filestore/storefile`, {
      method: "POST",
      headers: Api.H1(),
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          if (data.error) {
            configStore.setModalMessage(data.error)
          }
        } else {
          if (filetype === 'gstCert') {
            setValue('branch[0].documents[1].pkey', data.key.pkey)
            setValue('branch[0].documents[1].fileLink', data.key.filename)
            setValue('documents[2].pkey', data.key.pkey)
            setValue('documents[2].fileLink', data.key.filename)
            Entity.documents[2].pkey = data.key.pkey
            Entity.documents[2].fileLink = data.key.filename
            Entity.branch[0].documents[1].pkey = data.key.pkey
            Entity.branch[0].documents[1].fileLink = data.key.filename
            // setuplDocStatus(true)
            // uplDocs.gstCert = true
            // setUplDocs(uplDocs)
            uplDocStatus.gstDoc = true
            setuplDocStatus(uplDocStatus)

          } else if (filetype === 'tinCert') {
            setValue('documents[4].pkey', data.key.pkey)
            setValue('documents[4].fileLink', data.key.filename)
            Entity.documents[4].pkey = data.key.pkey
            Entity.documents[4].fileLink = data.key.filename
            // setuplDocStatus(true)
            uplDocStatus.tinDoc = true
            setuplDocStatus(uplDocStatus)

          }else if (filetype === 'taxresCert') {
            setValue('documents[5].pkey', data.key.pkey)
            setValue('documents[5].fileLink', data.key.filename)
            Entity.documents[5].pkey = data.key.pkey
            Entity.documents[5].fileLink = data.key.filename
            // setuplDocStatus(true)
            uplDocStatus.taxResDoc = true
            setuplDocStatus(uplDocStatus)

          } else if (filetype === 'UID') {
            setValue('documents[7].pkey', data.key.pkey)
            setValue('documents[7].fileLink', data.key.filename)
            setValue('branch[0].documents[0].pkey', data.key.pkey)
            setValue('branch[0].documents[0].fileLink', data.key.filename)
            Entity.documents[7].pkey = data.key.pkey
            Entity.documents[7].fileLink = data.key.filename
            Entity.branch[0].documents[0].pkey = data.key.pkey
            Entity.branch[0].documents[0].fileLink = data.key.filename
            // setuplDocStatus(true)
            uplDocStatus.uidDoc = true
            setuplDocStatus(uplDocStatus)


          }  else if (filetype === 'panCard') {
            setValue('documents[1].pkey', data.key.pkey)
            setValue('documents[1].fileLink', data.key.filename)
            Entity.documents[1].pkey = data.key.pkey
            Entity.documents[1].fileLink = data.key.filename
            // setuplDocStatus(true)
            uplDocStatus.panDoc = true
            setuplDocStatus(uplDocStatus)

          } else if (filetype === 'IEC CODE') {
            setValue('documents[0].pkey', data.key.pkey)
            setValue('documents[0].fileLink', data.key.filename)
            Entity.documents[0].pkey = data.key.pkey
            Entity.documents[0].fileLink = data.key.filename
            // setuplDocStatus(true)
            uplDocStatus.iecDoc = true
            setuplDocStatus(uplDocStatus)

          }
  
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleScopeofBusiness = (event) => {
    const index = scopeofbusiness.indexOf(event.target.value)
    if (index === -1) {
      setscopeofbusiness(['Cross Border'])
    } else {
      setscopeofbusiness(scopeofbusiness.filter((x) => x !== event.target.value))
    }
    // return null

  }

  function isUserEntityAdmin () {
    if( userRoleType.role === "Entity Admin"){
      return true
    }
    return false
  }

  function isUserEntityUser () {
    if( userRoleType.role === "Entity User"){
      return true
    }
    return false
  }

  function isUserBranchUser () {
    if( userRoleType.role === "Branch User"){
      return true
    }
    return false
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      console.log(typeof reader, 'rrrr');
      let url = `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/storeImage`
      const formData = new FormData()
      formData.append("myFile", file)
      formData.append("label", "profilePhoto")
      formData.append("key", "profile")
      fetch(url, {
        method: "POST",
        headers: Api.H1(),
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) configStore.setModalMessage(data.error)
          else {
            console.log("Profile pic added", data);
            setValue('entityLogo', data.finalObj)
            Entity.entityLogo = data.finalObj
            // aguserStore.aguserRec.profilePic = data.finalObj
          }
        }).catch((error) => {
          console.error(error)
        })
      reader.onload = () => {
        console.log(typeof reader.result, 'reader.result');
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  function getCities(r) {
    let country = getValues('entityCountry')
    const EntityCountry = Country.getAllCountries().filter(
      (r) => r.name === country
    );
    console.log(r.target.value.split('/')[1]);
    let stateCode = r.target.value.split('/')[1]
    let cities = City.getCitiesOfState(EntityCountry[0].isoCode, stateCode)
    setCities(cities)
  }

  const handleChangeGst = async (values) => {

    let value = values.trim()
    console.log('gst', value);
    setValue('branch[0].gst', value.toUpperCase())

    if (value.length === 15) {
      await BranchApi.checkGst(value.toUpperCase(), (response) => {

        console.log(response);
        if (response.status === true && (response.data.entityId !== getValues('entityId'))) {
          setError('branch[0].gst', { message: 'GST already registered' });
          setGstAvailable(true)
        } else {
          clearErrors("branch[0].gst")
          setGstAvailable(false)
        }

      })
    } else if (value.length < 15) {
      clearErrors("branch[0].gst") 
      setGstAvailable(false)
    }

  }

  const handleChangePan = async (values) => {
    let value = values.trim()
    if (value.length === 10) {
      setValue('panNum', value.toUpperCase())
        await EntityV2Api.checkPan(value.toUpperCase(), (response) => {
            if (response.status === true && (response.data.entityId !== getValues('entityId')) ) {
                setError('panNum', { message: 'PAN already registered' });
                setValue('panNum', value.toUpperCase())
                setPanAvailable(true)

            } else {
                clearErrors("panNum")
                setValue('panNum', value.toUpperCase())
                setPanAvailable(false)
            }
        });

    }else if (value.length < 10) {
      clearErrors("panNum")
      setValue('panNum', value.toUpperCase())
      setPanAvailable(false)
    }else if(value.length > 10 || values.length > 10){
      setValue('panNum', value.substr(0,10).toUpperCase())
      setPanAvailable(false)
    }

   
}

const handleChangeTin = async (values) => {
  let value = values.trim()
  console.log('loko', value);
  if (value.length >= 9 && value.length <= 20) {
      await EntityV2Api.checkTin(value.toUpperCase(), (response) => {
          setValue('tinNum', value.toUpperCase())
          if (response.status === true  && (response.data.entityId !== getValues('entityId')) ) {
              setError('tinNum', { message: 'Tin already registered' });
              setValue('tinNum', value.toUpperCase())
              setTinAvailable(true)

          } else {
              clearErrors("tinNum")
              setValue('tinNum', value.toUpperCase())
              setTinAvailable(false)
          }
      });

  }else if (value.length < 9){
    clearErrors("tinNum")
    setValue('tinNum', value.toUpperCase())
    setTinAvailable(false)
  }else if(value.length > 20 ){
    setValue('tinNum', value.substr(0,20).toUpperCase())
    setTinAvailable(false)
  }

  
}
  const setLogInf = (value) => {
    Entity.logisticInformation[0].contactnum = value

  }


  const addNewBranchAddress = () => {
    const array = [...defaultEntityValue.logisticInformation]
    array.push(logisticInformation[0])
    defaultEntityValue.logisticInformation = array
    setdefaultEntitytValue({ ...defaultEntityValue })
    EntityData.logisticInformation = array
    setEntityData({ ...EntityData })
    let logisticData = getValues(`logisticInformation`)
    logisticData.push(logisticInformation[0])
    setValue('logisticInformation' , logisticData)
  }

  const   removeBranchAddressLine = (i) => {
    const array = [...defaultEntityValue.logisticInformation]
    if (array.length > 1) {
      array.splice(i, 1)
    }
    if(i === 0){
      // clearErrors(`logisticInformation[0].customercontact`)
      // clearErrors(`logisticInformation[0].department`)
      // clearErrors(`logisticInformation[0].contactnum`)
      // clearErrors(`logisticInformation[0].email`)
      unregister(`logisticInformation[0].customercontact`)
      unregister(`logisticInformation[0].department`)
      unregister(`logisticInformation[0].contactnum`)
      unregister(`logisticInformation[0].email`)
    }
    defaultEntityValue.logisticInformation = array
    setdefaultEntitytValue({ ...defaultEntityValue })
    EntityData.logisticInformation = array
    setEntityData({ ...EntityData })
    let logisticData = getValues(`logisticInformation`)
    if(logisticData?.length > 1) {
      logisticData.splice(i,1)
    }
    setValue('logisticInformation' , logisticData)
  
    // resetField(`logisticInformation.${i}.customercontact`)
    // resetField(`logisticInformation.${i}.department`)
    // resetField(`logisticInformation.${i}.contactnum`)
    // resetField(`logisticInformation.${i}.email`)

    // let arrayNew = [...defaultEntityValue.contactInformation]
    // if (arrayNew.length > 1) {
    //   arrayNew.splice(i, 1)
    // }
    // defaultEntityValue.contactInformation = arrayNew
    // setdefaultEntitytValue({ ...defaultEntityValue })
  }

  function isPendingStatus() {
    const status = Entity.status
    return status === ENTITY_STATUS.PENDING
  }

  function isRejectedStatus() {
    const status = Entity.status
    return status === ENTITY_STATUS.REJECTED
  }

  function isDeactivatedStatus() {
    const status = Entity.status
    return status === ENTITY_STATUS.DEACTIVATED
  }
  function isIncompleteStatus() {
    const status = Entity.status
    return status === ENTITY_STATUS.INCOMPLETE
  }
  function isVerifiedStatus() {
    const status = Entity.status
    return status === ENTITY_STATUS.VERIFIED
  }

  function checkLogistics (i) {
    let datas = getValues(`logisticInformation`)
    if(!datas || datas?.length === 0){
      datas = {
        customercontact: "",
        department: "",
        contactnum: "",
        email: ""
    }
    }
    let data = datas[i]
    if(data?.customercontact?.length > 0 || data?.department?.length > 0 || data?.contactnum?.length > 0 || data?.email?.length > 0  || (i > 0 )){
      return true
    }else if (data?.customercontact?.length === 0 && data?.department?.length === 0 && data?.contactnum?.length === 0 && data?.email?.length === 0  &&  i == 0  ){
      return false
    }
    return false
  }

  const deleteDoc = (x, i) => {
    if (x === 'gst' || x === 'uid') {
      Entity.branch[0].documents[i].pkey = ''
      Entity.branch[0].documents[i].fileLink = ''
      setValue(`branch[0].documents[${i}].pkey`, '')
      setValue(`branch[0].documents[${i}].fileLink`, '')
      if(x === 'gst') {
        setValue(`documents[2].pkey`, '')
        setValue(`documents[2].fileLink`, '')
      }
      if(x==='uid'){
        setValue(`documents[7].pkey`, '')
        setValue(`documents[7].fileLink`, '')
      }


    } else {
      Entity.documents[i].pkey = ''
      Entity.documents[i].fileLink = ''
      setValue(`documents[${i}].pkey`, '')
      setValue(`documents[${i}].fileLink`, '')
    }

  }

  const submitHandler = async (data) => {
    //  console.log(data);
    if(!panAvailable && !tinAvailable && !gstAvailable){
      let Data = getValues()
      let uploaded = await docUploaded()
      if(!uploaded  ){
        // setuplDocStatus(false)
        Entity.entityName=''
        Entity.entityName = Data.entityName
    return  configStore.setModalMessage('Please upload all the required documents')
      }
      if(EntityUtils.isIndia(Entity)){
        const extractedPan = EntityUtils.extractPanFromGST(Data.branch[0].gst)
        if(extractedPan !== Data.panNum){
          setError(`branch[0].gst` , { message :'Pan and Gst Mismatch'})
          return 
        }
      }
      if(scopeofbusiness?.length === 0){
        return null
      }
    
      let Checkchanged= await CheckChanges()
      if(!Checkchanged){
        return  configStore.setModalMessage('Please Change any Fields for updating')  
      }else if (Entity.customer.crossBorder.agragaBranch?.length > 0 && Entity.customer?.crossBorder?.relationshipManager?.length > 0 &&  Entity.customer?.crossBorder?.customerService?.length > 0 && (isRejectedStatus() || isIncompleteStatus())) {
        Data.status = ENTITY_STATUS.PENDING
      }
      Data.scopeOfBusiness = scopeofbusiness
      // const checklogisticInformation = await checkLogisticsInformation(Data)
      // if (checklogisticInformation) {
       await  EntityV2Api.updateEntity(Entity.entityId, Data.branch[0].gst, setloading, (Datas) => {
          console.log(Datas);
          if (Datas) {
            props.setmanageEntity(false)
          }
        }, Data)
    }

    
    // }


  }

  async function docUploaded (){
    let iecDoc = getValues('documents[0].fileLink')
    let panDoc = getValues('documents[1].fileLink')
    let gstDoc = getValues('branch[0].documents[1].fileLink')
    let tinDoc = getValues('documents[4].fileLink')
    let taxResDoc = getValues('documents[5].fileLink')
    // let uidDoc = getValues('documents[7].fileLink')
    let uploaded = true
    if(EntityUtils.isIndia(Entity) ){
      let iecuploaded= await checkDocsUplodes('iecDoc', iecDoc)
      let panuploaded= await checkDocsUplodes("panDoc" , panDoc)
     let  gstuploaded= await checkDocsUplodes("gstDoc" , gstDoc)
     if(iecuploaded && panuploaded && gstuploaded ){
      uploaded = true
     }else {
      uploaded = false
     }
      // return false
    }else if ( !EntityUtils.isIndia(Entity)){
     let tinuploaded= await checkDocsUplodes("tinDoc", tinDoc)
     let taxuploaded= await checkDocsUplodes("taxResDoc" , taxResDoc)
    //  let uiduploaded=await checkDocsUplodes("uidDoc", uidDoc)
      if(tinuploaded && taxuploaded  ){
        uploaded = true
       }else {
        uploaded = false
       }
    }
    // return true
    setuplDocStatus(uplDocStatus)
    return uploaded
  }

  function checkDocsUplodes (x , r ) {
    if(r.length === 0){
      uplDocStatus[x] = false
    return    false
    }
   return  true
  }
  async function checkLogisticsInformation(data) {
    let result = true
    const emailRegexPattern =
    /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)+$/
     await data.logisticInformation?.map((x, i) => {
      if ((x.customercontact?.length > 0 || x.department?.length > 0 || x.contactnum?.length > 0 || x.email?.length > 0)) {
        if (!x.customercontact?.length > 0) {
          setError(`logisticInformation.${i}.customercontact` , { message :'Name is required'})
          // return false
          result = false
        }  if (!x.department?.length > 0) {
          setError(`logisticInformation.${i}.department` , { message :'Department is required'})
          // return false
          result = false
        }  if (!x.contactnum?.length > 0) {
          setError(`logisticInformation.${i}.contactnum` , { message :'Contact Num is required'} )
          // return false
          result = false
        } if (!x.email?.length > 0) {
          setError(`logisticInformation.${i}.email ` ,  { message :'Email is required'})
          // return false
          result = false
        } if (x.email?.length > 0 && !emailRegexPattern.test(x.email.trim())) {
          setError(`logisticInformation.${i}.email ` ,  { message :'Invalid email pattern eg abc@gmail.com'})
          // return false
          result = false
        }
        // } else {
        //   return true
        // }

      } else {
        result = true
      }
    })

    return result
  }

  function statusColor() {
    let colorObj = {}
    if (Entity.status === ENTITY_STATUS.PENDING) {
      colorObj.outLineColor = 'warning'
      colorObj.backGroundColor = '#ffe0b3'
    } else if (Entity.status === ENTITY_STATUS.VERIFIED) {
      colorObj.outLineColor = 'success'
      colorObj.backGroundColor = '#b3ffcc'
    } else if (Entity.status === ENTITY_STATUS.REJECTED) {
      colorObj.outLineColor = 'error'
      colorObj.backGroundColor = '#ffb3b3'
    } else if (Entity.status === ENTITY_STATUS.INCOMPLETE) {
      colorObj.outLineColor = 'warning'
      colorObj.backGroundColor = '#ffe0b3'
    }else if (Entity.status === ENTITY_STATUS.DEACTIVATED) {
      colorObj.outLineColor = 'error'
      colorObj.backGroundColor = '#fa7f96'
    }
    return colorObj
  }

  function handleDelete() {
    let objdata = {
      'filename': '',
      'fileLink': '',
      'pkey': '',
    }
    setValue('entityLogo', objdata)
    setImage('')

  }

  async function  getUserName (Entity) {
    let relationshipManagerId =  Entity.customer.crossBorder.relationshipManager
    let customerServiceId =  Entity.customer.crossBorder.customerService
  // await Promise.all(await UsersApis.viewAgragaUser(id, (data)=> {
  //   name = `${data.data.firstName}  ${data.data.lastName} ` 
  //   setValue('')
  //   // console.log(data);
  //  }))
  //  return name
 
  let datas = {
    relationshipManager: '',
    customerService : ''
  }

  if ( relationshipManagerId?.length > 0 ){
 UsersApis.viewAgragaUser(relationshipManagerId, (data)=> {
  datas.relationShipManager = `${data.data.firstName}  ${data.data.lastName} `
   })
  }
  if( customerServiceId?.length>0 ){
   UsersApis.viewAgragaUser(customerServiceId, (data)=> {
    datas.customerService = `${data.data.firstName}  ${data.data.lastName} `
    setAgusersName(datas)
  })
 }
}

  const CheckChanges = () => {
    console.log(  JSON.stringify(getValues()) , JSON.stringify(stringifyEntitydata));
    if (
    JSON.stringify(getValues()) !== JSON.stringify(stringifyEntitydata)){
      return true
    }
    return false
  }

  // console.log('mkmkmk', getValues() , isSubmitSuccessful , loginStore.userRec.aguserRec);
  // console.log('customerbh',CUSTOMER_CATEGORY)

  return (
    // <Box  sx={ { height: "100%"} }>
    <Box sx={{
      flex: 1, backgroundColor: "white", borderRadius: "10px", display: "flex", flexDirection: "column", padding: "10px", height: "100%", width: "100%" ,
    
  }}>

      <Stack direction='row' sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }} >{Entity.entityName}</Typography>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>Status :  <Button variant='outlined' color={statusColor().outLineColor} style={{ backgroundColor: statusColor().backGroundColor }} >{Entity.status}</Button></Typography>
      </Stack>
      <Typography variant="h6" sx={{ fontWeight: 500 }} >Entity Details :</Typography>
      <form onSubmit={handleSubmit(submitHandler)} style={{ padding: '1%' }}   >

        <Stack direction='row' spacing={2} pb={3} sx={{ display: 'flex', justifyContent: "space-between" }}>
          <LabeledTextField rules={{ required: "Name is required" }} width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Entity Name*" control={control} name="entityName" disabled = {isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser() || isDeactivatedStatus()}/>
          <LabeledSelectField rules={{ required: "Category is required" }} width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Entity Category*" control={control} name="customer.customerType" options={Object.values(CUSTOMER_CATEGORY)} disabled = {isPendingStatus() ||  isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()}/>
          {/* <LabeledTextField rules={{  }} width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Entity Industry" control={control} name="entityIndustry" /> */}
          <Box width="100%"></Box>
        </Stack>
        <Stack direction='row' spacing={2} pb={3}>

          <LabeledSelectField rules={{ required: "Country is required" }} width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Entity Country*" control={control} name="entityCountry" options={Country.getAllCountries()} disabled = {true}/>
          {EntityUtils.isIndia(Entity) ? <>
            <LabeledTextField rules={{ required: "Pan Number is required" , validate : ()=> panAvailable === false || "Pan Already Exist" }} width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="PAN Number*" control={control} name="panNum" disabled = {isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()} callback={handleChangePan} />
          </> :
            <>
              <LabeledTextField rules={{ required: "Tin Number is required", validate : ()=> tinAvailable === false || "TIN Already Exist"}} width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="TIN Number*" control={control} name="tinNum" disabled = {isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()} callback={debounce((r)=>handleChangeTin(r), 500 )} />
            </>}


          <Stack direction='column' sx={{ width: '100%' }}>
            {EntityUtils.isIndia(Entity) && getValues('documents[1].fileLink')?.length === 0 ? <>
              {/* {uplDocStatus.panDoc &&
                <Typography variant="h7" sx={{ fontWeight: 500 }} width={300} >Upload PAN document*</Typography>
              } */}

              {!uplDocStatus.panDoc ?
                <Typography variant="h7" sx={{ fontWeight: 500, color: 'red' , width : '100%' }}  >Upload PAN document*</Typography>:
                <Typography variant="h7" sx={{ fontWeight: 500 , width : '100%'  }} >Upload PAN document*</Typography>
              }
              <Button
                component="label"
                tabIndex={-1}
                startIcon={<CloudUploadIcon sx={{ color : !uplDocStatus.panDoc ?'red' : '#2C358A'}} />}
                disabled = { isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()}
                sx={{
                  border: !uplDocStatus.panDoc ?'1px dashed red' : '1px dashed #2C358A' ,
                  padding: "5px 70px",
                  color: !uplDocStatus.panDoc ? 'red': '#2C358A',
                }}

              >
                Upload file
                <VisuallyHiddenInput type="file"
                  onChange={(e1) => {
                    change(e1.target.files, "panCard", getValues('panNum') || 'panDocs')
                    // clearErrorMessages("gst")
                  }}
                />
              </Button>
            </> :
              null
            }
            {!EntityUtils.isIndia(Entity) && getValues('documents[4].fileLink')?.length === 0 ?
              <>
                {/* {uplDocStatus &&
                  <Typography variant="h7" sx={{ fontWeight: 500 }} width={300} >Upload TIN document*</Typography>
                } */}

                {!uplDocStatus.tinDoc ?
                  <Typography variant="h7" sx={{ fontWeight: 500, color: 'red' }} width={300} >Upload TIN document*</Typography>:
                  <Typography variant="h7" sx={{ fontWeight: 500 }} width={300} >Upload TIN document*</Typography>
                }
                <Button
                  component="label"
                  tabIndex={-1}
                  disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()}
                  startIcon={<CloudUploadIcon sx={{ color : !uplDocStatus.tinDoc ?'red' : '#2C358A'}}  />}
                  sx={{
                    border: !uplDocStatus.tinDoc ?'1px dashed red' : '1px dashed #2C358A',
                    padding: "5px 70px",
                    color: !uplDocStatus.tinDoc ? 'red': '#2C358A',
                  }}
                >
                  Upload file
                  <VisuallyHiddenInput type="file"
                    onChange={(e1) => {
                      change(e1.target.files, "tinCert", getValues('tinNum') || 'tinDocs')
                      // clearErrorMessages("gst")
                    }}
                  />
                </Button>
              </> :
              null
            }
            {
              EntityUtils.isIndia(Entity) && getValues('documents[1].fileLink')?.length > 0 ? <>
                <Stack>
                  <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}>Uploaded PAN document</Typography>
                  <TextField size='small' color='primary' variant="outlined" disabled='true' sx={{ background: "#fafafa" }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">   <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >

                        <TaskIcon />
                        <Typography variant="caption" sx={{ maxWidth: 200 }} > {getValues('documents[1].fileLink')}</Typography>

                      </IconButton></InputAdornment>,
                      endAdornment: <InputAdornment position="end">   <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => window.open(`${process.env.REACT_APP_FILE_SERVER + "/api/v1/filestore/getfile/" + getValues('documents[1].pkey') + "/" + getValues('documents[1].fileLink')}?w=164&h=164&fit=crop&auto=format}`)}
                      >
                        <Visibility />
                      </IconButton>
                        {(!isPendingStatus() && !isVerifiedStatus() && !isDeactivatedStatus()) &&
                          <IconButton color='error' disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()}  onClick={() => deleteDoc('panCard', 1)}>
                            <DisabledByDefaultIcon />
                          </IconButton>

                        }
                      </InputAdornment>,
                    }}
                  ></TextField>

                </Stack>
              </>
                : null
            }
            {
              !EntityUtils.isIndia(Entity) && getValues('documents[4].fileLink')?.length > 0 ? <>
                <Stack>
                  <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}>Uploaded TIN document</Typography>
                  <TextField size='small' color='primary' variant="outlined" disabled='true' sx={{ background: "#fafafa" }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">   <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >

                        <TaskIcon />
                        <Typography variant="caption" sx={{ maxWidth: 200 }} > {getValues('documents[4].fileLink')}</Typography>

                      </IconButton></InputAdornment>,
                      endAdornment: <InputAdornment position="end">   <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => window.open(`${process.env.REACT_APP_FILE_SERVER + "/api/v1/filestore/getfile/" + getValues('documents[4].pkey') + "/" + getValues('documents[4].fileLink')}?w=164&h=164&fit=crop&auto=format}`)}
                      >
                        <Visibility />
                      </IconButton>
                        {(!isPendingStatus() && !isVerifiedStatus()) &&
                          <IconButton color='error' disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()} onClick={() => deleteDoc('tinCert', 4)}>
                            <DisabledByDefaultIcon />
                          </IconButton>

                        }
                      </InputAdornment>,
                    }}
                  ></TextField>

                </Stack>
              </>
                : null
            }
            {/* <Typography variant="h7" sx={{ fontWeight: 500 }} >Upload PAN document*</Typography>
                    <Button
                      component="label"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      sx={{
                        border: '1px dashed blue',
                        padding : "5px 30px"   ,
                        // marginLeft : '10%',
                        // width : '50%',
                      }}
                    >
                      Upload file
                      <VisuallyHiddenInput type="file" 
                      onChange={(e1) => {
                              change(e1.target.files, "gstCert", "gstdocs")
                              // clearErrorMessages("gst")
                            }}
                       />
                    </Button> */}
          </Stack>
          
        </Stack>
        <Stack direction='row' spacing={2} pb={3}>
        <Stack direction='column' sx={{ width: '33%' }}>
          {!EntityUtils.isIndia(Entity) &&   getValues('documents[5].fileLink')?.length === 0 ?
              <>
                {/* {uplDocStatus &&
                  <Typography variant="h7" sx={{ fontWeight: 500 }} width={300} >Upload TAX Residency Certificate*</Typography>
                } */}

                {!uplDocStatus.taxResDoc ?
                  <Typography variant="h7" sx={{ fontWeight: 500, color: 'red' }} width={300} >Upload TAX Residency Certificate*</Typography> :
                  <Typography variant="h7" sx={{ fontWeight: 500 }} width={300} >Upload TAX Residency Certificate*</Typography>
                }
                <Button
                  component="label"
                  tabIndex={-1}
                  disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser() || isDeactivatedStatus()}
                  startIcon={<CloudUploadIcon sx={{ color : !uplDocStatus.taxResDoc ?'red' : '#2C358A'}}/>}
                  sx={{
                    border:!uplDocStatus.taxResDoc ?'1px dashed red' : '1px dashed #2C358A',
                    padding: "5px 70px",
                    color: !uplDocStatus.taxResDoc ? 'red': '#2C358A',

                  }}
                >
                  Upload file
                  <VisuallyHiddenInput type="file"
                    onChange={(e1) => {
                      change(e1.target.files, "taxresCert", getValues('tinNum') || 'tinDocs')
                      // clearErrorMessages("gst")
                    }}
                  />
                </Button>
              </> :
              null
          }

          {
              !EntityUtils.isIndia(Entity) && getValues('documents[5].fileLink')?.length > 0 ? <>
                <Stack>
                  <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}>Uploaded TAX Residency document</Typography>
                  <TextField size='small' color='primary' variant="outlined" disabled='true' sx={{ background: "#fafafa" }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">   <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >

                        <TaskIcon />
                        <Typography variant="caption" sx={{ maxWidth: 200 }} > {getValues('documents[5].fileLink')}</Typography>

                      </IconButton></InputAdornment>,
                      endAdornment: <InputAdornment position="end">   <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => window.open(`${process.env.REACT_APP_FILE_SERVER + "/api/v1/filestore/getfile/" + getValues('documents[5].pkey') + "/" + getValues('documents[5].fileLink')}?w=164&h=164&fit=crop&auto=format}`)}
                      >
                        <Visibility />
                      </IconButton>
                        {(!isPendingStatus() && !isVerifiedStatus()) &&
                          <IconButton color='error'  disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser() || isDeactivatedStatus()} onClick={() => deleteDoc('taxresCert', 5)}>
                            <DisabledByDefaultIcon />
                          </IconButton>

                        }
                      </InputAdornment>,
                    }}
                  ></TextField>

                </Stack>
              </>
                : null
            }
          
            </Stack>
          </Stack>
        <Stack direction='row' spacing={2} pb={3}>
          <Stack width='100%'>
            <FormControl>
              <FormLabel>Scope of Business*</FormLabel>
              <FormGroup sx={{ display: 'flex', flexDirection: 'row' }} className='Mui-required' >
                <FormControlLabel label='Cross Border' control={<Checkbox value='Cross Border' checked={scopeofbusiness?.includes('Cross Border')} onChange={handleScopeofBusiness}  disabled={!isUserEntityAdmin() || isVerifiedStatus() || isPendingStatus()}/>} />
                {/* <FormControlLabel label='Domestic Transport' control={<Checkbox  value='Domestic Transport' checked={scopeofbusiness.includes('Domestic Transport')} onChange={handleScopeofBusiness}/>} /> */}
              </FormGroup>
            </FormControl>
            {scopeofbusiness?.length === 0 && 
              <Typography color='error'>Please Select Scope of Business</Typography>
            }
     
          </Stack>
          {
            EntityUtils.isIndia(Entity) ? <>
              <LabeledTextField rules={{
                required: "IEC Code is required",
                pattern: {
                  value: /^[A-Za-z0-9]{10}$/,
                  message: "Invalid IEC Code format. Must be 10 characters alphanumeric"
                },
                maxLength: {
                  value: 10,
                  message: "IEC Code should have 10 characters alphanumeric"
                }
              }} width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="IEC Code*" control={control} name="IECCode"   disabled={isPendingStatus()  || isUserEntityUser() || isUserBranchUser()}/>

            </> : null
          }

          <Stack direction='column' sx={{ width: '100%' }}>
            {EntityUtils.isIndia(Entity) && getValues('documents[0].fileLink')?.length === 0 ? <>
              {/* {uplDocStatus &&
                <Typography variant="h7" sx={{ fontWeight: 500 }} >Upload IEC document</Typography>
              } */}

              {!uplDocStatus.iecDoc ?
                <Typography variant="h7" sx={{ fontWeight: 500, color: 'red' }} >Upload IEC document</Typography>:
                <Typography variant="h7" sx={{ fontWeight: 500 }} >Upload IEC document</Typography>
              }

              <Button
                rules = {{ required : getValues('documents[0].fileLink').length == 0 ? "Please Upload IEC Document": ''} }
                component="label"
                // {...(getValues('documents[0].fileLink').length == 0 ? { color :'error'} : null)}
                tabIndex={-1}
                startIcon={<CloudUploadIcon sx={{ color : !uplDocStatus.iecDoc ?'red' : '#2C358A'}} />}
                disabled={isPendingStatus() ||  isUserEntityUser() || isUserBranchUser() || isDeactivatedStatus()}

                sx={{
                  border:   !uplDocStatus.iecDoc ?'1px dashed red' : '1px dashed #2C358A',
                  padding: "5px 30px",
                  color: !uplDocStatus.iecDoc ? 'red': '#2C358A',
                }}
            >
                Upload file
                <VisuallyHiddenInput type="file"
                  onChange={(e1) => {
                    change(e1.target.files, "IEC CODE", getValues('IECCode') || 'IIECODE')
                  }}
                />
              </Button>
            </> : null
            }

            {EntityUtils.isIndia(Entity) && getValues('documents[0].fileLink')?.length > 0 ? <>
              <Stack>
                <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}>Uploaded IEC document</Typography>
                <TextField size='small' color='primary' variant="outlined" disabled='true' sx={{ background: "#fafafa" }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">   <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >

                      <TaskIcon />
                      <Typography variant="caption" sx={{ maxWidth: 200 }} > {getValues('documents[0].fileLink')}</Typography>

                    </IconButton></InputAdornment>,
                    endAdornment: <InputAdornment position="end">   <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => window.open(`${process.env.REACT_APP_FILE_SERVER + "/api/v1/filestore/getfile/" + getValues('documents[0].pkey') + "/" + getValues('documents[0].fileLink')}?w=164&h=164&fit=crop&auto=format}`)}
                    >
                      <Visibility />
                    </IconButton>
                      {(!isPendingStatus() ) &&
                        <IconButton color='error'   disabled={isPendingStatus()  || isUserEntityUser() || isDeactivatedStatus() ||isUserBranchUser()} onClick={() => deleteDoc('iecDoc', 0)}>
                          <DisabledByDefaultIcon />
                        </IconButton>

                      }
                    </InputAdornment>,
                  }}
                ></TextField>

              </Stack>
            </> : null

            }

          </Stack>
        </Stack>

        <Stack direction='column' spacing={2} pb={3}>
          <Typography variant="h6" sx={{ fontWeight: 500 }} >Entity Logo :</Typography>
          <Stack >
            <Box sx={{ width: "100%", marginLeft: '5%', display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Box style={{ marginRight: 50 }}>
                  {image && (
                    <div>
                      <Avatar alt="Uploaded Image" src={image} style={{ width: 100, height: 100 }} />
                      <Typography variant="body2">
                        Size: {(image.length / 1024).toFixed(2)} KB
                      </Typography>
                    </div>
                  )

                  }
                </Box>
                {
                  ((getValues('entityLogo.fileLink')?.length === 0 || getValues('entityLogo.fileLink') === undefined) && ( !isUserEntityUser() &&  !isUserBranchUser() && !isPendingStatus() && !isDeactivatedStatus())) ?
                    <>
                      <Box>
                        <input
                          accept="image/*"
                          id="image-upload"
                          type="file"
                          onChange={handleImageChange}
                          style={{ display: 'none' }}
                        />
                        <label htmlFor="image-upload" >
                          <Button variant="contained"   component="span">
                            Browse
                          </Button>
                        </label>

                      </Box>
                    </> : null
                }

                {
                  (getValues('entityLogo.fileLink')?.length > 0 && ( !isUserEntityUser() &&  !isUserBranchUser() && !isPendingStatus() && !isDeactivatedStatus())) ?
                    <>
                      <Box

                        onClick={() => handleDelete()} // handleDelete function to delete the image
                        sx={{
                          cursor: 'pointer',
                          marginLeft: '10px'
                        }}
                      >
                        <DeleteIcon  />
                      </Box>

                    </> : null
                }

              </Box>
              <Box ></Box>
            </Box>

          </Stack>
        </Stack>

        <Typography variant="h6" sx={{ fontWeight: 500 }} >Branch Details :</Typography>
        <Stack direction='row' spacing={2} pb={3}>
          {
            EntityUtils.isIndia(Entity) ? <>
              <LabeledTextField rules={{
                required: "GST Number is required", pattern: {
                  value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                  message: "Invalid GST format. Eg: 22AAAAA0000A1Z5 or 29ABCDE1234F1ZK"
                },
                maxLength: {
                  value: 15,
                  message: "GST cannot exceed 15 characters"
                },
                validate : ()=> gstAvailable === false || "Gst Already Exist"
              }} width='100%'  disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="GST Number*" control={control} name="branch[0].gst" callback={handleChangeGst} />

            </> : <>
              <LabeledTextField rules={{
                pattern: {
                  value: /^[a-zA-Z0-9 ]{0,20}$/,
                },
                maxLength: {
                  value: 20,
                  message: "UID Name cannot exceed 20 characters"
                }
              }} width='100%'  disabled={isPendingStatus()  || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Unique Identifier Document Name" control={control} name="branch[0].uidName" />

            </>}

          <Stack width="100%">
            {EntityUtils.isIndia(Entity) && (getValues('branch[0].documents[1].fileLink')?.length === 0 ||  getValues('documents[2].fileLink' ).length === 0)? <>
              {/* {uplDocStatus &&
                <Typography variant="h7" sx={{ fontWeight: 500 }} width={300} >Upload Gst document*</Typography>
              } */}

              {!uplDocStatus.gstDoc ?
                <Typography variant="h7" sx={{ fontWeight: 500, color: 'red' }} width={300} >Upload Gst document*</Typography>:
                <Typography variant="h7" sx={{ fontWeight: 500 }} width={300} >Upload Gst document*</Typography>
              }
              <Button
                component="label"
                tabIndex={-1}
                disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()}
                startIcon={<CloudUploadIcon sx={{ color : !uplDocStatus.gstDoc ?'red' : '#2C358A'}} />}
                sx={{
                  border:  !uplDocStatus.gstDoc ?'1px dashed red' : '1px dashed #2C358A',
                  padding: "5px 70px",
                  color: !uplDocStatus.gstDoc ? 'red': '#2C358A',
                }}
              >
                Upload file
                <VisuallyHiddenInput type="file"
                  onChange={(e1) => {
                    change(e1.target.files, "gstCert", "gstdocs")
                    // clearErrorMessages("gst")
                  }}
                />
              </Button>
            </> : null
            }
            {EntityUtils.isIndia(Entity) && (getValues('branch[0].documents[1].fileLink')?.length > 0 && getValues('documents[2].fileLink')?.length > 0) ? <>
              <Stack>
                <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}>Uploaded Gst document</Typography>
                <TextField size='small' color='primary' variant="outlined" disabled='true' sx={{ background: "#fafafa" }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">   <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >

                      <TaskIcon />
                      <Typography variant="caption" sx={{ maxWidth: 200 }} >  {getValues(`branch[0].documents[1].fileLink`)}</Typography>

                    </IconButton></InputAdornment>,
                    endAdornment: <InputAdornment position="end">   <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => window.open(`${process.env.REACT_APP_FILE_SERVER + "/api/v1/filestore/getfile/" + getValues(`branch[0].documents[1].pkey`) + "/" + getValues(`branch[0].documents[1].fileLink`)}?w=164&h=164&fit=crop&auto=format}`)}
                    >
                      <Visibility />
                    </IconButton>
                      {(!isPendingStatus() && !isVerifiedStatus()) &&
                        <IconButton color='error'  disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()} onClick={() => deleteDoc('gst', 1)}>
                          <DisabledByDefaultIcon />
                        </IconButton>

                      }
                    </InputAdornment>,
                  }}
                ></TextField>

              </Stack>
            </> : 
           <></>

            }
            {
             !EntityUtils.isIndia(Entity) ? <>
             <>
              <LabeledTextField rules={{
                pattern: {
                  value: /^[a-zA-Z0-9 ]{0,20}$/,
                },
                maxLength: {
                  value: 20,
                  message: "UID Name cannot exceed 20 characters"
                }
              }} width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Unique Identifier Document Reference Number" control={control} name="branch[0].uidRefNo"  disabled={isPendingStatus()  || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()}/>
            </>
              </>: <></>
            }
          </Stack>
          {
            EntityUtils.isIndia(Entity) ? <>
              <LabeledTextField rules={{ required: "Address is required" }} width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Address*" control={control} name="branch[0].branchAddresses[0].address"  disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()} />
            </> :
              <Stack width="100%">
                {EntityUtils.isIndia(Entity) && getValues('branch[0].documents[1].fileLink')?.length === 0 ? <>
                  {/* {uplDocStatus &&
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300} >Upload Gst document*</Typography>
                  } */}

                  {!uplDocStatus.gstDoc ?
                    <Typography variant="h7" sx={{ fontWeight: 500, color: 'red' }} width={300} >Upload Gst document*</Typography> :
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300} >Upload Gst document*</Typography>
                  }
                  <Button
                    component="label"
                    tabIndex={-1}
                    disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()}
                    startIcon={<CloudUploadIcon sx={{ color : !uplDocStatus.gstDoc ?'red' : '#2C358A'}} />}
                    sx={{
                      border:  !uplDocStatus.gstDoc ?'1px dashed red' : '1px dashed #2C358A',
                      padding: "5px 70px",
                      color: !uplDocStatus.gstDoc ? 'red': '#2C358A',
                    }}
                  >
                    Upload file
                    <VisuallyHiddenInput type="file"
                      onChange={(e1) => {
                        change(e1.target.files, "gstCert", "gstdocs")
                        // clearErrorMessages("gst")
                      }}
                    />
                  </Button>
                </> : null
                }
                {EntityUtils.isIndia(Entity) && getValues('branch[0].documents[1].fileLink')?.length > 0 ? 
                <>
                  <Stack>
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}>Uploaded Gst document</Typography>
                    <TextField size='small' color='primary' variant="outlined" disabled='true' sx={{ background: "#fafafa" }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">   <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >

                          <TaskIcon />
                          <Typography variant="caption" sx={{ maxWidth: 200 }} >  {getValues(`branch[0].documents[1].fileLink`)}</Typography>

                        </IconButton></InputAdornment>,
                        endAdornment: <InputAdornment position="end">   <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={() => window.open(`${process.env.REACT_APP_FILE_SERVER + "/api/v1/filestore/getfile/" + getValues(`branch[0].documents[1].pkey`) + "/" + getValues(`branch[0].documents[1].fileLink`)}?w=164&h=164&fit=crop&auto=format}`)}
                        >
                          <Visibility />
                        </IconButton>
                          {(!isPendingStatus() && !isVerifiedStatus()) &&
                            <IconButton color='error'  disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()} onClick={() => deleteDoc('gst', 1)}>
                              <DisabledByDefaultIcon />
                            </IconButton>

                          }
                        </InputAdornment>,
                      }}
                    ></TextField>

                  </Stack>
                </> : 
              <>
              </>
                }
                {!EntityUtils.isIndia(Entity) && getValues('branch[0].documents[0].fileLink')?.length === 0 ? <>
                  {/* {uplDocStatus &&
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300} >Upload UID document*</Typography>
                  } */}

                  {!uplDocStatus.uidDoc ?
                    <Typography variant="h7" sx={{ fontWeight: 500, color: 'red' }} width={300} >Upload UID document</Typography> :
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300} >Upload UID document</Typography>
                  }
                  <Button
                    component="label"
                    tabIndex={-1}
                    disabled={isPendingStatus() ||  isUserEntityUser() || isUserBranchUser() || isDeactivatedStatus()}
                    startIcon={<CloudUploadIcon sx={{ color : !uplDocStatus.uidDoc ?'red' : '#2C358A'}} />}
                    sx={{
                      border:  !uplDocStatus.uidDoc ?'1px dashed red' : '1px dashed #2C358A',
                      padding: "5px 70px",
                      color: !uplDocStatus.uidDoc ? 'red': '#2C358A',
                    }}
                  >
                    Upload file
                    <VisuallyHiddenInput type="file"
                      onChange={(e1) => {
                        change(e1.target.files, "UID", getValues('uidRefno') || 'uidDocs')
                        // clearErrorMessages("gst")
                      }}
                    />
                  </Button>
                </> : null
                }
                {!EntityUtils.isIndia(Entity) && getValues('branch[0].documents[0].fileLink')?.length > 0 ? 
                <>
                  <Stack>
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}>Uploaded UID document</Typography>
                    <TextField size='small' color='primary' variant="outlined" disabled='true' sx={{ background: "#fafafa" }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">   <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >

                          <TaskIcon />
                          <Typography variant="caption" sx={{ maxWidth: 200 }} >  {getValues(`branch[0].documents[0].fileLink`)}</Typography>

                        </IconButton></InputAdornment>,
                        endAdornment: <InputAdornment position="end">   <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={() => window.open(`${process.env.REACT_APP_FILE_SERVER + "/api/v1/filestore/getfile/" + getValues(`branch[0].documents[0].pkey`) + "/" + getValues(`branch[0].documents[0].fileLink`)}?w=164&h=164&fit=crop&auto=format}`)}
                        >
                          <Visibility />
                        </IconButton>
                          {!isPendingStatus()  &&
                            <IconButton color='error'  disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()} onClick={() => deleteDoc('uid', 0)}>
                              <DisabledByDefaultIcon />
                            </IconButton>

                          }
                        </InputAdornment>,
                      }}
                    ></TextField>

                  </Stack>
                </> : 
              <>
              </>
                }
              </Stack>


          }

        </Stack>
       
       
        {
          !EntityUtils.isIndia(Entity) ? <>
          <Stack direction='row' spacing={2} pb={3}>
          <LabeledTextField rules={{ required: "Address is required" }} width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Address*" control={control} name="branch[0].branchAddresses[0].address"  disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()} />
          <LabeledSelectField rules={{ required: "State is required" }} width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="State*" control={control} name="branch[0].state" callback={(r) => getCities(r)} options={states}  disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()} />
          <LabeledTextField rules={{ required: "City is required" }} width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="City*" control={control} name="branch[0].branchAddresses[0].city"  disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser() || isDeactivatedStatus()} />
          </Stack>
          </>: null
        }
       
        <Stack direction='row' spacing={2} pb={3}>

          {
            EntityUtils.isIndia(Entity)? <>

            <LabeledSelectField rules={{ required: "State is required" }} width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="State*" control={control} name="branch[0].state" callback={(r) => getCities(r)} options={states}   disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()}/>
          <LabeledSelectField rules={{ required: "City is required" }} width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="City*" control={control} name="branch[0].branchAddresses[0].city" options={cities}  disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()} />
          <LabeledTextField rules={{ required: "Pin Code is required" }} width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Pin code" control={control} name="branch[0].branchAddresses[0].pincode"  disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()} />
            </>: <>
            <LabeledTextField rules={{ required: "Zip Code is required" }} width='32.3%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Zip code*" control={control} name="branch[0].branchAddresses[0].zipcode"  disabled={isPendingStatus() || isVerifiedStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()} />
            </>
          }

        </Stack>

        {scopeofbusiness?.includes('Cross Border') && getValues('customer.crossBorder.agragaBranch').length > 0  && (<>

          <Typography variant="h6" sx={{ fontWeight: 500 }} >Agraga Cross Border - Contact Details</Typography>

          <Stack direction='row' spacing={2} pb={3}>
            <LabeledTextField width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Agraga Branch" control={control} name="customer.crossBorder.agragaBranch" disabled />
           {/* <LabeledTextField width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Relationship Manager" control={control} name="customer.crossBorder.relationshipManager" disabled /> */}
           <Box  width= "100%" >
           <Stack width= "100%">
           <Typography  variant="h8" sx={{ fontWeight: 400 , width: '100%' , fontSize : '14px' }}>Relationship Manager</Typography>
          </Stack>
          <Box style = {{fontSize : '14px' , border : "1px solid darkgrey" , borderRadius : "5px" , backGroundColor : '#a6a5a4' }} >
          <Stack width= "100%" style={{ display : 'flex' , flexDirection : 'row'}}>
          <Typography  variant="h8" sx={{ fontWeight: 600 , marginLeft: 2 }}>Name : </Typography>
           <Typography  variant="h8" sx={{ fontWeight: 400 , marginLeft: 2 , opacity : 0.6 }}>{agusersName.relationShipManager}</Typography>
           </Stack>
          <Stack width= "100%" style={{ display : 'flex' , flexDirection : 'row'}}>
          <Typography  variant="h8" sx={{ fontWeight: 600 , marginLeft: 2 }}>Email : </Typography>
           <Typography  variant="h8" sx={{ fontWeight: 400 , marginLeft: 2 , opacity : 0.6}}>{getValues('customer.crossBorder.relationshipManager')}</Typography>
           </Stack>
          
           </Box>
           {/* <TextField  sx={{ fontWeight: 500 , marginLeft : 1  , width : '100%' }} control={control} name="customer.crossBorder.customerService">{aguserStore.getName4email(getValues('customer.crossBorder.relationshipManager'))} </TextField> */}
           </Box>

           <Box  width= "100%" >
           <Stack width= "100%">
           <Typography  variant="h8" sx={{ fontWeight: 400 , width: '100%' , fontSize : '14px' }}>Customer Service</Typography>
          </Stack>
          <Box style = {{fontSize : '14px' , border : "1px solid darkgrey" , borderRadius : "5px" , backGroundColor : '#a6a5a4' }} >
          
            <Stack width= "100%" style={{ display : 'flex' , flexDirection : 'row'}}>
            <Typography  variant="h8" sx={{ fontWeight: 600 , marginLeft: 2 }}>Name : </Typography>
           <Typography  variant="h8" sx={{ fontWeight: 400 , marginLeft: 2  , opacity : 0.6}}>{agusersName.customerService  }</Typography>
           </Stack>
           <Stack width= "100%" style={{ display : 'flex' , flexDirection : 'row'}}>
           <Typography  variant="h8" sx={{ fontWeight: 600 , marginLeft: 2 }}>Email : </Typography>
           <Typography  variant="h8" sx={{ fontWeight: 400 , marginLeft: 2 , opacity : 0.6}}>{getValues('customer.crossBorder.customerService')}</Typography>
           </Stack>
          
           </Box>
           {/* <TextField  sx={{ fontWeight: 500 , marginLeft : 1  , width : '100%' }} control={control} name="customer.crossBorder.customerService">{aguserStore.getName4email(getValues('customer.crossBorder.relationshipManager'))} </TextField> */}
           </Box>

            {/* <LabeledTextField width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Customer Service" control={control} name="customer.crossBorder.customerService" disabled /> */}
          </Stack>
        </>
        )

        }

        {scopeofbusiness?.includes('Domestic Transport') && (<>
          <Typography variant="h6" sx={{ fontWeight: 500 }} >Agraga Domestic Transport - Contact Details</Typography>

          <Stack direction='row' spacing={2} pb={3}>
            <LabeledTextField width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Agraga Branch" control={control} name="customer.domesticTransport.agragaBranch" disabled />
            <LabeledTextField width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Relationship Manager" control={control} name="customer.domesticTransport.relationshipManager" disabled />
            <LabeledTextField width='100%' labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Customer Service" control={control} name="customer.domesticTransport.customerService" disabled />
          </Stack>
        </>
        )

        }



        <Typography variant="h6" sx={{ fontWeight: 500 }} > Contact Information:</Typography>
        <Card pb={0}>
          <CardContent direction='column' pb={0} sx={{ display: 'flex', justifyContent: 'space-between', padding: 1, paddingBottom: 0, background: '#fafafa' }} >
            <Typography variant="h8" sx={{ fontWeight: 600 }}>Contact Person Name </Typography>
            <Typography variant="h8" sx={{ fontWeight: 600 }}> Designation </Typography> 
            <Typography variant="h8" sx={{ fontWeight: 600 }}>Contact Person Number </Typography>
            <Typography variant="h8" sx={{ fontWeight: 600 , width: '20%' }}>Contact Person Email </Typography>
          </CardContent>
        </Card>

        {EntityData.logisticInformation?.map((r, index) => {
          return <>
            <Stack direction='row' spacing={2} sx={{ paddingTop: 2 }}>

              {/* <TextField size='small' variant="outlined" className={styles.root} sx={{ width: '30%' }}></TextField> */}
              {console.log("logistices",checkLogistics(index) , index)}
              {checkLogistics(index) ? 
                <LabeledTextField 
           {...(checkLogistics (index) ? {rules : { required: "Name is required"}} :{ rules: {}})} textfieldStyles={{ background: "#fafafa", width: 300 }} fontSize="14px" control={control} name={`logisticInformation.${index}.customercontact`} callback={ setLogInf}  disabled={isPendingStatus()  || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()} /> :  
                         <LabeledTextField 
            textfieldStyles={{ background: "#fafafa", width: 300 }} fontSize="14px" control={control} name={`logisticInformation.${index}.customercontact`} callback={ setLogInf}  disabled={isPendingStatus()  || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()} />

              }
              {/* <LabeledTextField 
           {...(checkLogistics (index) ? {rules : { required: "Name is required"}} :{ rules: {}})} textfieldStyles={{ background: "#fafafa", width: 300 }} fontSize="14px" control={control} name={`logisticInformation.${index}.customercontact`} callback={ setLogInf}  disabled={isPendingStatus()  || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()} /> */}
              <LabeledTextField      {...(checkLogistics (index) ? {rules : { required: "Designation is required"}} : { rules: {}})} textfieldStyles={{ background: "#fafafa", width: 300 }} fontSize="14px" control={control} name={`logisticInformation.${index}.department`} callback={ setLogInf}   disabled={isPendingStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()}/>
              <LabeledTextField    {...(checkLogistics (index) ? {rules : { required: "Contact Num is required" ,   pattern: {
                  value: /^[0-9]/,
                  message: "Only numbers are allowed"
                }, maxLength: {
                  value: 15,
                  message: "Number cannot exceed 15 Characters"
                },minLength: {
                  value: 7,
                  message: "Number should be atleast 7 Characters"
                }}} : { rules: {}})}
              labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa", width: 300 }} fontSize="14px" control={control} name={`logisticInformation.${index}.contactnum`} callback={ setLogInf}  disabled={isPendingStatus() || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()}/>
              <LabeledTextField  {...(checkLogistics (index) ?
               {rules : { required: "Email is required",  pattern: {
                  value: /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)+$/,
                  message : 'Invalid email pattern eg abc@gmail.com'
                },
               }} : { rules: {}})} textfieldStyles={{ background: "#fafafa", width: 300 }} fontSize="14px" control={control} name={`logisticInformation.${index}.email`} callback={ setLogInf}   disabled={isPendingStatus()  || isUserEntityUser() || isUserBranchUser()|| isDeactivatedStatus()}/>
              {/* <TextField size='small' variant="outlined" className={styles.root} sx={{ width: '30%' }}></TextField>
                  <TextField size='small' variant="outlined" className={styles.root} sx={{ width: '30%' }}></TextField> */}
              {
                EntityData.logisticInformation.length - 1 === index && (!isPendingStatus() &&  !isUserEntityUser() && !isUserBranchUser() && !isDeactivatedStatus()) ? (
                  <IconButton aria-label='send' size='medium' color='error' m={4} onClick={() => removeBranchAddressLine(index)}>
                    <DeleteIcon />
                  </IconButton>
                ) : <IconButton aria-label='send' size='medium' color='error' m={4} onClick={() => removeBranchAddressLine(index)} disabled>
                  <DeleteIcon />
                </IconButton>
              }


            </Stack>
            {EntityData.logisticInformation.length - 1 === index && (!isPendingStatus() &&  !isUserEntityUser() && !isUserBranchUser() &&  !isDeactivatedStatus()) ?
              <>   <Box width='100%'>
                <Stack direction="row" spacing={2} sx={{ float: 'right' }} m={1}>
                  <Button variant='outlined' startIcon={<AddIcon />} p={2} onClick={() => addNewBranchAddress()}>Add Row</Button>
                  {/* <Box sx  ={{display : 'flex' , alignItems: 'center' , float : 'right' , background : '#fafafa'}}>
     <IconButton aria-label='send' size='medium' color='primary' m={1} onClick={()=> showBranchForm('new')}>
           <AddIcon/>
           </IconButton>
           <Typography variant="h8"  sx={{fontWeight: 500 }}>Add Row</Typography>
     </Box> */}

                </Stack>
              </Box></> : null
            }
          </>
        })
        }

        {isRejectedStatus() && (
          <>
          <Box sx={{display : 'flex' , alignItems : 'center' , marginTop : 4}}>
          <Typography variant="h6" sx={{ fontWeight: 600 }} >Rejected Remarks :</Typography>
          <Typography variant="h8" sx={{ fontWeight: 500 , marginLeft : 1 }}>{EntityData.remarks} </Typography>
          </Box>

          </>
        )

        }
        <Box mt={8}  sx={{ display: "flex", flexDirection: "row", justifyContent: "end", gap: 2 }}>
        <Button onClick={() => {
               props.setmanageEntity(false)
               EntityV2Store.EntityRec.set(new EntityTypeV2())
            }} size="small"
                // startIcon={<img style={{ width: "90%", height: "90%" }} src={prices} />} 
                sx={{ textTransform: "capitalize", backgroundColor: "#d9dade" }}>Cancel</Button>
        <Button variant='contained' p={2}  type="submit" disabled={!isUserEntityAdmin() || isDeactivatedStatus() || isPendingStatus()} >Submit</Button>
        </Box>
      </form>

    </Box>
    // </Box>
 
  )
}

export default EntityList
