//import { useEffect, useState } from "react";
import MonApi from "../state/mongo-api";
import { AgReportInput } from "../components/AgComponents";
import { reportStore } from "../state/reportStore";
//import { configStore } from "../state/CommonState";
// export default function TransactionDetails() {
//   const [transactionData, setTansactionData] = useState([]);
//   const [checked, setChecked] = useState(false);
//   let currentEntity = configStore.currentSelectedBranch
//   console.log(currentEntity.entityId)
//   let url = `${process.env.REACT_APP_API_SERVER}/api/v1/integration/apiTransactionHistoryData`;
//   let data = {
//    entityId:currentEntity.entityId
//   };

//   useEffect(() => {
//     MonApi.apost(url, data, "POST").then((data) => {
//       setTansactionData(data.data);
//       return;
//     });
//   }, []);

//   console.log("D", transactionData);

//   return (
//     <div style={{ display: "flex", justifyContent: "center" }}>
//       <hr />
//       <div
//         className="table-container "
//         style={{ width: "90%", fontSize: "0.85rem" }}
//       >
//         <button
//           style={{
//             float: "right",
//             marginRight: "140px",
//             padding: "10px",
//             background: "green",
//             color: "white",
//             cursor: "pointer",
//           }}
//           onClick={() => {
//             let currentEntity = configStore.currentSelectedBranch
//             console.log(currentEntity.entityId)
//             let url = `${
//               process.env.REACT_APP_API_SERVER
//             }/api/v1/common/report/TransactionDetails/${
//               currentEntity.entityId
//             }/${
//               new Date().toISOString().split("T")[0]
//             }/TransactionDetails_Payout.csv`;
//             window.location.href = url;
//           }}
//         >
//           Generate Report
//         </button>
//         <br></br>
//         <br></br>
//         <br></br>
//         <table
//           className="table  is-striped is-narrow is-hoverable "
//           style={{ width: "90%" }}
//         >
//           <thead>
//             <tr
//               className="tour5"
//               style={{
//                 borderTop: "2px solid lightgrey",
//                 borderBottom: "2px solid lightgrey",
//               }}
//             >
//               <th colSpan={4} style={{ borderRight: "none" }}>
//                 <div>
//                   <h5
//                     className="title is-5"
//                     style={{
//                       float: "left",
//                       marginBottom: "2px",
//                       color: "#2c358a",
//                     }}
//                   >
//                     Transaction Details
//                   </h5>
//                 </div>
//               </th>
//               <th style={{ borderLeft: "none", float: "right" }}>
//                 {/* <button
//                                     className="button is-rounded islink is-small"

//                                     onClick={() => {
//                                         setShowNewentity(!showNewEntity)
//                                     }}>
//                                     Add Entity
//                                 </button> */}
//               </th>
//             </tr>
//             {/* {
//                             (showNewEntity) ?
//                                 <tr>
//                                     <td colSpan={4}>
//                                         <AddEntity resetState={setShowNewentity} />
//                                     </td>
//                                 </tr> :
//                                 <></>
//                         } */}
//             <tr>
//               <th>Date & Time</th>
//               <th>Transaction No.</th>
//               <th>Amount</th>
//               <th>Status</th>
//               <th>Mode Of Payment</th>
//               <th>Verified</th>
//               <th>Booking NO</th>

//             </tr>
//           </thead>
//           <tbody>
//             {transactionData?.map((e, i) => {
//               return (
//                 <>
//                   <tr style={{ borderBottom: "1px solid lightgrey" }} key={i}>
//                     <td>{e?.currentPayment?.transDate}</td>

//                     <td>{e?.currentPayment?.transNum}</td>
//                     <td>{e?.currentPayment?.transAmount}</td>
//                     <td>

//                       { e?.customerStatus}
//                     </td>
//                     <td>
//                       {e?.phonePay_paymentstatus == "COMPLETED"
//                         ? "PhonePay"
//                         : "Manual"}
//                     </td>
//                     <td>
//                       {e?.phonePay_paymentstatus == "COMPLETED" && e?.ManualphonepayEnabled == undefined? (
//                         <input type="checkbox" defaultChecked={true} disabled />
//                       ) : (

//                         <input
//                           type="checkbox"
//                           checked={e?.ManualphonepayEnabled}
//                           onChange={async () => {

//                             let url = `${process.env.REACT_APP_API_SERVER}/api/v1/integration/apiManualPhonePayEnabled`;
//                             await MonApi.apost(url, {
//                               bookingNum: e?.bookingNum,
//                               actionName: e?.actionName,
//                               displayStatus:!checked,
//                             });
//                           }}
//                         />
//                       )}
//                     </td>
//                     <td>{e?.bookingNum}</td>
//                   </tr>
//                 </>
//               );
//             })}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }
import React, { useState, useEffect } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

import {
  EntityColumn,
  VehicleColumn,
  EntityColumnnonagraga,
  UserColumn,
  TraansactionColumn,
} from "../components/ag-table/columns";
import VehicleApi from "../state/vehicle_api";
import ReactTablePagination from "../components/ag-table/ReactTablePagination";
import TableFilter from "../components/ag-table/TableFilter";
import RoleUtils from "../util/RoleUtils";
import { PERMISSIONS, ROLES, VERTICALS } from "../util/constants";

import { Sorting } from "../components/ag-table/ag-sorting";
import {
  reducer,
  PAGE_CHANGED,
  PAGE_SIZE_CHANGED,
  PAGE_SORT_CHANGED,
  PAGE_FILTER_CHANGED,
  TOTAL_COUNT_CHANGED,
  PAYLOAD_CHANGED,
} from "../components/ag-table/ag-reducer";
import ApiAgTable from "../components/ag-table/api_ag-table";
import {
  aguserStore,
  configStore,
  entityStore,
  loginStore,
  routerStore,
  vehicleStore,
} from "../state/CommonState";
import { useSnapshot } from "valtio";
import { AguserType, EntityType, VehicleType } from "../state/Types";
import InviteUser from "../agusers/invite-user";
import { data } from "../charts/c1";
import { loader } from "../util/loader";

const queryClient = new QueryClient();

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
  queryPageFilter: "",
  queryPageSortBy: [],
  queryPayload: {},
};

const DataTable = (props) => {
  useSnapshot(configStore);
  useSnapshot(vehicleStore);

  const domain = loginStore.email.split("@")[1];
  const filterStatus = {
    Pending: "PENDING",
    Verified: "VERIFIED",
    Rejected: "REJECTED",
    InsExpired: "INS-EXPIRED",
    PUCExpired: "PUC-EXPIRED",
    FcExpired: "FC-EXPIRED",
    RcExpired: "RC-EXPIRED",
  };

  const [selectedFilter, SetSelectedFilter] = useState("all");

  const [keyword, setKeyword] = useState("");
  const [inviteGst, setInviteGst] = useState("");
  const [data1, setData1] = useState({ results: [] });
  const [transactionData, setTansactionData] = useState([]);
  const [checked, setChecked] = useState([]);
  const onClickFilterCallback = (filter) => {
    setKeyword(filter);
  }; //EntityV2Column
  let column = {};
  column = TraansactionColumn;
  //VehicleColumn
  // if (domain === "agraga.com") {
  //   column = VehicleColumn;
  // } else {
  //   column = EntityColumnnonagraga;
  // }
  // let columns = useMemo( () => COLUMNS, [])
  let columns = column;
  let url = `${process.env.REACT_APP_API_SERVER}/api/v1/integration/apiTransactionHistoryData`;

  const [
    {
      queryPageIndex,
      queryPageSize,
      totalCount,
      queryPageFilter,
      queryPageSortBy,
      queryPayload,
    },
    dispatch,
  ] = React.useReducer(reducer, initialState);
  useEffect(() => {
    ApiAgTable.fetchData(
      url,
      queryPageIndex,
      queryPageSize,
      queryPageFilter,
      queryPageSortBy,
      queryPayload
    ).then((res) => {
      res
        .clone()
        .json()
        .then(
          (res) => {
            if (JSON.stringify(data1.results) !== JSON.stringify(res.data)) {
              if (JSON.stringify(data1) !== JSON.stringify(res.data)) {
                setData1(res);
                console.log("resklfl", res);
              }
            }
          },
          (err) => {
            console.log(err);
          }
        );
    });
  }, [
    queryPayload,
    queryPageIndex,
    queryPageSize,
    queryPageFilter,
    queryPageSortBy,
    configStore.currentSelectedEntity,
    configStore.currentSelectedBranch
  ]);

  const totalPageCount = Math.ceil(totalCount / queryPageSize);
  console.log("DSC", data1.data);
  let transData = [];
  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('-');
}
  data1?.data?.forEach((e) => {
    if (e.currentPayment) {
      transData.push({
        isFullPayment:e?.isFullPayment,
        transDate: formatDate(e.currentPayment.transDate),
        transNum: e?.currentPayment?.transNum,
        transAmount: e?.currentPayment?.transAmount,
        customerStatus: e?.customerStatus,
        ManualphonepayEnabled: e?.ManualphonepayEnabled==undefined?false:true,
        bookingNum: e?.bookingNum,
        phonePay_paymentstatus: e.phonePay_paymentstatus,
        actionName:e.actionName,
        modeOfPayment:
          e?.phonePay_paymentstatus == "COMPLETED" ? "PhonePe" : "Manual",
      });
    }
  });
  console.log("transData", transData);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data: transData || [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
      },
      manualPagination: true,
      pageCount: data1 ? totalPageCount : null,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );
  const manualPageSize = [];

  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]);

  useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    gotoPage(0);
  }, [pageSize, gotoPage]);

  useEffect(() => {
    dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
    gotoPage(0);
  }, [sortBy, gotoPage]);

  useEffect(() => {
    dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword });
    gotoPage(0);
  }, [keyword, gotoPage]);

  // useEffect(() => {
  //   console.log(selectedFilter);
  //   dispatch({
  //     type: PAYLOAD_CHANGED,
  //     payload: { filter: selectedFilter || "all" },
  //   });
  //   gotoPage(0);
  // }, [selectedFilter]);

  React.useEffect(() => {
    if (data1?.count) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data1.count,
      });
    }
  }, [data1?.count]);

  const checkbox = async (e,k) =>{
    let a = checked
    let l = false
    if(a.indexOf(k)!=-1){
      let i = a.indexOf(k)
      a.splice(i, 1)
    }else{
      a.push(k)
      l = true
    }
    setChecked(a)
    loader.show()
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/integration/apiManualPhonePayEnabled`;
                                      await MonApi.apost(url, {
                                        bookingNum: e?.bookingNum,
                                        actionName: e?.actionName,
                                        displayStatus: l,
                                        transAmount:e.transAmount,
                                        transNum :e.transNum
                                      });
                                      loader.hide()
  }

  return (
    <>
      <div style={{
            display:"flex",
            float:"right",
           }}>
         {/* <div style={{marginRight: "10px",
            padding: "5px",
            marginRight: "10px",
            padding: "10px",
            color: "white",
            cursor: "pointer"}}>
              <AgReportInput lable="From Date" record={reportStore} type="date" name="phonepe_report_from_date1" /></div>
         <div style={{marginRight: "10px",
            padding: "5px",
            marginRight: "10px",
            padding: "10px",
            color: "white",
            cursor: "pointer"}}>

              <AgReportInput lable="To Date" record={reportStore} type="date" name="phonepe_report_to_date1" /></div> */}
      <div>
        <button
          style={{
            float: "right",
            marginRight: "10px",
            padding: "10px",
            background: "green",
            color: "white",
            cursor: "pointer",
          }}
          onClick={() => {
            let Entity = configStore.currentSelectedEntity?.entityId; 
            let Branch = configStore.currentSelectedBranch?.branchId; 
            let url = `${
              process.env.REACT_APP_API_SERVER
            }/api/v1/common/clinet_transactionreport/TransactionDetailsclient/${new Date(new Date(reportStore.phonepe_report_from_date1)).toISOString().split("T")[0]}/${new Date(new Date(reportStore.phonepe_report_to_date1)).toISOString().split("T")[0]}/${Entity}/${Branch}/TransactionDetails_Payout.csv`;
            window.location.href = url;
          }}
        >
          Generate Report
        </button>
        </div> 
      </div>
      <h1 className="title">Transaction History</h1>
     
    
      <div className="box">
        <div className="table-container" style={{ overflow: "initial" }}>
          <table className="table is-fullwidth is-small ">
            <thead className="is-scrollable-header has-background-white-ter is-unselectable">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  <th>S.No</th>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <Sorting column={column} />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              className="contractlist"
              style={{ fontSize: "0.8rem" }}
              {...getTableBodyProps()}
            >
              {page.map((row, i) => {
                prepareRow(row);

                return (
                  <>
                    <tr {...row.getRowProps()}>
                      <td>{pageIndex * pageSize + i + 1}</td>
                      {row.cells.map((cell) => {
                        {
                          let e = cell["row"]["original"];

                          switch (cell["column"]["Header"]) {
                            case "Verified":
                              return (
                                <td>
                                  <input
                                  name={pageIndex * pageSize + i + 1}
                                      type="checkbox"
                                      checked={e?.isFullPayment.toLowerCase()=='yes'?true:false}
                                      disabled
                                    />
                                  {/* {e?.phonePay_paymentstatus == "COMPLETED" &&
                                  e?.ManualphonepayEnabled == false ? (
                                    <input
                                      type="checkbox"
                                      defaultChecked={true}
                                      disabled
                                    />
                                  ) : (
                                  <>
                                  {e.ManualphonepayEnabled == true ? <input
                                  type="checkbox"
                                  checked={e.ManualphonepayEnabled}
                                    
                                />
                                    :  
                                    <>
                                    {
                                      ((checked.indexOf(pageIndex * pageSize + i + 1)!=-1))?<input
                                      type="checkbox"
                                      checked={(checked.indexOf(pageIndex * pageSize + i + 1)!=-1)?true:false}
                                      name={(pageIndex * pageSize + i + 1).toString()}
                                        onClick={async () => {
                                          let l = await checkbox(e,pageIndex * pageSize + i + 1)
                                      }}
                                    />:<input
                                    type="checkbox"
                                    defaultChecked={(checked.indexOf(pageIndex * pageSize + i + 1)!=-1)?true:false}
                                    name={(pageIndex * pageSize + i + 1).toString()}
                                      onClick={async () => {
                                        let l = await checkbox(e, pageIndex * pageSize + i + 1)
                                    }}
                                  />
                                    }
                                    </>
                                   }
                                  </>
                                  )} */}
                                 
                                </td>
                              );
                            //case "Actions":
                            // return (
                            //   <td>
                            //     <div
                            //       className="dropdown post-options is-right is-hoverable "
                            //       onClick={null}
                            //     >
                            //       <div className="dropdown-trigger">
                            //         <button
                            //           className="button"
                            //           aria-haspopup="true"
                            //           aria-controls="dropdown-menu-post"
                            //           //   onClick={() => {
                            //           //     vehicleStore.vehicleRec.set(e);
                            //           //     routerStore.vehicleCurrentView =
                            //           //       "view";
                            //           //   }}
                            //         >
                            //           <span className="icon is-small">
                            //             <i
                            //               className="fas fa-ellipsis-h"
                            //               aria-hidden="true"
                            //             ></i>
                            //           </span>
                            //         </button>
                            //       </div>
                            //       {RoleUtils.isUserAuthorized(
                            //         PERMISSIONS.EDIT.REJECTED_VEHICLES
                            //       ) &&
                            //         e.Status !== "Pending" && (
                            //           <div
                            //             className="dropdown-menu"
                            //             id="dropdown-menu-post"
                            //             role="menu"
                            //           >
                            //             <div className="dropdown-content">
                            //               <a
                            //                 href="/#"
                            //                 className="dropdown-item"
                            //                 onClick={async (event) => {
                            //                   console.log(e);

                            //                   await VehicleApi.viewVehicle(
                            //                     e.Regnumber
                            //                   );
                            //                   routerStore.vehicleCurrentView =
                            //                     "edit";
                            //                   event.stopPropagation();
                            //                 }}
                            //               >
                            //                 Modify
                            //               </a>
                            //               {/* <a
                            //                 href="/#"
                            //                 className="dropdown-item"
                            //                 onClick={() => {
                            //                   console.log(e);
                            //                   // BranchApi.viewBranch(e._id);
                            //                   VehicleApi.getList(e._id);
                            //                   routerStore.branchCurrentView =
                            //                     "view";
                            //                 }}>
                            //                 View
                            //               </a> */}
                            //             </div>
                            //           </div>
                            //         )}
                            //     </div>
                            //   </td>
                            // );

                            default:
                              return (
                                <td {...cell.getCellProps()}>
                                  <span>{cell.render("Cell")}</span>
                                </td>
                              );
                          }
                        }
                      })}
                    </tr>
                    {/* {inviteGst === row.original.gst ? (
                      <tr>
                        <td style={{ backgroundColor: "grey" }} colSpan={9}>
                          <InviteUser
                            gst={row.original.gst}
                            resetState={setInviteGst}
                            admin="Yes"
                            style={{ float: "right" }}
                          />
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )} */}
                  </>
                );
              })}
            </tbody>
          </table>
          {rows.length > 0 && (
            <>
              <div className="columns" style={{ width: "100%" }}>
                <div className="column is-6">
                  <ReactTablePagination
                    page={page}
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    setPageSize={setPageSize}
                    manualPageSize={manualPageSize}
                    dataLength={totalCount}
                  />
                </div>
                <div className="column is-6" style={{ textAlign: "right" }}>
                  <div className="select">
                    <select
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value));
                      }}
                    >
                      {[10,20, 50, 100].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const TransactionData = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <DataTable />
    </QueryClientProvider>
  );
};

export default TransactionData;
