import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { CustomProgressDottedBar, Line } from "../components/circle-line.js";
import {
  EntityV2Store,
  bookingsStore,
  configStore,
  contractsStore,
  entityStore,
  loginStore,
} from "../state/CommonState.js";
import Utils from "../util/Utils.js";
import LCLChargeClientViewv2 from "./LCLChargeClientView-v3.js";
import logo from "../aglogo.svg";
import schLogoMap from "../enquiry/carrier-logo.js";
import LCLPricingSchedule from "../pricing/lcl.schedule.js";
import FCLChargeClientViewv2 from "./FCLChargeClientView-v3.js";
import RoleUtils from "../util/RoleUtils.js";
import { BRANCH_STATUS, ENTITY_STATUS, PERMISSIONS } from "../util/constants.js";
import airlogoMap from "../enquiry/air-logo.js";
import BranchApi from "../state/branch-api.js";
import EntityApi from "../state/entity-api.js";
import AguserApi from "../state/AguserApi.js";
import AirChargesClientViewv3 from "./AirChargesClientView-v3.js";
import AirPricingSchedule from "../pricing/air.schedule.js";
import FCLPricingSchedule from "../pricing/fcl.schedule.js";
import EntityV2Api from "../state/entities-api.js";
import { dtactionStore } from "../dtactions/dtaction-store.js";
import {PremiumInsurance} from "../mytasks/act-coi-confirmation.js";
import InsuranceIcon from "../assets/images/icon-02.svg";
import EntityApi1 from "../state/entities-api";

export function DummySchedulev3({ stype, dummyContract, ...props }) {
  const [showCharges, setShowCharges] = useState(false);
  const [branches, setbranches] = useState([]);
  const [premium, setpremium] = useState(false);
  const [policy, setpolicy] = useState();
  const [entityData, SetEntityData] = useState();

  let client = loginStore.isClient();
  useSnapshot(contractsStore)
  useSnapshot(bookingsStore)
  useSnapshot(configStore)
  let brec = bookingsStore.current
  let rec = contractsStore.current

  const getEntitydata = async () => {
    let entityData = await EntityApi1.viewEntity(rec?.entityId);
    return entityData;
  };
  useEffect(()=>{
    if(entityData==undefined || entityData.length==0){
      getEntitydata().then((data1) => {
          SetEntityData(data1);
        });
    }
  },[])
  if (dummyContract) rec = dummyContract
  if (!rec) return <></>
  let voldis = 0
    rec['chargesList'].map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        if (e.chargeDescription == 'Volume Discount') {
            voldis = e.total * erate
        }
    })
    let usdRate = configStore.getExchangeRate('USD')
    let voldisusd = voldis / usdRate
    if(voldis>0){
      voldis = -(voldis)
      voldisusd = -(voldisusd)
  }
  let airlogo = logo
  if(rec && rec.airline && rec.airline.trim().length>0){
    if(rec.airline.trim().length>2){
      airlogo = airlogoMap[rec.airline
        .substring(
          rec.airline.lastIndexOf("(") + 1,
          rec.airline.lastIndexOf(")")
        )
        .trim()]
    }else if(rec.airline.trim().length==2) {
      airlogo = airlogoMap[rec.airline.trim()]
    }
  }
  if(brec && brec.airline && brec.airline.trim().length>0){
    if(brec.airline.trim().length>2){
      airlogo = airlogoMap[brec.airline
        .substring(
            brec.airline.lastIndexOf("(") + 1,
            brec.airline.lastIndexOf(")")
        )
        .trim()]
    }else if(brec.airline.trim().length==2) {
        airlogo = airlogoMap[brec.airline]
    }
  }
  if (brec && brec.iata) airlogo = airlogoMap[brec.iata]
  if (bookingsStore.current.createdFromPricing){
    if(stype=="LCL"){
      return <LCLPricingSchedule contract={bookingsStore.current.contract} isDummy={true} viewonly={true} nobutton={true}/>
    }
    if(stype=="AIR"){
      return <AirPricingSchedule contract={bookingsStore.current.contract} isDummy={true} viewonly={true} nobutton={true}/>
    }
    if(stype=="FCL"){
      return <FCLPricingSchedule contract={bookingsStore.current.contract} isDummy={true} viewonly={true} nobutton={true}/>
    }
  }
   async function checkBranchGst(data){
    let isGstAvail = false
  await  data.map((r,i)=>{
    if( r.status === BRANCH_STATUS.VERIFIED && r.gst.length > 0 && r.branchAddresses[0].address.length > 0) {
      isGstAvail= true
     return isGstAvail
    } 
    } 
    )
    return isGstAvail

   }
  return (

    <div className="">
      <PremiumInsurance  data={(data)=>{
       setpremium(data)
      }} ></PremiumInsurance>
      <div className="columns" style={{ fontSize: "0.8rem" }}>
        <div
          className="column is-1"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px",
          }}
        >
          {stype.toLowerCase() === "air" || stype.toLowerCase() === "lcl" || brec.iata ? (
            <img src={airlogo} />
          ) : (
            <>
            {
            (rec.carrier=="Agraga" && rec.scac && rec.scac.length>0)?
            <img src={schLogoMap[rec.scac]} alt={rec.scac}/>:
            <>
            {
              (rec.carrier=="Agraga" && (rec.scac==undefined || rec.scac.length==0))?
              <img src={airlogo} />:
              <img
            src={
              schLogoMap[
              rec.carrier
                .substring(
                  rec.carrier.lastIndexOf("(") + 1,
                  rec.carrier.lastIndexOf(")")
                )
                .trim()
              ]
            }
            alt={rec.carrier}
          />
            }
            </>
            }
            </>
          )}
        </div>
        <div className="column is-9">
          <div className="columns" style={{ flexDirection: "column" }}>
            <div style={{ alignSelf: "center" }}>
            {
                (rec.transitTime!=undefined && Number(rec.transitTime)>0)?<>
                <p style={{ color: "grey" }}>Transit Time</p>
              <p style={{ fontSize: "0.9rem", textAlign: "center" }}>
                <strong>{rec.transitTime} days</strong>
              </p>
                </>:<p style={{ color: "grey" }}>Schedule Awaited</p>
              }
            </div>
            <table
              className="schtable"
              style={{ width: "100%", textAlign: "center" }}
            >
              <tr>
                {rec.origin !== rec.portOfReceipt && (
                  <td style={{ textAlign: "center" }}>{rec.origin}</td>
                )}
                <td style={{ textAlign: "center" }}>{rec.portOfReceipt}</td>
                {rec.portOfReceipt !== rec.portOfLoading && (
                  <td style={{ textAlign: "center" }}>{rec.portOfLoading}</td>
                )}
                <td></td>
                <td></td>
                {rec.portOfDischarge !== rec.finalPlaceOfDelivery && (
                  <td style={{ textAlign: "center" }}>{rec.portOfDischarge}</td>
                )}
                <td style={{ textAlign: "center" }}>
                  {rec.finalPlaceOfDelivery}
                </td>
                {rec.destination !== rec.finalPlaceOfDelivery && (
                  <td style={{ textAlign: "center" }}>{rec.destination2!=undefined && rec.destination2.length>0?"Multiple":rec.destination}</td>
                )}
              </tr>
              <tr>
                {rec.origin !== rec.portOfReceipt && (
                  <td>
                    <CustomProgressDottedBar
                      setStyle={(dotted, hide) => [
                        hide,
                        ["Door-to-Port", "Door-to-Door"].includes(
                          rec.shipmentScope
                        )
                          ? dotted
                          : false,
                      ]}
                    />
                  </td>
                )}
                <td>
                  <CustomProgressDottedBar
                    setStyle={(dotted, hide) => [
                      ["Door-to-Port", "Door-to-Door"].includes(
                        rec.shipmentScope
                      )
                        ? dotted
                        : rec.origin !== rec.portOfReceipt
                          ? false
                          : hide,
                      false,
                    ]}
                  />
                </td>
                {rec.portOfReceipt !== rec.portOfLoading && (
                  <td>
                    <CustomProgressDottedBar
                      setStyle={(dotted, hide) => [false, false]}
                    />
                  </td>
                )}
                <td>
                  <Line />
                </td>
                <td>
                  <Line />
                </td>
                {rec.portOfDischarge !== rec.finalPlaceOfDelivery && (
                  <td>
                    <CustomProgressDottedBar
                      setStyle={(dotted, hide) => [false, false]}
                    />
                  </td>
                )}
                <td>
                  <CustomProgressDottedBar
                    setStyle={(dotted, hide) => [
                      false,
                      ["Door-to-Door", "Port-to-Door"].includes(
                        rec.shipmentScope
                      )
                        ? dotted
                        : rec.destination !== rec.finalPlaceOfDelivery
                          ? false
                          : hide,
                    ]}
                  />
                </td>
                {rec.destination !== rec.finalPlaceOfDelivery && (
                  <td>
                    <CustomProgressDottedBar
                      setStyle={(dotted, hide) => [
                        ["Door-to-Door", "Port-to-Door"].includes(
                          rec.shipmentScope
                        )
                          ? dotted
                          : false,
                        hide,
                      ]}
                    />
                  </td>
                )}
              </tr>
            </table>
          </div>
        </div>
        <div className="column is-2" style={{ textAlign: "center" }}>
        {
                        ((rec.quoteValue+voldis) < 0) ?
                            <>
                                <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(rec.quoteValue)}</strong></div>
                                <div style={{ fontSize: '0.8rem' }}>{Utils.N(rec.quoteValueUSD, 'USD')}</div>
                                <div style={{ fontSize: '1.0rem' }}>(Less) Volume Discount <strong>{Utils.N(voldis)}</strong></div>
                            </> : <>
                            <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(rec.quoteValue+voldis)}</strong></div>
                            <div style={{ fontSize: '0.8rem' }}>{Utils.N(rec.quoteValueUSD+voldisusd, 'USD')}</div>
                            </>
                    }


          {((!props.viewonly && rec.quoteStatus == 'ACCEPTED' && rec.status === 'SCHEDULES_UPLOADED') && (RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.BOOKING) || client)) && (contractsStore.current['quoteStatus'] === "ACCEPTED") ? (
            <><div style={{ fontSize: "0.6rem", color: "grey" }}>
            (Taxes, as Applicable)
          </div>
          <div style={{display:"flex",justifyContent: "center", alignItems: "center" }}>
          <button
              className={
                configStore.scheduleOrCarrierform.toString() === "carrierform"
                  ? "button is-rounded is-link is-small is-hidden"
                  : "button is-rounded is-link is-small"
              }
              onClick={async () => {
            //  await BranchApi.getAllBranches(rec.entityId , async (data)=> {
            //   let results = await checkBranchGst(data)
            //    if (results=== true) {
            //     bookingsStore.initialize();
            //     bookingsStore.current.schedule = {};
            //     bookingsStore.current.contract = { ...rec };
            //     bookingsStore.current.hasToShowDummySchedule = true;
            //     bookingsStore.current.contractNum = rec._id;
            //     configStore.scheduleOrCarrierform = "carrierform";
            //    }else {
            //     if(loginStore.isClient()){
            //       // let entityData = EntityV2Store.list.filter((r,i)=> r.entityId === rec.entityId)
            //       await EntityApi.getEntity(rec.entityId)
            //       let entityData = entityStore.entityRec
            //       configStore.setModalMessage(`The GST Number has not been updated.
            //       Please contact the Reporting manager or Customer Success to update and proceed Booking .
            //       Contact ID :

            //       Reporting Manager - ${entityData?.customer?.crossBorder?.relationshipManager}
            //       Customer Success - ${entityData?.customer?.crossBorder?.customerService }
            //       Customer ID ${rec.entityId}. Please check and update to proceed booking`)
            //     }else
            //     configStore.setModalMessage(`The GST Number has not been updated for the below ID.Customer ID ${rec.entityId}. Please check and update to proceed booking`)
            //    }
            //  } )
            EntityV2Api.viewEntity(rec.entityId , (data)=> {
                if(data.status === ENTITY_STATUS.DEACTIVATED  ){
                configStore.setModalMessage(`Entity ${data.entityName} is Deactivated , please Activate it for Procced to booking `)
                }
                else if (data?.entityCountry?.toLowerCase() === 'india' && (data?.branch[0]?.gst?.length < 1 || data?.branch[0]?.branchAddresses[0]?.address < 1 || data?.branch[0]?.state?.length < 1)) {
                  if (loginStore.isClient()) {
                    let dataObject = {
                      cb: data?.customer?.crossBorder?.relationshipManager,
                      dt: data?.customer?.domesticTransport?.relationshipManager
                    }

                    AguserApi.getProfile(dataObject, (data) => {
                      let message = "Please update your KYC before proceeding for the Booking. Please reach out to your Relationship Manager if you face any challenges "

                      if (data?.CB_User || data?.DT_User) {
                        let user = data?.CB_User || data?.DT_User
                        message += `please contact ${user?.firstName} on ${user?.phonePrefix} ${user?.phone} or ${user?.email} or ${data?.PAT_User?.email}`
                      } else if (data?.PAT_User) {
                        message += `please contact on ${data?.PAT_User?.email}`
                      }

                      configStore.setModalMessage(message)
                    })
                  } else {
                    configStore.setModalMessage('Please Update at least one GST and Address to place the Booking')
                  }
                }
                else if (data?.entityCountry?.toLowerCase() !== 'india' && (data?.tinNum?.length < 1 || data?.branch[0]?.branchAddresses[0]?.address?.length < 1 || data?.branch[0]?.state?.length < 1)){
                  if (loginStore.isClient()) {
                    let dataObject = {
                      cb: data?.customer?.crossBorder?.relationshipManager,
                      dt: data?.customer?.domesticTransport?.relationshipManager
                    }

                    AguserApi.getProfile(dataObject, (data) => {
                      let message = "Please update your KYC before proceeding for the Booking. Please reach out to your Relationship Manager if you face any challenges "

                      if (data?.CB_User || data?.DT_User) {
                        let user = data?.CB_User || data?.DT_User
                        message += `please contact ${user?.firstName} on ${user?.phonePrefix} ${user?.phone} or ${user?.email} or ${data?.PAT_User?.email}`
                      }
                       else if (data?.PAT_User) {
                        message += `please contact on ${data?.PAT_User?.email}`
                      }
                    
                      configStore.setModalMessage(message)
                    })
                  } else {
                    configStore.setModalMessage('Please update the Tax Identification Number and Address to place the Booking')
                  }
                }
                  else {
                bookingsStore.initialize();
                bookingsStore.current.schedule = {};
                bookingsStore.current.contract = { ...rec };
                bookingsStore.current.hasToShowDummySchedule = true;
                bookingsStore.current.contractNum = rec._id;
                bookingsStore.current.fbaDetails = Utils.fbaDetails(rec)
                configStore.scheduleOrCarrierform = "carrierform";

                            }
               } )
           
            
              }}
            >
              Proceed to Booking
            </button>
            {/* <button className={
                configStore.scheduleOrCarrierform.toString() === "carrierform"
                  ? "button is-rounded is-link is-small is-hidden"
                  : "button is-rounded is-link is-small"
              } style={{marginLeft:"8px"}} onClick={()=>{
                configStore.coiModalVisible2=true
              }}>Insurance</button> */}
              { (rec.shipmentType=="LCL"||rec.shipmentType.includes("FCL")) && entityData?.entityCountry.toLowerCase()=="india"
              &&(rec?.finalPlaceOfDelivery.toLowerCase().includes("india")==true || rec?.portOfReceipt.toLowerCase().includes("india")==true) ?

              <div >
                <button className={
                configStore.scheduleOrCarrierform.toString() === "carrierform"
                  ? "button is-rounded is-link is-small is-hidden"
                  : "blinking"
              }
              style={{marginLeft:"8px",cursor:"pointer",display: "inline-block", border: "none",animation: "blink 1s infinite"}} onClick={()=>{
                   configStore.coiModalVisible2=true
                 }}>
                    <img src={InsuranceIcon} alt="Insurance Icon" width="54" height="70" />
                </button>
            </div>:""}
            </div>
            
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="columns" style={{ fontSize: "0.8rem" }}>
        {showCharges ? (
          <button
            className="button is-warning is-small is-fullwidth"
            onClick={() => setShowCharges(false)}
          >
            Hide Detailed Pricing
            <span className="icon" style={{ marginLeft: "5px" }}>
              <i className="fas fa-sort-up"></i>
            </span>
          </button>
        ) : (
          <button
            className="button is-info is-small is-fullwidth"
            onClick={() => setShowCharges(true)}
          >
            View Detailed Pricing
            <span className="icon" style={{ marginLeft: "5px" }}>
              <i className="fas fa-sort-down"></i>
            </span>
          </button>
        )}
      </div>
      {showCharges  ? (
        <div
          className="columns"
          style={{
            fontSize: "0.8rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {
            stype.toLowerCase() === "lcl" && <LCLChargeClientViewv2 />
          }
          {
            stype.toLowerCase() === "fcl" && <FCLChargeClientViewv2 />
          }
          {
            stype.toLowerCase() === "air" && <AirChargesClientViewv3 />
          }
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
