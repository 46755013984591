import { proxy } from "valtio"

let rtpStore = proxy({
    markupView: 'list',
    markupMetadata: [],
    selectedMarkupCharge: '',
    customerTBA: 'A',
    countryName: 'India',
})
class MarkupValue {
    constructor() {
        this.initialize()
    }
    initialize() {
        this.perUnit = {
            percent: 0,
            value: 0
        }
        this.perShipment = {
            percent: 0,
            value: 0
        }
    }
}
class CountryMarkupv2 {
    constructor() {
        this.initialize()
    }
    initialize() {
        this.origin = ''
        this.destination = ''
        this.reworking = ''
        this.markup = new MarkupValue()
    }
}
class CountryMarkup {
    constructor() {
        this.initialize()
    }
    initialize() {
        this.origin = ''
        this.destination = ''
        this.reworking = ''
        this.markup = 0
    }
}

class PortsMarkup {
    constructor() {
        this.initialize()
    }
    initialize() {
        this.por = ''
        this.pol = ''
        this.rep = ''
        this.pod = ''
        this.fpod = ''
        this.markup = 0
    }
}
class Markup {
    constructor() {
        this.initialize()
    }
    initialize() {
        this._id = ''
        this.defaultMarkup = 0
        this.countryMarkup = [new CountryMarkup()]
        this.portsMarkup = [new PortsMarkup()]
    }
}
class Markupv2 {
    constructor() {
        this.initialize()
    }
    initialize() {
        this._id = 'freightify'
        this.defaultMarkup = new MarkupValue()
        this.countryMarkup = [new CountryMarkupv2()]
        this.portsMarkup = [new PortsMarkup()]
    }
}
class Markupv3 {
    constructor() {
        this.initialize()
    }
    initialize() {
        this._id = 'cmacgm'
        this.defaultMarkup = new MarkupValue()
        this.countryMarkup = [new CountryMarkupv2()]
        this.portsMarkup = [new PortsMarkup()]
    }
}
let markupsStore = proxy({
    records: [],
    freightify: new Markupv2(),
    cmacgm: new Markupv3(),
    current: new Markup(),
    initialize: () => {
        markupsStore.current = new Markup()
    },
    newMarkupCountry: {origin: '', destination: '', markup: 0},
    newMarkupPorts: {por: '', pol: '', pod: '', fpod: ''},
    markupCountry: [],
    markupPorts: [],
})
export {
    rtpStore, markupsStore, Markup, CountryMarkup, PortsMarkup, CountryMarkupv2
}