import { Box, Button, Typography } from "@mui/material";
import { VisibilityTrackerV2 } from "./VisibilityTracker";
import { Points } from "./Points";
import { ShareBooking } from "./ShareBooking";
import Utils from "../util/Utils";
import { enquiryStore } from "../enquiry/enquiry-data";
import { bookingsStore, breadCrumbStore, viewBookingStore } from "../state/CommonState";
import logo from "../assets/images/logo.svg";
import documentCenter from "../assets/images/document.svg";
import details from "../assets/images/details.svg";
import arrowDropDown from "../assets/images/arrowdropdown.svg";
import { AgMuiSelect } from "./AgMuiSelect";
import { useEffect, useState } from "react";
import airlogoMap from "../enquiry/air-logo";
import schLogoMap from "../enquiry/carrier-logo";

function getStatusColor(status) {
    const colorFinder = {
        inprogress: {
            backgroundColor: "#EBB02D33",
            color: "#D59A17"
        },
        submitted: {
            backgroundColor: "#717AD233",
            color: "#717AD2"
        },
        archived: {
            backgroundColor: "#2C8A5733",
            color: "#2C8A57"
        },
        cancelled: {
            backgroundColor: "#F25B5B33",
            color: "#F25B5B"
        },
        "cancellation requested" : {
            backgroundColor : "#FF751633",
            color : "#FF7516"
        }
    }
    return colorFinder[status.toLowerCase()];
}

const statusTag = {
    delayed: "Yet to commence",
    ontime: "In-Transit",
    inprogress: "In-Transit",
    completed: "Completed",
    cancelled: "Cancelled",
    "cancellation requested" : "Cancellation Requested"
}

function getText(status) {
    const Text = {
        delayed: "Yet to commence",
        ontime: "In-Transit",
        completed: "Completed",
        "in-transit": "In-Transit",
        complete: "Completed",
        cancelled: "Cancelled",
        "cancellation requested" : "Cancellation Requested"
    }
    return Text[status?.toLowerCase()];
}

function getStatusOfBooking(status) {
    console.log("the status is ", status);
        if (status === 'Y' || status === "yes" || status === "ARCHIVED") {
            return "completed";
        } else if(status==="INPROGRESS") {
            return "ontime";
        }else if(status === "SUBMITTED"){
            return "delayed";
        }else{
            return status?.toLowerCase();
        }
    }

function getStatusOfBooking2(booking) {
    if (booking.status_closed === 'Y' || booking.status_closed === 'Yes' || booking.booking.status === 'ARCHIVED') {
        return "completed";
    } else if(booking.status_closed === 'N' && booking['gatein_pol'] || booking?.containerlist?.some(container => container.gatein_pol)) {
        return "ontime";
    }else if((booking.status_closed === 'N' || !booking.status_closed) && !(booking['gatein_pol'] || booking?.containerlist?.some(container => container.gatein_pol)) && booking.booking.status === "SUBMITTED"){
        return "delayed";
    }else{
        return booking.booking.status?.toLowerCase();
    }
}    

export function BookingCard({ booking, showButtons = true,isSeparateView=false }) {
    const currentEnquiry = booking.booking.contract;
    console.log("the contract is ", currentEnquiry);
    const shipmentType = currentEnquiry.shipmentType.split(" ")[0];
    const isOriginDoor = currentEnquiry.shipmentScope.split("-")[0].toLowerCase() === "door";
    const isDestinationDoor = currentEnquiry.shipmentScope.split("-")[2].toLowerCase() === "door";
    let legendColor = getStatusColor(booking.booking.status);
    let theStatusOfBooking = getStatusOfBooking(booking.booking.status)
    const {isImport,isExport} = Utils.getBookingsImportAndExportStatus(booking);
    if (!legendColor) {
        legendColor = {
            backgroundColor: "#555555",
            color: "black"
        }
    }


    const getDate = (isDestination=false)=>{
        if(isDestination){
            if(shipmentType.toLowerCase() === "air" ){
                 return booking.ata_at_arrival_airport?.length >0 ? 
                { date : Utils.formatDateBooking(booking.ata_at_arrival_airport),arrived:true}
                 :
                {date : Utils.formatDateBooking(booking.eta_at_arrival_airport),arrived:false}
            }else{
                if(shipmentType.toLowerCase()==="lcl"){
                     return booking.gatein_fpod?.length > 0 ? 
                    { date : Utils.formatDateBooking(booking.gatein_fpod),arrived:true}
                     : { date : Utils.formatDateBooking(booking.eta_fpod), arrived : false}
                }else{
                    let gateinFpod = Utils.getFCLAtaatFpod(booking);
                    return gateinFpod?.length > 0 ? { date : Utils.formatDateBooking(gateinFpod),arrived:true} :{ date : Utils.formatDateBooking(booking.eta_fpod),arrived:false}
                }
            }
        }else{
           if(shipmentType.toLowerCase() === "air" ){
                return  booking.loaded_on_board?.length > 0 ? 
                             
               { date: Utils.formatDateBooking(booking.loaded_on_board),arrived:true}
                :
              { date : Utils.formatDateBooking(booking.etd_airport_of_departure), arrived : false}
           }else{
                return  booking.sob_pol?.length > 0 ?
               { date : Utils.formatDateBooking(booking.sob_pol),arrived:true}
                :
               { date : Utils.formatDateBooking(booking.etd_at_pol), arrived : false}
           }
        }
    }
    const transitTime =( booking.booking?.contract?.transitTime && booking.booking.contract.transitTime?.length>0) ? booking.booking.contract.transitTime : undefined;
const originDate = getDate();
const destinationDate = getDate(true);
const contract = booking?.booking?.contract;
const isAir = contract?.shipmentType === "Air";
let scac = null;
if(contract?.scac){
    scac = contract?.scac
}else if(contract?.cscac){
    scac = contract?.cscac
}else if(contract.carrier?.length>0){
    const regex = /\(([^()]*)\)(?!.*\([^()]*\))/;
    const match = contract.carrier.match(regex);
    if(match){
        scac = match[1];
    }
}
let shipmentLogo = schLogoMap[scac];
let airLogo = contract?.airline ? airlogoMap[contract?.airline] : null;
const [containerIndex,setContainerIndex] = useState(-1);
const containers = [];
const isFClWithContainerList = booking.shipmentType?.includes("FCL") && booking.containerlist && booking.containerlist?.length>1;
const isCancelled = booking.booking?.status?.toLowerCase()==="cancelled" || booking.booking?.status?.toLowerCase() === "cancellation requested"
if(isFClWithContainerList){
    containers.push({lable:"All Container",value:-1});
    booking.containerlist?.forEach((container,index)=>{
        containers.push({lable:`Container ${index+1}`,value:index});
    });
}
const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

const updateMedia = () => {
  setIsMobile(window.innerWidth <= 600);
};

useEffect(() => {
  window.addEventListener('resize', updateMedia);
  return () => {
    window.removeEventListener('resize', updateMedia);
  };
}, [window.innerWidth]);
    return     <>{!isMobile?<Box sx={{ position: "relative", display: "flex", flexDirection: "column",  border: isSeparateView ? "" : "1px solid #D4D4D4", borderRadius: "12px", padding:"10px",gap:1, backgroundColor: "white",boxShadow: isSeparateView ? "2px 2px 10px 0px #00000026"  : "" }}>
        <Box sx={{ position: "absolute", right: "14px", top: "14px" }}>
            <ShareBooking bookingId={booking._id} branchId={booking.booking.branchId} entityId={booking.booking.entityId} />
        </Box>
         <Box sx={{display:"flex",flexDirection:'row',justifyContent:"space-between",width:"100%"}}>
            <Box sx={{display:"flex",flexDirection:"column",gap:0.5}}>
                <p className="text-sm font-bold text-black">Status</p>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Figtree", borderRadius: "46px", paddingY: "2px", paddingX: "8px", color: legendColor.color, backgroundColor: legendColor.backgroundColor, height: "20px", fontSize: "14px" }}>{statusTag[theStatusOfBooking.toLowerCase()]}</Box>
            </Box>
            { isSeparateView && isFClWithContainerList && <Box sx={{display:"flex",flexDirection:"column",gap:"4px"}}>
            <Typography sx={{ fontWeight: "bold", fontFamily: "Figtree", fontSize: "12px",display: "flex", color: "black" }}>Select Container</Typography>
<AgMuiSelect iconComponent={arrowDropDown} fontSize="14px" onChange={(e)=>{
                    setContainerIndex(e.target.value);
}} value={containerIndex} options={containers.map(({lable})=>lable)} values={containers.map(({value})=>value)} height="30px" width="160px" style={{borderRadius:"7px"}} />
            </Box>}
         <Box sx={{ display: "flex",  flexDirection: "column", justifyContent: "space-between", gap: 0.5,height:"100%",width:"78%"}}>
<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2 }}>
    {/* <Points propertyKey="Shipper" value={isExport ? booking.booking.shipper : booking?.Consignee?.companyName || "Not Assigned"} /> */}
    <Points propertyKey="Shipment Type" value={shipmentType} />
    <Points propertyKey="Booking Ref." value={booking["agraga_booking_#"]} />
    <Points propertyKey="Branch" value={booking.Branches?.state?.split("/")[0]} />
    <Points propertyKey="Shipper" value={booking.booking.shipper} />
    

</Box>
<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2 }}>
    <Points propertyKey="Customer Ref." value={booking.cust_ref_number} />
    {/* <Points propertyKey="Consignee" value={isImport? booking.booking.shipper:  booking?.Consignee?.companyName || "Not Assigned" }  /> */}
    <Points propertyKey={isAir? "HAWB No. " : "HBL No."} value={isAir? booking.hawb_number : booking.hbl_number} />
   
    <Points propertyKey={ isAir ? "MAWB No. " : "MBL No."} value={ isAir? booking.mawb_number : booking.mbl_number} />
    <Points propertyKey="Consignee" value={ booking.booking.consignee && booking.booking.consignee.length>0 ?  booking.booking.consignee : "Not Assigned"  }  />
</Box>
</Box>
<Box sx={{width:"30px",height:"30px",backgroundColor:"white"}}></Box>
         </Box>
         <Box sx={{display:"flex",flexDirection:"row",gap:2,flex:1,width:"100%"}}>
             <Box sx={{display:"flex",flexDirection:'column',width:"110px",height:"100%",justifyContent:"space-around",alignItems:"center",gap:1}}>
                {/* <Box sx={{width:"100%",height:"73px",display:"flex",justifyContent:"center",alignItems:"center"}}> */}
                    <img style={{height:"40px",width:"80px",objectFit:"contain"}} src={isAir ? airLogo ? airLogo : logo : shipmentLogo ? shipmentLogo : logo} />
                {/* </Box> */}
             { transitTime ? booking.booking?.status?.toLowerCase()==="archived"?  <p className="text-xs">Cargo Delivered</p> : isCancelled ? <></> : <div className="flex flex-col gap-[3px] items-center">
                <p className="text-xs">Arriving In</p>
               <p className="text-[18px] font-bold text-black">{transitTime} Days</p>
               </div> : <></>}
             </Box>
             <Box sx={{height:"100%",display:"flex",width:"92%",justifyContent:"center",alignItems:"center"}}>
<VisibilityTrackerV2 isCancelledBooking={booking.booking?.status?.toLowerCase()==="cancellation requested" || booking.booking?.status?.toLowerCase()==="cancelled" } isViewFull={true} mode={booking.shipmentType} booking={Utils.getTrackerPoints(booking)} container={isSeparateView && isFClWithContainerList && containerIndex>-1 ? <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",color:"black",fontSize:"12px"}}>{containers[containerIndex+1].lable} : <span style={{color:"#555555",fontWeight:"normal"}}>{booking.containerlist[containerIndex].container_types}</span></Typography> :null} />
             </Box>
         </Box>
      { !isSeparateView && <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center" }}>
            <Button 
            // disabled={!showButtons} 
            onClick={() => {
                breadCrumbStore.setBreadCrumbs(["My Bookings",`Booking`]);
                breadCrumbStore.id = booking._id
                viewBookingStore.accordionToBeOpended = 0;
                viewBookingStore.current = booking;
                bookingsStore.current = booking;
                enquiryStore.initializeWithConract(booking.booking.contract);
            }} size="small" fullWidth variant="contained" startIcon={<img src={documentCenter} />} sx={{ textTransform: "capitalize", fontFamily: "Figtree", height: "30px",backgroundColor:"#4BA7DD","&:hover":{
                backgroundColor:"#4BA7DD"
            } }}>Document Center</Button>
            <Button onClick={() => {
                breadCrumbStore.setBreadCrumbs(["My Bookings",`Booking`]);
                breadCrumbStore.id = booking._id
                viewBookingStore.accordionToBeOpended = 1;
                viewBookingStore.current = booking;
                bookingsStore.current = booking;
                enquiryStore.initializeWithConract(booking.booking.contract); 
            }} size="small" fullWidth variant="contained" startIcon={<img src={details} />} sx={{ textTransform: "capitalize", fontFamily: "Figtree", height: "30px",backgroundColor:"#4BA7DD","&:hover":{
                backgroundColor:"#4BA7DD"
            } }}>Booking Details</Button>
            {/* <Button disabled={!showButtons} onClick={() => {
                viewBookingStore.current = booking;
                breadCrumbStore.setBreadCrumbs(["My Bookings", `Booking Id - ${booking._id}`])
                enquiryStore.initializeWithConract(booking.booking.contract);
            }} size="small" fullWidth variant="contained" startIcon={<img src={actions} />} sx={{ textTransform: "capitalize", fontFamily: "Figtree", height: "30px" }}>Pending Actions</Button> */}
        </Box>}
    </Box>:

<>
<div className="graphForMobile" style={{
        backgroundColor: 'white',
        borderRadius: '5px',
        padding: '.5rem',

        display: 'flex',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
    }}>
<VisibilityTrackerV2 isCancelledBooking={booking.booking?.status?.toLowerCase()==="cancellation requested" || booking.booking?.status?.toLowerCase()==="cancelled" } isViewFull={true} mode={booking.shipmentType} booking={Utils.getTrackerPoints(booking)} container={isSeparateView && isFClWithContainerList && containerIndex>-1 ? <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",color:"black",fontSize:"12px"}}>{containers[containerIndex+1].lable} : <span style={{color:"#555555",fontWeight:"normal"}}>{booking.containerlist[containerIndex].container_types}</span></Typography> :null} />
</div>
    <div style={{
        backgroundColor: 'white',
        borderRadius: '5px',
        padding: '.5rem'
    }}>
{/* <VisibilityTrackerV2 isCancelledBooking={booking.booking?.status?.toLowerCase()==="cancellation requested" || booking.booking?.status?.toLowerCase()==="cancelled" } isViewFull={true} mode={booking.shipmentType} booking={Utils.getTrackerPoints(booking)} container={isSeparateView && isFClWithContainerList && containerIndex>-1 ? <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",color:"black",fontSize:"12px"}}>{containers[containerIndex+1].lable} : <span style={{color:"#555555",fontWeight:"normal"}}>{booking.containerlist[containerIndex].container_types}</span></Typography> :null} /> */}

    <Box sx={{display:"flex",flexDirection:"row",gap:0.5}}>
                <p className="text-sm font-bold text-black">Status</p>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Figtree", borderRadius: "46px", paddingY: "2px", paddingX: "8px", color: legendColor.color, backgroundColor: legendColor.backgroundColor, height: "20px", fontSize: "14px" }}>{statusTag[theStatusOfBooking.toLowerCase()]}</Box>
    </Box>
    <Box
  sx={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: 1, /* Spacing between rows */
    // height: "100%",
    // width: "78%",
  }}
>
  <Points propertyKey="Shipment Type" value={shipmentType} />
  <Points propertyKey="Booking Ref." value={booking["agraga_booking_#"]} />
  <Points propertyKey="Branch" value={booking.Branches?.state?.split("/")[0]} />
  <Points propertyKey="Shipper" value={booking.booking.shipper} />
  <Points propertyKey="Customer Ref." value={booking.cust_ref_number} />
  <Points
    propertyKey={isAir ? "HAWB No." : "HBL No."}
    value={isAir ? booking.hawb_number : booking.hbl_number}
  />
  <Points
    propertyKey={isAir ? "MAWB No." : "MBL No."}
    value={isAir ? booking.mawb_number : booking.mbl_number}
  />
  <Points
    propertyKey="Consignee"
    value={
      booking.booking.consignee?.length > 0
        ? booking.booking.consignee
        : "Not Assigned"
    }
  />
</Box>
</div></>}
    </>
}