import { useState } from "react";
import { useSnapshot } from "valtio";
import { Agmongofile } from "../components/AgFile";
import TaskDatetime from "./task-datetime";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
let server = process.env.REACT_APP_FILE_SERVER
export default function Taskfiletable(props) {
    useSnapshot(taskStore)
    const [newLabel, setNewLabel] = useState('')
    const [newInvoiceNo, setNewInvoiceNo] = useState('')
    const [newInvoiceDate, setNewInvoiceDate] = useState('')

    const [newButtonEnabled, setNewButtonEnabled] = useState(false)
    let task = taskStore.current
    let docs = task.files
    let removeadd = ["Carrier Release Order","AWB Upload","DO Release (Air)","DO Release (Ocean)","BL Draft Approval","BL Upload"]
    if (!docs) docs = []
    return (
        <div>
            <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                <tr>
                    {
                        (removeadd.indexOf(task.actionName)==-1)?<th></th>:<></>
                    }
                    <th>Document</th>
                    <th>Link</th>
                   {props?.additionalField?<th>Document No</th>:<></>} 
                   {props?.additionalField?<th>Document Date</th>:<></>}
                    <th>Upload</th>
                    {
                        (props.showRejectReason) ?
                            <th>Reject Reason</th>
                            : <></>
                    }
                </tr>
                {
                    docs.map((e, index) => <tr style={{ padding: '0.5rem' }} id={e.label.replaceAll(" ","")+"___"+index}>
                        {
                        (removeadd.indexOf(task.actionName)==-1)?<td>
                                <p className="control" style={{ padding: '0px' }}>
                                    <button className="button is-success  is-inverted"
                                        onClick={() => {
                                            taskStore.current.files.splice(index+1, 0, {
                                                label: e.label,
                                                filename: '',
                                                ...(props?.additionalField ? { invoiceNo: '' } : {}), 
                                                ...(props?.additionalField ? { invoiceDate: '' } : {}),
                                                approved: '',
                                                rejectReason: ''
                                            })
                                        }}>
                                        <span className="icon is-small">
                                            <i className="fas fa-plus"></i>
                                        </span>
                                    </button>
                                    
                                        <button
                                            className="button is-danger is-inverted"
                                            onClick={() => {
                                                taskStore.current.files.splice(index, 1);
                                            }}
                                        >
                                            <span className="icon is-small">
                                                <i className="fas fa-trash"></i>
                                            </span>
                                        </button>
                                    
                                </p>
                    </td>:<></>
                        }


                        <td >{e.label}</td>
                        <td >
                            {
                                (e.filename) ?<>
                                    <a target='_blank' href={server + '/api/v1/filestore/getfile/' + task.bookingNum + '/' + e.filename}>
                                        {e.filename}
                                    </a>{
                                        (e.approved != 'Approve')?<>&nbsp;<button class="button is-danger" onClick={()=>e.filename=""}><i class="fa fa-trash" aria-hidden="true"></i></button></>:<></>
                                    } </> : <></>
                            }
                        </td>
                        {props?.additionalField?<td><input placeholder="Enter Invoice No" className="input is-small"
                            value={e.invoiceNo} onChange={(e) => {
                                docs[index].invoiceNo = e.target.value
                                setNewInvoiceNo(e.target.value)
                            }} /></td>:<></>}
                        
                        {props?.additionalField?<td>
                            <input placeholder="Enter Invoice Date" type="date" className="input is-small"
                            value={e.invoiceDate} onChange={(e) => {
                                docs[index].invoiceDate = e.target.value
                                setNewInvoiceDate(e.target.value)
                            }} /></td>
                        :<></>}
                        <td>
                            {
                                (e.approved !== 'Approve') ?
                                    <Agmongofile disabled={(e.approved === 'Approve') ? true : false} parent={e.label.replaceAll(" ","")+"___"+index}
                                        label={e.label} filekey={task.bookingNum}
                                        callback={(data,id) => {
                                            console.log(id)
                                            docs[id].filename = data.key.filename
                                            // e.filename = data.key.filename
                                            //Taskapi.updateTask(taskStore.current)
                                        }} />
                                    : <></>
                            }
                        </td>
                        {
                            (props.showRejectReason) ?
                                <td>
                                    {
                                        (e.approved === 'Reject') ? e.rejectReason : ''
                                    }
                                </td>
                                : <></>
                        }
                    </tr>)
                }
                {
                        (removeadd.indexOf(task.actionName)==-1)?<tr>
                        <td></td>
                        <td>
                            <input placeholder="Name for new Document" className="input is-small"
                                value={newLabel} onChange={(e) => {
                                    if (e.target.value.length > 0) setNewButtonEnabled(true)
                                    else setNewButtonEnabled(false)
                                    setNewLabel(e.target.value)
                                }} />
                        </td>
                        <td>
                            <button disabled={!newButtonEnabled} className="button is-small islink"
                                onClick={() => {
                                    taskStore.current.files.push({
                                        label: newLabel,
                                        filename: '',
                                        ...(props?.additionalField ? { invoiceNo: '' } : {}), 
                                        ...(props?.additionalField ? { invoiceDate: '' } : {}),
                                        approved: '',
                                        rejectReason: ''
                                    })
                                    setNewLabel('')
                                    setNewButtonEnabled(false)
                                }}>Add Document Type to Table</button>
                        </td>
                    </tr>:<></>
                }

            </table>
            <hr />
        </div>
    )
}