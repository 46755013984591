import { proxy, subscribe } from "valtio";
import { configStore } from "../state/CommonState";

import { CargoDetails, ContainerDetails } from "../state/Types";
import QuoteCalc from "../util/quote-calc";
import { calcCargoDetails } from "../CargoComp";

class Enquiry {
        constructor() { this.initialize() }
    set(e) {
        Object.assign(this, new Enquiry())
        Object.assign(this, e)
    }
    copyFromContract(c) {
        this.initialize()
        console.log("###STUFFINGS", c.shipmentType.substr(0, 3))
        if (c.shipmentType.substr(0, 3) === 'FCL') {
            let stuffings = c.shipmentType.substr(5, c.shipmentType.length - 6).split('/')
            this.stuffingType = stuffings[0]
            this.destuffingType = stuffings[1]
            // this.carrierMBL = 'No'
            console.log("\n\n\n")
            console.log("###STUFFINGS", stuffings)
        }
        this.originType = 'Port'
        this.destinationType = 'Port'
        this.entity = c.entity
        this.entityId = c.entityId
        this.hazardous = c.hazardous
        this.quoteClass = c.quoteClass
        this.packingGroup = c.packingGroup
        this.unnumber = c.unnumber
        this.isfromAgragaEnquiry = c.isfromAgragaEnquiry
        this.checkAgCustomer = c.checkAgCustomer
        this.countryFromEnquiry = c.countryFromEnquiry
        // this.carrierMBL = c.carrierMBL
        this.nonStackable = c.nonStackable
        this.temperatureControlled = c.temperatureControlled
        this.temperature = c.temperature
        this.cargoValue = c.cargoValue
        this.minTemperature = c.minTemperature
        this.maxTemperature = c.maxTemperature
        this.shipmentType = c.shipmentType
        this.shipmentScope = c.shipmentScope
        this.enqRoute.origin = c.origin
        this.enqRoute.destination = c.destination
        this.enqRoute.cargoReadinessDate = c.cargoReadinessDate
        this.enqRoute.customerReferenceNum = c.customerReferenceNum
        this.agragareason = c.agragareason
        if(c.isFBA==true && c.fba!=undefined && c.fba=='No'){
            this.fba = 'Yes'
        }else{
            this.fba = c.fba
        }
        this.fbaDays = c.fbaDays
        this.fbaSAD = c.fbaSAD
        this.fbaPAL = c.fbaPAL
        this.fbaSurvey = c.fbaSurvey
        this.fbaOCC = c.fbaOCC
        this.fbaDCC = c.fbaDCC
        this.fbaIorRequired = c.fbaIorRequired
        this.pick_up_by_amazon = c.pick_up_by_amazon
        this.cargoDetails = [new Cargo()]
        if (c.cargoList.length > 0) this.cargoDetails = []
        c.cargoList.map(e => {
            let a = new Cargo()
            a.packageType = e.packageType
            a.quantity = e.numPackages
            a.weight.value = e.wtPerPackage
            a.volume.value = e.volPerPackage
            a.totalWeight.value = e.totalWeight
            a.totalVolume.value = e.totalVolume
            a.chargeableWeight.value = e.chargeableWeight
            a.dimensions.height = e.height
            a.dimensions.width = e.width
            a.dimensions.length = e.length
            this.cargoDetails.push(a)
        })
        this.containerDetails = [new Container()]
        if (c.containerList.length > 0) this.containerDetails = []
        c.containerList.map(e => {
            let a = new Container()
            a.containerType = e.containerType
            a.id = e.id
            a.numContainers = e.numContainers
            a.wtPerContainer = e.wtPerContainer
            if(e.unit!=undefined && e.unit.trim().length>0){
                a.unit = e.unit
            }
            this.containerDetails.push(a)
        })
        this.containerDetails = [...this.containerDetails]
        this.cargoDimensionUnit = "Cms"
        const setcargo = (cargolist)=>{
            let cargo = []
            if(cargolist.length>0){
              for (let i = 0; i < cargolist.length; i++) {
                let l = cargolist[i]
                if (l['weight'] == undefined) {
                  if(cargolist[i]['weightPerPackage']==undefined){
                    cargolist[i]['weightPerPackage'] = "Kgs"
                  }
                  if(l['quantity']==undefined){
                    l["quantity"]=+cargolist[i]['numPackages']
                  }
                    l['weight'] = {value:+cargolist[i]['wtPerPackage'],unit:cargolist[i]['weightPerPackage']}
                   
                    l['dimensions'] = {
                      "length":+cargolist[i]['length'],
                      "width":+cargolist[i]['width'],
                      "height":+cargolist[i]['height']
                    }
                    let shipmentType = enquiryStore.current.shipmentType;
                    let calculatedCargo = calcCargoDetails(cargolist[i],enquiryStore.current.shipmentScope !== "Port-to-Port" || shipmentType.toLowerCase().includes('air') || shipmentType.toLowerCase().includes('fcl'))
                    l['totalWeight'] = {"value":+calculatedCargo['totalWeight'],"unit":"Kgs"}
                    l['totalVolume'] = {"value":+calculatedCargo['totalVolume'],"unit":"CBM"}
                }
                cargo.push(l)
              }   
            }
            return cargo
          }
        if(c.multidest==undefined){
            this.multidest = [
                {
                    destination:c.destination,
                    cargoDetails:this.cargoDetails,
                }
            ]
          }else{
            let multidest = c.multidest
            for (let i = 0; i < c.multidest.length; i++) {
              multidest[i]['cargoDetails'] = setcargo(c.multidest[i]['cargoDetails'])
            }
            this.multidest = JSON.parse(JSON.stringify(multidest))
          }
        this.tsClientviewOn = c.tsClientviewOn
    }
    initialize() {
        this.createdBy = ''
         this.entityId = ''
        this.shipmentType = 'LCL'
        this.shipmentScope = 'Port-to-Port'
        this.originType = 'Port'
        this.destinationType = 'Port'
        this.hazardous = 'No'
        this.fba = 'No'
        this.quoteClass = ''
        this.packingGroup = ''
        this.unnumber = ''
        this.nonStackable = 'No'
        this.temperatureControlled = 'No'
        this.temperature = 0
        this.cargoValue = 0
        this.minTemperature = ''
        this.maxTemperature = ''
        this.stuffingType = 'Factory'
        this.destuffingType = 'Factory'
        this.commodityType=''
        this.premium=''
        this.sum_insured=''
        this.insurance_Amount=''
        this.marineInsuranceEnabled="No"
        this.insurance_Amount_currency_type="USD"
        // this.carrierMBL = 'No'
        this.isfromAgragaEnquiry = 'No'
        this.checkAgCustomer = 'No'
        this.agragareason = ''
        this.countryFromEnquiry = ''
        this.enquiryNum = ''
        this.status = 'ENQUIRY'
        this.enqRoute = new EnqRoute()
        this.cargoDetails = null
        this.cargoDetails = [new Cargo()]
        this.cargoDimensionUnit = 'Cms'
        this.containerDetails = null
        this.containerDetails = [new Container()]
        this.vehicleDetails = new VehicleTypes()
        this.hasToShowDummySchedule = false
        // FBA Start
        this.fbaDays = 0
        this.fbaSAD = 'No'
        this.fbaPAL = 'Yes'
        this.fbaSurvey = 'No'
        this.fbaOCC = 'No'
        this.fbaDCC = 'No'
        this.fbaIorRequired = 'Yes'
        this.pick_up_by_amazon = 'No'
        // FBA End
        this.vehicles = [new Vehicle()]
    }
    addCargoLine() {
        this.cargoDetails.push(new Cargo())
    }
    mergeIntoContract(rec) {
        let c = { ...rec }
        let enqCargoList = this.cargoDetails
        c.cargoList = []

        for (let i = 0; i < enqCargoList.length; i++) {
            let e = new CargoDetails()
            let a = enqCargoList[i]
            e.packageType = a.packageType
            e.numPackages = a.quantity
            e.wtPerPackage = a.weight.value
            e.volPerPackage = a.volume.value
            e.totalWeight = a.totalWeight.value
            e.totalVolume = a.totalVolume.value
            e.chargeableWeight = a.chargeableWeight.value
            e.height = a.dimensions.height
            e.width = a.dimensions.width
            e.length = a.dimensions.length
            c.cargoList.push(e)
        }
        c.containerList = []
        for (let i = 0; i < this.containerDetails.length; i++) {
            let e = new ContainerDetails()
            let a = this.containerDetails[i]
            e.containerType = a.containerType
            e.numContainers = a.numContainers
            e.wtPerContainer = a.wtPerContainer
            c.containerList.push(e)
        }

        let data = new QuoteCalc(c).recalc()
        return data
    }
}

class Cargo {
    constructor() {
        this.initialize()
    }
    initialize() {
        this.packageType = ''
        this.quantity = ''
        this.weight = {
            value: 0.0,
            unit: 'Kgs'
        }
        this.chargeableWeight = {
            value: 0.0,
            unit: 'Kgs'
        }
        this.volume = {
            value: 0.0,
            unit: 'CBM'
        }
        this.totalWeight = {
            value: 0.0,
            unit: 'Kgs'
        }
        this.totalVolume = {
            value: 0.0,
            unit: 'CBM'
        }
        this.dimensions = {
            length: 0.0,
            width: 0.0,
            height: 0.0,
            unit: 'Cms'
        }
    }
}
class VehicleTypes {
    constructor() {
        this.initialize()
    }
    initialize() {  
        this.fields = {
            'tataAce': 'Tata Ace',
            'bolero': 'Bolero',
            'trailer14': '14 Foot Trailer',
            'tata407': 'Tata 407',
            'trailer20': '20 Foot Trailer',
            'trailer24': '24 Foot Trailer',
            'sxl32': '32 Foot SXL',
            'mxl32': '32 Foot MXL',
        }
        this.fieldkeys = Object.keys(this.fields)
        for (let i = 0; i < this.fieldkeys.length; i++) {
            let key = this.fieldkeys[i]
            this[key] = 0
        }
    }
}
class Container {
    constructor() {
        this.initialize()
    }
    initialize() {
        this.containerType = ''
        this.numContainers = 0
        this.wtPerContainer = 0
        this.unit = 'Kgs'
    }
}

class Vehicle {
    constructor(vType,numVehicles=0) {
        this.vType = vType
        this.numVehicles = numVehicles
    }

    // vType = "";
    // numVehicles = 0;
}

class EnqRoute {
    set(e) {
        Object.assign(this, new EnqRoute())
        Object.assign(this, e)
    }
    initialize() {
        this.origin = ''
        this.destination = ''
        this.cargoReadinessDate = ''
        this.customerReferenceNum = ''
    }
    constructor() {
        this.initialize()
    }
}

let routeFlag = proxy({
    originRouteFlag: 'No',
    destinationFlag: 'No'
})

let enquiryStore = proxy({
    cargoDetailsChangeCtr: 0,
    list: [],
    filters:[],
    current: new Enquiry(),
    cargoDetailsChanges: 0,
    vehicleDetailsNeeded:false,
    isForEnquiry : false,
    messageForEnquiry:"",
    initialize: () => {
        enquiryStore.current = new Enquiry()
        enquiryStore.cargoDetailsChanges = 0
    },
    initializeWithConract: (c) => {
        enquiryStore.current = new Enquiry()
        enquiryStore.current.copyFromContract(c)
    },
    currentCargoTableKey: '',
    currentEnquiryListComponent: 'list' // list, view
})
const unsubscribe = subscribe(enquiryStore.current.cargoDetails, () => {
    enquiryStore.cargoDetailsChangeCtr++
    console.log('CARGODETAILS CHANGED=', enquiryStore.current.cargoDetails[0].volume.value, enquiryStore.cargoDetailsChangeCtr++)
    configStore.displaySchedules = false
})
const unsubscribe1 = subscribe(enquiryStore.current.containerDetails, () => {
    console.log('CONTAINERDETAILS CHANGED=', enquiryStore.current.containerDetails[0].containerType)
    configStore.displaySchedules = false
})

// devtools(proxy([{enquiryStore,contractsStore,bookingsStore}]))
export {
    EnqRoute, Enquiry, enquiryStore, Cargo, Container, VehicleTypes,routeFlag, Vehicle
}