import { useSnapshot } from "valtio"
import { AgmodalStore, configStore, contractsStore, entityStore, loginStore, modalStore } from "../state/CommonState"
import { enquiryStore } from "../enquiry/enquiry-data"
import { useEffect, useState } from "react"
import EnquiriesApi from "../state/enq-api"
import { loader } from "../util/loader"
import { AgCustomFile } from "../components/AgFile"



export default function ActrejectModalV3(props) {
    useSnapshot(enquiryStore)
    useSnapshot(modalStore)
    useSnapshot(configStore)
    useSnapshot(loginStore)
    useSnapshot(entityStore)
    useSnapshot(contractsStore)
    useSnapshot(AgmodalStore)
    let { id } = props
    const [reason, setReason] = useState("");
    const [error, setError] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [activeProduct, setActiveProduct] = useState('LCL')
    let modalClass = 'modal'

    if (configStore.quotationModalVisible4) modalClass += ' is-active'

    const NmO_onClick = () => {
        // configStore.quotationModalVisible4 = true

        let stat = EnquiriesApi.saveEnquiry()
        EnquiriesApi.getAllEnquiries()

        if (stat) {
            setActiveProduct("LCL")
            configStore.displaySchedules = false
            configStore.showEnquiries = true
            contractsStore.current = {}
            modalStore.checkingDestination = false
            configStore.homescreenCurrentShipmentType = "LCL"
            enquiryStore.current.entityName = ""
            enquiryStore.current.gst = ""
        }



    }
    const submit = async () => {if (props.type === 'cancel') {
        loader.show()
        EnquiriesApi.cancelEnquiry(props.id, reason,(data)=>{
            loader.hide()
            configStore.setModalMessage(data)
            configStore.quotationModalVisible4 = false
            setReason("")
        })
        enquiryStore.currentEnquiryListComponent = 'list'
    } else {
        loader.show()
        configStore.agragaEnquiryreason = reason
        enquiryStore.current.agragareason = reason
        const callback = (data) =>{
            loader.hide()
            configStore.setEnquiryModalMessage(configStore.enquiryModalNum)
            configStore.quotationModalVisible4 = false
        }
        let stat
        if (props.fromPricing)
            stat = EnquiriesApi.saveEnquiry4pricing(callback)
        else stat = EnquiriesApi.saveEnquiry(callback)
        // EnquiriesApi.getAllEnquiries()

        if (stat) {
            setActiveProduct("LCL")
            configStore.displaySchedules = false
            configStore.showEnquiries = true
            contractsStore.current = {}
            modalStore.checkingDestination = false
            configStore.homescreenCurrentShipmentType = "LCL"
            enquiryStore.current.entityName = ""
            enquiryStore.current.gst = ""
        }


    }
        // configStore.acceptMail = true

    }
    const filecat = '' + Date.now()
    const [docs, setDocs] = useState([{ label: 'Commercial Invoice', fileName: '' }, { label: 'Packing List', fileName: '' }])
    useEffect(() => {
        if (enquiryStore.current.hazardous === 'Yes') {
            setDocs([...docs, { label: 'MSDS', fileName: '' }])
        }
        console.log('useEffect Called....');
    }, [enquiryStore.current.hazardous])

    const [labelName, setLabelName] = useState('')
    const [hasError, setHasError] = useState(false)
    return (
        <div className={modalClass}
        // onClick={() => { configStore.quotationModalVisible4 = false }}
        >
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px' }} >
                <section className="modal-card-body" >

                    <h2 style={{ color: props.type === 'cancel' ? "black" :"red" }}>{props.type === 'cancel' ? 'Do you want to cancel this Enquiry ?' : 'Special Instructions to Procurement:'}</h2>
                    <br></br>
                    <textarea class={error?"textarea is-info is-danger":"textarea is-info"}   placeholder="Your Reasons..." rows={5} onChange={(e) => {
                            setReason(e.target.value)
                    }}></textarea>
                    {error?<span style={{color:'red'}}>Enter a Valid Reason minimum 4 characters</span>:''}
                    <br></br>
                    
                    <div className="table-container ">
                        <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable ">
                            <thead>
                                <tr>
                                    <th style={{ width: '16rem' }}>Documents</th>
                                    <th>Link</th>
                                    <th style={{ width: '12rem' }}>Uploads</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    docs.map((e, i) => (
                                        <tr>
                                            <td>{e.label}</td>
                                            <td className={`${e.label === 'MSDS' && hasError ? 'is-danger-border' : ''}`}>
                                                {
                                                    e.fileName ?
                                                        <p><a href={process.env.REACT_APP_FILE_SERVER + '/clientfiles/' + e.fileName} target="_blank">{e.fileName}</a>
                                                            <span className="icon has-text-danger" style={{ cursor: 'pointer' }} onClick={() => {
                                                                setDocs((prev) => {
                                                                    const updatedDoc = [...prev]
                                                                    updatedDoc[i].fileName = ""
                                                                    return updatedDoc;
                                                                })
                                                            }}>
                                                                <i className="fas fa-trash"></i>
                                                            </span>
                                                        </p>
                                                        : <></>
                                                }
                                            </td>
                                            <td id={`cwf${i+1}`}>
                                                <AgCustomFile source='carrierwebform' cat={filecat} filetype={e.label} parent={`cwf${i+1}`} multi={false}
                                                    callback={(filename) => {
                                                        setHasError(false)
                                                        setDocs((prev) => {
                                                            const updatedDoc = [...prev]
                                                            updatedDoc[i].fileName = filename
                                                            return updatedDoc;
                                                        })
                                                    }} />
                                                </td>
                                        </tr>
                                    ))
                                }
                                <tr>
                                    <td>
                                        <input
                                            placeholder="Name for new Document"
                                            className="input is-small"
                                            value={labelName}
                                            onChange={(e) => {
                                                setLabelName(e.target.value);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <button
                                            className="button is-small is-link"
                                            onClick={() => {
                                                if(labelName!==''){
                                                setDocs([...docs, {
                                                    label: labelName,
                                                    filename: ''
                                                }])
                                                setLabelName('');
                                            }}
                                        }
                                        >
                                            Add Document Type to Table
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <button class="button is-danger" disabled={isSubmitting} style={{ float: "right" }}
                        // disabled = {reason.length==0}
                        // onClick={submit}
                        onClick={async ()=>{
                            setIsSubmitting(true);
                            // await NmO_onClick()
                            if (props.type === 'cancel') {
                                if (reason !==''&&reason.length>3) {
                                    setError(false)
                                    submit()
                                    AgmodalStore.pageVisible = false;
                                    AgmodalStore.apiCall = true;
                                } else {
                                    setIsSubmitting(false)
                                    setError(true)
                                }
                            } else {
                                const check = docs.find((e) => e.label === 'MSDS')
                                if (enquiryStore.current.hazardous === 'Yes' && check.fileName === '') {
                                    setHasError(true)
                                    setIsSubmitting(false)
                                } else {
                                    const allFilesEmpty = docs.filter(doc => doc.fileName);
                                    if(allFilesEmpty.length>0){
                                        enquiryStore.current.uploads = allFilesEmpty
                                    }
                                    submit()
                                }
                            }
                            
                        }}
                    >Submit</button>
                    <button class="button is-warning" onClick={() => {
                        configStore.quotationModalVisible4 = false;
                        // configStore.setEnquiryModalMessage(configStore.enquiryModalNum)
                        // enquiryStore.currentEnquiryListComponent = "list"
                        configStore.buttondisable = false
                        if(props.type === 'cancel') {
                            setError(false)
                        }
                        }} >Cancel</button>
                </section>
            </div>
        </div>
    )
}