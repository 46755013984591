import { cargoStore } from "../state/CommonState";
import Utils from "../util/Utils";

export const CargoView = (props) => {
    let quoteDataRec = props.rec
    let l = props.rec.multidest;
    for (let i = 0; i < l.length; i++) {
        for (let j = 0; j < l[i]['cargoDetails'].length; j++) {
            let c = Utils.convert_calcCargoDetails(l[i]['cargoDetails'][j],quoteDataRec.shipmentScope,quoteDataRec.shipmentType,props.displayCargo4fcl,"Cms")
            let k = l[i]['cargoDetails'][j]
            if(c.totalVolume!=NaN && k['totalVolume']['value']!=undefined){
                k['totalVolume']['value'] = c.totalVolume
            }
            if(c.totalWeight!=NaN && k['totalWeight']['value']!=undefined){
                ['totalWeight']['value'] = c.totalWeight
            }
            if(c.volPerPackage!=NaN){
                k['volPerPackage'] = c.volPerPackage
            }
            if(JSON.stringify(l[i]['cargoDetails'][j])!=JSON.stringify(k)){
                l[i]['cargoDetails'][j] = JSON.parse(JSON.stringify(k))
            }
        }
    }
    const displayCargo4fcl = props.displayCargo4fcl
    const isP2P = () => quoteDataRec.shipmentScope === 'Port-to-Port' && !displayCargo4fcl
    const hide = () => (!quoteDataRec.shipmentType.toLowerCase().includes('air') && isP2P() ? { className: 'is-hidden' } : {})
    const dispForair = () => (quoteDataRec.shipmentType.indexOf('Air') >= 0) ? {} : { className: 'is-hidden' }
    let grandtotals = {
        totNumPackages: 0,
      totTotalWeight: 0,
      totTotalVolume: 0,
      totChargeableWeight: 0
    }
    if(l!=undefined){
        let rows1 = [];
        for (let i = 0; i < l.length; i++) {
            let rows = [];
            let totals = cargoStore.calculateGetCargoTotals(l[i].cargoDetails)
            grandtotals.totNumPackages = grandtotals.totNumPackages+totals.totNumPackages
            grandtotals.totTotalWeight = grandtotals.totTotalWeight+totals.totTotalWeight
            grandtotals.totTotalVolume = parseFloat((grandtotals.totTotalVolume+totals.totTotalVolume).toFixed(3))
            grandtotals.totChargeableWeight = grandtotals.totChargeableWeight+totals.totChargeableWeight
            for (let j = 0; j < l[i].cargoDetails.length; j++) {
                let e = l[i].cargoDetails[j]
                if(!quoteDataRec.shipmentType.toLowerCase().includes('air') && isP2P()){
                    rows.push(
                        <tr key={e.packageType+i+j} style={{border:"solid 0.5px #D4D4D4", backgroundColor:(i%2)==1?"#F3F3F3":"none"}}>
                            <td style={{textAlign: 'center',color:"#555555", padding:"10px"}}>{j + 1}</td>
                            <td style={{textAlign: 'center',color:"#555555", padding:"10px"}}>{e.packageType}</td>
                            <td style={{textAlign: 'center',color:"#555555", padding:"10px"}}>{(e.quantity!=undefined)?e.quantity:e.numPackages}</td>
                            <td style={{textAlign: 'center',color:"#555555", padding:"10px"}}>{(e.totalWeight?.value!=undefined)?e.totalWeight.value:e.totalWeight}</td> 
                            { quoteDataRec.shipmentType.toLowerCase().includes('air') && <td style={{textAlign: 'center',color:"#555555", padding:"10px"}} {...dispForair()}>{e.chargeableWeight}</td> }
                            <td style={{textAlign: 'center',color:"#555555", padding:"10px"}}>{(e.totalVolume?.value!=undefined)?e.totalVolume.value:e.totalVolume}</td>
                        </tr>
                    )
                }else{
                    rows.push(
                        <tr key={e.packageType+i+j} style={{border:"solid 0.5px #D4D4D4", backgroundColor:(i%2)==1?"#F3F3F3":"none"}}>
                            <td style={{textAlign: 'center',color:"#555555", padding:"10px"}}>{j + 1}</td>
                            <td style={{textAlign: 'center',color:"#555555", padding:"10px"}}>{e.packageType}</td>
                            <td style={{textAlign: 'center',color:"#555555", padding:"10px"}}>{(e.quantity!=undefined)?e.quantity:e.numPackages}</td>
                            <td style={{textAlign: 'center',color:"#555555", padding:"10px"}} {...hide()}>{(e.weight?.value!=undefined)?e.weight.value:e.wtPerPackage}</td>
                            <td style={{textAlign: 'center',color:"#555555", padding:"10px"}} {...hide()}>{(e.dimensions?.length!=undefined)?e.dimensions.length:e.length}</td>
                            <td style={{textAlign: 'center',color:"#555555", padding:"10px"}} {...hide()}>{(e.dimensions?.width!=undefined)?e.dimensions.width:e.width}</td>
                            <td style={{textAlign: 'center',color:"#555555", padding:"10px"}} {...hide()}>{(e.dimensions?.height!=undefined)?e.dimensions.height:e.height}</td>
                            {
                                !quoteDataRec.shipmentType.toLowerCase().includes('air') && quoteDataRec.shipmentScope !== 'Port-to-Port'||displayCargo4fcl ?
                                <td style={{textAlign: 'center',color:"#555555", padding:"10px"}} >{e.volPerPackage}</td> : <></>
                            }
                            <td style={{textAlign: 'center',color:"#555555", padding:"10px"}}>{(e.totalWeight?.value!=undefined)?e.totalWeight.value:e.totalWeight}</td> 
                            <td style={{textAlign: 'center',color:"#555555", padding:"10px"}}>{(e.totalVolume?.value!=undefined)?e.totalVolume.value:e.totalVolume}</td>
                        </tr>
                    )
                }
            }
            rows1.push(<div className="table-container">
                <table style={{border:"none", width:"100%"}}>
                    <thead>
                        
                            {
                                (l.length>1)?<tr><th colSpan={(() => {
                                    if (quoteDataRec.shipmentType.toLowerCase().includes('lcl') && isP2P()) return 5;
                                    if (quoteDataRec.shipmentType.toLowerCase().includes('lcl') || quoteDataRec.shipmentType.toLowerCase().includes('fcl')) return 10;
                                    else return 11;
                                })()} style={{border:"solid 0.5px #4BA7DD", backgroundColor:"#4BA7DD", color:"#fff",fontWeight:"900", padding:"10px"}}>
                                Destination{i+1}:{l[i]['destination']}
                            </th></tr>:""
                            }
                        
                        <tr style={{ fontWeight:"900",border:"solid 0.5px #D4D4D4"}}>
                        <th rowSpan="2" style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>S.No.</th>
                        <th rowSpan="2" style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>Package Type</th>
                        <th rowSpan="2" style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}># of Packages</th>
                        {
                            quoteDataRec.shipmentType.toLowerCase().includes('air') ? ( <th rowSpan="2" style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>Weight per Package (kgs)</th>) : quoteDataRec.shipmentScope === 'Port-to-Port' && !displayCargo4fcl ? <></> : ( <th rowSpan="2" style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>Weight per Package (kgs)</th>)
                        }
                        {
                            !quoteDataRec.shipmentType.toLowerCase().includes('air') && quoteDataRec.shipmentScope === 'Port-to-Port'&&!displayCargo4fcl ? <></> :<>
                        <th colSpan="3" style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>Dimensions per Package (CMS)</th></> 
                        }
                        {
                            !quoteDataRec.shipmentType.toLowerCase().includes('air') && quoteDataRec.shipmentScope !== 'Port-to-Port' || displayCargo4fcl? <th rowSpan="2" style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>Volume per Package (CBM)</th> : <></>
                        }
                        {
                            (<th rowSpan="2" style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>{quoteDataRec.shipmentType.toLowerCase().includes('air') ? 'Total Gross Weight (kgs)' : 'Total Weight (kgs)'}</th>)
                        }
                        <th rowSpan="2" style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>{quoteDataRec.shipmentType.toLowerCase().includes('air') ? 'Total Volume Weight (kgs)' : 'Total Volume (CBM)'}</th>
                    </tr>
                    {  !quoteDataRec.shipmentType.toLowerCase().includes('air') && quoteDataRec.shipmentScope === 'Port-to-Port'&& !displayCargo4fcl  ? <></>: <tr style={{ fontWeight:"900",border:"solid 0.5px #D4D4D4"}}>
                        <th style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>L</th>
                        <th style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>W</th>
                        <th style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>H</th>
                    </tr> }
                    </thead>
                    <tbody>
                    <tr style={{display :'none'}} >
                        <td ></td>
                        <td ></td>
                        <td ></td>
                        {  isP2P() ? <></> :
                        <><td {...hide()}></td>
                        <td {...hide()}></td>
                        <td {...hide()}></td>
                        <td {...hide()}></td>
                        <td {...hide()}></td></> 
                        }
                        <td ></td>
                        <td ></td>
                    </tr>
                        {rows}
                        <tr className="has-text-weight-bold" style={{ fontWeight:"900",border:"solid 0.5px #D4D4D4"}}>
                        <td ></td>
                        <td></td>
                        <td style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>{totals.totNumPackages}</td>
                        {   !quoteDataRec.shipmentType.toLowerCase().includes('air') && isP2P() ? <></> :
                        <><td {...hide()}></td>
                        <td {...hide()}></td>
                        <td {...hide()}></td>
                        <td {...hide()}></td>
                        {
                            !quoteDataRec.shipmentType.toLowerCase().includes('air') && quoteDataRec.shipmentScope !== 'Port-to-Port' || displayCargo4fcl ? <td></td> : <></>
                        }
                        </> 
                        }
                        { <td style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>{totals.totTotalWeight}</td> }
                        <td style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>{totals.totTotalVolume}</td>
                    </tr>
                    {
                        quoteDataRec.shipmentType.toLowerCase().includes('air') && 
                        <tr className="has-text-weight-bold">
                            <td colSpan={8}><p className="is-size-7 has-text-right has-text-danger">* Chargeable weight (kgs) &emsp;</p></td>
                            <td><p className="has-text-centered">{totals.totChargeableWeight}</p></td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>)
        }
        if(l.length>1){
            rows1.push(<div className="table-container">
                <table style={{border:"none", width:"100%"}}>
                    <tbody>
                    <tr className="has-text-weight-bold" style={{ fontWeight:"900",border:"solid 0.5px #D4D4D4"}}>
                        <td ></td>
                        <td></td>
                        <td style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>Grand Number Of Package : {grandtotals.totNumPackages}</td>
                        {   !quoteDataRec.shipmentType.toLowerCase().includes('air') && isP2P() ? <></> :
                        <><td {...hide()}></td>
                        <td {...hide()}></td>
                        <td {...hide()}></td>
                        <td {...hide()}></td>
                        {
                            !quoteDataRec.shipmentType.toLowerCase().includes('air') && quoteDataRec.shipmentScope !== 'Port-to-Port' || displayCargo4fcl ? <td></td> : <></>
                        }
                        </> 
                        }
                        { <td style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>Grand Total Weight : {grandtotals.totTotalWeight}</td> }
                        <td style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>Grand Total Volume : {grandtotals.totTotalVolume}</td>
                    </tr>
                    {
                        quoteDataRec.shipmentType.toLowerCase().includes('air') && 
                        <tr className="has-text-weight-bold">
                            <td colSpan={8}><p className="is-size-7 has-text-right has-text-danger">*Grand Chargeable weight (kgs) &emsp;</p></td>
                            <td><p className="has-text-centered">{grandtotals.totChargeableWeight}</p></td>
                        </tr>
                    }
                    </tbody>
                    </table></div>)
        }
    return (
        <>{rows1}</>
    )
    }else{
        return <></>
    }
}