import { Box, Tooltip, Typography } from "@mui/material";

export function Points({ propertyKey, value }) {
    return <Box sx={{ display: "flex", flexDirection: "row", minWidth: "187px",width:"max-content" ,flex:1,gap: "10px" }}>
        <Typography sx={{ fontWeight: 500, fontFamily: "Figtree", fontSize: "12px",minWidth:"90px", maxWidth: "90px", display: "flex", color: "black" }}>{propertyKey}</Typography>
        <Typography sx={{ fontFamily: "Figtree", fontSize: "12px" }}>:</Typography>
        <Tooltip title={value} componentsProps={{
    tooltip: {
      sx: {
        bgcolor: "white",
        color: "black",
        boxShadow:"4px 4px 20px 0px #00000033"
      }
    }
  }} style={{backgroundColor:"transparent",color:"#555555"}}><Typography sx={{ fontFamily: "Figtree", fontSize: "12px", textOverflow: "ellipsis",width:"max-content" ,maxWidth: "max-content", display: "flex", color: "black", overflow: "hidden", whiteSpace: "nowrap" }}>{value}</Typography></Tooltip>
    </Box>
}