import React from 'react'
import { Box, Button, Dialog, MenuItem, TextField, Typography } from "@mui/material";
import cancelIcon from "../assets/images/TrackBookingIcon.svg";
import { useEffect, useState } from "react";
import Api from '../state/Api';
import { configStore, dataListStore, newDashboardState } from '../state/CommonState';
import trackMyBookingOceanStore from './TrackMyBooking-store';
import { useSnapshot } from 'valtio';
import caution from '../assets/images/CautionIcon.svg'
import { loader } from '../util/loader';

function NewBookingPopup({ open, onCancel, onSubmit, booking,shipmentType }) {
    useSnapshot(trackMyBookingOceanStore);
    let textFieldLabel = ['Carrier Booking Reference#', 'Carrier MBL Number', 'Container Number']
    const [isValid, setValid] = useState(true)
    const [textField, setTextField] = useState('')

    useEffect(() => {
        if (dataListStore.P44CarrierList.length === 0) {
            Api.getP44FCLCarrierList()
        }
    }, [isValid]);

    const map = dataListStore.P44CarrierList.map((carrier) => ({
        key: carrier["Display Name"],
        value: carrier["SCAC code"],
    }))
    return (
        <Dialog
            slotProps={{ backdrop: { sx: { backgroundColor: 'transparent', backdropFilter: 'blur(3px)' } } }}
            PaperProps={{
                sx: {
                    backgroundColor: "transparent",
                    minWidth: trackMyBookingOceanStore.textfield ? '937px' : '1137px',
                    border: "none",
                    boxShadow: "none"
                }
            }} open={open} sx={{
                backgroundColor: "transparent !important",

                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#2C358A !important"
                }
            }} onClose={onCancel}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", backgroundColor: "transparent" }}>
                {configStore.currentSelectedEntity.status === 'DEACTIVATED' ?
                    <Box sx={{ width: "575px", height: "250px", position: "relative", backgroundColor: "transparent", display: "flex", justifyContent: "end" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", height: "222px", backgroundColor: "#F5F5F5", width: "100%", alignItems: "center", borderRadius: "10px", border: '2px solid #E8E8E8', position: "absolute", bottom: 0, padding: "15px", justifyContent: "space-between" }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '40px' }}>
                                <Typography sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Figtree', fontSize: '24px', lineHeight: '40px', fontWeight: 'bold' }}>You have been deactivated.</Typography>
                                <Typography sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Figtree', fontSize: '24px', lineHeight: '40px', fontWeight: 'bold' }}>Please reach out to &nbsp;<span style={{ color: '#2B6ED4' }}>pat@agraga.com</span>&nbsp; for</Typography>
                                <Typography sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Figtree', fontSize: '24px', lineHeight: '40px', fontWeight: 'bold' }}>further clarification.</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: "100%", height: "60px", position: "absolute", top: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60px", width: "60px", borderRadius: "50%", backgroundColor: "#F3E3BC" }}>
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px", width: "40px", borderRadius: "50%", backgroundColor: "#EBB02D" }}>
                                    <img src={caution} />
                                </Box>
                            </Box>
                        </Box>
                    </Box> :
                    shipmentType === 'Ocean' ?
                        <Box sx={{ width: trackMyBookingOceanStore.textfield ? "800px" : "1100px", height: trackMyBookingOceanStore.textfield ? "430px" : "292px", position: "relative", backgroundColor: "transparent", display: "flex", justifyContent: "end" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", height: trackMyBookingOceanStore.textfield ? "350px" : "264px", backgroundColor: "#F5F5F5", width: "100%", alignItems: "center", borderRadius: "10px", border: '2px solid #E8E8E8', position: "absolute", bottom: 0, padding: "15px", justifyContent: "space-between" }}>
                                <Box sx={{ paddingTop: '30px' }}>
                                    <Typography sx={{ fontFamily: 'Figtree', fontSize: '22px', fontWeight: 'bold' }}>Add New Booking</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 5 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <label sx={{ fontFamily: 'Figtree', fontSize: '11px', fontWeight: 'bold' }}>Customer Reference Number</label>
                                        <TextField placeholder="Enter Customer Reference No." sx={{ '& input': { padding: '9px' }, width: '300px', borderRadius: '7px', backgroundColor: '#FFFFFF' }} onChange={(e)=>trackMyBookingOceanStore.customerReference =  e.target.value} />
                                    </Box>
                                    {/* select type */}
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <label sx={{ fontFamily: 'Figtree', fontSize: '11px', fontWeight: 'bold' }}>Select Type*</label>
                                        <TextField
                                            InputProps={{
                                                style: {
                                                    height: "40px"
                                                }
                                            }}
                                            placeholder='Select an option'
                                            error={trackMyBookingOceanStore.validation}
                                            defaultValue={'Select an option'}
                                            helperText={trackMyBookingOceanStore.validation ? 'This field is required' : ''}
                                            select
                                            SelectProps={{
                                                MenuProps: {
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'left', // Align menu to the left
                                                    },
                                                    transformOrigin: {
                                                        vertical: 'top',
                                                        horizontal: 'left', // Align menu to the left
                                                    },
                                                    getContentAnchorEl: null, // Remove default behavior
                                                }
                                            }}
                                            sx={{
                                                "&  .MuiFormHelperText-root.Mui-error": {
                                                    backgroundColor: "#F5F5F5",
                                                    margin: 0,
                                                    // paddingLeft: 10
                                                }, width: '300px', borderRadius: '7px', backgroundColor: '#FFFFFF'
                                            }}
                                            value={trackMyBookingOceanStore.textfield === '' ? 'default' : trackMyBookingOceanStore.textfield}
                                            onChange={(e) => {
                                                if (e.target.value) {
                                                    trackMyBookingOceanStore.validation = false
                                                    trackMyBookingOceanStore.textfield = e.target.value
                                                }
                                            }}>
                                            <MenuItem style={{ height: '20px' }} value={'default'} selected={true} disabled={true}>Select an option</MenuItem>
                                            {textFieldLabel.map((key) => (
                                                <MenuItem style={{ height: '20px' }} value={key}>{key}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                    {/* carrier */}
                                    {
                                        trackMyBookingOceanStore.textfield === '' && <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <label sx={{ fontFamily: 'Figtree', fontSize: '11px', fontWeight: 'bold' }}>Carrier*</label>
                                            <TextField
                                                placeholder='Select an option'
                                                error={trackMyBookingOceanStore.validation}
                                                InputProps={{
                                                    style: {
                                                        height: "40px",
                                                        padding: '9px'
                                                    }
                                                }}
                                                helperText={trackMyBookingOceanStore.validation ? 'This field is required' : ''}
                                                select
                                                SelectProps={{
                                                    MenuProps: {
                                                        style: {
                                                            height: "360px"
                                                        },
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'left', // Align menu to the left
                                                        },
                                                        transformOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'left', // Align menu to the left
                                                        },
                                                        getContentAnchorEl: null, // Remove default behavior
                                                    }
                                                }}
                                                sx={{
                                                    "&  .MuiFormHelperText-root.Mui-error": {
                                                        backgroundColor: "#F5F5F5",
                                                        margin: 0,
                                                        // paddingLeft: 10
                                                    }, width: '300px', borderRadius: '7px', backgroundColor: '#FFFFFF'
                                                }}
                                                value={trackMyBookingOceanStore.carrier === '' ? 'default' : trackMyBookingOceanStore.carrier}
                                                onChange={(e) => trackMyBookingOceanStore.carrier = e.target.value}>
                                                <MenuItem style={{ height: '20px' }} value={'default'} selected disabled  >Select an option</MenuItem>
                                                {map.map(({ key, value }) => (
                                                    <MenuItem style={{ height: '20px' }} value={value}>{key}</MenuItem>
                                                ))}
                                            </TextField>
                                        </Box>
                                    }
                                </Box>
                                {trackMyBookingOceanStore.textfield ?
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 5 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <label sx={{ fontFamily: 'Figtree', fontSize: '11px', fontWeight: 'bold' }}>Carrier*</label>
                                            <TextField
                                                placeholder='Select an option'
                                                error={trackMyBookingOceanStore.validation}
                                                InputProps={{
                                                    style: {
                                                        height: "40px",
                                                        padding: '9px'
                                                    }
                                                }}
                                                helperText={trackMyBookingOceanStore.validation ? 'This field is required' : ''}
                                                select
                                                SelectProps={{
                                                    MenuProps: {
                                                        style: {
                                                            height: "300px",
                                                        },
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'left', // Align menu to the left
                                                        },
                                                        transformOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'left', // Align menu to the left
                                                        },
                                                        getContentAnchorEl: null, // Remove default behavior
                                                    }
                                                }}
                                                sx={{
                                                    "&  .MuiFormHelperText-root.Mui-error": {
                                                        backgroundColor: "#F5F5F5",
                                                        margin: 0,
                                                        // paddingLeft: 10
                                                    }, width: '300px', borderRadius: '7px', backgroundColor: '#FFFFFF'
                                                }}
                                                value={trackMyBookingOceanStore.carrier === '' ? 'default' : trackMyBookingOceanStore.carrier}
                                                onChange={(e) => trackMyBookingOceanStore.carrier = e.target.value}>
                                                <MenuItem style={{ height: '20px' }} value='default' disabled>Select an option</MenuItem>
                                                {map.map(({ key, value }) => (
                                                    <MenuItem style={{ height: '20px' }} value={value}>{key}</MenuItem>
                                                ))}
                                            </TextField>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <label sx={{ fontFamily: 'Figtree', fontSize: '11px', fontWeight: 'bold' }}>{trackMyBookingOceanStore.textfield}</label>
                                            <TextField
                                                placeholder='Enter Number'
                                                error={trackMyBookingOceanStore.validation}
                                                helperText={trackMyBookingOceanStore.validation ? 'This field is required' : ''}
                                                value={trackMyBookingOceanStore.textfield === 'Carrier MBL Number' ? trackMyBookingOceanStore.carrierMBLNumber : trackMyBookingOceanStore.textfield === 'Container Number' ? trackMyBookingOceanStore.containerNumber : trackMyBookingOceanStore.carrierBookingRefNumber}
                                                onChange={(e) => {
                                                    if (trackMyBookingOceanStore.textfield === 'Carrier MBL Number') {
                                                        trackMyBookingOceanStore.containerNumber = '';
                                                        trackMyBookingOceanStore.carrierBookingRefNumber = '';
                                                        trackMyBookingOceanStore.carrierMBLNumber = (e.target.value).trim();
                                                    } else if (trackMyBookingOceanStore.textfield === 'Container Number') {
                                                        trackMyBookingOceanStore.carrierBookingRefNumber = '';
                                                        trackMyBookingOceanStore.carrierMBLNumber = '';
                                                        trackMyBookingOceanStore.containerNumber = (e.target.value).trim();
                                                    } else {
                                                        trackMyBookingOceanStore.carrierBookingRefNumber = (e.target.value).trim();
                                                        trackMyBookingOceanStore.carrierMBLNumber = '';
                                                        trackMyBookingOceanStore.containerNumber = '';
                                                    }
                                                }}
                                                sx={{
                                                    '& input': { padding: '9px' }, "&  .MuiFormHelperText-root.Mui-error": {
                                                        backgroundColor: "#F5F5F5",
                                                        margin: 0,
                                                        // paddingLeft: 10
                                                    }, width: '300px', borderRadius: '7px', backgroundColor: '#FFFFFF'
                                                }} />
                                        </Box>
                                    </Box> : <></>}
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end", gap: 3, width: "100%" }}>
                                    <Button onClick={() => {
                                        onCancel()
                                    }} sx={{
                                        width: '110px', height: '40px',
                                        textTransform: "capitalize", backgroundColor: "#FFFFFF", color: "#2B6ED4", borderRadius: "5px", border: '2px solid #E8E8E8', "&:hover": {
                                            backgroundColor: "#FFFFFF", color: "#2B6ED4"
                                        }
                                    }}>Cancel</Button>
                                    <Button onClick={() => {
                                        console.log(trackMyBookingOceanStore, "trackMyBookingOceanStore");
                                        if (!trackMyBookingOceanStore.textfield || !trackMyBookingOceanStore.carrier || (!trackMyBookingOceanStore.carrierBookingRefNumber && !trackMyBookingOceanStore.carrierMBLNumber && !trackMyBookingOceanStore.containerNumber)) {
                                            trackMyBookingOceanStore.validation = true;
                                            setTimeout(()=>{
                                                trackMyBookingOceanStore.validation = false;
                                            },2000)
                                            return;
                                        } else {
                                            trackMyBookingOceanStore.validation = false;
                                            loader.show()
                                            onSubmit(trackMyBookingOceanStore)
                                        }
                                    }} sx={{
                                        width: '110px', height: '40px',
                                        textTransform: "capitalize", backgroundColor: "#2B6ED4", color: "#F5F5F5", borderRadius: "5px", "&:hover": {
                                            backgroundColor: "#2B6ED4", color: "#F5F5F5"
                                        }
                                    }}>Submit</Button>
                                </Box>
                            </Box>
                            <Box sx={{ width: "100%", height: trackMyBookingOceanStore.textfield ? "150px" : "60px", position: "absolute", top: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60px", width: "60px", borderRadius: "50%", backgroundColor: "#EAF6FF" }}>
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px", width: "40px", borderRadius: "50%", backgroundColor: "#2B6ED4" }}>
                                        <img src={cancelIcon} alt='' />
                                    </Box>
                                </Box>
                            </Box>
                        </Box> :
                        <Box sx={{ width: "800px", height: "292px", position: "relative", backgroundColor: "transparent", display: "flex", justifyContent: "end" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", height: "264px", backgroundColor: "#F5F5F5", width: "100%", alignItems: "center", borderRadius: "10px", position: "absolute", border: '2px solid #E8E8E8', bottom: 0, padding: "15px", justifyContent: "space-between" }}>
                                <Box sx={{ paddingTop: '30px' }}>
                                    <Typography sx={{ fontFamily: 'Figtree', fontSize: '22px', fontWeight: 'bold' }}>Add New Booking</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 8 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <label sx={{ fontFamily: 'Figtree', fontSize: '11px', fontWeight: 'bold' }}>Customer Reference Number</label>
                                        <TextField placeholder="Enter Customer Reference No." sx={{ '& input': { padding: '9px' }, width: '300px', borderRadius: '7px', backgroundColor: '#FFFFFF' }} onChange={(e)=>trackMyBookingOceanStore.customerReference =  e.target.value} />
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <label sx={{ fontFamily: 'Figtree', fontSize: '11px', fontWeight: 'bold' }}>MAWB Number*</label>
                                        <TextField placeholder="Enter MAWB Number"
                                            error={trackMyBookingOceanStore.validation}
                                            helperText={trackMyBookingOceanStore.validation ? 'This field is required' : ''}
                                            sx={{
                                                '& input': { padding: '9px' }, "&  .MuiFormHelperText-root.Mui-error": {
                                                    backgroundColor: "#F5F5F5",
                                                    margin: 0,
                                                    // paddingLeft: 10
                                                }, width: '300px', borderRadius: '7px', backgroundColor: '#FFFFFF'
                                            }}
                                            onChange={(e) => {
                                                trackMyBookingOceanStore.MAWBNumber = (e.target.value).trim()
                                            }} />
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end", gap: 3, width: "100%" }}>
                                    <Button onClick={() => {
                                        onCancel()
                                    }} sx={{
                                        width: '110px', height: '40px',
                                        textTransform: "capitalize", backgroundColor: "#FFFFFF", color: "#2B6ED4", borderRadius: "5px", "&:hover": {
                                            backgroundColor: "#FFFFFF", color: "#2B6ED4"
                                        }
                                    }}>Cancel</Button>
                                    <Button onClick={() => {
                                        if (!trackMyBookingOceanStore.MAWBNumber) {
                                            trackMyBookingOceanStore.validation = true;
                                            return;
                                        } else {
                                            trackMyBookingOceanStore.validation = false;
                                            onSubmit(trackMyBookingOceanStore)
                                        }
                                    }} sx={{
                                        width: '110px', height: '40px',
                                        textTransform: "capitalize", backgroundColor: "#2B6ED4", color: "#F5F5F5", borderRadius: "5px", "&:hover": {
                                            backgroundColor: "#2B6ED4", color: "#F5F5F5"
                                        }
                                    }}>Submit</Button>
                                </Box>
                            </Box>
                            <Box sx={{ width: "100%", height: "60px", position: "absolute", top: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60px", width: "60px", borderRadius: "50%", backgroundColor: "#EAF6FF" }}>
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px", width: "40px", borderRadius: "50%", backgroundColor: "#2B6ED4" }}>
                                        <img src={cancelIcon} alt='' />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                }

            </Box>
        </Dialog>
    )
}

export default NewBookingPopup
