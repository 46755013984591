import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import logo from "../aglogo.svg";
import Utils from "../util/Utils";
import ShipmentGraph, { graphNode } from "../my-actions/shipment-graph";
import { Button } from "@mui/material";
import {
  ArrowDownwardRounded,
  ArrowDropDown,
  ArrowDropUp,
} from "@mui/icons-material";
import airlogoMap from "../schedules/air-logo";
import schLogoMap from "../enquiry/carrier-logo";
import { Typography, Box } from "@mui/material";
import { rtpStore } from "../pricing/pricing-store";
import { contractsStore } from "../state/CommonState";
import { bookingsStore } from "../state/CommonState";
import { configStore, loginStore } from "../state/CommonState";

function _g(node) {
  let e = new graphNode();
  //Object.assign(e, { r1: 'r1', r2: 'r2', r3: 'r3', r4: 'r4' })
  Object.assign(e, node);
  e.isActive = true;
  e.activelines = 2;
  return e;
}

export function FCLPPSchedule(props) {
  const [showCharges, setShowCharges] = useState(false);
  const [ptbVisible, setptbVisible] = useState(true);
  const [checkedState, setCheckedState] = useState([])
  
  let e = { ...props.data };

 
  
  useSnapshot(contractsStore);
  useSnapshot(bookingsStore);
  let brec = bookingsStore.current;
  useSnapshot(configStore);
  useSnapshot(rtpStore);
  let hideme = props.hideme;
  let cwfVisible = props.cwfVisible;

  //if (!cwfVisible && !ptbVisible) setptbVisible(true)
  let rec = contractsStore.current;
  if (props.contract) rec = props.contract;
  let subscript = -1;
  if (props.subscript >= 0) subscript = props.subscript;
  useEffect(() => {
   // console.log("shipperpda TYPE=", rtpStore.shipperpda);
    if (rtpStore.shipperpda == " ") {
      rec.quoteValueDeduction = rec.oih;
    } else {
      rec.quoteValueDeduction = 0;
    }
  }, [rtpStore.shipperpda]);
  useEffect(() => {
    if (subscript < 0) return;
    let filterType = rtpStore.dpd != "Direct Port Delivery" ? "NDPD" : "DPD";
    let e = rtpStore.records[subscript];
    //console.log('\n\n\nDPD TYPE=', filterType, e.additionalType)
    if (rtpStore.additionalsets) {
      let recs = rtpStore.additionalsets.filter(
        (e1) => e.ID == e1.ID && e1.additionalType == filterType
      );
      //console.log('LENGTH=', recs.length)
      if (recs && recs.length) {
        rtpStore.records[subscript] = recs[0];
      }
    }
  }, [rtpStore.dpd]);
  

  if (!rec) return <></>;

 
  let shippmentDetails = [];
  
  let isDummy = props.isDummy ? true : false;
  //if (props.isDummy) return <div>{JSON.stringify(rec)}</div>
  if (props.isDummy) {
    e = {
      por: rec.portOfReceipt,
      pol: rec.portOfLoading,
      pod: rec.portOfDischarge,
      fpod: rec.finalPlaceOfDelivery,
      rep: rec.reworkingPort,
      "VESSEL NAME": "",
      VOYAGE: "",
      "STUFFING CUT OFF DATE": "2020-01-01",
      "ETD (POL)": "2020-01-01",
      "ETA (POD)": "2020-01-01",
      "CO-LOADER": "",
    };
  }
  let theSchedule = e;
  if (!e["POR (CFS / ICD / PORT)"]) e["POR (CFS / ICD / PORT)"] = e["POL"];
  let srec = {
    por: e.por,
    pol: e.pol,
    pod: e.pod,
    vessel: e["VESSEL NAME"],
    voyage: e["VOYAGE"],
    cutOffDt: e["STUFFING CUT OFF DATE"],
    etdPol: e["ETD (POL)"],
    etaPod: e["ETA (POD)"],
    coLoader: e["CO-LOADER"],
  };
  const pod = { location: srec.pod };
  const por = { location: srec.por };
  const pol = { location: srec.pol };

  let showOrigin = false;
  let showDestination = false;
  // if (rec.shipmentScope.toLowerCase().indexOf('door-') >= 0) showOrigin = true
  // if (rec.shipmentScope.toLowerCase().indexOf('-door') >= 0) showDestination = true
  let etaDate = Utils.newDate(e.etapod);
  if (e.pod != e.fpod) etaDate = Utils.newDate(e.etafpod);
  let etdDate = Utils.newDate(e.etdpol);
  let days = Utils.getDays(etdDate, etaDate);

  e.transitTime = Utils.getdeffentdate(e.deta,e.etd);
  let _date = (dt) => {
     
    return Utils.formatDateShort(new Date(new Date(dt.split("T")[0]).toISOString().split("T")[0]));
  };
  
  //console.log('LCLCOMPONENT***',srec, srec.por, srec.pol, por.location, pol.location)
  let graphnodes = [];
  // console.log("ads",showOrigin )
  // console.log("ads",isDummy )
  // console.log("ads",por.location,pol.location )
  // console.log("ads",e.ts1name )
  // console.log("ads",e.ts2name )
  // console.log("ads",e.pod,e.fpod )
  if(e.fpod && e.fpod.trim().length==0 && e.fpod != e.pod){
    e.fpod = e.pod
  }

  if (showOrigin) {
    graphnodes.push(
      _g({
        r2: rec.origin,
        graphic: "origin",
      })
    );
  }
  graphnodes.push(
    _g({
      r1: props.isDummy ? "" : "",
      r2: e.pol+"  ("+e.ocode+")",
      r3: !props.isDummy
        ? "ETD " + _date(por.location !== pol.location ? e.etdpor : e.etdpol)
        : "",
    })
  );
  if (por.location !== pol.location) {
    graphnodes.push(
      _g({
        r1: props.isDummy ? "" : "ETA " + _date(e.etapol),
        r2: pol.location,
        r3: props.isDummy ? "" : "ETD " + _date(e.etdpol),
      })
    );
  }
  if (!isDummy) {
    graphnodes.push(
      _g({
        r2: e["v1name"]+"-"+e["s1voy"] ,
        r4: e.service1,
        transparent: true,
      })
    );
    if (e.ts1name && e.ts1name.length && e.ts1name!=e.pod) {
      graphnodes.push(
        _g({
          r1: "ETA " + _date(e.ts1eta),
          r2: e.ts1name+" ("+e.ts1code+")",
          r3: "ETD " + _date(e.ts1etd),
        })
      );
      graphnodes.push(
        _g({
          r2: e["v2name"]+"-"+e["s2voy"] ,
          r4: e.service2,
          transparent: true,
        })
      );
    }
    if (e.ts2name && e.ts2name.length && e.ts2name!=e.pod) {
      graphnodes.push(
        _g({
          r1: "ETA " + _date(e.ts2eta),
          r2: e.ts2name,
          r3: "ETD " + _date(e.ts2etd),
        })
      );
      graphnodes.push(
        _g({
          r2: e["v3name"]+"-"+e["s3voy"],
          r4: e.service3,
          transparent: true,
        })
      );
    }
  }

  graphnodes.push(
    _g({
      r1: props.isDummy ? "" : "ETA " + _date(e.etapod),
      r2: e.pod +"  ("+e.dcode+")",
      r3: props.isDummy || e.pod == e.fpod ? "" : "ETD " + _date(e.etdpod),
    })
  );
  if (e.pod != e.fpod) {
    graphnodes.push(
      _g({
        r1: props.isDummy ? "" : "ETA " + _date(e.etafpod),
        r2: e.fpod,
      })
    );
  }
  if (showDestination) {
    graphnodes.push(
      _g({
        r2: rec.destination,
        graphic: "destination",
      })
    );
  }
  //console.log("F",graphnodes)
  let grandTotal = 0.0;
  let voldis = 0.0;
  rec["chargesList"]?.map((e) => {
    let erate = configStore.getExchangeRate(e.currency);
    if (!erate || erate === 0) erate = 1.0;
    if (e.chargeDescription == "Volume Discount") {
      voldis = e.total * erate;
      //grandTotal -= voldis
    } else if (e.isIncluded == "Yes") grandTotal += e.total * erate;
  });
  let usdRate = configStore.getExchangeRate("USD");
  let grandusd = grandTotal / usdRate;
  let pricingRec = props.pricingRec;
  let sss = props.pricingRec?.dummyContract;
  let cscac;
  if (e.cscac) cscac = e.cscac;
  let airlogo = logo;
  if (brec && brec.iata) airlogo = airlogoMap[brec.iata];

  const showContract = () => {
    if (loginStore.isClient()) {
      if (pricingRec.set != 5) return true;
      if (pricingRec.validTo != "") return true;
    }
    return false;
  };

  function formatDate(date) {
    //console.log("Dq",date)
    var d = new Date(date.split("T")[0]),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
   //console.log([day, month, year].join('-'))
    return [day, month, year].join('-');
}
  //console.log("DF",e)
  if (e.ts1name == "") {
    let data = {
      origin: e["oname"]+"  ("+e.ocode+")",
      originDate: formatDate(e["oetd"]),
      destination: e["dname"]+"  ("+e.dcode+")" ,
      destinationDate: formatDate(e["deta"]),
      serviceName: e["service1"],
      vessel: e["v1name"],
      voyage: e["s1voy"],
      serviceCode :e["r1code"]

    };
    shippmentDetails.push(data);
  }
  if (e.ts1name != ""   && e.ts2etd=="") {
    let data = {
      origin: e["oname"]+"  ("+e.ocode+")",
      originDate: formatDate(e["oetd"]),
      destination: e["ts1name"]+"  ("+e.ts1code+")",
      destinationDate:formatDate(e["ts1eta"]),
      serviceName: e["service1"],
      vessel: e["v1name"],
      voyage: e["s1voy"],
      serviceCode :e["r1code"]
    };
    shippmentDetails.push(data);
    let data1 = {
        origin:e["ts1name"]+"  ("+e.ts1code+")",
        originDate: formatDate(e["ts1etd"]),
        destination: e["dname"]+"  ("+e.dcode+")" ,
        destinationDate: formatDate(e["etapod"]),
        serviceName: e["service2"],
        vessel: e["v2name"],
        voyage: e["s2voy"],
        serviceCode :e["r2code"]

      };
      shippmentDetails.push(data1);

  }
  if (e.ts2etd!="") {
    let data = {
      origin: e["oname"]+"  ("+e.ocode+")",
      originDate: formatDate(e["oetd"]),
      destination: e["ts1name"]+"  ("+e.ts1code+")",
      destinationDate:formatDate(e["ts1eta"]),
      serviceName: e["service1"],
      vessel: e["v1name"],
      voyage: e["s1voy"],
      serviceCode :e["r1code"]
    };
    shippmentDetails.push(data);
    let data1 = {
        origin:e["ts1name"]+"  ("+e.ts1code+")",
        originDate: formatDate(e["ts1etd"]),
        destination: e["ts2name"]+"  ("+e.ts2code+")" ,
        destinationDate: formatDate(e["ts2eta"]),
        serviceName: e["service2"],
        vessel: e["v2name"],
        voyage: e["s2voy"],
        serviceCode :e["r2code"]

      };
      shippmentDetails.push(data1);
      let data2 = {
        origin:e["ts2name"]+"  ("+e.ts2code+")",
        originDate: formatDate(e["ts2etd"]),
        destination: e["pod"]+"  ("+e.dcode+")" ,
        destinationDate: formatDate(e["deta"]),
        serviceName: e["service3"],
        vessel: e["v3name"],
        voyage: e["s3voy"],
        serviceCode :e["r3code"]

      };
      shippmentDetails.push(data2);

  }
  //console.log("D",shippmentDetails)
  const handleOnChange=(position)=>{
    //console.log("checkedState",checkedState)

  const updatedCheckedState = checkedState.map((item, index) =>
    {
      if(index==position){
        e.checkboxEnabled=!item
        return !item
      } else{
        return item
      }
    }
  );
 
  setCheckedState(updatedCheckedState);
  }


  

 
  return (
    <div className="box">
      {pricingRec && (pricingRec.set == 5 || pricingRec.set == 6 || pricingRec.set == 6.1) && (
        <div style={{ fontSize: "1.0rem", fontWeight: "bold", color: "#F64" }}>
          {pricingRec.validTo == "" ? (
            <div>
              <span style={{ border: "1px solid black" }}>SPOT</span>
            </div>
          ) : (
            <div>Rates valid till {_date(pricingRec.validTo)}</div>
          )}
        </div>
      )}
      <div className="columns" style={{ fontSize: "0.8rem" }}>
        <div
          className="column is-1"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px",
          }}
        >
          {schLogoMap[cscac] ? (
            <img src={schLogoMap[cscac]} alt={cscac} />
          ) : (
            <strong>{cscac}</strong>
          )}
        </div>
        <div className="column is-9">
        {
                                    (days!=undefined && Number(days)>0)?<>
                                    <div
                style={{ color: "grey", width: "100%", textAlign: "center",marginLeft:"35px"}}
              >
                {" "}
                Transit Time
              </div>
              <div
                style={{
                  fontSize: "0.9rem",
                  width: "100%",
                  textAlign: "center",
                  marginLeft:"35px"
                }}
              >
                <strong>{e.transitTime} days</strong>
              </div>
                                    </>:<div
                style={{ color: "grey", width: "100%", textAlign: "center",marginLeft:"35px"}}
              >
                {" "}
                Schedule Awaited
              </div>
                                }
          <div>
         
            <ShipmentGraph insidepricing={true} graphNodes={graphnodes} />
           
          </div>
        </div>
      </div>
      <div className="columns" style={{ fontSize: "0.8rem" }}>
        {showCharges ? (
          loginStore.isClient() ? (
            <Button
              size="small"
              onClick={() => {
                setShowCharges(false);
              }}
              variant="contained"
              endIcon={<ArrowDropUp />}
              fullWidth
              sx={{
                display: "flex",
                textTransform: "capitalize",
                alignItems: "center",
                backgroundColor: "#ffdc7d",
                color: "black",
                "&:hover": {
                  backgroundColor: "#ffdc7d",
                },
              }}
            >
              Hide Shipment Details
            </Button>
          ) : (
            <button
              className="button is-warning is-small is-fullwidth"
              onClick={() => setShowCharges(false)}
            >
              Hide Shipment Details
              <span className="icon" style={{ marginLeft: "5px" }}>
                <i className="fas fa-sort-up"></i>
              </span>
            </button>
          )
        ) : loginStore.isClient() ? (
          <Button
            size="small"
            onClick={() => {
              setShowCharges(true);
            }}
            variant="contained"
            endIcon={<ArrowDropDown />}
            fullWidth
            sx={{ textTransform: "capitalize", alignItems: "center" }}
          >
            View Detailed Pricing
          </Button>
        ) : (
          <button
            className="button is-info is-small is-fullwidth"
            onClick={() => {
              setShowCharges(true);
            }}
          >
            View Shipment Details
            <span className="icon" style={{ marginLeft: "5px" }}>
              <i className="fas fa-sort-down"></i>
            </span>
          </button>
        )}
      </div>
      {showCharges ? (
        <>
          <div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 4.4,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Figtree",
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "black",
                  width: "150%",
                }}
              >
                {"Departure"}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Figtree",
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "black",
                  width: "150%",
                }}
              >
                {"Arrival"}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Figtree",
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "black",
                  width: "150%",
                }}
              >
                {"Service Name"}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Figtree",
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "black",
                  width: "150%",
                }}
              >
                {"Vessel/Voyage"}
              </Typography>
            </Box>
            {shippmentDetails?.map((e,index) => {
              return <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 4.4,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "150px",

                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Figtree",
                      fontSize: "12px",
                      color: "black",
                    }}
                  >
                    {e["origin"]}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Figtree",
                      fontSize: "12px",
                      color: "black",

                      //color: originDate.arrived ? "#2A6ED4" : "#555555",
                    }}
                  >
                    {(e["originDate"])}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "150px",
                    marginLeft:"12px"
                }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Figtree",
                      fontSize: "12px",
                      color: "black",
                    }}
                  >
                    {e["destination"]}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Figtree",
                      fontSize: "12px",
                      //color: originDate.arrived ? "#2A6ED4" : "#555555",
                      color: "black",
                    }}
                  >
                    {(e["destinationDate"])}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "150px",
                    marginLeft: "15px",
                  }}
                >
                  
                  <Typography
                    sx={{
                      fontFamily: "Figtree",
                      fontSize: "12px",
                      color: "black",
                    }}
                  >
                    {e["serviceName"]}
                  </Typography>
                  {/* <Typography
                    sx={{
                      fontFamily: "Figtree",
                      fontSize: "12px",
                      //color: originDate.arrived ? "#2A6ED4" : "#555555",
                      color: "black",
                    }}
                  >
                    {"["+e["serviceCode"]+"]"}
                  </Typography> */}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    marginLeft: "15px",
                    width:"130px"
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Figtree",
                      //fontWeight: "bold",
                      fontSize: "12px",
                      color: "black",
                    }}
                  >
                    {e["vessel"]+" /"}
                  </Typography>

                  <Typography
                    sx={{
                      fontFamily: "Figtree",
                      fontSize: "12px",
                      //fontWeight: "bold",
                      color: "black",

                      //color: originDate.arrived ? "#2A6ED4" : "#555555",
                    }}
                  >
                    {e["voyage"]}
                  </Typography>
                </Box>
                {shippmentDetails.length>1 && <><br></br>
                  <br></br><br></br>
                </>}
              </Box>;
             
            })}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
