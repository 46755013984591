import { bookingsStore, configStore, entityStore, EntityV2Store, loginStore } from "../state/CommonState"
import MonApi from "../state/mongo-api"
import Newapi from "../state/new-api"
import Utils from "../util/Utils"
import { validateFileApproval } from "./task-file-approval"
import { taskStore } from "./task-store"
import Api from "../state/Api"


class Task {
    initialize(booking, entity) {
        this.bookingNum = booking._id
        this.origin = booking.contract?.origin
        this.destination = booking.contract?.destination
        this.shipmentType = booking.contract?.shipmentType
        this.shipmentScope = booking.contract?.shipmentScope
        this.por = booking.contract?.portOfReceipt
        this.fpod = booking.contract?.finalPlaceOfDelivery
        this.entityCountry = entity.entityCountry
        this.entityId = booking.contract?.entityId
        this.branchId = booking?.branchId
        for (let i = 2; i < 6; i++) {
            if(booking.contract["destination"+i]!=undefined && booking.contract["destination"+i].length>0){
                this["destination"+i] = booking.contract["destination"+i]
            }
        }
    }
    constructor(actionName) {
        console.log('CREATING NEW TASK', actionName)
        this._id = ''
        this.actionName = actionName
        this.stage = 0
        this.files = []
        this.original_file = []
        this.isValid = true
        this.isValidfield = []
        this.dueDate = ''
        this.dueTime = ''
        this.bookingNum = ''
        this.origin = ''
        this.destination = ''
        this.shipmentType = ''
        this.shipmentScope = ''
        this.por = ''
        this.fpod = ''
        this.entityCountry = ''
        this.entityId = ''
        this.branchId=''
        this.entityName = ''
        this.status = 'PENDING'
        this.pendingWith = 'CUSTOMER'
        this.createdOn = Date.now()
        this.updatedOn = Date.now()
        this.createdBy = loginStore.email
        this.completedOn = ''
        this.completedBy = ''
        this.customerStatus = 'PENDING'
        this.customerCompletedBy = ''
        this.customerCompletedOn = ''
    }
}
export default class Taskapi {
    static async getNextNum() {
        let ret = await MonApi.nextnum('myactions')
        return ret.nextNum
    }
    static async saveTask(task,bookingID="") {
        // if(taskStore.current.actionName != 'Request for Booking Cancellation'){
        //     taskStore.setModalMessage('Triggering Action')
        // }

        taskStore.triggerActionVisible = false
        let booking = { ...bookingsStore.current }
        let nextNum = await Taskapi.getNextNum()
        if(bookingID.trim().length==0){
            task._id = {
                bookingNum: booking._id,
                num: nextNum
            }
        }else{
            task._id = {
                bookingNum: bookingID,
                num: nextNum
            }
        }
        task.updatelist = [
            {
                updatedOn:Date.now(),
                updatedBy:loginStore.email,
                status:"created"
            }
        ]
        //task.initialize(booking)
        console.log(" the task insisde save task is ",task.actionName);
        task.bookingNum = task._id.bookingNum;
        let ret = await MonApi.insertOne('Myactions', task)
        if(task?.financeInformation?.length > 0){
            await Api.updateFinanceInformation(task.entityId,task.financeInformation)
        }
        if (ret.error) {
            taskStore.setModalMessage('Error in triggering action. *** ' + ret.error)
        } else {
            taskStore.current = task
            if(taskStore.current.actionName == 'Request for Booking Cancellation'){
                Taskapi.sendActionAgragaTriggerEmail()
            }
            if(taskStore.current.actionName != 'Request for Booking Cancellation' && taskStore.current.actionName !== 'Additional Document Request'&&taskStore.current.actionName !=="Shipping Bill Checklist Approval"){
                Taskapi.sendActionTriggerEmail()
            }
            taskStore.setModalEnd('My Action has been triggered and mail sent successfully.')
        }
    }
    static async saveTaskForEnablingMarineInsurance(task,bookingID="") {
        console.log("123456");
        // if(taskStore.current.actionName != 'Request for Booking Cancellation'){
        //     taskStore.setModalMessage('Triggering Action')
        // }

        taskStore.triggerActionVisible = false
        let booking = { ...bookingsStore.current }
        let nextNum = await Taskapi.getNextNum()
        if(bookingID.trim().length==0){
            task._id = {
                bookingNum: booking._id,
                num: nextNum
            }
        }else{
            task._id = {
                bookingNum: bookingID,
                num: nextNum
            }
        }
        task.updatelist = [
            {
                updatedOn:Date.now(),
                updatedBy:loginStore.email,
                status:"created"
            }
        ]
        //task.initialize(booking)
        console.log(" the task insisde save task is ",task.actionName);
        task.bookingNum = task._id.bookingNum;
        let ret = await MonApi.insertOne('Myactions', task)
        if(task?.financeInformation?.length > 0){
            await Api.updateFinanceInformation(task.entityId,task.financeInformation)
        }
        if (ret.error) {
            taskStore.setModalMessage('Error in triggering action. *** ' + ret.error)
        } else {
            taskStore.current = task
            if(taskStore.current.actionName != 'Request for Booking Cancellation' && taskStore.current.actionName !== 'Additional Document Request'){
                await Taskapi.sendActionTriggerEmail()
            }
            //taskStore.setModalEnd('Action triggered successfully.')
        }
    }
    static async updateTask(task) {
        let u = {
            updatedOn:Date.now(),
            updatedBy:loginStore.email,
            status:task.lastview
        }
        if(loginStore.isClient()){
            u['status'] = 'CUSTOMER'
        }else{
            u['status'] = 'AGRAGA'
        }
        if(task.updatelist==undefined){
            task.updatelist = [u]
        }else{
            task.updatelist.push(u)
            task.updatelist.sort((a,b)=>{
                return b.updatedOn - a.updatedOn
            })
        }
        if(task.actionName=='Carting Order' && task.stage==2){
            let d1 = await MonApi.find("Webhooks",{_id:task.entityId},{})
            if(d1.length>0){
                try {
                    let d = {
                        bookingID:task.bookingNum
                    }
                    for (let i = 0; i < task.co_data.length; i++) {
                        let v = task.co_data[i]
                        if(v[v.label]!=undefined && v[v.label].trim().length!=0){
                            d[v.label] = v[v.label]
                        }
                    }
                    d['response_type'] = "Myactions_Carting Order"
                    const response = await fetch(d1[0]['webhook_url'], {
                        method: 'post',
                        body: JSON.stringify(d),
                        headers: {'Content-Type': 'application/json'}
                    });
                    const data1 = await response.json();
                    console.log(data1)
                } catch(error) {
                    console.log(error)
                }
            }
        }
        console.log(task)
        let ret = {error:false}
        if(["CI / PL Pending","BL Draft Approval","AWB Draft Approval","Shipping Instructions (LCL)","Shipping Instructions (Air)","Shipping Instructions (FCL)"].indexOf(task.actionName)!=-1){
            ret = await MonApi.apost(`${process.env.REACT_APP_API_SERVER}/api/v1/myactions/autotrigger`,task)
        }else{
            ret = await MonApi.replaceOne('Myactions', task)
        }
        if (ret.error) {
            configStore.setModalMessage('Error in triggering action. *** ' + ret.error)
        } else {
            if(!task.yetToSubmitted){
                if(task.pendingWith == 'CUSTOMER' && task.status!="COMPLETED"){
                    Taskapi.sendActionTriggerEmail()
                }else{
                    if(task.updatelist[0]['updatedBy'].toLowerCase().indexOf("@agraga.com")==-1){
                        Taskapi.sendActionAgragaTriggerEmail()
                    }
                }
            }
        }
    }
    static async getTaskList() {
        let filter = {}
        if (loginStore.isClient()) filter = { 'gst': configStore.currentSelectedEntity.gst }
        let ret = await MonApi.find('Myactions', filter, {})
        console.log('result =', ret)
        return ret
    }
    static async getTaskCopy(task) {
        let filter = {_id: task._id}
        let ret = await MonApi.find('Myactions', filter, {})
        const response = await Api.getFinanceDetails(ret[0].entityId)
        if (response?.financeInformation?.length > 0) {
            ret[0].financeInformation =  response?.financeInformation
        }
        return ret
    }
    static async getTaskForAction(bookingNum, action) {
        let filter = {'_id.bookingNum': bookingNum, actionName: action}
        let ret = await MonApi.find('Myactions', filter, {})
        return ret
    }
    static async getTasklist4Booking(bookingNum) {
        let filter = { 'bookingNum': bookingNum }
        if (loginStore.isClient()) filter['gst'] = configStore.currentSelectedEntity.gst
        let ret = await MonApi.find('Myactions', filter, {})
        console.log('result =', ret)
        return ret
    }
    static async replaceBooking(booking) {
        let ret = await MonApi.replaceOne('Bookings', booking)
        if (ret.error) {
            configStore.setModalMessage('Error in saving revised schedule. *** ' + ret.error)
            return false
        } else {
            //configStore.setModalMessage('Action updated successfully.')
        }
        return true
    }
    static async genPdf(task, fieldMapping) {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/pdfgen/vgmdeclaration/${task.bookingNum}`
        let rows = []
        let index = 1
        Object.keys(fieldMapping).map(e => {
            let row = [index]
            row.push(fieldMapping[e])
            row.push(task[e])
            rows.push(row)
            index++
        })
        let ret = await MonApi.apost(url, rows)
        //console.log('RETURN FROM PDFGEN = ', ret)
        return ret
    }
    static async genvgmdecl(task, fieldMapping) {
        let containers = Object.keys(task.data)
        for (let i = 0; i < containers.length; i++) {
            let container = containers[i]
            let approved = ''
            task.files.map((k)=>{
                if(k.label == container){
                    approved = k.approved
                }
            })
            if(approved.length==0 || approved=='Reject'){
                let data = task.data[container]
                let url = `${process.env.REACT_APP_API_SERVER}/api/v1/pdfgen/vgmdeclaration/${task.bookingNum}/${i}`
                let rows = []
                let index = 1
                if(containers.hazType=='Hazardous'){
                    fieldMapping['imdgClass'] = 'IMDG Class'
                    fieldMapping['packingGroup'] = 'Packing Group'
                    // fieldMapping['unno'] = 'UN No.'
                    fieldMapping['unnumber'] = 'unnumber'
                }
                Object.keys(fieldMapping).map(e => {
                    let row = [index]
                    row.push(fieldMapping[e])
                    if(fieldMapping[e]=="Weighment Time"){
                        if(task.version_v2!=undefined && task.version_v2==true){
                            row.push(`${data["wbTimehh"]}:${data["wbTimemm"]}:${data["wbTimeAP"]}`)
                        }else{
                            row.push(data[e])
                        }
                    }else{
                        row.push(data[e])
                    }
                    rows.push(row)
                    index++
                })
                let ret = await MonApi.apost(url, rows)
                for (let j = 0; j < task.files.length; j++) {
                    if(task.files[j].label.replace(' - VGM Declaration','')==container){
                        task.files[j].filename = ret.key.filename
                        task.files[j].approved = "Approve"
                    }
                }
                console.log('RETURN FROM PDFGEN = ', container, ret)
            }

        }
    }
    static async genSIinstructions_air(task) {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/pdfgen/shippinginstructions/${task.bookingNum}`
        let ret = await MonApi.apost(url, task)
        console.log('RETURN FROM PDFGEN = ', ret)
        return ret
    }
    static async genSIinstructions_fcl(task) {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/pdfgen/shippinginstructionsfcl/${task.bookingNum}`
        let ret = await MonApi.apost(url, task)
        console.log('RETURN FROM PDFGEN = ', ret)
        return ret
    }
    static async genSIinstructions_lcl(task) {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/pdfgen/shippinginstructionslcl/${task.bookingNum}`
        let ret = await MonApi.apost(url, task)
        console.log('RETURN FROM PDFGEN = ', ret)
        return ret
    }
    static async saveEntity() {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/entity`
        let ret = await MonApi.apost(url, entityStore.entityRec, 'PUT')
        return ret
    }
    static async setEntityVgmDetails(entityId, vgm) {
        console.log('UPDATING ENTITY VGM', entityId, vgm)
        let ret = await MonApi.updateOne('Entities', { _id: entityId }, { vgm })
        console.log(ret)
    }
    static async getEmailsCenterlOperations() {
        let ret = await MonApi.find('Agusers', {
            status: "ACTIVE",
            department: "Central Operations"
          }, { '_id': 1,'role':1 })
        return ret
    }
    static async sendActionTriggerEmail() {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/common/sendclientactionmail`
        let data = await MonApi.apost(`${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/listuserIds`, {entityId:taskStore.current.entityId,branchId:bookingsStore.current.branchId})
            let cc;
            let customer = data?.shEntitiesCustomer[0]?.customer;
            if(customer?.crossBorder?.relationshipManager && customer?.crossBorder?.customerService){
                cc = [customer?.crossBorder?.relationshipManager, customer?.crossBorder?.customerService];
            }else if(customer?.domesticTransport?.relationshipManager && customer?.domesticTransport?.customerService){
                console.log(customer?.domesticTransport?.relationshipManager,"***",customer?.domesticTransport?.customerService);
                cc = `${customer?.domesticTransport?.relationshipManager} ,${customer?.domesticTransport?.customerService}`
            }else{
                cc = `pat@agraga.com`
            }
            let from = 'ActionsAgraga <actions@agraga.com>'
            let to = data.entityUserIdList.join(',')
            let subject = taskStore.current.bookingNum + ' // ' + taskStore.current.actionName
            let message = `
        <p>We have a new Action for you!</p>
        <p>Please <strong><a href="<<server>>/myactions/${taskStore.current.bookingNum}ACTLINGACT${taskStore.current.actionName}">Click Here</a></strong> to view and complete</p>
        <br/><br/>
        <p>Thank You</p>
        <p><strong>Team Agraga</strong></p>`
        

            //configStore.setModalMessage('Sending Email ...')
            const doc = {
                from, to, cc, subject, message
            }
            if (!to) {
                doc.to = cc.join(',');
                doc.cc = '';
            }
            if(!to && !cc){
                to = "pat@agraga.com"
              }
            let ret
        try{    
                if (doc.to){
                ret = await MonApi.apost(url, doc)
                }
        }catch(e){
                console.log('ERROR IN SENDING MAIL', e)
            }
        console.log('return from sending mail=',ret)
            //configStore.setModalMessage(`Email sent to ${ret.message.accepted}`)
    }
    static async sendActionAgragaTriggerEmail() {
        let b = bookingsStore.current
        let branch = await MonApi.aget(`${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/branch/${b.branchId}`)
        let country = ""
        if(branch.data){
            country = branch.data.country.toLowerCase()
        }
        if(b?.contract){
            let contract = b?.contract
            let st = contract.shipmentType
            let st1 = st.substr(0, 3).toUpperCase()
        
            let por = contract.portOfReceipt.toLowerCase()
            let fpod = contract.finalPlaceOfDelivery.toLowerCase()
            if (st1 === 'AIR') {
                por = contract.originAirport.toLowerCase()
                fpod = contract.destinationAirport.toLowerCase()
            }
            //console.log('GET ACTIONS FILTERS', por, '/', fpod, '/', country)
            let imp_exp = ''
            if(country.trim().length>0){
                if (por.indexOf(country) >= 0) imp_exp = 'Export'
                else if (fpod.indexOf(country) >= 0) imp_exp = 'Import'
            }
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/common/sendclientactionmail`
        let co = await Taskapi.getEmailsCenterlOperations()
        let to = []
        for (let i = 0; i < co.length; i++) {
            if(taskStore.current.shipmentType.toLowerCase().indexOf('air')!=-1){
                if(
                (co[i]['role'].indexOf('Air export Coordination') != -1 && (imp_exp=="" || imp_exp=="Export")) ||
                (co[i]['role'].indexOf('Air Import Coordination') != -1 && (imp_exp=="" || imp_exp=="Import")) ||
                (co[i]['role'].indexOf('Air export Documentation') != -1 && (imp_exp=="" || imp_exp=="Export")) ||
                (co[i]['role'].indexOf('Air Import Documentation') != -1 && (imp_exp=="" || imp_exp=="Import"))){
                    to.push(co[i]["_id"])
                }
            }else{
                if(
                (co[i]['role'].indexOf('Ocean export Coordination') != -1 && (imp_exp=="" || imp_exp=="Export")) ||
                (co[i]['role'].indexOf('Ocean Import Coordination') != -1 && (imp_exp=="" || imp_exp=="Import")) ||
                (co[i]['role'].indexOf('Ocean export Documentation') != -1 && (imp_exp=="" || imp_exp=="Export")) ||
                (co[i]['role'].indexOf('Ocean Import Documentation') != -1 && (imp_exp=="" || imp_exp=="Import"))){
                    to.push(co[i]["_id"])
                }
            }
        }
        if(to.length==0){
            to.push("pat@agraga.com")
        }
        let data = await MonApi.apost(`${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/listuserIds`, {entityId:taskStore.current.entityId,branchId:bookingsStore.current.branchId})
            let cc;
            let customer = data?.shEntitiesCustomer[0]?.customer;
            if(customer?.crossBorder?.relationshipManager && customer?.crossBorder?.customerService){
                cc = [customer?.crossBorder?.relationshipManager, customer?.crossBorder?.customerService];
            }else if(customer?.domesticTransport?.relationshipManager && customer?.domesticTransport?.customerService){
                console.log(customer?.domesticTransport?.relationshipManager,"***",customer?.domesticTransport?.customerService);
                cc = `${customer?.domesticTransport?.relationshipManager} ,${customer?.domesticTransport?.customerService}`
            }else{
                cc = `pat@agraga.com`
            }
            let from = 'ActionsAgraga <actions@agraga.com>'
            to = to.join(',')
            let subject = "Agraga Booking ID " + taskStore.current.bookingNum + ' | ' + taskStore.current.actionName
            let message = `<p>Customer has completed a <strong>${taskStore.current.actionName}</strong>. Please check and confirm.</p>`
            //configStore.setModalMessage('Sending Email ...')
            const doc = {
                from, to, cc, subject, message
            }
            if (!to) {
                doc.to = cc.join(',');
                doc.cc = '';
            }
            if(!to && !cc){
                to = "pat@agraga.com"
              }
            let ret
        try{    
                if (doc.to){
                ret = await MonApi.apost(url, doc)
                }
        }catch(e){
                console.log('ERROR IN SENDING MAIL', e)
            }
        console.log('return from sending mail=',ret)
        }
            //configStore.setModalMessage(`Email sent to ${ret.message.accepted}`)
    }
    static async getbookingdsr(bookingid) {
        let ret = await MonApi.find('Bookingdsr', { 'agraga_booking_#': bookingid },{})
        return ret
    }
}

export {
    Task
}