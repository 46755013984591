const categoryMapping = {
    customer: "customerType",
    vendor: "vendorType",
  };
  
  const salesMapping = {
    customer: "relationshipManager",
    vendor: "",
  };
  
  const customerServiceMapping = {
    customer: "customerService",
    vendor: "",
  };
  
  class BranchUtils {
    static isIndia(entity) {
      return entity?.entityCountry?.toLowerCase() === "india";
    }
  
    static isCustomer(entity) {
      return entity?.entityType?.toLowerCase() === "customer";
    }
  
    static getType(entity) {
      let entityType = entity.customer
        ? "customer"
        : entity.vendor
        ? "vendor"
        : null;
      return entityType;
    }
  
    static getCategory(entity) {
      const entityType = this.getType(entity);
      return entityType ? entity[entityType][categoryMapping[entityType]] : "";
    }
  
    static getSalesPerson(entity) {
      const entityType = this.getType(entity);
      return entityType ? entity[entityType][salesMapping[entityType]] : "";
    }
  
    static getCustServicePerson(entity) {
      const entityType = this.getType(entity);
      return entityType
        ? entity[entityType][customerServiceMapping[entityType]]
        : "";
    }
  }
  
  export default BranchUtils;
  