import { useSnapshot } from "valtio"
import { entityStore } from "../state/CommonState"
import Actd1s1, { Actd1s1Trigger } from "./act-1date1stage"
import Additionalcostapproval, { AdditionalcostapprovalTrigger } from "./act-additional-cost-approval"
import Aro, { AroTrigger } from "./act-aro"
import Awbupload, { AwbuploadTrigger } from "./act-awb-upload"
import Awbdraftapproval, { AwbdraftapprovalTrigger } from "./act-awbdraftapproval"
import Awbrelease, { AwbreleaseTrigger } from "./act-awbrelease"
import Blupload, { BluploadTrigger } from "./act-bl-upload"
import Blrelease, { BlreleaseTrigger } from "./act-blrelease"
import Boechecklistapproval, { BoechecklistapprovalTrigger } from "./act-boe-checklist-approval"
import Ccchp, { CcchpTrigger } from "./act-ccchp"
import Cro, { CroTrigger } from "./act-cro"
import Delaydorelease, { DelaydoreleaseTrigger } from "./act-delaydorelease"
import Delaypickupdestination, { DelaypickupdestinationTrigger } from "./act-delaypickupdestination"
import DOreleaseair, { DOreleaseairTrigger } from "./act-dorelease-air"
import DOreleaseocean, { DOreleaseoceanTrigger } from "./act-dorelease-ocean"
import Emptypending, { EmptypendingTrigger } from "./act-emptypending"
import Igmmanifest, { IgmmanifestTrigger } from "./act-igm-manifest"
import InvoiceAcceptance, { InvoiceAcceptanceTrigger } from "./act-invoice-acceptance"
import InvoiceAcceptancepayment, { InvoiceAcceptancepaymentTrigger } from "./act-invoice-acceptance-payment"
import Lclao, { LclaoTrigger } from "./act-lclao"
import Otheraction, { OtheractionTrigger } from "./act-otheraction"
import Sbchecklistapproval, { SbchecklistapprovalTrigger } from "./act-sbchecklistapproval"
import { Schedulechange, SchedulechangeTrigger } from "./act-schedulechangeTrigger"
import Shipinstructions, { ShipinstructionsTrigger } from "./act-shipinstructions"
import ShipinstructionsFCL, { ShipinstructionsFCLTrigger } from "./act-shipinstructions-fcl"
import ShipinstructionsLCL, { ShipinstructionsLCLTrigger } from "./act-shipinstructions-lcl"
import Vgmdecl, { VgmdeclInit, VgmdeclTrigger } from "./act-vgmdecl"
import {BookingCancellationReq} from "./booking-cancellation-req"
import Ciplupload, { CipluploadTrigger } from "./ciplupload"
import Pickupappointment, { PickupappointmentTrigger } from "./pickup-appointment"
import TaskDatetime from "./task-datetime"
import { CarrierRelaeseOrder } from "../pages/ActionPreviews/CarrierReleaseOrder"
import { ShippingBillCheckListApproval } from "../pages/ActionPreviews/ShippingBillChecklistApproval"
import Cartingorder, { CartingorderTrigger } from "./act-carting-order"
import ScheduleDeviationsApproval from "./act-schedule-deviations-approval"
import CustomsKycRequirements from "./act-customsKycRequirements"
import { OtherActionsView } from "../pages/ActionPreviews/OtherActionsView"
import { CIPLUpload } from "../pages/ActionPreviews/CIPLUpload"
import CoiCreation,{CoiCreationTrigger} from "./act-coi-creation"
import DocumentRequest,{DocumentRequestTrigger} from "./act-document-request"
import { KycDocumentsActionTrigger } from "./act-kycDocuments"
import InvoiceAcceptances, { InvoiceTrigger } from "./act-invoice"
import InvoicePayment from "./act-invoicePayment"

let bolDetailFCL = {
    marks: '',
    numPackages: '',
    pkgType: '',

    containerNum: '',
    sbNum: '',
    customSealNum: '',
    lineSealNum: '',    
    hsCodes: '',
    commercialInvoiceNum: '',
    otherInfo: '',
    description: '',
    netWeight: '',
    grossWeight: '',
    dimensions: '',
    volume: ''
}
let bolDetail = {
    marks: '',
    numPackages: '',
    pkgType: '',
    description: '',
    netWeight: '',
    grossWeight: '',
    dimensions: '',
    cbm: '',
    shippingBillNum: '',
    otherInfo: ''
}
let vgmData = {
    billOfLading: '',
    shipperName: '',
    shipperLicense: '',
    authorizedOfficial: '',
    authorizedDesignation: '',
    authorizedContactDetails: '',
    containerNum: '',
    containerSize: '',
    maxWeight: '',
    verifiedGrossWeight: '',
    weightMethod: '1',
    wbRegnum: '',
    wbAddress: '',
    wbDate: '',
    wbTime: '',
    wbTimehh: '12',
    wbTimemm: '00',
    wbTimeAP: 'PM',
    wbSlipnum: '',
    hazType: '',
    imdgClass: '',
    packingGroup: '',
    unno: '',
    status: 'PENDING'
}
let invoicePayment = {
    transNum: '',
    transDate: '',
    transAmount: 0,
    deductions: ''
}

const constructUploads = (docs) => {
    /*let ret = {}
    docs.map(e => ret[e] = {
        filename: '',
        approved: '',
        rejectReason: ''
    })*/
    let ret = []
    docs.map((e, i) => ret.push({
        label: e,
        filename: '',
        invoiceNo:'',
        invoiceDate:'',
        approved: '',
        rejectReason: ''
    }))
    return ret
}

const getfilenames = (docs) =>{
    let ret = []
    docs.map((e)=> ret.push(e.label))
    return ret
}
let myactionsConstructUploads = constructUploads
const actionList = [
    {
        "Num": 1,
        "Action": "Commercial Invoice & Packing List Pending",
        "Import": "Yes",
        "Export": "Yes",
        "FCL": "Yes",
        "LCL": "Yes",
        "AIR": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        muiComp : CIPLUpload,
        component: Ciplupload,
        triggercomp: CipluploadTrigger,
        payload: {
            files: constructUploads(['Commercial Invoice', 'Packing List'])
        }
    },
    {
        "Num": 2,
        "Action": "Pick-up Appointment",
        "Export": "Yes",
        "FCL": "Yes",
        "LCL": "Yes",
        "AIR": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        triggercomp: PickupappointmentTrigger,
        component: Pickupappointment,
        payload: {
            details:[{
                address: '',
                pinCode: '',
                contactdetails:[{
                    contactName: '',
                    contactNumber: '',
                    contactEmail: ''
                }],
                pickupDate: '',
                pickupTimeFrom: '',
                pickupTimeTo: ''
            }]
        }
    },
    {
        "Num": 3,
        "Action": "Shipping Bill Checklist Approval",
        "Export": "Yes",
        "FCL": "Yes",
        "LCL": "Yes",
        "AIR": "Yes",
        "D2PCECOC_OCC": "Yes",
        "D2DCECOC_OCC": "Yes",
        "P2PCECOC_OCC":"Yes",
        "P2DCECOC_OCC":"Yes",
        "OCC":"Yes",
        payload: {
            files: constructUploads(['Shipping Bill Checklist']),
            remarks: ''
        },
        muiComp:ShippingBillCheckListApproval,
        triggercomp: SbchecklistapprovalTrigger,
        component: Sbchecklistapproval
    },
    {
        "Num": 4,
        "Action": "Shipping Instructions (Air)",
        "Export": "Yes",
        "AIR": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        bolDetail: {
            marks: '',
            numPackages: '',
            pkgType: '',
            description: '',
            netWeight: '',
            grossWeight: '',
            dimensions: '',
            cbm: '',
            shippingBillNum: '',
            otherInfo: ''
        },
        payload: {
            files: constructUploads(['Shipping Instructions']),

            expName: '',
            expAddress: '',
            expContactPerson: '',
            expContactNumber: '',
            expEmail: '',

            impName: '',
            impAddress: '',
            impContactPerson: '',
            impContactNumber: '',
            impEmail: '',
            
            party1Name: '',
            party1Address: '',
            party1ContactPerson: '',
            party1ContactNumber: '',
            party1Email: '',

            party2Name: '',
            party2Address: '',
            party2ContactPerson: '',
            party2ContactNumber: '',
            party2Email: '',

            expCity: '',
            expZip: '',
            expCountry: '',
            expState: '',
            expTaxid: '',

            impCity: '',
            impZip: '',
            impCountry: '',
            impState: '',
            impTaxid: '',

            party1City: '',
            party1Zip: '',
            party1Country: '',
            party1State: '',

            party2City: '',
            party2Zip: '',
            party2Country: '',
            party2State: '',

            termsDelivery: '',
            termsDeliveryLocation: '',
            por: '',
            airportLoading: '',
            airportDischarge: '',
            fpod: '',
            hazardous: '',
            cargoReadinessDate: '',
            lcNumber: '',
            lcDate: '',
            hsCodes: '',
            invoiceNumber: '',
            invoiceDate: '',
            fumigationRequired: '',
            poNumber: '',
            preCarriage: '',
            cargoValue: '',
            setTemperature: '',
            setValue: '',
            setTemperatureMin: '',
            setValueMin: '',
            setTemperatureMax: '',
            setValueMax: '',
            dataLog: '',
            bolDetails: [{...bolDetail}]
        },
        triggercomp: ShipinstructionsTrigger,
        component: Shipinstructions
    },
    {
        "Num": 31,
        "Action": "Shipping Instructions (FCL)",
        "Export": "Yes",
        "FCL": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        bolDetail: {
            marks: '',
            numPackages: '',
            pkgType: '',
        
            containerNum: '',
            sbNum: '',
            customSealNum: '',
            lineSealNum: '',
            hsCodes: '',
            commercialInvoiceNum: '',
            otherInfo: '',
            description: '',
            netWeight: '',
            grossWeight: '',
            dimensions: '',
            volume: ''
        },
        payload: {
            files: constructUploads(['Shipping Instructions']),
            expName: '',
            expAddress: '',
            expContactPerson: '',
            expContactNumber: '',
            expEmail: '',

            impName: '',
            impAddress: '',
            impContactPerson: '',
            impContactNumber: '',
            impEmail: '',
            
            party1Name: '',
            party1Address: '',
            party1ContactPerson: '',
            party1ContactNumber: '',
            party1Email: '',

            party2Name: '',
            party2Address: '',
            party2ContactPerson: '',
            party2ContactNumber: '',
            party2Email: '',

            expCity: '',
            expZip: '',
            expCountry: '',
            expState: '',
            expTaxid: '',
            impCity: '',
            impZip: '',
            impCountry: '',
            impState: '',
            impTaxid: '',
            party1City: '',
            party1Zip: '',
            party1Country: '',
            party1State: '',
            party2City: '',
            party2Zip: '',
            party2Country: '',
            party2State: '',
            termsDelivery: '',
            termsDeliveryLocation: '',
            por: '',
            pol: '',
            pod: '',
            fpod: '',
            hazardous: '',
            cargoReadinessDate: '',
            lcNumber: '',
            lcDate: '',
            hsCodes1: '',
            invoiceNumber: '',
            invoiceDate: '',
            fumigationRequired: '',
            poNumber: '',
            preCarriage: '',
            preCarriageRail: '',
            cargoValue: '',
            setTemperature: '',
            setValue: '',
            humidityPercentage: '',
            ventillationSettings: '',
            bolDetails: [{...bolDetailFCL}]
        },
        triggercomp: ShipinstructionsFCLTrigger,
        component: ShipinstructionsFCL
    },
    {
        "Num": 32,
        "Action": "Shipping Instructions (LCL)",
        "Export": "Yes",
        "LCL": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        bolDetail: {
            marks: '',
            numPackages: '',
            pkgType: '',
        
            containerNum: '',
            sbNum: '',
            customSealNum: '',
            lineSealNum: '',
            hsCodes: '',
            commercialInvoiceNum: '',
            otherInfo: '',
            description: '',
            netWeight: '',
            grossWeight: '',
            dimensions: '',
            volume: ''
        },
        payload: {
            files: constructUploads(['Shipping Instructions']),
            expName: '',
            expAddress: '',
            expContactPerson: '',
            expContactNumber: '',
            expEmail: '',

            impName: '',
            impAddress: '',
            impContactPerson: '',
            impContactNumber: '',
            impEmail: '',
            
            party1Name: '',
            party1Address: '',
            party1ContactPerson: '',
            party1ContactNumber: '',
            party1Email: '',

            party2Name: '',
            party2Address: '',
            party2ContactPerson: '',
            party2ContactNumber: '',
            party2Email: '',

            expCity: '',
            expZip: '',
            expCountry: '',
            expState: '',
            expTaxid: '',
            impCity: '',
            impZip: '',
            impCountry: '',
            impState: '',
            impTaxid: '',
            party1City: '',
            party1Zip: '',
            party1Country: '',
            party1State: '',
            party2City: '',
            party2Zip: '',
            party2Country: '',
            party2State: '',
            termsDelivery: '',
            termsDeliveryLocation: '',
            por: '',
            airportLoading: '',
            airportDischarge: '',
            fpod: '',
            hazardous: '',
            cargoReadinessDate: '',
            lcNumber: '',
            lcDate: '',
            hsCodes: '',
            invoiceNumber: '',
            invoiceDate: '',
            fumigationRequired: '',
            poNumber: '',
            preCarriage: '',
            cargoValue: '',
            setTemperature: '',
            setValue: '',
            dataLog: '',
            bolDetails: [{...bolDetailFCL}]
        },
        triggercomp: ShipinstructionsLCLTrigger,
        component: ShipinstructionsLCL
    },
    {
        "Num": 5,
        "Action": "BL Draft Approval",
        "Import": "Yes",
        "Export": "Yes",
        "FCL": "Yes",
        "LCL": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            files: constructUploads(['BL Draft']),
            remarks: ''
        },
        triggercomp: SbchecklistapprovalTrigger,
        component: Sbchecklistapproval
    },
    {
        "Num": 42,
        "Action": "Invoice Acceptance",
        "Import": "Yes",
        "Export": "Yes",
        "FCL": "Yes",
        "LCL": "Yes",
        "AIR": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            files: constructUploads(['Invoice']),
            dueDate: '',
            dueTime: '',
            netAmount: 0,
            gstAmount: 0,
            totalAmount: 0,
            paidAmount: 0,
            isFullPayment: 'No',
            paymentReceived: 0,
            currentPayment: {...invoicePayment},
            previousPayments: [],
            currencyType:'',
            remarks: ''
        },
        triggercomp: InvoiceTrigger,
        component: InvoiceAcceptances
    },
    {
        "Num": 43,
        "Action": "Payment Details",
        // triggercomp: InvoiceTrigger,
        component: InvoicePayment
    },
    {
        "Num": 6,
        "Action": "Invoice Acceptance & Payment Details (COD)",
        // "Import": "Yes",
        // "Export": "Yes",
        // "FCL": "Yes",
        // "LCL": "Yes",
        // "AIR": "Yes",
        // "P2P": "Yes",
        // "P2D": "Yes",
        // "D2P": "Yes",
        // "D2D": "Yes",
        // payload: {
        //     files: constructUploads(['Invoice']),
        //     dueDate: '',
        //     dueTime: '',
        //     netAmount: 0,
        //     gstAmount: 0,
        //     totalAmount: 0,
        //     paidAmount: 0,
        //     isFullPayment: 'No',
        //     paymentReceived: 0,
        //     currentPayment: {...invoicePayment},
        //     previousPayments: [],
        //     remarks: ''
        // },
        // triggercomp: InvoiceAcceptancepaymentTrigger,
        component: InvoiceAcceptancepayment
    },
    
    {
        "Num": 7,
        "Action": "Invoice Acceptance (Credit)",
        // "Import": "Yes",
        // "Export": "Yes",
        // "FCL": "Yes",
        // "LCL": "Yes",
        // "AIR": "Yes",
        // "P2P": "Yes",
        // "P2D": "Yes",
        // "D2P": "Yes",
        // "D2D": "Yes",
        // payload: {
        //     files: constructUploads(['Invoice']),
        //     dueDate: '',
        //     dueTime: '',
        //     netAmount: 0,
        //     gstAmount: 0,
        //     totalAmount: 0,
        //     remarks: ''
        // },
        // triggercomp: InvoiceAcceptanceTrigger,
        component: InvoiceAcceptance
    },
    // {
    //     "Num": 6,
    //     "Action": "Invoice Acceptance & Payment Details",
    //     "Import": "Yes",
    //     "Export": "Yes",
    //     "FCL": "Yes",
    //     "LCL": "Yes",
    //     "AIR": "Yes",
    //     "P2P": "Yes",
    //     "P2D": "Yes",
    //     "D2P": "Yes",
    //     "D2D": "Yes",
    //     payload: {
    //         files: constructUploads(['Invoice']),
    //         dueDate: '',
    //         dueTime: '',
    //         netAmount: 0,
    //         gstAmount: 0,
    //         totalAmount: 0,
    //         paidAmount: 0,
    //         isFullPayment: 'No',
    //         paymentReceived: 0,
    //         currentPayment: {...invoicePayment},
    //         previousPayments: [],
    //         remarks: ''
    //     },
    //     triggercomp: InvoiceAcceptancepaymentTrigger,
    //     component: InvoiceAcceptancepayment
    // },
    // {
    //     "Num": 7,
    //     "Action": "Invoice Acceptance",
    //     "Import": "Yes",
    //     "Export": "Yes",
    //     "FCL": "Yes",
    //     "LCL": "Yes",
    //     "AIR": "Yes",
    //     "P2P": "Yes",
    //     "P2D": "Yes",
    //     "D2P": "Yes",
    //     "D2D": "Yes",
    //     payload: {
    //         files: constructUploads(['Invoice']),
    //         dueDate: '',
    //         dueTime: '',
    //         netAmount: 0,
    //         gstAmount: 0,
    //         totalAmount: 0,
    //         remarks: ''
    //     },
    //     triggercomp: InvoiceAcceptanceTrigger,
    //     component: InvoiceAcceptance
    // },
    {
        "Num": 8,
        "Action": "BL Release",
        "Import": "Yes",
        "Export": "Yes",
        "FCL": "Yes",
        "LCL": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            files: constructUploads(['Bill of Lading']),
            blType: 'Seaway'
        },
        triggercomp: BlreleaseTrigger,
        component: Blrelease
    },
    {
        "Num": 9,
        "Action": "Delivery Appointment",
        "Import": "Yes",
        "FCL": "Yes",
        "LCL": "Yes",
        "AIR": "Yes",
        "P2D_CECDC": "Yes",
        "D2D_CECDC": "Yes",
        triggercomp: PickupappointmentTrigger,
        component: Pickupappointment,
        payload: {
            details:[{
                address: '',
                pinCode: '',
                contactdetails:[{
                    contactName: '',
                    contactNumber: '',
                    contactEmail: ''
                }],
                pickupDate: '',
                pickupTimeFrom: '',
                pickupTimeTo: ''
            }]
        }
    },
    {
        "Num": 10,
        "Action": "Delay in Delivery Order release",
        "Import": "Yes",
        "Export": "Yes",
        "FCL": "Yes",
        "LCL": "Yes",
        "AIR": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            reason: '',
            lastFreeDate: ''
        },
        triggercomp: DelaydoreleaseTrigger,
        component: Delaydorelease
    },
    {
        "Num": 11,
        "Action": "Delay in Pick-up at Destination",
        "Import": "Yes",
        "Export": "Yes",
        "FCL": "Yes",
        "LCL": "Yes",
        "AIR": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            reason: '',
            lastFreeDate: ''
        },
        triggercomp: DelaypickupdestinationTrigger,
        component: Delaypickupdestination
    },
    {
        "Num": 12,
        "Action": "Carrier Release Order",
        "Import": "Yes",
        "Export": "Yes",
        "FCL": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            files: constructUploads(['Carrier Release Order']),
            validTill: ''
        },
        muiComp :CarrierRelaeseOrder,
        triggercomp: CroTrigger,
        component: Cro
    },
    {
        "Num": 13,
        "Action": "Airline Release Order",
        "Import": "Yes",
        "Export": "Yes",
        "AIR": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            files: constructUploads(['Airline Release Order']),
            validTill: ''
        },
        triggercomp: AroTrigger,
        component: Aro
    },
    {
        "Num": 14,
        "Action": "LCL Acceptance Order",
        "Import": "Yes",
        "Export": "Yes",
        "LCL": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            files: constructUploads(['Carting Order']),
            validTill: ''
        },
        triggercomp: LclaoTrigger,
        component: Lclao
    },
    {
        "Num": 15,
        "Action": "Empty Container Pick-up Pending",
        "Import": "Yes",
        "Export": "Yes",
        "FCL": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        payload: {
            validTill: ''
        },
        triggercomp: EmptypendingTrigger,
        component: Emptypending
    },
    {
        "Num": 16,
        "Action": "Customs Cleared Cargo Hand-over Pending",
        "Import": "Yes",
        "Export": "Yes",
        "LCL": "Yes",
        "AIR": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        payload: {
            cutOff: ''
        },
        triggercomp: CcchpTrigger,
        component: Ccchp
    },
    {
        "Num": 17,
        "Action": "Stuffed Container Hand-over Pending",
        "Import": "Yes",
        "Export": "Yes",
        "FCL": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        metaData: {
            stage0Text: 'Delay in Container Handover',
            dateLabel: 'Cut-off Date',
            timeLabel: 'Cut-off Time',
            stage1Text1: 'Please note that you have not yet handed-over the stuffed Container.',
            stage1Text2: 'Hand-over cut off is'
        },
        payload: {
            oneDate: '',
            oneTime: ''
        },
        triggercomp: Actd1s1Trigger,
        component: Actd1s1
    },
    {
        "Num": 18,
        "Action": "VGM Declaration",
        "Export": "Yes",
        "FCL": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            files: constructUploads(['VGM Declaration']),
            data: {}
        },
        initializecomp: VgmdeclInit,
        triggercomp: VgmdeclTrigger,
        component: Vgmdecl
    },
    {
        "Num": 19,
        "Action": "Empty Container Return to Carrier Pending",
        "Import": "Yes",
        "Export": "Yes",
        "FCL": "Yes",
        "P2P": "Yes",
        "D2P": "Yes",
        metaData: {
            stage0Text: 'Empty Container Return Delay',
            dateLabel: 'Delay in Pick-up at Destination',
            timeLabel: '',
            stage1Text1: 'Please note that your Buyer has not yet returned the Empty Container to the Carrier.',
            stage1Text2: 'The Last Free Date is'
        },
        payload: {
            oneDate: '',
            oneTime: ''
        },
        triggercomp: Actd1s1Trigger,
        component: Actd1s1
    },
    {
        "Num": 20,
        "Action": "AWB Draft Approval",
        "Import": "Yes",
        "Export": "Yes",
        "AIR": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            files: constructUploads(['Airway Bill Draft']),
            dueDate: '',
            dueTime: '',
            remarks: ''
        },
        triggercomp: AwbdraftapprovalTrigger,
        component: Awbdraftapproval
    },
    {
        "Num": 21,
        "Action": "AWB Release",
        "Import": "Yes",
        "Export": "Yes",
        "AIR": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            files: constructUploads(['Airway Bill'])
        },
        triggercomp: AwbreleaseTrigger,
        component: Awbrelease
    },
    {
        "Num": 22,
        "Action": "Bill of Entry Checklist Approval",
        "Import": "Yes",
        "FCL": "Yes",
        "AIR": "Yes",
        "P2PCECDC": "Yes",
        "P2DCECDC": "Yes",
        "D2PCECDC": "Yes",
        "D2DCECDC": "Yes",

        payload: {
            files: constructUploads(['Bill of Entry Checklist']),
            dueDate: '',
            dueTime: '',
            remarks: ''
        },
        triggercomp: BoechecklistapprovalTrigger,
        component: Boechecklistapproval
    },
    {
        "Num": 23,
        "Action": "Other Actions (LCL)",
        "Import": "Yes",
        "Export": "Yes",
        "LCL": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            files: constructUploads([
                'Stuffing Photos at Origin',
                'Stuffing Photos at Transhipment',
                'De-stuffing photos at Destination',
                'Schedule change approval communcation',
                'Additional cost approval communication'
            ])
        },
        muiComp: OtherActionsView,
        triggercomp: OtheractionTrigger,
        component: Otheraction
    },
    {
        "Num": 24,
        "Action": "IGM Manifest Details",
        "Import": "Yes",
        "FCL": "Yes",
        "LCL": "Yes",
        "P2PCECDC": "Yes",
        "P2DCECDC": "Yes",
        "D2PCECDC": "Yes",
        "D2DCECDC": "Yes",
        payload: {
            preNum: 4,
            igmDetails: [
                {name:'GIGM Number', text:'', dt:''},
                {name:'LIGM Number', text:'', dt:''},
                {name:'TP Number', text:'', dt:''},
                {name:'SMTP Number', text:'', dt:''}
            ]
        },
        triggercomp: IgmmanifestTrigger,
        component: Igmmanifest
    },
    {
        "Num": 32,
        "Action": "IGM Manifest Details (Air)",
        "Import": "Yes",
        "AIR": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            preNum: 1,
            igmDetails: [
                {name:'Airline IGM Number', text:'', dt:''},
            ]
        },
        triggercomp: IgmmanifestTrigger,
        component: Igmmanifest
    },
    {
        "Num": 25,
        "Action": "AWB Upload",
        "Import": "Yes",
        "AIR": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            files: constructUploads(['AWB']),
            isBank: 'No'
        },
        triggercomp: AwbuploadTrigger,
        component: Awbupload
    },
    {
        "Num": 26,
        "Action": "BL Upload",
        "Import": "Yes",
        "FCL": "Yes",
        "LCL": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            files: constructUploads(['Upload the BL']),
            isBank: 'No',
            isOriginal: 'No'
        },
        triggercomp: BluploadTrigger,
        component: Blupload
    },
    {
        "Num": 27,
        "Action": "DO Release (Ocean)",
        "Import": "Yes",
        "FCL": "Yes",
        "LCL": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            files: constructUploads(['Browse & Upload the DO for Ocean']),
            isBROOriginal: 'No',
            isOriginalEndorsedBLReceived: 'No',
        },
        triggercomp: DOreleaseoceanTrigger,
        component: DOreleaseocean
    },
    {
        "Num": 27,
        "Action": "DO Release (Air)",
        "Import": "Yes",
        "AIR": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            files: constructUploads(['Browse & Upload the Screenshot']),
            isBROOriginal: 'No'
        },
        triggercomp: DOreleaseairTrigger,
        component: DOreleaseair
    },
    {
        "Num": 28,
        "Action": "Schedule Change Approval",
        "Import": "Yes",
        "Export": "Yes",
        "AIR": "Yes",
        "LCL": "Yes",
        "FCL": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            changeReason: 'Schedule Not available from selected carrier',
            changeReasonText: '',
            schedules: [],
            acceptStatus: '', // ACCEPT, REJECT
            rejectReason: ''
        },
        triggercomp: SchedulechangeTrigger,
        component: Schedulechange
    },
    {
        "Num": 29,
        "Action": "Other Actions (FCL)",
        "Import": "Yes",
        "Export": "Yes",
        "FCL": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            files: constructUploads([
                'Schedule change approval communcation',
                'Additional cost approval communication',
            ])
        },
        muiComp: OtherActionsView,
        triggercomp: OtheractionTrigger,
        component: Otheraction
    },
    {
        "Num": 30,
        "Action": "Other Actions (Air)",
        "Import": "Yes",
        "Export": "Yes",
        "AIR": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            files: constructUploads([
                'Cargo photos prior to hand-over to Airline',
                'Cargo photos at the time of Delivery',
                'Schedule change approval communcation',
                'Additional cost approval communication'
            ])
        },
        muiComp: OtherActionsView,
        triggercomp: OtheractionTrigger,
        component: Otheraction

    },
    {
        "Num": 33,
        "Action": "Request for Booking Cancellation",
        payload: {
            files: constructUploads(['invoice']),
            reason: '',
            cost_reason: '',
            reject_reason: '',
            cancelstatus:''
        },
        component: BookingCancellationReq
    },
    {
        "Num":34,
        "Action":"Cost Adjustment Approval",
        "Import": "Yes",
        "Export": "Yes",
        "AIR": "Yes",
        "LCL": "Yes",
        "FCL": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        payload: {
            reason: '',
            rejectReason:'',
            total:0.0,
            chargesList:[],
            oldchargesList:[],
            showModal:false,
            quoteValue:0,
            quoteValueUSD:0
        },
        triggercomp: AdditionalcostapprovalTrigger,
        component: Additionalcostapproval
    },{
        "Num":35,
        "Action":"Schedule Deviations",
        payload: {
            reason: '',
            rejectReason:'',
            total:0.0,
            chargesList:[],
            oldchargesList:[],
            showModal:false,
            quoteValue:0,
            quoteValueUSD:0
        },
        component: ScheduleDeviationsApproval
    },
    {
        "Num":36,
        "Action":"Carting Order",
        payload: {
            shipping_instructions_cut_off:"",
            vgm_cut_off:"",
            gate_cut_off:"",
            etd_at_pol:"",
            eta_fpod:""
        },
        triggercomp: CartingorderTrigger,
        component: Cartingorder
    },{
        "Num":37,
        "Action":"Customs KYC Requirements",
        payload: {
            reason: '',
            rejectReason:'',
            total:0.0,
            chargesList:[],
            oldchargesList:[],
            showModal:false,
            quoteValue:0,
            quoteValueUSD:0
        },
        component: CustomsKycRequirements
    },{
        "Num":38,
        "Action":"Additional Document Request",
        "Import": "Yes",
        "Export": "Yes",
        "FCL": "Yes",
        "LCL": "Yes",
        "AIR": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        triggercomp:DocumentRequestTrigger,
        component: DocumentRequest
    },
    // {
    //     "Num": 39,
    //     "Action": "KYC Documents",
    //     "Import": "Yes",
    //     "Export": "Yes",
    //     "FCL": "Yes",
    //     "LCL": "Yes",
    //     "P2P": "Yes",
    //     "P2D": "Yes",
    //     "D2P": "Yes",
    //     "D2D": "Yes",
    //     "AIR":  "Yes",
    //     triggercomp: KycDocumentsActionTrigger
    // },

    {
        "Num": 40,
        "Action": "COI Creation (Insurance)",
        "Import": "Yes",
        "Export": "Yes",
        "AIR": "Yes",
        "LCL": "Yes",
        "FCL": "Yes",
        "P2P": "Yes",
        "P2D": "Yes",
        "D2P": "Yes",
        "D2D": "Yes",
        // payload: {
        //     changeReason: 'Schedule Not available from selected carrier',
        //     changeReasonText: '',
        //     schedules: [],
        //     acceptStatus: '', // ACCEPT, REJECT
        //     rejectReason: ''
        // },
        triggercomp: CoiCreationTrigger,
        component: CoiCreation
    },
    
]
const getActionList = () => actionList
const getActionListNames = () => actionList.map(e => e.Action)
const getActionsForShipmentType = (b, entity, pendingActions = []) => {
    if(b?.contract){
    let contract = b?.contract
    let country = ""
    for (let i = 0; i < entity?.branch.length; i++) {
        if(b.branchId==entity?.branch[i]._id){
            country = entity?.branch[i].country.toLowerCase();
        }
    }
    //return actionList.map(e => e.Action)
    let pending = pendingActions.map(e => e.actionName)
    let st = contract.shipmentType
    let st1 = st.substr(0, 3).toUpperCase()

    let por = contract.portOfReceipt.toLowerCase()
    let fpod = contract.finalPlaceOfDelivery.toLowerCase()
    if (st1 === 'AIR') {
        por = contract.originAirport.toLowerCase()
        fpod = contract.destinationAirport.toLowerCase()
    }
    //console.log('GET ACTIONS FILTERS', por, '/', fpod, '/', country)
    let imp_exp = ''
    if (por.indexOf(country) >= 0) imp_exp = 'Export'
    else if (fpod.indexOf(country) >= 0) imp_exp = 'Import'
        let Origin = (contract.origin.length > 0 && st1 !== 'AIR' )? contract.origin : contract.originAirport
        let Destination = (contract.destination.length > 0 && st1 !== 'AIR') ? contract.destination : contract.destinationAirport
        let Scope = contract.shipmentScope

        console.log(Origin.includes("Chennai"))
    let actions = []
    for (let i = 0; i < actionList.length; i++) {
        let toAdd = false
        let e = actionList[i]
        if (e[st1] && e[st1] === 'Yes') toAdd = true
        if (toAdd) {
            if (imp_exp && e[imp_exp] !== 'Yes') toAdd = false
        }
        if(toAdd){
            if(Scope=='Port-to-Port'){
                if(e['P2P'] == 'Yes'){
                    toAdd = true
                }else{
                    toAdd = false
                }
                if(e['P2PCECOC_OCC']=== 'Yes' && e['OCC']==='Yes'){
                    if(country.toLowerCase().includes("india") && Origin.toLowerCase().includes("india")&&b?.contract?.fbaOCC === 'Yes'){
                        toAdd = true
                    }else{
                        toAdd = false
                    }
                }
                if(e['P2PCECDC']=='Yes'){
                    if(country.toLowerCase().includes("india") && Destination.toLowerCase().includes("india")){
                        toAdd = true
                    }else{
                        toAdd = false
                    }
                }
            }
            if(Scope=='Port-to-Door'){
                if(e['P2D'] == 'Yes'){
                    toAdd = true
                }else{
                    toAdd = false
                }
                if(e['P2DCECOC_OCC']=== 'Yes' && e['OCC']==='Yes'){
                    if(country.toLowerCase().includes("india") && Origin.toLowerCase().includes("india")&&b?.contract?.fbaOCC === 'Yes'){
                        toAdd = true
                    }else{
                        toAdd = false
                    }
                }
                if(e['P2DCECDC']=='Yes'){
                    if(country.toLowerCase().includes("india") && Destination.toLowerCase().includes("india")){
                        toAdd = true
                    }else{
                        toAdd = false
                    }
                }
                if(e['P2D_CECDC']=='Yes'){
                    if(Destination.toLowerCase().includes(country.toLowerCase())){
                        toAdd = true
                    }else{
                        toAdd = false
                    }
                }
            }
            if(Scope=='Door-to-Port'){
                if(e['D2P'] == 'Yes'){
                    toAdd = true
                }else{
                    toAdd = false
                }
                if(e['D2PCECOC_OCC']=== 'Yes' && e['OCC']==='Yes'){
                    if(country.toLowerCase().includes("india") && Origin.toLowerCase().includes("india")&&b?.contract?.fbaOCC === 'Yes'){
                        toAdd = true
                    }else{
                        toAdd = false
                    }
                }
                if(e['D2PCECDC']=='Yes'){
                    if(country.toLowerCase().includes("india") && Destination.toLowerCase().includes("india")){
                        toAdd = true
                    }else{
                        toAdd = false
                    }
                }
                if(e['D2PCECOC']=='Yes'){
                    if(country.toLowerCase().includes("india") && Origin.toLowerCase().includes("india")){
                        toAdd = true
                    }else{
                        toAdd = false
                    }
                }
            }
            if(Scope=='Door-to-Door'){
                if(e['D2D'] == 'Yes'){
                    toAdd = true
                }else{
                    toAdd = false
                }
                if(e['D2DCECOC_OCC']=== 'Yes' && e['OCC']==='Yes'){
                    if(country.toLowerCase().includes("india") && Origin.toLowerCase().includes("india")&&b?.contract?.fbaOCC === 'Yes'){
                        toAdd = true
                    }else{
                        toAdd = false
                    }
                }
                if(e['D2DCECDC']=='Yes'){
                    if(country.toLowerCase().includes("india") && Destination.toLowerCase().includes("india")){
                        toAdd = true
                    }else{
                        toAdd = false
                    }
                }
                if(e['D2DCECOC']=='Yes'){
                    if(country.toLowerCase().includes("india") && Origin.toLowerCase().includes("india")){
                        toAdd = true
                    }else{
                        toAdd = false
                    }
                }
                if(e['D2D_CECDC']=='Yes'){
                    if(Destination.toLowerCase().includes(country.toLowerCase())){
                        toAdd = true
                    }else{
                        toAdd = false
                    }
                }
            }
        }
        if (toAdd && (pending.indexOf(e.Action) < 0||pending.includes('Additional Document Request'))) actions.push(e.Action)            
    }

    if(!entity.isCreditCustomer || entity.isCreditCustomer==undefined){
        entity.isCreditCustomer = 'No'
    }
    // let a = []
    // actions.map(e=>{
    //     if(e!='Invoice Acceptance & Payment Details' && e!='Invoice Acceptance'){
    //         if(e=='Invoice Acceptance & Payment Details (COD)' && entity.isCreditCustomer == 'No'){
    //             a.push(e)
    //         }   
            
    //         if(e=='Invoice Acceptance (Credit)' && entity.isCreditCustomer == 'Yes'){
    //             a.push(e)
    //         }
    
    //         if(e!='Invoice Acceptance & Payment Details (COD)' && e!='Invoice Acceptance (Credit)'){
    //             a.push(e)
    //         } 
    //     }
          
    // })
    return actions
    }else{
        return []
    }
}
const getComponent = (actionName) => actionList.find(e => e.Action === actionName)?.component
const getTriggercomp = (actionName) => actionList.find(e => e.Action === actionName)?.triggercomp
const getPayload = (actionName) => actionList.find(e => e.Action === actionName).payload
const getAction = (actionName) => actionList.find(e => e.Action === actionName)
const addVgmdata = (task, containerType) => task.data[containerType] = { ...vgmData }
export {
    getActionList,
    getActionListNames,
    getActionsForShipmentType,
    getComponent,
    getTriggercomp,
    getPayload,
    getAction,
    addVgmdata,
    vgmData,
    myactionsConstructUploads,
    invoicePayment,
    getfilenames
}