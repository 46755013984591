import { WelcomeCard } from "../mui-components/welcomecard";
import Add from "../assets/images/plus.svg"
import { breadCrumbStore, configStore, contractsStore, filtersState, newDashboardState, viewBookingStore } from "../state/CommonState";
import trackIcon from "../assets/images/track2.svg";
import contractIcon from "../assets/images/contract.svg";
import pendingAction from "../assets/images/pendingitems.svg";
import ApiAgTable from "../components/ag-table/api_ag-table";
import { Nodata } from "../mui-components/nodatafound";
import { CenteredCircularProgress } from "../mui-components/CircularProgress";
import { useEffect, useRef, useState } from "react";
import { useSnapshot } from "valtio";
import Utils, { Constants } from "../util/Utils";
import { enquiryStore } from "../enquiry/enquiry-data";
import BookingsApi from "../state/bookings-api";
import { taskStore } from "../mytasks/task-store";
import arrow from "../assets/images/yellowarrow.svg";
import { HorizontalDashedLine } from "../mui-components/HorizontalDashedLine";
import arrowtilted from "../assets/images/arrowtilted.svg"
 
export function DashboardV2(){
    useEffect(()=>{
       breadCrumbStore.onBackArrow = null;
    },[])
    return <div className="flex w-full flex-col h-full">
        <div className="flex flex-row w-full h-max justify-between items-center">
            <WelcomeCard/>
            <button onClick={()=>{
                filtersState.initialize();
                breadCrumbStore.setBreadCrumbs(["Create Booking"],"Create Booking");
              newDashboardState.selectedTab = 0;  
            }} type="button" className="border-0 h-[40px] w-[169px] bg-blue-dark rounded-[5px]">
                <div className="flex flex-row justify-center items-center gap-[5px]">
                    <img src={Add}/>
                    <p className="font-semibold text-white">Create Booking</p>
                </div>
            </button>
        </div>
        <div className="flex flex-1 flex-row py-3 gap-2 w-full h-[89%]">
            <div className="flex flex-col gap-2 w-[70%]">
               <TrackBookings/>
               <Actions/>
            </div>
            <div className="flex flex-col gap-2 w-[30%]">
               <Contracts/>
               {/* <PendingReconciliation/> */}
            </div>
        </div>
    </div>
}

function getText(status) {
    const Text = {
        delayed: "Yet to commence",
        ontime: "In-Transit",
        inprogress : "In-Transit",
        completed: "Completed",
        "in-transit": "In-Transit",
        complete: "Completed",
        cancelled: "Cancelled",
        "cancellation requested" : "Cancellation Requested"
    }
    return Text[status?.toLowerCase()];
}

function getColorForTag(status) {
    const Color = {
        delayed: "#4BA7DD33",
        ontime: "#F4B14A33",
        completed: "#71A28233",
        complete: "#71A28233",
        inprogress:"#F4B14A33",
        "in-transit": "#F4B14A33",
        cancelled : "#F25B5B33",
        "cancellation requested" : "#FF751633"
    }
    return Color[status?.toLowerCase()];
}

function getColorForTagText(status) {
    const Color = {
        delayed: "#4BA7DD",
        ontime: "#F4B14A",
        completed: "#71A282",
        complete: "#71A282",
        "inprogress": "#F4B14A",
        "in-transit": "#F4B14A",
        cancelled : "#F25B5B",
        "cancellation requested" : "#FF7516"
    }
    return Color[status?.toLowerCase()];
}


function getStatusOfBooking(status) {
    console.log("the status is ", status);
        if (status === 'Y' || status === "yes" || status === "ARCHIVED") {
            return "completed";
        } else if(status==="INPROGRESS") {
            return "ontime";
        }else if(status === "SUBMITTED"){
            return "delayed";
        }else{
            return status?.toLowerCase();
        }
    }

    function getStatusOfBooking2(booking) {
            if (booking.status_closed === 'Y' || booking.status_closed === 'Yes' || booking.booking.status === 'ARCHIVED') {
                return "completed";
            } else if(booking.status_closed === 'N' && booking['gatein_pol'] || booking?.containerlist?.some(container => container.gatein_pol)) {
                return "ontime";
            }else if((booking.status_closed === 'N' || !booking.status_closed) && !(booking['gatein_pol'] || booking?.containerlist?.some(container => container.gatein_pol)) && booking.booking.status === "SUBMITTED"){
                return "delayed";
            }else{
                return booking.booking.status?.toLowerCase();
            }
        }    
    
function gotoDashboard(){
    newDashboardState.selectedTab = 1;
}    

function TrackBookings(){
    const config = useSnapshot(configStore);

    const [apiState, setApiState] = useState({ data: null, isLoading: true });
    useEffect(() => {
        try {
            setApiState(() => ({ data: null, isLoading: true }));
            ApiAgTable.getAllDsr4Display(0, 5, "", "", { filter: "ALL", filter1: "ALL" }).then(async (data) => {
                let lres = await data.json();
                setApiState({ isLoading: false, data: lres })
            })
        } catch (error) {
            setApiState(() => ({ data: null, isLoading: true }));
        }

    }, [config.currentSelectedEntity , config.currentSelectedBranch])
    
    return  <div className="min-h-[55%] max-h-[55%] w-min-[875px] w-full">
    <DashboardCard src={trackIcon} title={"Track Bookings"} viewAllOnClick={()=>{
         if(!configStore.currentSelectedEntity?.entityId){
            return
              }else {
                breadCrumbStore.setBreadCrumbs(["My Bookings"]);
             newDashboardState.selectedTab = 5;
              }
    }}>
     <div className="flex flex-col overflow-y-auto h-full">
     {apiState.isLoading ? <CenteredCircularProgress  /> : apiState.data?.results1 && apiState.data?.results1.length > 0 ?   <table className="table-fixed mr-1 max-h-full min-h-full">
  <thead className="rounded-md bg-[#F1F7FB] ">
    <tr>
      <th className="px-2 py-2 font-medium text-sm text-grey">Booking ID</th>
      <th className="px-2 py-2 font-medium text-sm text-grey">Mode</th>
      <th className="px-2 py-2 font-medium text-sm text-grey">Arrival Date</th>
      <th className="px-2 py-2 font-medium text-sm text-grey">Origin</th>
      <th className="px-2 py-2 font-medium text-sm text-grey">Destination</th>
      <th className="px-2 py-2 font-medium text-sm text-grey">Status</th>
      <th className="px-2 py-2 font-medium text-sm text-grey">Track</th>
    </tr>
  </thead>
  <tbody className="px-2">
  {apiState.data?.results1.map((booking, i) =>{
let { contract } = booking.booking;
const isOriginDoor = contract.shipmentScope.split("-")[0].toLowerCase() === "door";
const isDestinationDoor = contract.shipmentScope.split("-")[2].toLowerCase() === "door";
let status = booking.status_closed == 'Y' || booking.status_closed == 'Yes' || booking.booking.status == 'ARCHIVED' ? "ARCHIVED" : booking.booking.status;
let theStatusOfBooking = getStatusOfBooking(status)
const textColor = getColorForTagText(theStatusOfBooking)
const textTagColor = getColorForTag(theStatusOfBooking);
  return  <tr className="bg-white border-b-1 align-middle border-t-0 border-l-0 border-r-0  border-[1px] border-solid border-[#DDDDDD] w-full text-sm hover:bg-[#F5F5F5] max-h-[50px]">
      <td className="px-2 py-3 font-bold text-black">{booking._id}</td>
      <td className="px-2 py-3">{contract?.shipmentType?.split(" ")[0]?.toUpperCase()}</td>
      { booking.shipmentType.toLowerCase()==="air" ? <td className="px-2 py-3">{Utils.convertToDDMMYYYY(booking.eta_at_arrival_airport)}</td> : <td className="px-2 py-3">{booking?.eta_fpod?.length > 0 ? Utils.convertToDDMMYYYY(booking?.eta_fpod)  : Utils.convertToDDMMYYYY(booking.etd_pod)}</td>}
      <td className="px-2 py-3">{Utils.getCity(contract.origin, isOriginDoor)}</td>
      <td className="px-2 py-3">{contract?.multidest?.length>1? "Multiple" : Utils.getCity(contract.destination, isDestinationDoor)}</td>
      <td className="px-2 py-3"><div style={{backgroundColor:textTagColor,color:textColor}} className={`py-[1px] px-2 rounded-[60px] h-max w-max text-sm`}>{getText(theStatusOfBooking)}</div></td>
      <td className="px-2 py-2"><button onClick={()=>{
        breadCrumbStore.setBreadCrumbs(["My Bookings",`Booking`]);
        breadCrumbStore.id = booking._id
          viewBookingStore.current = booking;
          viewBookingStore.accordionToBeOpended = 0;
          enquiryStore.initializeWithConract(contract);
          newDashboardState.selectedTab = 5;
          breadCrumbStore.onBackArrow = ()=>{
            gotoDashboard();
          }
      }} type="button" className="bg-blue-light max-h-[28px] h-[28px] w-[71px] rounded-[5px] border-0 text-white">Track</button></td>
      {/* <td className="pr-4 py-2"><div class="flex items-center h-full"></div></td>
      <td className="pr-4 py-2"><div class="flex items-center h-full">1961</div></td> */}
    </tr>
  }
)}
  </tbody>
</table> : <Nodata />}  
   
     </div>
    </DashboardCard>
</div>
}

function calculateTimeDifference(dateStr, timeStr) {
    // Convert input strings to a Date object
    timeStr = timeStr.padStart(5, '0');
    const targetDateTime = new Date(`${dateStr}T${timeStr}`);
    const currentDateTime = new Date();


    const timeDiffMillis = targetDateTime - currentDateTime;



    // Calculate days, hours, and minutes
    const days = Math.round(timeDiffMillis / (24 * 60 * 60 * 1000));
    const hours = Math.round((timeDiffMillis % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    const minutes = Math.round((timeDiffMillis % (60 * 60 * 1000)) / (60 * 1000));

    

    if (days != 0) {
        const trend = decideDueTrend(days, 3);
        return { unit: "days", ...trend };
    } else if (hours != 0) {
        const trend = decideDueTrend(hours, 3);
        return { unit: "hours", ...trend };
    } else {
        const trend = decideDueTrend(minutes, 30);
        return { unit: "minutes", ...trend };
    }
}

function decideDueTrend(value, limit) {
    if (value < 0) {
        return { value: Math.abs(value), color: "#F25B5B", tag: "Overdue for" }
    } else {
        if (value < limit) {
            return { value: Math.abs(value), color: "#EBB02D", tag: "Due in" }
        }
        return { value: Math.abs(value), color: "#555555", tag: "Due in" }
    }
}

function Actions(){
    const config = useSnapshot(configStore);
    const [apiState, setApiState] = useState({ data: null, isLoading: true });
    useEffect(() => {
        try {
            setApiState(() => ({ data: null, isLoading: true }));
            ApiAgTable.myactionsData(0, 5, "", "", {
                actionType
                    :
                    "CUSTOMER",
                displayStatus
                    :
                    "PENDING"
            }).then(async (data) => {
                setApiState({ isLoading: false, data: await data.json() })
            })
        } catch (error) {
            setApiState(() => ({ data: null, isLoading: true }));
        }
    }, [config.currentSelectedEntity, config.currentSelectedBranch])
    // flex flex-1 min-h-[45%] max-h-[45%]
    return <div className="min-h-[45%] max-h-[45%] w-min-[875px] w-full">
    <DashboardCard src={pendingAction} title={"Pending Actions"}  viewAllOnClick={()=>{
         if(!configStore.currentSelectedEntity?.entityId){
            return
              }else {
             newDashboardState.selectedTab = 4;
              }
    }}>
    <div className="flex flex-col overflow-y-auto h-full">
     {apiState.isLoading ? <CenteredCircularProgress  /> : apiState.data?.results && apiState.data?.results.length > 0 ?   <table className="table-fixed mr-1 max-h-full">
  <thead className="rounded-md bg-[#F1F7FB] ">
    <tr>
      <th className="px-2 py-2 font-medium text-sm text-grey">Booking ID</th>
      <th className="px-2 py-2 font-medium text-sm text-grey">Mode</th>
      <th className="px-2 py-2 font-medium text-sm text-grey">Origin</th>
      <th className="px-2 py-2 font-medium text-sm text-grey">Destination</th>
      <th className="px-2 py-2 font-medium text-sm text-grey">Action</th>
      <th className="px-2 py-2 font-medium text-sm text-grey">Due Date</th>
    </tr>
  </thead>
  <tbody className="px-2">
  {apiState.data.results.map((action, i) =>{

let dueDate;
if (action?.dueDate?.length > 0) {
    if (action?.dueTime?.length > 0) {
        dueDate = calculateTimeDifference(action.dueDate, action.dueTime);
    } else {
        dueDate = calculateTimeDifference(action.dueDate, "00:00");
    }
}
const isOriginDoor = action.shipmentScope.split("-")[0].toLowerCase() === "door";
const isDestinationDoor = action.shipmentScope.split("-")[2].toLowerCase() === "door";

  return  <tr onClick={()=>{
    BookingsApi.getBooking(action.bookingNum, (data) => {
        contractsStore.current = data.contract
        // enquiryStore.initializeWithConract(data.contract)

        taskStore.current = action
        taskStore.currentContainer = ''
        taskStore.current.lastview = "CUSTOMER"
        if (taskStore.current.isValid == undefined) taskStore.current.isValid = true
        if (taskStore.current.isValidfield == undefined) taskStore.current.isValidfield = []
        taskStore.myActionVisible = true
       newDashboardState.selectedTab = 4
    })
    breadCrumbStore.onBackArrow = ()=>{
        gotoDashboard();
      }
  }} className="bg-white border-b-1 align-middle border-t-0 border-l-0 border-r-0  border-[1px] border-solid border-[#DDDDDD] w-full text-sm hover:bg-[#F5F5F5] hover:cursor-pointer">
      <td className="px-2 py-3">{action.bookingNum}</td>
      <td className="px-2 py-3">{action?.shipmentType?.split(" ")[0]?.toUpperCase()}</td>
      <td className="px-2 py-3">{Utils.getCity(action.origin, isOriginDoor)}</td>
      <td className="px-2 py-3">{action?.multidest?.length>1? "Multiple" : Utils.getCity(action.destination, isDestinationDoor)}</td>
      <td className="px-2 py-3  font-bold text-black">{action.actionName}</td>
      <td className="px-2 py-2" style={{color:dueDate?.color}}>{Utils.convertToDDMMYYYY(action.dueDate)}</td>
      {/* <td className="pr-4 py-2"><div class="flex items-center h-full"></div></td>
      <td className="pr-4 py-2"><div class="flex items-center h-full">1961</div></td> */}
    </tr>
  }
)}
  </tbody>
</table> : <Nodata />}  
   
     </div>
    </DashboardCard>
</div>
}

function Contracts(){
    const config = useSnapshot(configStore);   
    const [apiState, setApiState] = useState({ data: null, isLoading: true });
    const abortControllerRef = useRef(null);
    useEffect(()=>{ 
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        setApiState(() => ({ data: null, isLoading: true }));
        ApiAgTable.uploadCheduleData(0, 5, "", [], { date:Utils.getCurrentDateString(),
            mode:Constants.contracts,
            type : "ALL",
            status : "ALL"},abortController.signal).then(res=>{
            res.clone().json().then((res) => {
                if (JSON.stringify(apiState.data?.results) != JSON.stringify(res?.results)) {
                    if(JSON.stringify(apiState.data)!=JSON.stringify(res) && res.results && res.results.length > 0){
                        setApiState(() => ({ data: res, isLoading: false }));
                    }else{
                        setApiState(() => ({ data: null, isLoading: false }));
                    }
                }
                    setApiState((prev) => ({ ...prev, isLoading: false }));
            }, err => {
                setApiState((prev) => ({ ...prev, isLoading: false }));
                console.log(err)
            })
        }).catch((e)=>{
            setApiState(() => ({ data: null, isLoading: false }));
        })
    },[config.currentSelectedEntity, config.currentSelectedBranch])
    return <div className="min-h-[55%] max-h-[55%] min-w-[431px] w-full ">
    <DashboardCard viewAllOnClick={()=>{
        breadCrumbStore.setBreadCrumbs([Constants.createbookings])
        newDashboardState.selectedTab = 3
        contractsStore.navigateFromCharts = true;
    }} src={contractIcon} title={"Contracts Available"}>
        <div className="h-full w-full overflow-y-auto gap-2 pr-1">
    {apiState.isLoading ? <CenteredCircularProgress  /> : apiState.data?.results && apiState.data?.results.length > 0 ? 
      apiState.data?.results.map((contract)=><Contract contract={contract} />)
    : <Nodata message="No Contracts Available"/>  }
    </div>
    </DashboardCard>
</div>
}

function Contract({contract}){
    const isOriginDoor = contract.shipmentScope.split("-")[0].toLowerCase() === "door";
const isDestinationDoor = contract.shipmentScope.split("-")[2].toLowerCase() === "door";
    return <div className="group relative h-[88px] w-full my-1">
    <div onClick={()=>{
        enquiryStore.initializeWithConract(contract)
                            contractsStore.current = contract;
        //if (loginStore.isClient()) Api.createContractFromQuote(contractId)
        configStore.homescreenCurrentView = 'view'
         configStore.scheduleOrCarrierform = 'schedule'
        let stype = enquiryStore.current.shipmentType.substr(0, 3).toLowerCase()
        if (stype === 'lcl') configStore.homescreenCurrentShipmentType = 'LCL'
        else if (stype === 'fcl') configStore.homescreenCurrentShipmentType = 'FCL'
        else configStore.homescreenCurrentShipmentType = 'Air'
        configStore.homescreenCurrentShipmentScope = enquiryStore.current.shipmentScope;
        breadCrumbStore.setBreadCrumbs([Constants.createbookings,Constants.chooseschedule]);
        breadCrumbStore.id = contract._id
        newDashboardState.selectedTab = 3
        breadCrumbStore.onBackArrow = ()=>{
            gotoDashboard();
          }
    }} className="absolute h-[65px] bottom-0 w-full bg-white border-1 border-[#D4D4D4] border-solid rounded-[5px] group-hover:bg-[#F5F5F5] rounded-tl-none flex flex-row items-center justify-around hover:cursor-pointer">
        <div className="h-[38px] w-[89px] flex flex-col justify-between items-center mx-2">
            <p className="text-[#49854A] text-xs font-medium">Valid Upto</p>
            <p className="text-[#49854A] font-medium">{Utils.formatDateBooking(contract.validUpto,false)}</p>
        </div>
        <div className="h-[30px] w-[1px] bg-[#D4D4D4] mx-1"></div>
        <div className="h-[38px] min-w-[157px] flex-1 flex flex-col justify-between mx-2">
          <p className="text-sm font-bold w-full">{contract?.shipmentType?.split(" ")[0]?.toUpperCase()}</p>
          <div className="flex flex-row w-full justify-between items-center">
          <p className="text-black text-sm font-bold text-ellipsis overflow-hidden whitespace-nowrap">{Utils.getCity(contract.origin, isOriginDoor)}</p>
          <HorizontalDashedLine dashArray="3,2" width="50px"/>
          <p className="text-black text-sm font-bold text-ellipsis overflow-hidden whitespace-nowrap">{contract?.multidest?.length>1? "Multiple" : Utils.getCity(contract.destination, isDestinationDoor)}</p>
          </div>
        </div>
        <div className="h-[30px] w-[1px] bg-[#D4D4D4] mx-1"></div>
        <img className="mx-2" src={arrow} />
    </div>
        <div className="absolute bg-white flex justify-center items-center h-[22px] top-[2px] px-2 rounded-t-[5px] border-1 border-solid border-[#D4D4D4] border-t-1 border-l-1 border-r-1 border-b-0 group-hover:bg-[#F5F5F5]  text-black text-xs font-bold">{contract._id}</div>
    </div>
}

function PendingReconciliation(){
    return <div className="h-full w-full min-h-[45%] max-h-[45%] min-w-[431px] overflow-y-auto">
    <DashboardCard src={contractIcon} title={"Pending Reconciliation"}>
        <div className="w-full rounded-[5px] border-1 border-solid border-[#D4D4D4] hover:bg-[#F5F5F5] flex flex-row p-2 justify-between">
           <div className="flex flex-col gap-2">
            <p className="text-xs text-blue-dark font-bold">Paid at</p>
            <p className="font-bold text-black">12/03/2023</p>
           </div>
           <div className="flex flex-col gap-2">
            <p className="text-xs text-blue-dark font-bold">Amount Paid</p>
            <p className="font-bold text-black">₹ 12,000.00</p>
           </div>
           <img height="21px" width="21px" src={arrowtilted} />
        </div>

        <div className="w-full rounded-[5px] border-1 border-solid border-[#D4D4D4] hover:bg-[#F5F5F5] flex flex-row p-2 justify-between">
           <div className="flex flex-col gap-2">
            <p className="text-xs text-blue-dark font-bold">Paid at</p>
            <p className="font-bold text-black">12/03/2023</p>
           </div>
           <div className="flex flex-col gap-2">
            <p className="text-xs text-blue-dark font-bold">Amount Paid</p>
            <p className="font-bold text-black">₹ 12,000.00</p>
           </div>
           <img height="21px" width="21px" src={arrowtilted} />
        </div>

        <div className="w-full rounded-[5px] border-1 border-solid border-[#D4D4D4] hover:bg-[#F5F5F5] flex flex-row p-2 justify-between">
           <div className="flex flex-col gap-2">
            <p className="text-xs text-blue-dark font-bold">Paid at</p>
            <p className="font-bold text-black">12/03/2023</p>
           </div>
           <div className="flex flex-col gap-2">
            <p className="text-xs text-blue-dark font-bold">Amount Paid</p>
            <p className="font-bold text-black">₹ 12,000.00</p>
           </div>
           <img height="21px" width="21px" src={arrowtilted} />
        </div>
    </DashboardCard>
</div>
}

function DashboardCard({src,title,viewAllOnClick,children}){
    return <div className="flex flex-col h-full w-full bg-white rounded-xl shadow-custom p-3 gap-2">
             <div className="w-full flex flex-row justify-between">
                <div className="flex flex-row justify-start items-center gap-[5px]">
                    <div className="flex justify-center items-center bg-[#2C358A33] rounded-md h-[40px] w-[40px]">
                    <img src={src} />
                    </div>
                    <p className="text-black font-semibold text-[18px]">{title}</p>
                </div>
                <button onClick={viewAllOnClick} type="button" className="underline text-yellow font-semibold border-0 bg-white">View All</button>
             </div>
             {children}
    </div>
}