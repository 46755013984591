import { Box, CircularProgress, InputAdornment, TextField, Typography } from "@mui/material";
import { CiSearch } from "react-icons/ci";
import { Button } from "@mui/material";
import addIcon from "../assets/images/AddIcon.svg";
import Agproducttab from "../components/Agproducttab";
import { useEffect, useReducer, useRef, useState } from "react";
import { useSnapshot } from "valtio";
import { configStore, newDashboardState } from "../state/CommonState.js";
import Utils from "../util/Utils.js";
import ApiAgTable from "../components/ag-table/api_ag-table.js";
import { Nodata } from "../mui-components/nodatafound.js";
import { CenteredCircularProgress } from "../mui-components/CircularProgress.js";
import Api from "../state/Api.js";
import { BookingDetailCard } from './TrackMyBookingCard.js'
import NewBookingPopup from "./NewBookingPopup.js";
import trackMyBookingOceanStore from "./TrackMyBooking-store.js";
import { visibilityStore } from "./visibilityStore.js";
import { loader } from "../util/loader.js";
import { PAGE_CHANGED, PAGE_SIZE_CHANGED, PAGE_SORT_CHANGED, PAYLOAD_CHANGED, reducer, TOTAL_COUNT_CHANGED } from "../components/ag-table/ag-reducer.js";
import { usePagination, useSortBy, useTable } from "react-table";
import { DRS_COLUMNS } from "../components/ag-table/columns.js";
import ReactTablePagination from "../components/ag-table/ReactTablePagination.js";


// export default function SelectedBooking() {
//     useSnapshot(visibilityStore)
//     useSnapshot(newDashboardState)
//     const productList = ["In-Progress", "Completed"];
//     let list = []
//     if (configStore.currentSelectedEntity.isOceanChecked) {
//         list.push('Ocean')
//     }
//     if (configStore.currentSelectedEntity.isAirChecked) {
//         list.push('Air')
//     }
//     if (configStore.currentSelectedEntity.isDTChecked) {
//         list.push('Domestic Transport')
//     }
//     const [selectedType, setType] = useState(list[0]);
//     const [status, setStatus] = useState("In-Progress");
//     const [searchText, setSearchText] = useState("")
//     const [open, setOpen] = useState(false);
//     const [apiState, setApiState] = useState({ data: [], isLoading: true });
//     const [pageNumber, setPageNumber] = useState(0);
//     const [lazyLoader, setLazyLoad] = useState(false);
//     const [refresh, setRefresh] = useState(false);
//     const previousSearchText = useRef("");
//     const reachedEnd = useRef(false);
//     const containerRef = useRef(null);
//     const config = useSnapshot(configStore);

//     const handleRefresh = () => {
//         setRefresh(!refresh);
//         loader.hide()
//     }

//     const searchBooking = (e) => {
//         loader.hide()
//         let str;
//         if (e.data.type === 'Ocean') {
//             str = e.data.carrierBookingRefNumber || e.data.carrierMBLNumber || e.data.containerNumber
//         } else {
//             str = e.data.MAWBNumber
//         }
//         e.data.tsUpdatedBy === 'completed' || e.data.tsUpdatedBy === 'Delivered' ? setStatus('Completed') : setStatus('In-Progress')
//         setSearchText(str)
//     }

//     useEffect(() => {
//         return () => {
//             setSearchText("");
//             setStatus("In-Progress");
//             setType(list[0])
//             // newDashboardState.selectedTab = 0;
//         }
//     }, [])


//     useEffect(() => {
//         const container = containerRef.current;
//         const handleScroll = () => {
//             if (container && !lazyLoader &&
//                 container.scrollTop + container.clientHeight >= container.scrollHeight - 80 &&
//                 !reachedEnd.current
//             ) {
//                 setPageNumber((prevPageNumber) => prevPageNumber + 1);
//             }
//         }
//         const debounced = Utils.debouncing(handleScroll, 300);
//         if (container) {
//             container.addEventListener('scroll', debounced);
//         }
//         return () => {
//             if (container) {
//                 container.removeEventListener('scroll', debounced);
//             }
//         }
//     }, [])


//     const refreshNewData = (data) => {
//         const updatedData = apiState.data.map((e) => {
//             if (e._id === data._id) {
//                 return data;
//             }
//             return e;
//         });
//         setApiState({ isLoading: false, data: updatedData })
//     }

//     const loadData = () => {
//         try {
//             if (previousSearchText.current !== searchText) {
//                 setApiState((prev) => ({ ...prev, isLoading: true }))
//             }

//             if (!reachedEnd.current) {
//                 ApiAgTable.getAllVisibility(pageNumber, 10, "", "", { bookingStatus: status === "In-Progress" ? "Shipments In-Progress" : "Completed", type: selectedType, search: searchText }).then(async (data) => {
//                     let lres = await data.json();
//                     if (previousSearchText.current !== searchText) {
//                         setApiState({ isLoading: false, data: lres.results })
//                         previousSearchText.current = searchText;
//                     } else {
//                         // setApiState((prevData) => {
//                         //     if (prevData.data.length === 0) {
//                         //         return { isLoading: false, data: lres.results };
//                         //     } else {
//                         //         const updatedData = prevData.data.map((existingItem) => {
//                         //             const newItem = lres.results.find((newItem) => newItem._id === existingItem._id);
//                         //             if (newItem) {
//                         //                 return newItem;
//                         //             } else {
//                         //                 return existingItem;
//                         //             }
//                         //         });
//                         //         lres.results.forEach((newItem) => {
//                         //             if (!prevData.data.find((existingItem) => existingItem._id === newItem._id)) {
//                         //                 updatedData.push(newItem);
//                         //             }
//                         //         });
//                         //         return { isLoading: false, data: updatedData };
//                         //     }
//                         // });
//                         setApiState((prevData) => ({ isLoading: false, data: [...prevData.data, ...lres.results] }))
//                     }
//                     setLazyLoad(false);
//                     if ((pageNumber + 1) * 10 > lres.count) {
//                         reachedEnd.current = true;
//                     } else {
//                         reachedEnd.current = false;
//                     }
//                 })
//             }
//         } catch (error) {
//             setApiState(() => ({ data: [], isLoading: true }));
//         }
//     }

//     useEffect(() => {
//         setApiState({ isLoading: true, data: [] });
//         reachedEnd.current = false
//         setPageNumber(0);
//     }, [status, config.currentSelectedEntity, config.currentSelectedBranch, selectedType, refresh])


//     useEffect(() => {
//         setLazyLoad(true);
//         reachedEnd.current = false
//         loadData();
//     }, [status, searchText, pageNumber, config.currentSelectedEntity, config.currentSelectedBranch, selectedType, refresh, visibilityStore.recheck])


//     return <>
//         <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
//             <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black" }}>Track My Bookings</Typography>
//             <TextField
//                 sx={{ width: "20%" }}
//                 variant="outlined"
//                 InputLabelProps={{ shrink: true }}
//                 value={searchText}
//                 onChange={(e) => {
//                     previousSearchText.current = searchText;
//                     setSearchText(e.target.value)
//                     setApiState({ isLoading: true, data: [] });
//                     reachedEnd.current = false
//                     setPageNumber(0);
//                 }}
//                 InputProps={{
//                     startAdornment: (
//                         <InputAdornment position="start">
//                             <CiSearch style={{ fontSize: 20 }} />
//                         </InputAdornment>
//                     ),
//                     style: { borderRadius: '57px', height: '35px', backgroundColor: "white" },
//                 }}
//                 placeholder="Search"
//             />
//         </Box>
//         <div className="w-full h-[40px] bg-blue-dark rounded-t-md flex flex-row">
//             {list.map((type,i)=><div onClick={()=>{
//                 setType(type);
//             }} className={` h-full text-white font-bold text-[18px] flex items-center justify-center w-[80px] uppercase hover:cursor-pointer ${type==="Ocean" && "rounded-tl-md"} ${type===selectedType && "bg-yellow"}`}>{type}</div>)}
//         </div>
//         <Box sx={{ display: "flex", flexDirection: "column", borderRadius: "10px", backgroundColor: "white", minHeight: "80%", height: "100%", gap: 1, padding: "10px" }}>
//             <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
//                 <Box sx={{ width: "330px", height: "40px", display: "flex", justifyContent: "flex-start", alignItems: "center" }}> <Agproducttab isGreyBackground="#F5F5F5" productList={productList} activeProduct={status} setActiveProduct={setStatus} width={'228px'} /> </Box>
//                 <Button startIcon={<img src={addIcon} />} sx={{ textTransform: "none", height: "35px",backgroundColor:'#2C358A',color:'white',"&:hover": {
//                  backgroundColor:'#2C358A',color:'white'
//                 } }} onClick={() => setOpen(true)}>Add Bookings</Button>
//             </Box>
//             <Box ref={containerRef} sx={{
//                 display: "flex", flexDirection: "column", flex: 1, gap: 2, overflowX: "auto", paddingRight: "10px", "&::-webkit-scrollbar-thumb": {
//                     backgroundColor: "#2C358A !important"
//                 }, boxShadow: "inset 0 6px 6px -4px rgba(0,0,0,0.1)"
//             }}>
//                 {
//                     apiState.isLoading ?
//                         <CenteredCircularProgress />
//                         : apiState.data && apiState.data.length > 0 ?
//                             <>
//                                 {apiState.data.map((booking) => <BookingDetailCard shipmentType={selectedType} booking={booking} refreshCb={handleRefresh} 
//                                 data={refreshNewData}
//                                  />)
//                                 }
//                                 {lazyLoader &&
//                                     <Box sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
//                                         <CircularProgress />
//                                     </Box>
//                                 }
//                             </> :
//                             <Nodata />
//                 }
//             </Box>
//             <NewBookingPopup shipmentType={selectedType} open={open} onCancel={() => {
//                 setOpen(false);
//                 trackMyBookingOceanStore.setTrackBooking()
//             }} onSubmit={(data) => {
//                 Api.addTrackBookings(data, selectedType, handleRefresh,searchBooking)
//                 setOpen(false)
//                 trackMyBookingOceanStore.setTrackBooking()
//             }} />
//         </Box>
//     </>
// }

const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    queryPayload: {}
};

export default function SelectedBooking2() {
    useSnapshot(visibilityStore)
    useSnapshot(newDashboardState)
    const productList = ["In-Progress", "Completed"];
    let list = []
    if (configStore.currentSelectedEntity.isOceanChecked) {
        list.push('Ocean')
    }
    if (configStore.currentSelectedEntity.isAirChecked) {
        list.push('Air')
    }
    if (configStore.currentSelectedEntity.isDTChecked) {
        list.push('Domestic Transport')
    }
    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
    useReducer(reducer, initialState)
    const totalPageCount = Math.ceil(totalCount / queryPageSize)
    let columns = DRS_COLUMNS
    const [selectedType, setType] = useState(list[0]);
    const [status, setStatus] = useState("In-Progress");
    const [searchText, setSearchText] = useState("")
    const [open, setOpen] = useState(false);
    const [apiState, setApiState] = useState({ data: [], isLoading: true , count:0});
    const [pageNumber, setPageNumber] = useState(0);
    const [lazyLoader, setLazyLoad] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const previousSearchText = useRef("");
    const reachedEnd = useRef(false);
    const containerRef = useRef(null);
    const config = useSnapshot(configStore);

    const handleRefresh = () => {
        setRefresh(!refresh);
        loader.hide()
    }

    const searchBooking = (e) => {
        loader.hide()
        let str;
        if (e.data.type === 'Ocean') {
            str = e.data.carrierBookingRefNumber || e.data.carrierMBLNumber || e.data.containerNumber
        } else {
            str = e.data.MAWBNumber
        }
        e.data.tsUpdatedBy === 'completed' || e.data.tsUpdatedBy === 'Delivered' ? setStatus('Completed') : setStatus('In-Progress')
        setSearchText(str)
    }

    useEffect(() => {
        return () => {
            setSearchText("");
            setStatus("In-Progress");
            setType(list[0])
            // newDashboardState.selectedTab = 0;
        }
    }, [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable({
        columns,
        data: apiState.data,
        initialState: {
            pageIndex: queryPageIndex,
            pageSize: queryPageSize,
            sortBy: queryPageSortBy,
        },
        manualPagination: true,
        pageCount: apiState.count ? totalPageCount : null,
        autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: false
    },
        useSortBy,
        usePagination,
    );

    const manualPageSize = []

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
        gotoPage(0);
    }, [pageSize, gotoPage]);

    useEffect(() => {
        if (apiState?.count) {
            dispatch({ type: TOTAL_COUNT_CHANGED, payload: apiState.count });
        }
    }, [apiState.count]);

    useEffect(() => {
        dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
        gotoPage(0);
    }, [sortBy, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAYLOAD_CHANGED, payload: { search: searchText, branchId: configStore.currentSelectedBranch._id, type: selectedType, bookingStatus: status === "In-Progress" ? "Shipments In-Progress" : "Completed" } });
        gotoPage(0);
    }, [searchText, gotoPage, selectedType, status]);


    // useEffect(() => {
    //     const container = containerRef.current;
    //     const handleScroll = () => {
    //         if (container && !lazyLoader &&
    //             container.scrollTop + container.clientHeight >= container.scrollHeight - 80 &&
    //             !reachedEnd.current
    //         ) {
    //             setPageNumber((prevPageNumber) => prevPageNumber + 1);
    //         }
    //     }
    //     const debounced = Utils.debouncing(handleScroll, 300);
    //     if (container) {
    //         container.addEventListener('scroll', debounced);
    //     }
    //     return () => {
    //         if (container) {
    //             container.removeEventListener('scroll', debounced);
    //         }
    //     }
    // }, [])


    const refreshNewData = (data) => {
        const updatedData = apiState.data.map((e) => {
            if (e._id === data._id) {
                return data;
            }
            return e;
        });
        setApiState({ isLoading: false, data: updatedData })
    }

    const firstCount = useRef(1);
    useEffect(()=>{
      
      firstCount.current += 1;
    },[configStore.currentSelectedEntity,configStore.currentSelectedBranch])

    const loadData = () => {
        try {
            // if (previousSearchText.current !== searchText) {
                setApiState((prev) => ({ ...prev, isLoading: true }))
            // }

            // if (!reachedEnd.current) {
                ApiAgTable.getAllVisibility(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload).then(async (data) => {
                    let lres = await data.json();
                    // if (previousSearchText.current !== searchText) {
                        setApiState({ isLoading: false, data: lres.results,count:lres.count })
                        // previousSearchText.current = searchText;
                    // } else {
                        // setApiState((prevData) => {
                        //     if (prevData.data.length === 0) {
                        //         return { isLoading: false, data: lres.results };
                        //     } else {
                        //         const updatedData = prevData.data.map((existingItem) => {
                        //             const newItem = lres.results.find((newItem) => newItem._id === existingItem._id);
                        //             if (newItem) {
                        //                 return newItem;
                        //             } else {
                        //                 return existingItem;
                        //             }
                        //         });
                        //         lres.results.forEach((newItem) => {
                        //             if (!prevData.data.find((existingItem) => existingItem._id === newItem._id)) {
                        //                 updatedData.push(newItem);
                        //             }
                        //         });
                        //         return { isLoading: false, data: updatedData };
                        //     }
                        // });
                        // setApiState((prevData) => ({ isLoading: false, data: lres.results }))
                    // }
                    // setLazyLoad(false);
                    // if ((pageNumber + 1) * 10 > lres.count) {
                    //     reachedEnd.current = true;
                    // } else {
                    //     reachedEnd.current = false;
                    // }
                })
            // }
        } catch (error) {
            setApiState(() => ({ data: [], isLoading: false,count:0 }));
        }
    }

    // useEffect(() => {
    //     setApiState({ isLoading: true, data: [] });
    //     reachedEnd.current = false
    //     setPageNumber(0);
    // }, [status, config.currentSelectedEntity, config.currentSelectedBranch, selectedType, refresh])


    useEffect(() => {
        setLazyLoad(true);
        // reachedEnd.current = false
        loadData();
    }, [status, pageNumber, queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload,config.currentSelectedEntity, config.currentSelectedBranch, selectedType, refresh, visibilityStore.recheck])


    return <>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black" }}>Track My Bookings</Typography>
            <TextField
                sx={{ width: "20%" }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={searchText}
                onChange={(e) => {
                    previousSearchText.current = searchText;
                    setSearchText(e.target.value)
                    setApiState({ isLoading: true, data: [] });
                    reachedEnd.current = false
                    setPageNumber(0);
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <CiSearch style={{ fontSize: 20 }} />
                        </InputAdornment>
                    ),
                    style: { borderRadius: '57px', height: '35px', backgroundColor: "white" },
                }}
                placeholder="Search"
            />
        </Box>
        <div className="w-full h-[40px] bg-blue-dark rounded-t-md flex flex-row">
            {list.map((type,i)=><div onClick={()=>{
                setType(type);
            }} className={` h-full text-white font-bold text-[18px] flex items-center justify-center w-[80px] uppercase hover:cursor-pointer ${type==="Ocean" && "rounded-tl-md"} ${type===selectedType && "bg-yellow"}`}>{type}</div>)}
        </div>
        <Box sx={{ display: "flex", flexDirection: "column", borderRadius: "10px", backgroundColor: "white", minHeight: "80%", height: "100%", gap: 1, padding: "10px" }}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <Box sx={{ width: "330px", height: "40px", display: "flex", justifyContent: "flex-start", alignItems: "center" }}> <Agproducttab isGreyBackground="#F5F5F5" productList={productList} activeProduct={status} setActiveProduct={setStatus} width={'228px'} /> </Box>
                <Button startIcon={<img src={addIcon} />} sx={{ textTransform: "none", height: "35px",backgroundColor:'#2C358A',color:'white',"&:hover": {
                 backgroundColor:'#2C358A',color:'white'
                } }} onClick={() => setOpen(true)}>Add Bookings</Button>
            </Box>
            <Box ref={containerRef} sx={{
                display: "flex", flexDirection: "column", flex: 1, gap: 2, overflowX: "auto", paddingRight: "10px", "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#2C358A !important"
                }, boxShadow: "inset 0 6px 6px -4px rgba(0,0,0,0.1)"
            }}>
                {
                    apiState.isLoading ?
                        <CenteredCircularProgress />
                        : apiState.data && apiState.data.length > 0 ?
                            <>
                                {apiState.data.map((booking) => <BookingDetailCard shipmentType={selectedType} booking={booking} refreshCb={handleRefresh} 
                                data={refreshNewData}
                                 />)
                                }
                                {/* {lazyLoader &&
                                    <Box sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                                        <CircularProgress />
                                    </Box>
                                } */}
                            </> :
                            <Nodata />
                }
            </Box>
            <ReactTablePagination
                                    page={page}
                                    gotoPage={gotoPage}
                                    previousPage={previousPage}
                                    nextPage={nextPage}
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    pageOptions={pageOptions}
                                    pageSize={pageSize}
                                    pageIndex={pageIndex}
                                    pageCount={pageCount}
                                    setPageSize={setPageSize}
                                    manualPageSize={manualPageSize}
                                    dataLength={totalCount}
                                    rows={rows}
                                />
            <NewBookingPopup shipmentType={selectedType} open={open} onCancel={() => {
                setOpen(false);
                trackMyBookingOceanStore.setTrackBooking()
            }} onSubmit={(data) => {
                Api.addTrackBookings(data, selectedType, handleRefresh,searchBooking)
                setOpen(false)
                trackMyBookingOceanStore.setTrackBooking()
            }} />
        </Box>
    </>
}